import moment from 'moment';
import React from 'react'
import Status from '../../../../components/Status/Status';
import Helper from '../../../../helper/Helper';
import './CardHistoryJobs.scss'

const CardHistoryJobs =(props)=>{

    const getStatus = () => {
        const job = props.job;

        if (!job.origin.completed)
            return 'PENDING';
        if (!job.destination.completed)
            return 'IN TRANSIT';
        return 'DELIVERED';
    }
    return(
        <>
          <div className="card mt-3 mb-3">
                <div className="card-body card-history">
                    <div className="row " >
                        <div className="col-12 d-flex fs-13 font-bold">
                            <div className="col-6 p-2">
                                <span>
                                    {Helper.FORMAT.USDate(props.job.destination.time_completed)}
                                </span>
                            </div>
                            <div className="col-6 d-flex justify-content-end p-2">
                                <span>{Helper.FORMAT.USCurrency(props.job.driver_pay)} / <span className={props.job.remaining_driver_payable > 0 ? 'text-red' : ''}>{Helper.FORMAT.USCurrency(props.job.remaining_driver_payable)}</span></span>
                            </div>
                        </div>
                        <div className="col-12 d-flex ">
                            <div className="col-6 p-2">
                                <div className="d-flex flex-column">
                                    <span className="fs-13 font-bold">{props.job.vehicle.vin || '-'}</span>
                                    <span className="font-light fs-12">{props.job.vehicle.year} {props.job.vehicle.make} {props.job.vehicle.model}</span>
                                </div>
                            </div>
                            <div className="col-6 d-flex justify-content-end p-2">
                                <div className="d-flex align-items-center">
                                    {/* <span className="btn rounded-pill fs-9 font-medium no-select btn-danger-outline">VIEW INSPECTION</span> */}
                                    <Status statusName={getStatus()} />
                                </div>
                            </div>
                        </div>
                     {/*    <div className="col-12  d-flex">
                            <div className="col-6 p-2">
                                <div className="d-flex justify-content-between fs-10 font-bold">
                                    <span>Photos</span>
                                    <span>100 %</span>
                                </div>
                                <div className="w-100  progress-content">
                                    <div className="progress-bar" style={{width:'100%'}}></div>
                                </div>
                            </div>
                            <div className="col-6 p-2">
                                <div className="d-flex justify-content-between fs-10 font-bold">
                                    <span>Data</span>
                                    <span>10 %</span>
                                </div>
                                <div className="w-100 progress-content">
                                    <div className="progress-bar" style={{width:'10%'}}></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardHistoryJobs;