import gql from "graphql-tag";

const GET_TEMP_LINK = gql`
    query ($key:String, $minutes:Int, $is_thumbnail:Boolean, $uid: String){
      get_temp_link(key:$key minutes:$minutes is_thumbnail:$is_thumbnail uid: $uid)
    }
`;

const GET_TEMP_LINK_NEW_INSPECTIONS = gql`
  query ($is_thumbnail:Boolean, $uid: String){
    get_temp_link_new_inspections(is_thumbnail:$is_thumbnail uid: $uid)
    }
`;

const S3Queries = {
    GET_TEMP_LINK,
    GET_TEMP_LINK_NEW_INSPECTIONS
};

export default S3Queries;