import React, { useRef, useState } from "react";
import { DashboardLayout } from "../../layout/DashboardLayout/DashboardLayout";
import { Route, Switch, useLocation } from "react-router-dom";
import DashboardPage from "../DashboardPage/DashboardPage";
import OrderRequestsPage from "../OrderRequestsPage/OrderRequests";
import CarrierProfilePage from "../CarrierProfile/CarrierProfilePage";
import JobsPage from "../JobsPage/JobsPage";
import { useDispatch, useSelector } from "react-redux";
import { AllStates } from "../../redux/reducers";
import { INotifications } from "../../redux/reducers/Notifications.reducer";
import NotificationsPage from "../NotificationsPage/NotificationsPage";
import getAllNotifications, {
  setLastID,
} from "../../redux/actions/Notifications.action";
import DispatchPage from "../DispatchPage/DispatchPage";
import { useEffect } from "react";
import getOrders from "../../redux/actions/Order.action";
import getOrderRequestByStatus from "../../redux/actions/OrderRequests.action";
import { useToasts } from "react-toast-notifications";
import Helper from "../../helper/Helper";
import DriversPage from "../DriversPage/DriversPage";
import { CarrierDisclaimerPage } from "../CarrierDisclaimer/CarrierDisclaimerPage";
import { CarrierRegistrationInsurancePage } from "../CarrierRegistrationInsurancePage/CarrierRegistrationInsurancePage";
import { CarrierRegistrationPage } from "../CarrierRegistrationPage/CarrierRegistrationPage";
import { CarrierTermsConditionsPage } from "../CarrierTermsAndConditions/CarrierTermsConditionsPage";
import InspectionReport from "../InspectionReport/InspectionReport";
import PendingInspectionPage from "../PendingInspectionPage/PendingInspectionPage";
import { GraphqlService } from "../../services/graphql.service";
import DriverSignature from "../../components/DriverSignature/DriverSignature";
import AuthService from "../../services/auth.service";
import ChangeRequestPage from "../ChangeRequestPage/ChangeRequestPage";
import DriverPage from "../DriverPage/DriverPage";
import environments from "../../../environments/environments";
import CarrierDocumentsPage from "../CarrierDocumentsPage/CarrierDocumentsPage";
import { checkDocument } from "apollo-utilities";
import { checkDocuments } from "../../redux/actions/Documents.action";

const MainPage = () => {
  const { data, last_id } = useSelector<AllStates>(
    (state) => state.notifications
  ) as INotifications;
  const [myNotifications, setMyNotifications] = useState([]);
  const toast = useToasts();
  const hasLoaded = useRef(false);
  const dispatch = useDispatch();

  const { pathname } = useLocation();


//   useEffect(() => {
//     if (localStorage.getItem("driver_token")) checkSignature();
//   }, []);

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      !pathname.startsWith("/inspection-report") &&
      !pathname.startsWith("/pending-inspection")
    ) {
      if (
        !localStorage.getItem("profile") ||
        localStorage.getItem("driver_token")
      ) {
        Helper.Session.DoLogout(true);
      }

      try {
        const profile = JSON.parse(localStorage.getItem("profile"));
        AuthService.PROFILE = profile;
        AuthService.TOKEN = localStorage.getItem("token");
        console.log(pathname != "/carrier-documents");
        if (pathname != "/carrier-documents") dispatch(checkDocuments());
      } catch (ex) {
        console.log("out from here 2");
        Helper.Session.DoLogout(true);
      }

      dispatch(setLastID(+(localStorage.getItem("last_id") || "0")));
      if (environments.enableNotificationsListener)
        dispatch(getAllNotifications());
      dispatch(getOrders());
      dispatch(getOrderRequestByStatus("all"));
    }
  }, []);

  return (
    <>
        <Switch>
          <Route path="/">
            <DashboardLayout>
              <Switch>
                <Route exact path="/" component={DispatchPage} />
                <Route path="/dashboard" component={DashboardPage} />
                <Route path="/jobs" component={JobsPage} />
                <Route path="/dispatch" component={DispatchPage} />
                <Route path="/order-requests" component={OrderRequestsPage} />
                <Route path="/map" component={DriversPage} />
                <Route path="/notifications" component={NotificationsPage} />
                <Route path="/carrier/profile" component={CarrierProfilePage} />
                <Route
                  path="/carrier-documents"
                  component={CarrierDocumentsPage}
                />
                <Route path="/drivers" component={DriverPage} />
                <Route
                  path="/change-request/:code"
                  component={ChangeRequestPage}
                />
              </Switch>
            </DashboardLayout>
          </Route>
        </Switch>
    </>
  );
};

export default MainPage;
