import './DispatchDrivers.scss';
import Status from "../Status/Status";
import {createRef, useEffect, useRef, useState} from "react";
import ICONS from "../../../assets/svg";
import MenuOption from "../MenuOption/MenuOption";
import MenuOptionItem from "../MenuOptionItem/MenuOptionItem";
import { GraphqlService } from '../../services/graphql.service';
import DriverQueries from '../../graphql/query/Driver';
import Loading from '../Loading/Loading';
import { useToasts } from 'react-toast-notifications';

const itinerary = [
    { line_style: '',       pickup_count: 1, state: 'CA', dropoff_count: 0 },
    { line_style: '',       pickup_count: 1, state: 'CA', dropoff_count: 1 },
    { line_style: 'dashed', pickup_count: 0, state: 'OR', dropoff_count: 1 },
    { line_style: '',       pickup_count: 1, state: 'OR', dropoff_count: 0 },
    { line_style: 'none',   pickup_count: 0, state: 'CA', dropoff_count: 1 }
]

interface Drivers {
    // back end properties

    // UI properties
    id: number;
    selected: boolean;
}



const DispatchDrivers = ({onDriverClicked, driverSelected = null, modal = false}) => {
    const [drivers, setDrivers] = useState<any[]>([]);

    const [loading, setLoading] = useState(false);
    
    const [driverMenuSelected, setDriverMenuSelected] = useState(null);

    // for right click
    const myRefs = useRef([]);
    myRefs.current = drivers.map((element, i) => myRefs.current[i] ?? createRef());

    useEffect(() => {
        // setDrivers([1, 2, 3, 4].map(e => {
        //     return { id: e, selected: false };
        // }));
        loadDrivers();
        
    }, []);


    async function loadDrivers() {
        try {
            setLoading(true);
            const data = await GraphqlService.SendQuery(DriverQueries.GET_SCHEDULED_LIST);
            
            let cont = 0;
            const array = [...data].map(x => {
                const temp = JSON.parse(JSON.stringify(x));


                temp.driver.itinerary = temp.driver.itinerary.map((it, index) => {
                    // if ()
                    let pickup_count = 0;
                    let dropoff_count = 0;

                    it.orders.forEach(o => {
                        pickup_count += o.is_pickup ? 1 : 0;
                        dropoff_count += o.is_pickup ? 0 : 1;
                    });

                    cont += pickup_count - dropoff_count;
                    const _it = {...it};
                    _it.pickup_count = pickup_count;
                    _it.dropoff_count = dropoff_count;
                    _it.line_style = index == temp.driver.itinerary.length - 1 ?  'none' :
                        cont == 0 ? 'dashed' : 'solid';

                    return _it;
                })
                return temp;
            });

            if (!modal)
            {
                if (driverSelected)
                {
                    let d  = array.find(x => x?.driver.id == driverSelected);
                    onDriverClicked(d?.driver);
                }
            }
            setDrivers(array);
            setLoading(false);
            // console.log(array);
        } catch (ex) {
            setLoading(false);
            console.log('ex', ex.message);
        }
    }

    const updateDrivers = (driver: Drivers, unSelectAll = false) => {
        let temp = [...drivers];
        if (unSelectAll)
            temp = temp.map(e => { return { ...e, selected: false }; });

        let index = drivers.findIndex(e => e.driver.id == driver.id);
        temp[index] = {...driver};
        console.log(temp);

        setDrivers([...temp]);
    }

    const handleContextMenu = (evt, index, driver: Drivers) => {
        evt.preventDefault();
        evt.stopPropagation();

        setDriverMenuSelected(driver);
    }

    const handleCloseContextMenu = (index, driver: Drivers) => {
        setDriverMenuSelected(null);
    }

    return(
        <>
            <div className="col-12 flex-1-container">
            <div className='mb-3'>

<p className="font-14 font-medium">Drivers{loading ? '' : ` (${drivers.length})`}</p>
</div>

<div className="flex-1-container">

{loading ? <Loading /> :


drivers.map((driver, index) => {
return(
<div key={driver.driver.id}
ref={myRefs.current[index]}
onClick={(evt) => onDriverClicked(driver.driver)}
onContextMenu={(evt) => { handleContextMenu(evt, index, driver) }}
className={`card cursor-pointer position-relative mb-3 ${driver?.driver?.id == driverMenuSelected?.driver?.id ? 'active-card' : ''} ${driver?.driver.id == driverSelected ? 'bg-gold' : 'bg-gray'}`}>

{
<MenuOption handleMenuOptionClick={() => {handleCloseContextMenu(index, driver)}}
        nodeContainer={myRefs.current[index]}
        visible={driver.driver.id == driverMenuSelected?.driver?.id}>

{/* <MenuOptionItem icon={ICONS.IconDispatch} handleItemClick={() => {}}>
    Dispatch to this driver
</MenuOptionItem> */}
{/* <MenuOptionItem icon={ICONS.IconItinerary} handleItemClick={() => {}}>
    See driver itinerary
</MenuOptionItem> */}
<MenuOptionItem icon={ICONS.IconEdit} handleItemClick={() => {}}>
    Edit driver details
</MenuOptionItem>
<MenuOptionItem icon={ICONS.IconDeactivate} handleItemClick={() => {}}>
    Deactivate driver
</MenuOptionItem>
</MenuOption>
}

<div className="card-body">
<div className="d-flex justify-content-between align-items-center">
<div>
    <p className="font-12 font-medium m-0">{driver.driver.name}</p>
    <p className="font-10 m-0">{driver.driver.equipment}</p>
</div>

<div className="text-end">
    <Status statusName={driver.driver.status.text}/>
</div>
</div>

<div className="row">
<div className="col-8">
    <div className="line-container">
        {
            driver.driver.itinerary.map(driver => {
                return (
                    <div className={`line ${driver.line_style === 'dashed' ? 'dashed' : ''} ${driver.line_style === 'none' ? 'none' : ''}`}>
                        <div className="points">
                            <div className="points-container">
                                {driver.state != '' && <span className="states">{driver.state}</span>}
                                {driver.pickup_count > 0 && <span className="orders">{driver.pickup_count}</span>}

                                <div className={`d-flex triangles ${driver.dropoff_count && driver.pickup_count ? 'center-triangles' : ''}`}>
                                    {driver.dropoff_count > 0 && <div className="red-triangle"></div>}
                                    {driver.pickup_count > 0 && <div className="green-triangle"></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
    </div>
</div>

<div className="col-4 text-end">
    <p className="font-10 font-medium mt-2">{driver?.equipment||'-'}</p>
</div>
</div>
</div>
</div>
);
})
}

</div>
            </div>
        </>
    );
}

export default DispatchDrivers;
