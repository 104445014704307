import {Destination} from "../../classes/classes";

const LegDetailDestination = ({destination}: {destination: Destination}) => {
    return(
        <>
            <div className={`card bg-gray`}>
                <div className="card-body p-3">
                    <div className="row">
                        <div className="col-6">
                            <p className="font-14 m-0 font-medium">{destination?.address?.street}</p>
                            <p className="font-12 m-0">{destination?.address?.getName()}</p>
                        </div>

                        <div className="col-6">

                            <div className="card">
                                <div className="card-body p-3">

                                    {
                                        destination?.contacts?.map(contact => {
                                            return(
                                                <>
                                                    <p className="font-12 m-0 font-medium">{contact.name}</p>
                                                    <p className="font-12 mb-2 font-italic">Admin</p>

                                                    {
                                                        contact?.value?.map(value => <p key={value}
                                                                                        className="font-12 m-0">{value}</p>)
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default LegDetailDestination;
