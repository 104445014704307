import React,{useState,useRef} from 'react'
import ICONS from "../../../assets/svg";
import './CarrierServiceRequestsSign.scss';
import CardServiceTermCond from '../CardServiceTermCond/CardServiceTermCond';
import DriveInfoForm from '../DriveInfoForm/DriveInfoForm'
import FormGroup from '../FormGroup/FormGroup';
import BrokerCarrierAgreement from '../BrokerCarrierAgreement/BrokerCarrierAgreement';

type CarrierServiceRequestsSignProps={
  handleClickBackDetails:()=>void,
  handleClickDecline:()=>void,
  handleClickAccept:(name: string)=>void,
  order_id: number;
  
}


const CarrierServiceRequestsSign =(props:CarrierServiceRequestsSignProps)=>{

    const [name, setName] = useState('');
    return(
        <div className='flex-1-container'>
  
            <div className="col-12 cursor-pointer" onClick={()=>props.handleClickBackDetails()}>
              <img src={ICONS.IconArrowSkyBlue} alt="" />
              <span className="font-12 font-medium-500" style={{color:"#42ACDE"}} >Back to details</span>
            </div>

            <div className="flex-1-container">
                <div className="col-12 mt-4">
                <span  className="font-14 font-medium-500">Broker Agreement</span>
                </div>
            
                <div className="mb-3 mt-1">
                    <BrokerCarrierAgreement order_id={props.order_id} />
                </div>
            
                <div className="d-flex">
                    <FormGroup placeholder='Name..' name='name' label={'Name'} required value={name} onTextChange={(evt) => setName(evt)} />
                </div>
            </div>
            <div className="row mt-3">

            <div className="col-6 ">
                <button onClick={()=>props.handleClickDecline()}   className="btn btn-skyblue-outline w-100">
                    DECLINE
                </button>
            </div>

            <div className="col-6 ">
                <button disabled={!name.trim()} onClick={()=>props.handleClickAccept(name)} className="btn btn-skyblue w-100">
                    ACCEPT REQUEST
                </button>
            </div>

            </div>

            
          
        </div>
    )
}

export default CarrierServiceRequestsSign