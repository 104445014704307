import React,{useState,useEffect} from 'react'
import Helper from '../../helper/Helper';   
import FormGroup from '../FormGroup/FormGroup';
import  {Carrier, CarrierMain} from '../../classes/classes'
import DateTimePicker from '../DateTimePicker/DateTimePicker';
import ReactModal from 'react-modal';
import moment from 'moment';
import ICONS from '../../../assets/svg';


const InsuranceInfoForm =({onCancel,onSubmit,data, uploadFile = (key) => {}, removeFile = (key, document) => {}, previewFile = (document) => {}})=>{
    const [carrier, setCarrier] = useState <CarrierMain>(new CarrierMain());
    const [openModal,setOpenModal]=useState(false)
   
    const onHandelSubmit=()=>{
        onSubmit(new CarrierMain(carrier))
    }

    useEffect(() => {
        setCarrier(data);
    }, [data]);


    return(
        <>
            <div className="flex-1-container">

                <div className="col-12 mb-3 d-flex row">
                    <span className="font-14 font-medium mb-0" >Edit insurance info</span> 
                    <span onClick={onCancel} className="font-14 cursor-pointer font-medium mb-0" ><i className='fas fa-times'></i></span> 
                </div>


                <div className="flex-1-container">
                    <div className={`row `}>

                        <div className="col-12 mb-3 d-flex row align-items-center">
                            <span className="fs-14 font-medium mb-0" >Liability coverage</span> 
                            {/* <span className="text-gray fs-10 font-medium cursor-pointer no-select">CLEAR ALL</span> */}
                        </div>

                        <FormGroup  name={'liabilityCoverage'}
                                    value={`${carrier.carrier_insurance.liability_coverage}`}
                                    required={true}
                                    colSize={6}
                                    type={'text'}
                                    errorText={'Value must be greater than zero'}
                                    validation={x => +Helper.Masks.IntNumbers(x) > 0}
                                    label={'Liability coverage'}
                                    moneySymbol
                                    noDecimal
                                    placeholder={"0"}
                                    onTextChange={value => {
                                        let x = +Helper.Masks.DecimalNumbers(value);
                                        setCarrier({ ...carrier, carrier_insurance : {...carrier?.carrier_insurance , liability_coverage : x }})
                                    }}
                                    />

                        <FormGroup name={'expirationDate'}
                         onClick={(evt) => setOpenModal(true)}
                                    className={"position-relative cursor-pointer"}
                                    inputClassName='cursor-pointer'
                                    readOnly
                                    validation={x => new Date(x) > new Date()}
                                    errorText={'Expiration date must be greater than today'}
                                    hintText={'Expiration date'}
                                    value={carrier.carrier_insurance.expiration ? Helper.FORMAT.USDate(carrier.carrier_insurance.expiration, 'do') : '-'}
                                    required={true}
                                    colSize={6}
                                    label={'Expiration date '}
                                    placeholder={"00 / 00 / 0000"}
                                    onTextChange={ value =>{           
                                        //console.log()
                                    }} 
                                    >
                                        <i className="far fa-calendar-alt"></i>
                        </FormGroup> 

                        <div className="row col-12">
                        <FormGroup  name={'Cargo_coverage'}
                                    value={`${carrier.carrier_insurance.damage_coverage}`}
                                    required={true}
                                    errorText={'Value must be greater than zero'}
                                    validation={x => +Helper.Masks.IntNumbers(x) > 0}
                                    type={'text'}
                                    colSize={6}
                                    moneySymbol
                                    noDecimal
                                    label={'Cargo coverage'}
                                    placeholder={'0'}
                                    onTextChange={value => {
                                            value = Helper.Masks.DecimalNumbers(value);
                                            // let e = parseFloat(value as any);
                                            // let amount = 0;
                                            // if (!isNaN(e)) amount = e
                                            setCarrier({...carrier,carrier_insurance :{...carrier?.carrier_insurance ,damage_coverage : value as any }})

                                        }}
                                    />

                        <FormGroup  name={'deductible'}
                                    value={`${carrier.carrier_insurance.deductible}`}
                                    required={true}
                                    colSize={6}
                                    type={'text'}
                                    errorText={'Value must be greater than zero'}
                                    validation={x => +Helper.Masks.IntNumbers(x) > 0}
                                    label={'Deductible'}
                                    moneySymbol
                                    noDecimal
                                    placeholder={'0'}
                                    onTextChange={value => {
                                        value = Helper.Masks.DecimalNumbers(value);
                                        // let e = parseFloat(value as any);
                                        // let amount = 0;
                                        // if (!isNaN(e)) amount = e
                                        setCarrier({...carrier,carrier_insurance :{...carrier?.carrier_insurance , deductible : value as any }})
                                    }}
                                    />
                        </div>


                        <FormGroup  name={'insuranceCompanyName'}
                                    value={carrier.carrier_insurance.name}
                                    required={true}
                                    label={'Insurance company name'}
                                    placeholder={"Company Name..."}
                                    onTextChange={value => setCarrier({...carrier, carrier_insurance : {...carrier?.carrier_insurance, name :value}})}
                                    />

                        <FormGroup  name={'Policynumber'}
                                    value={carrier.carrier_insurance.policy_number}
                                    required={true}
                                    label={'Policy #'}
                                    placeholder={"XXXXXXXX"}
                                    onTextChange={value => setCarrier({...carrier, carrier_insurance : {...carrier?.carrier_insurance, policy_number :value}})}
                                    />

                        <FormGroup  name={'agentName*'}
                                    value={carrier.carrier_insurance.agent.name}
                                    required={true}
                                    label={'Agent name'}
                                    placeholder={"Agent name"}
                                    onTextChange={value => setCarrier({...carrier, carrier_insurance : {...carrier?.carrier_insurance , agent :{ ...carrier?.carrier_insurance.agent ,name :value }}})}
                                    />

                        <FormGroup  required hintText={'Phone number must be xxx-xxx-xxxx'}
                                    errorText={'Phone number must be xxx-xxx-xxxx'}
                                    validation={x => x.length == 12} 
                                    colSize={12}
                                    name={'agentPhone'}
                                    label={'Agent phone #'}
                                    placeholder={"XXX-XXX-XXXX"}
                                    value={carrier.carrier_insurance.agent.phone} 
                                    onTextChange={(value) => {
                                    let temp = Helper.Masks.USPhone(value);
                                    setCarrier({...carrier, carrier_insurance : {...carrier?.carrier_insurance , agent :{ ...carrier?.carrier_insurance.agent ,phone :temp }}})
                                    }}
                                    />
                                    

                        <FormGroup  name={'Agent_email_address'}
                                    label={'Agent Email Address'}
                                    required
                                    colSize={12}
                                    value={carrier.carrier_insurance.agent.email}
                                    className={'mt-3'}
                                    validation={Helper.Validators.IsValidEmail}
                                    type={'email'}
                                    placeholder={'abc@xyz.com'}
                                    onTextChange={value => setCarrier({...carrier, carrier_insurance : {...carrier?.carrier_insurance , agent :{ ...carrier?.carrier_insurance.agent ,email :value }}})}
                                    />

<FormGroup  name={'Agent_fax'}
                                    label={'Agent Fax (Optional)'}
                                    
                                    colSize={12}
                                    value={carrier.carrier_insurance.agent.fax}
                                    className={'mt-3'}
                                    type={'text'}
                                    placeholder={'XXXX-XXX-XXXX'}
                                    onTextChange={value => setCarrier({...carrier, carrier_insurance : {...carrier?.carrier_insurance , agent :{ ...carrier?.carrier_insurance.agent ,fax : Helper.Masks.USPhone(value) }}})}
                                    />


                            


                    </div>
                    <div
                                            className="col-12 d-flex justify-content-between align-items-center my-2">
                                            <span className={`label-span`}>Files</span>
                                        </div>
                                        <div className="col-12">
                                            {carrier.carrier_insurance.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => uploadFile('insurance')}>Upload a document showing your insurance</h4>}
                                            {carrier.carrier_insurance.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier.carrier_insurance.uploaded_documents.length - 1 ? '' : ' mb-3')}> 
                                                <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} onClick={(evt) => previewFile(document)} className="font-12 cursor-pointer">{document.name}</h4>
                                                <div className="d-flex">
                                                    {/* <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                                        <i className='fas fa-eye'></i>
                                                    </h4> */}
                                                    <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('insurance', document)}>
                                                        <i className='fas fa-times'></i>
                                                    </h4>
                                                </div>
                                            </div>)}
                                            <div className='justify-content-end align-items-center cursor-pointer row' onClick={(evt) => uploadFile('insurance')}>
                                                <i className='fas fa-plus edit font-light mr-2'></i>
                                                <span  className={`edit`}>Upload</span>
                                            </div>
                                        </div>
                </div>


                <div className="row mt-3 d-flex ">

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill text-light-blue"
                        onClick={onCancel}  >CANCEL</button>
                    </div>


                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill"
                        onClick={()=>onHandelSubmit()} >
                        SAVE CHANGES
                        </button>
                    </div>

                </div>
            </div>




            {openModal && <DateTimePicker date={carrier.carrier_insurance.expiration} 
                                    onCancelClicked={()=>setOpenModal(false)}
                                    onDateSubmitted={(e)=>{
                                        let temp = new Date(e)

                                        setCarrier({...carrier , carrier_insurance : {...carrier?.carrier_insurance , expiration : temp }})
                                        setOpenModal(false)
                                        }}
                                    />}
        </>
    )
}

export default  InsuranceInfoForm