import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Helper from '../../../../helper/Helper';
import Content from '../../components/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import { PendingInspection } from '../../../../classes/classes';
import InspectionObservable from '../../observables/InspectionObservable';
import { useToasts } from 'react-toast-notifications';
import {gql} from 'apollo-boost';
import { GraphqlService, ObservableQueryT } from '../../../../services/graphql.service';
import CardHistoryJobs from '../../components/CardHistoryJobs/CardHistoryJobs';
import ICONS from '../../../../../assets/svg';
import CardHistoryInspections from '../../components/CardHistoryInspections/CardHistoryInspections';
import useScreenDimension from '../../../../hooks/ScreenDimension/ScreenDimension';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Jobs from './Jobs/Jobs';
import Inspection from './Inspections/Inspections';
import DateTimePicker from '../../../../components/DateTimePicker/DateTimePicker';
import { Subscription } from 'rxjs';
import moment from 'moment';

const queryJobs = gql`
    query($from_date: String!, $to_date: String!){
  get_driver_orders(from_date: $from_date, to_date: $to_date){
    id
    vehicle{
      year
      make
      model
      vin
    }
    destination{
      time_completed
      completed
      scheduled
    }
    origin{
      completed
      scheduled
      time_completed
    }
    remaining_driver_payable
    driver_pay
    completed
  }
}
`;
const queryInspections = gql`
    query {
  get_inspection_progress {
    id
    order_id
    is_pickup
    progress
    uploading
    uploadedData
    uploadedSignature
    uploadedPhotos
    vehicle
    address
    vin
  }
}
`;

const HistoryStatus =[
    {
        label:"Jobs",
        value:"JOBS"
    },
    {
        label:"Inspections",
        value:"INSPECTIONS"
    }
]


const HistoryPage = () => {
    const {token} = useParams<any>();
    
    const [historyStatus,setHistoryStatus]=useState(HistoryStatus[0].value)

    const [inspections, setInspections] = useState([]);

    const {height} = useScreenDimension();

    const [pickerOpen, setPickerOpen] = useState(false);
    
    const [fromDate, setFromDate] = useState(new Date(moment().add(-1, 'day').format('YYYY/MM/DD 00:00:00')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')));


    const queryJobsRef = useRef<ObservableQueryT>();
    const queryJobsObsRef = useRef<Subscription>();

    const queryInspRef = useRef<ObservableQueryT>();
    const queryInspObsRef = useRef<Subscription>();
    
    const toast = useToasts();

    const [jobs, setJobs] = useState([]);

    async function handleRefresh() {

    }


    useEffect(() => {
        if (token)
        {
            localStorage.removeItem('carrier_token');
            localStorage.setItem('driver_token', token);
            Helper.Navigation.NavigateTo('/history');
        }
        else
        {
            loadInspections();
            loadJobs();
            return () => {
                queryInspObsRef.current?.unsubscribe();
                queryJobsObsRef.current?.unsubscribe();
                queryInspRef.current = null;
                queryJobsRef.current = null;
            }
        }
    }, [token]);
    


    function loadInspections() {
        queryInspRef.current = GraphqlService.SendQueryObservable(queryInspections);
        queryInspObsRef.current = queryInspRef.current.onResults.subscribe(({data, error}) => {
            if (error)
                console.log(error.message);
            else
            {
                // console.log(data)
                setInspections(data);
            }
        })

        
    }

    function loadJobs(from?, to?) {
        if (!from)
        {
            from = new Date(fromDate);
            to = new Date(toDate);
        }
        else
        {
            setFromDate(from);
            setToDate(to);
        }

        if (queryJobsObsRef.current)
        {
            queryJobsObsRef.current.unsubscribe();
            queryJobsRef.current = null;
        }

        queryJobsRef.current = GraphqlService.SendQueryObservable(queryJobs, {from_date: from, to_date: to});
        queryJobsObsRef.current = queryJobsRef.current.onResults.subscribe(({data, error}) => {
            if (error)
                console.log(error.message);
            else
            {
                console.log(data)
                setJobs(data);
            }
        })

        
    }
    

    return <>
                {/* <Toolbar vehicle={currentData.vehicle_data} /> */}
                
               <div className='d-flex' style={{height: `${height}px`}}>
                   
              
               <Content className=''>
                    <div className="mb-0 p-3 bg-white">
                        <h4 className="font-18 font-bold mb-4">History</h4>

                        <div className="col-12 bg-white d-flex align-items-center justify-content-evenly">
                            {
                                HistoryStatus.map((e, index) => {
                                    return <p key={index}
                                            className={`d-flex m-0 py-2 px-4 font-14 font-medium order-item ${e.value === historyStatus ? 'item-selected-red' : ''}`}
                                            onClick={() => setHistoryStatus(e.value)}>{e.label}</p>
                                })
                            }
                        </div>


                    </div>
                    <div className='flex-1-container'>

                            <PullToRefresh isPullable={false} className='flex-1-container' onRefresh={handleRefresh}>
                                <>
                           
                
                            <div className="flex-1-container">
                                <div className="col-12 flex-1-container">
                                    {
                                        historyStatus === "JOBS" && <>
                                            <div className="flex-1-container">
                                                <div className="d-flex justify-content-end bg-white p-3">
                                                    <h4 onClick={evt => setPickerOpen(true)} className="font-10"><i className="far fa-calendar-alt mr-1"></i> {moment(fromDate).format('MM/DD/YYYY')} - {moment(toDate).format('MM/DD/YYYY')}</h4>
                                                </div>
                                                <div className="flex-1-container py-3 px-2">
                                                    <div className="flex-1-container px-2">
                                                        <Jobs jobs={jobs} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        historyStatus === "INSPECTIONS" && <>


                                            <div className="flex-1-container py-3 px-2">
                                                <div className="flex-1-container px-2">
                                                    <Inspection inspections={inspections} />
                                                </div>
                                            </div>
                                        </>

                                    }
                                </div>
                            </div>
                                </>
                            </PullToRefresh>
                    </div>
                </Content>
               </div>

               {pickerOpen && <DateTimePicker hideTime date={[fromDate, toDate]} modal onCancelClicked={(evt) =>setPickerOpen(false)} onDateSubmitted={(evt) => {
                (evt[0] as Date).setHours(0, 0, 0);
                (evt[1] as Date).setHours(23, 59, 59);
                console.log(evt);
                loadJobs(evt[0], evt[1]);
                setPickerOpen(false);
        }} />}
            </>
}

export default HistoryPage;