import React, {useRef, useState} from "react";
import './DispatchTable.scss';
import Searchbar from "../Searchbar/Searchbar";
import ICONS from "../../../assets/svg";
import IColumn from "../Table/models/IColumn";
import Table from "../Table/Table";
import IMenuOption from "../Table/models/IMenuOption";
import { IOrderInternal } from "../../redux/reducers/Order.reducer";
import { AllStates } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import getOrders from "../../redux/actions/Order.action";
import ReactModal from 'react-modal';
import Helper from "../../helper/Helper";
import DispatchDrivers from "../DispatchDrivers/DispatchDrivers";
import FormGroup from "../FormGroup/FormGroup";
import { GraphqlService } from "../../services/graphql.service";
import gql from "graphql-tag";
import Loading from "../Loading/Loading";
import { useToasts } from "react-toast-notifications";
import Modal from "../Modal/Modal";
import LegDetail from "../LegDetail/LegDetail";

const DispatchTable = ({onRefreshStarted, onRowClicked}) => {
    const DISPATCH_TABS = {
        ALL: 1,
        NOT_DISPATCHED: 2,
        DISPATCHED: 3
    };

    const searchBarRef = useRef();
    const [tab, setTab] = useState(DISPATCH_TABS.ALL);
    const dispatch = useDispatch();




    const [dispatchOpened, setDispatchOpened] = useState(false);
    const [orderDispatch, setOrderDispatch] = useState(null);
    const [showLegDetailModal, setShowLegDetailModal] = useState(undefined);

    const [orderUndispatch, setOrderUndispatch] = useState(null);
    const [orderAssign, setOrderAssign] = useState(null);

    const [dispatchInfo, setDispatchInfo] = useState({price: '', driver_id: null, total: 0});



    const toast = useToasts();

    const columns: IColumn[] = [
        { name: 'formattedId',              label: 'ORDER #',       active: false, type: 'html',     sort_by: 'id',                 orderBy: 'ASC' },
        { name: 'status',                   label: 'STATUS',        active: false, type: 'badge',    sort_by: 'status',             orderBy: 'ASC' },
        { name: 'formattedDriver',          label: 'DRIVER',        active: false, type: 'html',     sort_by: 'driver_name',        orderBy: 'ASC' },
        { name: 'formattedVehicleName',     label: 'VEHICLE',       active: false, type: 'html',     sort_by: 'vehicle_name',       orderBy: 'ASC' },
        { name: 'formattedOrigin',          label: 'ORIGIN',        active: false, type: 'html',     sort_by: 'origin_name',        orderBy: 'ASC' },
        { name: 'formattedDestination',     label: 'DESTINATION',   active: false, type: 'html',     sort_by: 'destination_name',   orderBy: 'ASC' },
    ];



    const options: IMenuOption[] = [
        { label: 'Dispatch',             icon: ICONS.IconDispatch,         condition: row => !row.dispatched,                                             action: (row: any) => {setOrderDispatch(row.id); setDispatchInfo({total: row.price, driver_id: null, price: ''});} },
        { label: 'Change driver',        icon: ICONS.IconDriver,           condition: row => row.dispatched,                                              action: (row: any) => {setOrderAssign(row.id); setDispatchInfo({total: row.price, driver_id: null, price: (row.driver_pay || '') + ''}); } },
        { label: 'Undispatch',           icon: ICONS.IconDeactivate,       condition: row => row.dispatched,                                              action: (row: any) => {setOrderUndispatch(row.id);  setDispatchInfo({total: row.price, driver_id: null, price: ''}); } },
        { label: 'See order details',      icon: ICONS.IconDetails, action: (row) => {
                handleSeeLegDetails(row)
            }}
    ];

    const ordersOpen = useSelector<AllStates>(x => x.orders.open) as IOrderInternal;
    const ordersCompleted = useSelector<AllStates>(x => x.orders.completed) as IOrderInternal;
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    const getOpens = (item) => {
        if (!item) return [];

        let arrayItem = item.data.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
        return arrayItem.map(element => {
            return {
                ...element,
                formattedId: element.getFormattedID(),
                formattedDriver: element.getDriver(),
                formattedVehicleName: element.getVehicleName(),
                formattedOrigin: element.getOrigin(),
                formattedDestination: element.getDestination(),
                formattedPrice: element.getPrice(),
            };
        });
    }

    const getOrdersByStatus = (key = '') => {
        // console.log(ordersOpen.data)
        if (!key || key == 'ALL')
            return getOpens(ordersOpen);

        let dispatched = key == 'DISPATCHED';

        return getOpens(ordersOpen).filter(x => x.dispatched == dispatched);
    }

    const getCount = (key = '') => {
        if (!key || key == 'ALL')
            return ordersOpen.data;

        let dispatched = key == 'DISPATCHED';

        return ordersOpen.data.filter(x => x.dispatched == dispatched);
    }

    const handleSeeLegDetails = (row) => {
        setShowLegDetailModal(row);
    }
    

    async function onSendDispatchAction()
    {

        setLoading(true);
        const mutation = orderUndispatch ? gql`
               mutation($order_id: Int){
                    unassign_driver(order_id: $order_id){
                        message
                        success
                }
            }
        ` : gql`
        
        mutation($order_id: Int, $driver_id: Int, $driver_pay: Float){
            assign_driver(order_id: $order_id, driver_id: $driver_id, driver_pay: $driver_pay){
                message
                success
            }
        }`;

        const variables: any = {
            order_id: (orderDispatch || orderUndispatch || orderAssign)
        }

        if (!orderUndispatch)
        {
            variables.driver_id = dispatchInfo.driver_id;
            variables.driver_pay = +dispatchInfo.price;
        }

        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data.success)
                throw new Error(data.message);

            setLoading(false);
            setOrderAssign(null);
            setOrderDispatch(null)
            setOrderUndispatch(null);
            dispatch(getOrders());
            onRefreshStarted();

            toast.addToast(data.message, {appearance: 'success'});

        } catch (ex) {
            setLoading(false);

            toast.addToast(ex.message, {appearance: 'error'});
            console.log('ex', ex.message);
        }
    }

    useEffect(() => {
        dispatch(getOrders());
    }, []);

    return(
        <>
            <div className="flex-1-container">
            <p className="font-14 font-medium">{ordersOpen.data.length} active orders</p>

                <div className="d-flex justify-content-between align-items center mb-3">
                    <div className="d-flex justify-content-start align-items-center">
                        <p className={`font-12 font-medium cursor-pointer m-0 py-2 pr-3 ${tab === DISPATCH_TABS.ALL ? 'text-black active-tab at-left-0' : 'text-muted'}`}
                        onClick={() => {setTab(DISPATCH_TABS.ALL);setSearchTerm('')}}>All ({getCount().length})</p>
                        <p className={`font-12 font-medium cursor-pointer m-0 py-2 px-3 ${tab === DISPATCH_TABS.NOT_DISPATCHED ? 'text-black active-tab' : 'text-muted'}`}
                        onClick={() => {setTab(DISPATCH_TABS.NOT_DISPATCHED);setSearchTerm('')}}>Not dispatched ({getCount('NOT_DISPATCHED').length})</p>
                        <p className={`font-12 font-medium cursor-pointer m-0 py-2 px-3 ${tab === DISPATCH_TABS.DISPATCHED ? 'text-black active-tab' : 'text-muted'}`}
                        onClick={() => {setTab(DISPATCH_TABS.DISPATCHED);setSearchTerm('')}}>Dispatched ({getCount('DISPATCHED').length})</p>
                    </div>

                    <div className="d-flex justify-content-start align-items-center">
                        <div className="bg-gray rounded-circular circular me-2">
                            <img className={'cursor-pointer ' + (ordersOpen.loading ? 'spin' : '')} onClick={(evt) => {onRefreshStarted();dispatch(getOrders())}} src={ICONS.IconRefresh} alt="refresh"/>
                        </div>

                        <Searchbar  reference={searchBarRef}
                                    background={'#F8F8F8'}
                                    placeholder={'Search...'}
                                    value={searchTerm}
                                    onChange={(evt) => setSearchTerm(Helper.Masks.NoSpecialCharacters(evt.target.value))}
                                    />
                    </div>
                </div>

                <Table tableHeight={'70vh'}
                    columns={columns}
                    loading={ordersOpen.loading}
                    rows={getOrdersByStatus(tab == DISPATCH_TABS.ALL ? '' : (tab == DISPATCH_TABS.NOT_DISPATCHED ? 'NOT_DISPATCHED' : 'DISPATCHED'))}
                    menuOptions={options}
                    onRowClicked={onRowClicked} />
            </div>

            <Modal visiblePopUpModal={showLegDetailModal != undefined}
                   handleClickOutPopupModal={() => setShowLegDetailModal(undefined)}>
                <LegDetail order={showLegDetailModal} onClose={() => setShowLegDetailModal(undefined)}/>
            </Modal>

            <ReactModal onAfterClose={() => {
                setDispatchInfo({driver_id: null, price: '', total: 0});
            }} isOpen={orderDispatch != null || orderUndispatch != null || orderAssign != null} style={{content: Helper.ModalStyles.ContentStyle.Regular}}>
                <div className="flex-1-container p-3">


                    <div className="d-flex justify-content-between align-items-center mb-3">
                            {orderDispatch != null && <h4 className='font-18'>Select a driver to dispatch O-{orderDispatch}</h4>}
                            {orderUndispatch != null && <h4 className='font-18'>Undispatch O-{orderUndispatch}</h4>}
                            {orderAssign != null && <h4 className='font-18'>Select a driver to assign O-{orderAssign}</h4>}
                            <div className='d-flex align-items-center'>
                                <h4 className="font-16 mr-2 mb-0">Price: {Helper.FORMAT.USCurrency(dispatchInfo.total)}</h4>
                                <button onClick={(evt) => {
                                    setOrderDispatch(null);
                                    setOrderUndispatch(null);
                                    setOrderAssign(null);
                                }} className="btn"><i className="fas fa-times"></i></button>
                            </div>
                    </div>
                    
                    <div className="flex-1-container">
                        {!loading && <div className="flex-1-container" style={{overflowX: 'hidden'}}>

                        {!orderUndispatch && <>
                            {!dispatchInfo.driver_id ? <div className='flex-1-container' >
                            <DispatchDrivers onDriverClicked={(evt) => setDispatchInfo({...dispatchInfo, driver_id: evt.id})} />
                            </div> : <div className='p-3'>

                                    { <>
                                        <FormGroup label='Driver Pay' name='driver_pay' moneySymbol value={dispatchInfo.price} onTextChange={text => setDispatchInfo({...dispatchInfo, price: (+Helper.Masks.DecimalNumbers(text)).toString()})} />
                                    </>}

                                </div>}
                        </>}

                        {orderUndispatch && <div className=''>
                                <h4 className='font-24'>Are you sure you want to undispatch this order?</h4>
                                <p className='font-16 text-red text-center fw-bold'>You won't be able to revert this.</p>
                            </div>}


                    </div>}


                    {loading && <div className='h-100'>
                        <Loading />
                        <h4 className='font-16 text-center'>{orderDispatch ? 'DISPATCHING' : (orderUndispatch ? 'UNDISPATCHING' : 'ASSIGNING')} ORDER...</h4>
                    </div>}

                    {(dispatchInfo.driver_id || orderUndispatch) && <div className="row">
                        <div className="col-6">
                            <button disabled={loading} className="btn btn-danger w-100" onClick={(evt) => {
                                if (orderUndispatch)
                                {
                                    setOrderDispatch(null);
                                    setOrderUndispatch(null);
                                    setOrderAssign(null);
                                }
                                else
                                {
                                    setDispatchInfo({...dispatchInfo, driver_id: null});
                                }
                            }}>{!orderUndispatch ? 'GO BACK    ' : 'CANCEL'}</button>
                        </div>
                        <div className="col-6">
                            <button onClick={(evt) => onSendDispatchAction()} disabled={loading} className="btn btn-primary-dark w-100">CONFIRM</button>
                        </div>
                    </div>}
                    </div>
                </div>
            </ReactModal>

        </>
    );
}

export default DispatchTable;
