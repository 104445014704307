import React, {useEffect, useRef, useState} from "react";
import './LegDescription.scss';
import CarImage from '../../../../assets/images/example_car.jpg';
import CarInspection from '../../../../assets/images/inspection_car.png';
import CarInspection2 from '../../../../assets/images/Car_Damage.png';
import PopUpModal from '../../../components/PopUpModal/PopUpModal'

import CarImage2 from '../../../../assets/images/tesla.jpg';
import CarImage3 from '../../../../assets/images/teslay.jpg';
import CarImage4 from '../../../../assets/images/teslay2.jpg';
import CarImage5 from '../../../../assets/images/teslay4.jpg';
import CarImage6 from '../../../../assets/images/teslay5.jpg';

import Helper from "../../../helper/Helper";
import {GraphqlService} from "../../../services/graphql.service";
import S3Queries from "../../../graphql/query/S3";
import InspectionMap from "./Map/Map";

let data=[

    {
        title:"part1",
        img:CarImage
    },
    {
        title:"part2",
        img:CarImage2
    },
    {
        title:"part3",
        img:CarImage3
    },
    {
        title:"part4",
        img:CarImage4
    },
    {
        title:"part5",
        img:CarImage5
    },
    {
        title:"part6",
        img:CarImage6
    }


]

const LegDescription = ({inspection, dataResolved}) => {
    const nodeContainer = useRef<HTMLDivElement>();


    const [requiredPhotos, setRequiredPhotos] = useState([]);

    const [signatures, setSignatures] = useState([]);


    const [images, setImages] = useState<{label: string, code?: string, thumbnail: string, image: string}[]>([]);
    const [scale, setScale] = useState(1);


    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function onResizeHandle(evt) {
        let width = imgRef?.current?.scrollWidth;
        if (width == null)
            width = 444;

        setScale(width / 444);
    }

    useEffect(() => {
        onResizeHandle(null);
        window.addEventListener('resize', onResizeHandle);
        return () => window.removeEventListener('resize', onResizeHandle);
    });


    const DAMAGES_LABEL =
    {
        'BR': 'Broken',
        'CR': 'Cracked',
        'F' : 'Faded',
        'FF': 'Foreign Fluid',
        'FT': 'Flat Tire',
        'G' : 'Gouge',
        'HD': 'Hall Damage',
        'LC': 'Loose Contents',
        'M' : 'Missing',
        'MD': 'Major Damage',
        'O' : 'Other',
        'PC': 'Paint Chip',
        'R' : 'Rubbed',
        'RU': 'Rust',
        'S' : 'Scratched',
        'SC': 'Scuffed',
        'D' : 'Dented',
        'MS': 'Multiple Scratches'
    };

    const getTempLink = async (key: string, minutes: number = 5, is_thumbnail: boolean) => {
        try {
            
            Helper.UseApiKey();
            const k = key.startsWith('*') ? 'GET_TEMP_LINK' : 'GET_TEMP_LINK_NEW_INSPECTIONS';
            const data = await GraphqlService.SendQuery(S3Queries[k], key.startsWith('*') ? {key, minutes, is_thumbnail, uid: key} : {uid: key, is_thumbnail});

            return data;
        }
        catch (ex)
        {
            console.log('ex');
        }
    }


    const onDamageClicked =(damage, index)=> {
        // setImages();
        // alert('clicked');+
        
        const imgs = markingList.map(x => ({
            code: x.code,
            image: x.image,
            thumbnail: x.image_thumbnail,
            label: x.label
        }));
        setIndexSelected(index);
        setImages(imgs);
        setOpenPopUpModal(true);
        setIconKey(damage.code);
        setLabelPopUp(DAMAGES_LABEL[damage.code])
    }

    const onSignatureClicked =(signature, index)=> {
        // setImages();
        const imgs = signatures.map(x => ({
            image: x.image,
            thumbnail: x.image,
            label: x.label
        }));
        setIndexSelected(index);
        setImages(imgs);
        setOpenPopUpModal(true);
    }


    const ref = useRef(null);
    const [showDetails, setShowDetails] = useState(false);
    const [markingList, setMarkingList] = useState([]);
    const [openPopUpModal,setOpenPopUpModal]=useState(false);
    const [iconKey,setIconKey]=useState("");
    const [labelPopUp,setLabelPopUp]=useState("");
    const [overIndex, setOverIndex] = useState(null);
    const [indexSelected, setIndexSelected] = useState(null);
    const imgRef = useRef<HTMLImageElement>();
    // useEffect(() => {
    //     setMarkingList([...markingList]);
    // }, [ref.current]);

    async function load()
    {

        const list: any[] = await Promise.all(inspection.damages.map(async d => ({
            code: d.code,
            label: DAMAGES_LABEL[d.code],
            uid: d.uid,
            image: await getTempLink(d.uid, 5, false),
            point: {...d.point},
            image_thumbnail: await getTempLink(d.uid, 5, true)
    })));
    console.log('here manlo')

        // for (let d of inspection.damages)
        // {
        //     list.push({
        //         code: d.code,
        //         label: DAMAGES_LABEL[d.code],
        //         uid: d.uid,
        //         image: await getTempLink(d.uid, 5, false),
        //         point: {...d.point},
        //         image_thumbnail: await getTempLink(d.uid, 5, true)
        //     })
        // }
        

        setMarkingList(list);

        // const list2 = await Promise.all() as any[];

        const list2: any[] = await Promise.all(inspection.photos.map(async p => ({
            label: `Required image ${p.type}`,
            uid: p.uid,
            image: await getTempLink(p.uid, 5,  false),
            image_thumbnail: await getTempLink(p.uid, 5,  true)
        })));

        
        console.log(list2);

        // for (let i = 0; i < list2.length; i++)
        // {
        //     const image = new Image();
        //     // image.loading = 'lazy';
        //     image.src = list2[i].image_thumbnail;

        // }


        const _sign = [
            {
                image: await getTempLink(inspection.contact_signature, 5, false),
                label: 'Customer Signature ' + inspection.contact_signature
            },
            {
                image: await getTempLink(inspection.driver_signature, 5, false),
                label: 'Driver Signature ' + inspection.driver_signature
            }
        ];


        // for (let i = 0; i < _sign.length; i++)
        // {
        //     const image = new Image();
        //     image.src = _sign[i].image;
        // }

        // for (let i = 0; i < list.length; i++)
        // {
        //     const image = new Image();
        //     // image.loading = 'lazy';
        //     image.src = list[i].image_thumbnail;
        // }

        // for (let i = 0; i < list2.length; i++)
        // {
        //     const image = new Image();
        //     // image.loading = 'lazy';
        //     image.src = list2[i].image;

        // }

        // for (let i = 0; i < list.length; i++)
        // {
        //     const image = new Image();
        //     // image.loading = 'lazy';
        //     image.src = list[i].image;
        // }

        setSignatures(_sign)





        setRequiredPhotos(list2);
        dataResolved(true);
    }

    useEffect(() => {

        console.log(inspection)
        load()
            .then(() => dataResolved(true))
            .catch(err => dataResolved(false));

    }, [inspection]);


    const getObservations = () => {
        const array = [];
        array.push('Odometer reading: ' + inspection.odometer);
        if (inspection.accessories.length == 0)
            array.push('No accessories found');
        if (inspection.flags.too_dirty)
            array.push('Vehicle was dirty');
        if (inspection.flags.raining)
            array.push('It was raining');
        if (inspection.flags.too_dark)
            array.push('Too dark for proper inspection');
        if (inspection.damages.length == 0)
            array.push('No damages found');

        return array;
    }

    return(
        <>
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <p className="font-medium font-12 mb-4">{inspection.stop.is_pickup ? 'Pickup' : 'Dropoff'}</p>

                            <div className="row align-items-start">
                                <div className="col-4 row-sm col-sm-12">
                                    <div className={'mb-3 col-sm-6'}>
                                        <p className="font-medium font-14">Submitted on</p>
                                        <p className="font-12 my-1">{Helper.FORMAT.USDate(new Date(inspection.time), 'do')}</p>
                                        <p className="font-12">{Helper.FORMAT.USDate(new Date(inspection.time), 'to')}</p>
                                    </div>
                                    <div className={'col-sm-6'}>
                                        <p className="font-medium font-14">Address</p>
                                        <p className="font-12 my-1">{inspection.stop.address.street_number} {inspection.stop.address.street}</p>
                                        <p className="font-12">{inspection.stop.address.city}, {inspection.stop.address.state} {inspection.stop.address.postal_code}</p>
                                    </div>
                                </div>

                                {inspection.gps.Coordinate && <div className="col-8 col-sm-12 position-relative">
                                    <div className={'position-relative'} style={{paddingTop: '100%'}}>

                                        <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                                            <InspectionMap location={{lat: +inspection.gps.Coordinate?.Latitude, lng: +inspection.gps.Coordinate?.Longitude}} />
                                        </div>
                                    </div>
                                </div>}


                                {inspection.stop.no_fail && <div className="col-12">
                                    <span className="btn btn-danger rounded-pill">NO FAIL</span>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <p className="font-medium font-12 mb-4">Accessories</p>

                            <div className="row">   
                                {inspection.accessories.map(ac => <div className="col-6 d-flex justify-content-between align-items-center">
                                    <p className="font-medium font-14 mb-3">{ac.name}</p>
                                    <p className="font-medium font-12 mb-3">{ac.count}</p>
                                </div>)}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <p className="font-medium font-12 mb-4">Inspection notes</p>

                            <p className="font-12 mb-3">
                                {inspection.note}
                            </p>

                            <ul className={'px-3'}>
                                {getObservations().map((item, index) => <li>
                                    <p className={'font-12' + (index < getObservations().length - 1? ' mb-2' : '')}>{item}</p>
                                </li>)}
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="col-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <p className="font-medium font-12">Damages</p>
                            <div style={{maxWidth: '80%', margin: '0 auto'}}>
                                <div style={{width: '100%', marginBottom: '1rem', position: 'relative'}}>
                                    <div style={{position: 'relative', left: 0, top: 0, height: '100%'}}>
                                        <img style={{width: '100%', objectFit: 'contain'}} ref={imgRef} src={CarInspection2} alt=""/>
                                        {
                                            markingList.map((e, index) => {
                                                const w = 961;
                                                const h = 634;

                                                let top = 0;
                                                let left = 0;



                                                return <div
                                                    onClick={() => onDamageClicked(e, index)}
                                                    onMouseEnter={(evt) => {
                                                        if (!isMobile)
                                                            setOverIndex(index);
                                                        // setOverIndex(null);
                                                    }}
                                                    onMouseLeave={(evt) => setOverIndex(null)}
                                                    onTouchEnd={(evt) => setOverIndex(null)}
                                                    key={'pos-' + index}
                                                    style={{top: `calc(${e.point.y + '%'} - 16px)`, left: `calc(${e.point.x + '%'} - 16px)`, transform: `scale(${scale})`}}
                                                    className="inspection-position">
                                                    <div className={`inspection-key ${index == overIndex ? 'red-key' : ''}`}>{e.code}</div></div>
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <p className="font-medium font-12">{markingList.length} markings</p>
                                {inspection.damages.length > 0 && <button className="btn btn-blue-light-outline rounded-pill px-4 py-2"
                                                                          onClick={() => setShowDetails(!showDetails)}>{showDetails ? 'HIDE' : 'SHOW'} DETAILS</button>}
                            </div>
                            {
                                !showDetails ? null :
                                    <ul className="mb-3 p-0 mt-3">
                                        {
                                            markingList.map((e, index) => {
                                                return <li key={'item-' + index}
                                                           onClick={() => onDamageClicked(e, index)}
                                                           className={"marking-item" + (index == overIndex ? ' marking-item-hover' : '')}
                                                           onMouseEnter={() => {
                                                               // let array = [...markingList];
                                                               // let temp = {...e};
                                                               //
                                                               //
                                                               // temp.hover = true;
                                                               //
                                                               // array[index] = {...temp};
                                                               // setMarkingList([...array]);

                                                               if (!isMobile)
                                                                   setOverIndex(index);
                                                           }}
                                                           onMouseLeave={() => {
                                                               // let array = [...markingList];
                                                               // let temp = {...e};
                                                               //
                                                               //
                                                               // temp.hover = false;
                                                               //
                                                               // array[index] = {...temp};
                                                               // setMarkingList([...array]);
                                                               setOverIndex(null);
                                                           }}>
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div className="inspection-key mr-3">{e.code}</div>
                                                        <p className="font-medium font-12">{e.label}</p>
                                                        {/*<p>{'inspections/inspection_' + inspection.id + '/' + e.uid}</p>*/}
                                                    </div>

                                                    <img src={e.image_thumbnail} className="rounded" width={32} height={32} alt=""/>
                                                </li>
                                            })
                                        }
                                    </ul>
                            }

                        </div>
                    </div>
                </div>


                <div className="col-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <p className="font-medium font-12 mb-4">Photos</p>

                            <div className="row">
                                {requiredPhotos.map((p, index) => <div className="col-6 mb-3 cursor-pointer">
                                    <img src={p.image_thumbnail} onClick={() => {
                                        const imgs = requiredPhotos.map(p => ({
                                            image: p.image,
                                            thumbnail: p.image_thumbnail,
                                            label: p.label
                                        }));
                                        setIndexSelected(index);
                                        setImages(imgs);
                                        setOpenPopUpModal(!openPopUpModal);

                                    }} className="rounded" width={'100%'} height={156} alt=""/>
                                </div>)}

                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <p className="font-medium font-12 mb-3">Signatures</p>
                            <div className={'row'}>
                                <div className={'col-6 col-sm-12'}>
                                    <p className="font-medium font-11">{inspection.stop.is_pickup ? 'Shipper' : 'Consignee'}</p>
                                    <img onClick={(evt) => onSignatureClicked(signatures[0], 0)} src={signatures[0]?.image} width={'100%'} height={'156px'} className={'my-2 cursor-pointer'} />
                                    <p className="font-medium font-11">{inspection.signed_by}</p>
                                </div>
                                <div className={'col-6 col-sm-12 sm-st-3'}>
                                    <p className="font-medium font-11">Driver</p>
                                    <img onClick={(evt) => onSignatureClicked(signatures[1], 1)} src={signatures[1]?.image} width={'100%'} height={'156px'} className={'my-2 cursor-pointer'} />
                                    <p className="font-medium font-11">{inspection.driver.name}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <PopUpModal indexSelected={indexSelected} images={images} iconKey={iconKey} labelPopUpMpdal={labelPopUp} dataPopUpModal={data} visiblePopUpModal={openPopUpModal} handleClickOutPopupModal={()=> {
                setOpenPopUpModal(false);
                setIndexSelected(null);
            }}/>

        </>
    );
}

export default LegDescription;
