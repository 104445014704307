import React, { useEffect, useState } from 'react';
import FormGroup from '../FormGroup/FormGroup';
import {gql} from 'apollo-boost';
import { useToasts } from 'react-toast-notifications';
import { GraphqlService } from '../../services/graphql.service';
import useLoading from '../../hooks/useLoading/useLoading';
import Helper from '../../helper/Helper';


const sendOriginInspection = gql`
    mutation($velox_order_id:Int, $phones:[String], $emails:[String]){
        create_velox_pending_origin_inspection(velox_order_id:$velox_order_id, phones:$phones, emails: $emails){
            id
            message
            success
            link
        }
    }
`;

const sendDestinationInspection = gql`
 mutation($velox_order_id:Int, $phones:[String], $emails:[String]){
        create_velox_pending_destination_inspection(velox_order_id:$velox_order_id, phones:$phones, emails: $emails){
            id
            message
            success
            link
        }
    }
`;

class RequestInpsection
{
    vehicle_order_id: number = null;
    velox_order_id: number = null;
    type: string = '';
    phones: string[] = [];
    emails: string[] = [];
    id: number = null;
    is_pickup: boolean = false;

    constructor(props?: RequestInpsection)
    {
        if (props)
        {
            this.vehicle_order_id = props.vehicle_order_id;
            this.type = props.type;
            this.phones = !props?.phones ? [] : [...props.phones];
            this.emails = !props?.emails ? [] : [...props.emails];
            this.id = props.id;
            this.is_pickup = props.is_pickup;
            this.velox_order_id = props.velox_order_id;
        }
    }


    isValid()
    {
        if (!this.vehicle_order_id && !this.id && !this.velox_order_id) return false;

        if (!this.velox_order_id)
            if (this.type.trim() == '' && !this.id) return false;

        //if (this.phones.length == 0 && this.emails.length == 0) return false;

       // if (this.phones.length > 0 && this.phones.some(x => x == '' || !Helper.Validators.IsValidUSPhone(x)))
           // return false;

        if (this.emails.length > 0 && this.emails.some(x => x == '' || !Helper.Validators.IsValidEmail(x)))
            return false;

            
        return true;
    }
}

const RequestInspectionForm = ({onClose, data,onSubmit,showPhone=false,showEmail=false}) => {

    const [requestInspection, setRequestInspection] = useState(new RequestInpsection());
    const [dataTemp,setDataTemp]=useState<any>()


    useEffect(() => {
        if (data)
            setRequestInspection(new RequestInpsection(data));
    }, [data]);

    const loading = useLoading();
    const toast = useToasts();

    async function handleSendClicked(evt) {
        const mutation = requestInspection.velox_order_id ? (requestInspection.is_pickup ? sendOriginInspection : sendDestinationInspection) : (requestInspection.id ? gql`
        
        mutation($pending_inspection_id:Int, $emails:[String], $phones:[String]){
                resend_inventory_inspection(pending_inspection_id:$pending_inspection_id, emails:$emails, phones:$phones){
                    id
                    message
                    success
                    link
                }
                }
        ` : gql`
            mutation($vehicle_order_id:Int, $type:String, $emails: [String], $phones: [String]){
                create_pending_inspection(vehicle_order_id:$vehicle_order_id, type:$type, emails: $emails, phones: $phones){
                    id
                    message
                    success
                    link
                }
            }
        `);

        const variables: any = {emails: requestInspection.emails, 
            //phones: requestInspection.phones
        };




        if (requestInspection.id)
        {
            variables.pending_inspection_id = requestInspection.id;
        }
        else if (requestInspection.vehicle_order_id)
        {
            variables.vehicle_order_id = requestInspection.vehicle_order_id;
            variables.type = requestInspection.type;
        }
        else
        {
            variables.velox_order_id = requestInspection.velox_order_id;

        }

        try {
            loading.open((requestInspection.id ? 'Resending' : (requestInspection.vehicle_order_id ? 'Creating' : 'Sending')) + ' inspection request...');
            const data = await GraphqlService.SendMutation(mutation, variables);

            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            const h4 = <h4 className='fs-12'>{data.message}<a target='_blank' style={{display: 'block', marginTop: '.5rem'}} href={data.link}>{data.link}</a></h4>
            toast.addToast(h4, {appearance: 'success', autoDismiss: true});
            onClose(true);
                
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const sendDataEmail =()=>{
        onSubmit(dataTemp)
    }

    return <>
                <div className="row mb-1">
                    <h4 className="font-14 font-medium">{requestInspection.id ? 'Resend' : (requestInspection.vehicle_order_id ? 'Create' : 'Send')} Inspection {requestInspection.vehicle_order_id ? 'Request' : ''}</h4>
                    <h4 className="font-14 font-medium cursor-pointer" onClick={onClose}><i className='fas fa-times'></i></h4>
                </div>
           {/*      <div className='row mb-3 align-items-center'>
                    <h4 className="font-12 font-medium">{requestInspection.id ? 'ID' : (requestInspection.vehicle_order_id ? 'VO' : 'VLX')}-{requestInspection.vehicle_order_id || requestInspection.id || requestInspection.velox_order_id}</h4>
                    
                </div> */}
                {/*(!requestInspection.id && !requestInspection.velox_order_id) && <div >
                    <FormGroup type='select' options={{data: ['INCOMING', 'OUTGOING', 'WASH', 'NEW DAMAGE', 'STORAGE', 'OTHER'], label: x=>x, value: x => x}} placeholder={'-- Select --'} required value={requestInspection.type} onTextChange={(evt) => {
                        setRequestInspection(new RequestInpsection({...requestInspection as any, type: evt}));
                    }} name='type' label='Inspection Type' />
                </div>*/}

                 
                <div className="row py-3">
                <div className={`${showEmail?`row align-items-center ${showPhone?"col-6":"col-12"}`:"d-none"}`}>
                        <h4 className='font-11'>Emails</h4>
                        <button className="btn btn-blue-light" onClick={(evt) => {
                            const temp = new RequestInpsection(requestInspection);
                            const emails = [...temp.emails, ''];
                            temp.emails = emails;
                            setRequestInspection(temp);
                        }}>ADD
                        </button>
                    </div>
                    <div className={`${showPhone?`row align-items-center ${showEmail?"col-6":"col-12"}`:"d-none"}`}>
                        <h4 className='font-11'>Phones</h4>
                        <button className="btn btn-blue-light" onClick={(evt) => {
                             const temp = new RequestInpsection(requestInspection);
                            const phones = [...temp.phones, ''];
                            temp.phones = phones;
                            setRequestInspection(temp);
                        }}>ADD
                        </button>
                    </div> 
                </div>

                
                <div className="flex-1-container row-container">
                <div className={`${showEmail?`d-flex ${showPhone?"col-6":"col-12"}`:"d-none"}`}>
                        <div className="flex-1-container">
                            {requestInspection.emails.map((email, index) => <div className='d-flex'>
                            <FormGroup value={email} onTextChange={(text) => {
                                const temp = new RequestInpsection(requestInspection);
                                const emails = [...temp.emails];
                                // phones.splice(index, 1);
                                emails[index] = text;
                                temp.emails = emails;
                                setRequestInspection(temp);
                                setDataTemp(temp)
                            }}  validation={x => Helper.Validators.IsValidEmail(x)} label={'Email ' + (index + 1)} required name={'email_' + index} placeholder={'xx@xxxx.xx'} />
                            <h4 onClick={(evt) => {
                                const temp = new RequestInpsection(requestInspection);
                                const emails = [...temp.emails];
                                emails.splice(index, 1);
                                temp.emails = emails;
                                setRequestInspection(temp);
                                setDataTemp(temp)
                            }} className="fs-14 mt-4 font-light cursor-pointer ml-2"><i className='fas fa-times pt-1'></i></h4>

                            </div>)}
                        </div>
                    </div>
                    <div className={`${showPhone?`d-flex ${showEmail?"col-6":"col-12"}`:"d-none"}`}>
                    <div className="flex-1-container">
                            {requestInspection.phones.map((phone, index) => <div className='d-flex'>
                            <FormGroup validation={x => Helper.Validators.IsValidUSPhone(x)} value={phone} onTextChange={(text) => {
                                const temp = new RequestInpsection(requestInspection);
                                const phones = [...temp.phones];
                                // phones.splice(index, 1);
                                phones[index] =   Helper.Masks.USPhone(text);
                                temp.phones = phones;
                                setRequestInspection(temp);
                                setDataTemp(temp)
                            }} label={'Phone ' + (index + 1)} required name={'phone_' + index} placeholder={'000-000-0000'} />
                            <h4 onClick={(evt) => {
                                const temp = new RequestInpsection(requestInspection);
                                const phones = [...temp.phones];
                                phones.splice(index, 1);
                                temp.phones = phones;
                                setRequestInspection(temp);
                                setDataTemp(temp)
                            }} className="fs-14 mt-4 font-light cursor-pointer ml-2"><i className='fas fa-times pt-1'></i></h4>

                            </div>)}
                        </div>
                    </div> 
                </div>
                
                

                <div className="row mt-3">
                    <button className="btn btn-danger-outline col-6 py-2" onClick={onClose}>CANCEL
                    </button>
                    <button 
                    //onClick={handleSendClicked} 
                    //disabled={!requestInspection.isValid()}
                    //disabled={}  
                    onClick={()=> sendDataEmail()}
                            className="btn btn-blue-outline col-6 py-2">{requestInspection.vehicle_order_id ? 'Request' : 'SAVE'}
                    </button>
                </div>
    </>
}

export default RequestInspectionForm;