import React, {useRef, useState} from "react";
import Modal from "../../components/Modal/Modal";
import PickupInspection from "../../components/PickupInspection/PickupInspection";
import Searchbar from "../../components/Searchbar/Searchbar";
import Table from "../../components/Table/Table";
import SideActionManager from "../../components/SideActionManager/SideActionManager";
import ICONS from "../../../assets/svg";
import IColumn from "../../components/Table/models/IColumn";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import getOrders from "../../redux/actions/Order.action";
import { AllStates } from "../../redux/reducers";
import { IOrderInternal } from "../../redux/reducers/Order.reducer";
import LegDetail from "../../components/LegDetail/LegDetail";
import './JobsPage.scss';
import useScreenDimension from "../../hooks/ScreenDimension/ScreenDimension";
import JobsPageMobile from "./JobsPageMobile";
import IMenuOption from "../../components/Table/models/IMenuOption";
import { Loading } from "../../components/Loading/Loading";
import ReactModal from "react-modal";
import DispatchDrivers from "../../components/DispatchDrivers/DispatchDrivers";
import FormGroup from "../../components/FormGroup/FormGroup";
import Helper from "../../helper/Helper";
import { GraphqlService } from "../../services/graphql.service";
import { gql } from "apollo-boost";
import { useToasts } from "react-toast-notifications";
import JobsLegDetails from "../../components/JobsLegDetail/JobsLegDetails";
enum JobStatuses {
    OPEN,
    COMPLETED,
}

const JobsPage = () => {

    const toast = useToasts();

    const dispatch = useDispatch();
    const { isMobile } = useScreenDimension();

    const [pickupModal, setPickupModal] = useState(false);
    const searchBarRef = useRef();

    const [jobStatus, setJobStatus] = useState(JobStatuses.OPEN);
    const [actionManagerOpened, setActionManagerOpened] = useState(false);

    const [loading, setLoading] = useState(false);
    const [showLegDetailModal, setShowLegDetailModal] = useState(undefined);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderDispatch, setOrderDispatch] = useState(null);
    

    const [orderUndispatch, setOrderUndispatch] = useState(null);
    const [orderAssign, setOrderAssign] = useState(null);
    const [dispatchInfo, setDispatchInfo] = useState({price: '', driver_id: null, total: 0});
    const [orderID, setOrderID] = useState(null);


    const columns: IColumn[] = [
        { name: 'formattedId',          label: 'ORDER #',     active: false, type: 'html',  sort_by: 'id',                   orderBy: 'ASC' },
        { name: 'status',               label: 'STATUS',      active: false, type: 'badge', sort_by: 'status',               orderBy: 'ASC' },
        { name: 'formattedDriver',      label: 'DRIVER',      active: false, type: 'html',  sort_by: 'formattedDriver',      orderBy: 'ASC' },
        { name: 'formattedBrokerName',  label: 'BROKER',      active: false, type: 'html',  sort_by: 'formattedBrokerName',  orderBy: 'ASC' },
        { name: 'formattedVehicleName', label: 'VEHICLE',     active: false, type: 'html',  sort_by: 'formattedVehicleName', orderBy: 'ASC' },
        { name: 'formattedOrigin',      label: 'ORIGIN',      active: false, type: 'html',  sort_by: 'originSort',           orderBy: 'ASC' },
        { name: 'formattedDestination', label: 'DESTINATION', active: false, type: 'html',  sort_by: 'destinationSort',      orderBy: 'ASC' },
        { name: 'formattedPrice',       label: 'PAYOUT',      active: false, type: 'html',  sort_by: 'priceSort',            orderBy: 'ASC' }
    ];

    
    const ordersOpen = useSelector<AllStates>(x => x.orders.open) as IOrderInternal;
    const ordersCompleted = useSelector<AllStates>(x => x.orders.completed) as IOrderInternal;



    const dispatchToDriver = (row: {id: number, price: number, driver_pay: string}) => {
        setOrderAssign(row.id);
        setDispatchInfo({
          total: row.price,
          driver_id: null,
          price: (row.driver_pay || "") + "",
        });
      }

    const statusesOptions = [
        {
            label: 'Open ',
            key: 'OPEN',
            value: JobStatuses.OPEN
        },
        {
            label: 'Completed ',
            key: 'COMPLETED',
            value: JobStatuses.COMPLETED
        }
    ]

    const options: IMenuOption[] = [
      {
        label: "View Change Request",
        icon: ICONS.IconDetails,
        condition: (row) => row.pending_change_request,
        action: (row: any) => {
          // console.log(row)
          Object.assign(document.createElement("a"), {
            target: "_blank",
            href: row.change_requests?.find((x) => x.active)?.weblink,
          }).click();
        },
      },
      {
        label: "Dispatch",
        icon: ICONS.IconDispatch,
        condition: (row) => !row.dispatched,
        action: (row: any) => {
          setOrderDispatch(row.id);
          setDispatchInfo({ total: row.price, driver_id: null, price: "" });
        },
      },
      {
        label: "Change driver",
        icon: ICONS.IconDriver,
        condition: (row) => row.dispatched,
        action: dispatchToDriver,
      },
      {
        label: "Undispatch",
        icon: ICONS.IconDeactivate,
        condition: (row) => row.dispatched,
        action: (row: any) => {
          setOrderUndispatch(row.id);
          setDispatchInfo({ total: row.price, driver_id: null, price: "" });
        },
      },
      {
        label: "See order details",
        icon: ICONS.IconDetails,
        action: (row) => {
          handleSeeLegDetails(row);
        },
      },
    ];

    const handleSeeLegDetails = (row) => {
        setShowLegDetailModal(row);
    }


    async function onSendDispatchAction()
    {

        setLoading(true);
        const mutation = orderUndispatch ? gql`
               mutation($order_id: Int){
                    unassign_driver(order_id: $order_id){
                        message
                        success
                }
            }
        ` : gql`
        
        mutation($order_id: Int, $driver_id: Int, $driver_pay: Float){
            assign_driver(order_id: $order_id, driver_id: $driver_id, driver_pay: $driver_pay){
                message
                success
            }
        }`;

        const variables: any = {
            order_id: (orderDispatch || orderUndispatch || orderAssign)
        }

        if (!orderUndispatch)
        {
            variables.driver_id = dispatchInfo.driver_id;
            variables.driver_pay = +dispatchInfo.price;
        }

        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data.success)
                throw new Error(data.message);

            setLoading(false);
            setOrderAssign(null);
            setOrderDispatch(null)
            setOrderUndispatch(null);
            dispatch(getOrders());
            

            toast.addToast(data.message, {appearance: 'success'});

        } catch (ex) {
            setLoading(false);

            toast.addToast(ex.message, {appearance: 'error'});
            console.log('ex', ex.message);
        }
    }


    const getCount = (key = '') => {
        if (!key)
            return ordersOpen.data.length + ordersCompleted.data.length;
        return key == 'OPEN' ? ordersOpen.data.length : ordersCompleted.data.length;
    }
    const getOpens = (item) => {
        if (!item) return [];

        let arrayItem = item.data.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim()));
        return arrayItem.map(element => {
            return {
                ...element,
                formattedId: element.getFormattedID(),
                formattedDriver: element.getDriver(),
                formattedBrokerName: element.getBrokerName(),
                formattedVehicleName: element.getVehicleName(),
                formattedOrigin: element.getOrigin(),
                formattedDestination: element.getDestination(),
                originSort: element.origin.address.getName(),
                destinationSort: element.destination.address.getName(),
                formattedPrice: element.getPrice(),
                priceSort: element.price
            };
        });

    }

    useEffect(() => {
        dispatch(getOrders())
    }, []);

    if (isMobile)
        return <JobsPageMobile statusesOptions={statusesOptions}
                               ordersOpen={ordersOpen}
                               ordersCompleted={ordersCompleted} />

    return (
        <>

            <div className="flex-1-container col-12">
                <div className="row title-section-base">
                    <div className="col-12">
                        Jobs
                    </div>
                </div>

                <div className="flex-1-container bg-white p-3 main-radius">
                    <div className="row d-flex justify-content-between align-items-center mb-3">
                        <div className="col-6">
                            <p className="font-14 font-medium">{getCount('OPEN')} active orders</p>
                        </div>


                        <div className="d-flex justify-content-between align-items center mb-3">
                            <div className="d-flex my-3 cursor-pointer">
                                {
                                    statusesOptions.map((opt, index) =>
                                        <div className={'me-3'} key={'key-' + index}>
                                            <p onClick={(evt) => {setJobStatus(opt.value);setSearchTerm('')}} className={`font-12 cursor-pointer mb-1 font-medium${opt.value != jobStatus ? ' text-gray' : ''}`}>{opt.label} ({getCount(opt.key)})</p>
                                            <div style={{width: '24px', height: '2px', background: jobStatus == opt.value ? '#42ACDE' : 'transparent'}} />
                                        </div>
                                    )
                                }

                            </div>

                            <div className="d-flex justify-content-start align-items-center">
                                        <div className="bg-gray rounded-circular circular me-2">
                                            <img className={'cursor-pointer ' + (ordersOpen.loading ? 'spin' : '')} onClick={(evt) => dispatch(getOrders())} src={ICONS.IconRefresh} alt="refresh"/>
                                        </div>
                                        <div style={{width: '300px'}}>

                                        <Searchbar  reference={searchBarRef}
                                                    background={'#F8F8F8'}
                                                    placeholder={'Search...'}
                                                    value={searchTerm}
                                                    onChange={(evt) => setSearchTerm(evt.target.value)}
                                                    />
                                        </div>
                                    </div>
                        </div>

                        
                    </div>

                    <Table tableHeight={'70vh'}
                           onRowClicked={(row) => {
                               setOrderID(row.id);
                            //    console.log(row);
                           }}
                           columns={columns}
                           loading={(jobStatus == JobStatuses.OPEN ? ordersOpen : ordersCompleted).loading}
                           rows={(jobStatus == JobStatuses.OPEN ? getOpens(ordersOpen) : getOpens(ordersCompleted))}
                           menuOptions={options}/>
                </div>

                {/*Side Action Manager*/}
                <SideActionManager opened={orderID != null}
                                   onCloseManager={(ev) => {
                                       setOrderID(null);
                                       console.log('here');
                                   }}>
                    {/*<div className="row mt-4">*/}
                    {/*    <div className="col-6 ">*/}
                    {/*        <button   className="btn btn-skyblue-outline w-100">*/}
                    {/*            DECLINE*/}
                    {/*        </button>*/}
                    {/*    </div>*/}

                    {/*    <div className="col-6 ">*/}
                    {/*        <button  className="btn btn-skyblue w-100">*/}
                    {/*            SIGN TO ACCEPT*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <JobsLegDetails order_id={orderID} onClose={() => setOrderID(null)} onChangeDriver={dispatchToDriver}/>
                </SideActionManager>
            </div>


            <Modal visiblePopUpModal={showLegDetailModal != undefined}
                   handleClickOutPopupModal={() => setShowLegDetailModal(undefined)}>
                <LegDetail order={showLegDetailModal} onClose={() => setShowLegDetailModal(undefined)}/>
            </Modal>

            <ReactModal onAfterClose={() => {
                setDispatchInfo({driver_id: null, price: '', total: 0});
            }} isOpen={orderDispatch != null || orderUndispatch != null || orderAssign != null} style={{content: Helper.ModalStyles.ContentStyle.Regular}}>
                <div className="flex-1-container p-3">


                    <div className="d-flex justify-content-between align-items-center mb-3">
                            {orderDispatch != null && <h4 className='font-18'>Select a driver to dispatch O-{orderDispatch}</h4>}
                            {orderUndispatch != null && <h4 className='font-18'>Undispatch O-{orderUndispatch}</h4>}
                            {orderAssign != null && <h4 className='font-18'>Select a driver to assign O-{orderAssign}</h4>}
                            <div className='d-flex align-items-center'>
                                <h4 className="font-16 mr-2 mb-0">Price: {Helper.FORMAT.USCurrency(dispatchInfo.total)}</h4>
                                <button onClick={(evt) => {
                                    setOrderDispatch(null);
                                    setOrderUndispatch(null);
                                    setOrderAssign(null);
                                }} className="btn"><i className="fas fa-times"></i></button>
                            </div>
                    </div>
                    
                    <div className="flex-1-container">
                        {!loading && <div className="flex-1-container" style={{overflowX: 'hidden'}}>

                        {!orderUndispatch && <>
                            {!dispatchInfo.driver_id ? <div className='flex-1-container' >
                            <DispatchDrivers onDriverClicked={(evt) => setDispatchInfo({...dispatchInfo, driver_id: evt.id})} />
                            </div> : <div className='p-3'>

                                    { <>
                                        <FormGroup errorText={+dispatchInfo.price == 0 ? 'Driver pay must be more than 0' : 'Driver pay must be less or equals than ' + Helper.FORMAT.USCurrency(dispatchInfo.total)} hintText={''} colSize={12} name='price' label={'Driver Pay'} required value={dispatchInfo.price} onTextChange={(evt) => setDispatchInfo({...dispatchInfo, price: Helper.Masks.DecimalNumbers(evt)})} />
                                    </>}

                                </div>}
                        </>}

                        {orderUndispatch && <div className=''>
                                <h4 className='font-24'>Are you sure you want to undispatch this order?</h4>
                                <p className='font-16 text-red text-center fw-bold'>You won't be able to revert this.</p>
                            </div>}


                    </div>}


                    {loading && <div className='h-100'>
                        <Loading />
                        <h4 className='font-16 text-center'>{orderDispatch ? 'DISPATCHING' : (orderUndispatch ? 'UNDISPATCHING' : 'ASSIGNING')} ORDER...</h4>
                    </div>}

                    {(dispatchInfo.driver_id || orderUndispatch) && <div className="row">
                        <div className="col-6">
                            <button disabled={loading} className="btn btn-danger w-100" onClick={(evt) => {
                                if (orderUndispatch)
                                {
                                    setOrderDispatch(null);
                                    setOrderUndispatch(null);
                                    setOrderAssign(null);
                                }
                                else
                                {
                                    setDispatchInfo({...dispatchInfo, driver_id: null});
                                }
                            }}>{!orderUndispatch ? 'GO BACK    ' : 'CANCEL'}</button>
                        </div>
                        <div className="col-6">
                            <button onClick={(evt) => onSendDispatchAction()} disabled={loading} className="btn btn-primary-dark w-100">CONFIRM</button>
                        </div>
                    </div>}
                    </div>
                </div>
            </ReactModal>
        </>
    );

    // return(
    //     <>
    //         {/*<div className="col-12">*/}
    //         {/*    <button className="btn btn-primary" onClick={() => setPickupModal(true)}>Open Job Modal</button>*/}
    //
    //         {/*    <Modal visiblePopUpModal={pickupModal}*/}
    //         {/*           children={<PickupInspection />}*/}
    //         {/*           handleClickOutPopupModal={() => setPickupModal(false)} />*/}
    //         {/*</div>*/}
    //     </>
    // );
}

export default JobsPage;
