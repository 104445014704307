import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import Helper from '../../../../helper/Helper';
import Content from '../../components/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
import CardInspection from '../../components/CardInspection/CardInspection';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import './Accessories.scss'
import ICONS from '../../../../../assets/svg';
import { PendingInspection } from '../../../../classes/classes';
import InspectionObservable from '../../observables/InspectionObservable';
import { useToasts } from 'react-toast-notifications';
import {gql} from 'apollo-boost';
import { GraphqlService } from '../../../../services/graphql.service';

const accessoriesList = [
    {
        name: 'Charger',
        custom: 'on'
    },
    {
        name: 'Glasses',
        custom: 'on'
    },
    {
        name: 'Umbrella',
        custom: 'on'
    },
    {
        name: 'Manual',
        custom: 'on'
    },
    {
        name: 'Key',
        custom: 'on'
    },
    {
        name: 'Car Cover',
        custom: 'on'
    },
    {
        name: 'Floor Mat',
        custom: 'on'
    },
    {
        name: 'Custom',
        custom: 'on'
    }];




const Accessories = ({onSubmit, onCancel}) => {
    const {token, origin} = useParams<any>();
    const [vehicle, setVehicle] = useState('')
    const [data, setData] = useState<any>([])
    const [disableClick, setDisableClick] = useState(false);
    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());
    const originalDataRef = useRef<PendingInspection>(new PendingInspection());
    const toast = useToasts();
    const backLink = `/pending-inspection/${origin}/${token}/required-photos`;
    const forwardLink = `/pending-inspection/${origin}/${token}/notes`;

    const handleData = async (item2, data2, index) => {
        // console.log('item', item2, data2);

        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';

        const mutation = gql`
           ${`mutation($pending_${word}_inspection_id: Int, $accessorie: Accessories_input){
                add_update_accessorie_pending_${word}_inspection( pending_${word}_inspection_id:$pending_${word}_inspection_id, accessorie:$accessorie){
                    id
                    message
                    success
                }
            }`}
        `;

        const variables ={[`pending_${word}_inspection_id`]: originalDataRef.current.pending_inspection_id, accessorie: data2};

        console.log(variables);

        try {
           const data= await GraphqlService.SendMutation(mutation, variables)
           console.log(data);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
        
        
    }

    async function deleteAccessorie(name) {
        // console.log('here', name);
        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';
        const mutation = gql`
            mutation($pending_${word}_inspection_id: Int, $name: String) {
                remove_accesorie_pending_${word}_inspection(
                    pending_${word}_inspection_id: $pending_${word}_inspection_id
                    name: $name
                ) {
                    id
                    message
                    success
                }
            }
        `;

        const variables ={[`pending_${word}_inspection_id`]: originalDataRef.current.pending_inspection_id, name};

        try {

            const acc = currentData.accessories.filter(x => x.name.toLowerCase() != name.toLowerCase());
            setCurrentData({...currentData, accessories: acc});

            const data = await GraphqlService.SendMutation(mutation, variables)
            console.log(data);
        } catch (ex) {
            setCurrentData(new PendingInspection(originalDataRef.current));
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            
        }
    }

    useEffect(() => {
        originalDataRef.current.vehicle_data = originalData.vehicle_data;
        originalDataRef.current.accessories = originalData.accessories;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
    }, [originalData]);


    const getCount = (name) => currentData.accessories.find(x => x.name.toLowerCase() == name.toLowerCase())?.count || 0

    useEffect(() => {
        let query = InspectionObservable.GetObservable(token, origin?.toLowerCase() == 'true');
        
        let subs = query.onResults.subscribe(result => {
            if (result.error)
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            else
            {
                console.log('something changed');
                let o = new PendingInspection(result.data);
                o.is_origin = origin?.toLowerCase() == 'true';
                
                
                if (JSON.stringify(originalDataRef.current.accessories) != JSON.stringify(o.accessories) || originalDataRef.current.pending_inspection_id == null)
                {
                    setCurrentData(new PendingInspection(result.data));
                    
                    setOriginalData(o);
                    console.log(o.accessories)

                    if (originalDataRef.current.pending_inspection_id != null)
                        toast.addToast(`USER has updated inspection accessorie`, {appearance: 'warning', autoDismiss: true});
                }
            }
        });

        return () => {
            subs.unsubscribe();
            query.stopPolling();
        }
    }, []);


    return <>
        <Toolbar vehicle={currentData.vehicle_data} driver_name={currentData.driver_name} />

        <Content>
            <div className="mb-3">
                <h4 className="font-11">Accessories</h4>
                <h5 className="font-10 font-normal">Tap on an icon to add an accessory</h5>
            </div>
            <div className='flex-1-container'>
                <div className="col-12 mb-3">
                    <div className="row w-100 gx-2">

                        {
                            accessoriesList.map((item, i) => {
                                let count = getCount(item.name);
                                console.log('count', count);
                                return <div id={'acc-' + item.name.split(' ').join('-')} className="col-3 mt-1 position-relative mb-2">
                                    <CardInspection 
                                    onRemove={(name) => deleteAccessorie(name)}
                                    count={count}  onSubmit={value => {
                                        handleData(item.name, value, i)
                                    }} custom={item.custom} name={item.name} onSelect={e => console.log(e)}/>
                                </div>
                            })
                        }

                    </div>

                    {/* <div className="row mt-3">
                                <FormGroup name={'not_accessories'}
                                       checked={disableClick}
                                       label={'No accessories'}
                                       className={"d-flex check-label-i"}
                                       //colSize={6}
                                       type={'checkbox'}
                                       onCheckChanged={(event) => {
                                        setDisableClick(event)

                                       }}/>
                                </div>*/}


                    <div className="d-flex mt-3 flex-wrap">
                        {
                            currentData.accessories.sort((x, y) => x.name > y.name ? 1 : -1).map(item => {
                                return <div className="card-customItem mr-3" onClick={(evt) => {
                                    const acc = accessoriesList.find(x => x.name.toLowerCase() == item.name.toLowerCase());

                                    if (acc)
                                    {
                                        console.log(acc);
                                        let d = document.querySelector('#acc-' + acc.name.split(' ').join('-'))?.firstChild as any;
                                        d?.click();
                                    }

                                }}>
                                    <span>{item.name + " " + `(${item.count})`}</span>
                                    <img onClick={(evt) => {
                                        evt.stopPropagation();
                                        deleteAccessorie(item.name);
                                    }}
                                         src={ICONS.IconCloseBig} alt=""/>
                                </div>
                            })
                        }
                    </div>


                </div>
            </div>

            <div className="row mt-3">
                <div className="col-6">
                <button onClick={(evt) => Helper.Navigation.NavigateTo(backLink)} className="btn btn-back w-100"><i
                    className="fas fa-chevron-left"></i> REQUIRED PHOTOS
                </button>
                </div>
                <div className="col-6">
                    <button onClick={(evt) => Helper.Navigation.NavigateTo(forwardLink)}
                            className="btn btn-skyblue w-100">NEXT: NOTES
                    </button>
                </div>
            </div>
        </Content>
    </>
}

export default Accessories;
