import React, {useState, useEffect} from 'react'
import Helper from '../../helper/Helper';
import FormGroup from '../FormGroup/FormGroup';
import ICONS from '../../../assets/svg';
import {CarrierMain} from '../../classes/classes';
import useLoading from "../../hooks/useLoading/useLoading";
import {useToasts} from "react-toast-notifications";
import {GraphqlService} from "../../services/graphql.service";
import CarrierMutation from "../../graphql/mutation/Carrier";

const CompanyAccountInfoForm = ({onSubmit, onCancel, data}) => {
    const toast = useToasts();
    const loading = useLoading();

    const [carrier, setCarrier] = useState<CarrierMain>(new CarrierMain());
    const [email, setEmail] = useState("")

    useEffect(() => {
        setCarrier(data);
    }, [data]);

    const handleUpdateCarrier = () => {
        try {
            let data = {
                "id": carrier.id,
                "name": carrier.name,
                "multi_carrier": true,
                "code": carrier.code,
                "contact": {
                    "name": carrier.contact.name,
                    "email": carrier.contact.email,
                    "phone": carrier.contact.phone,
                }
            };

            loading.open('saving...');
            GraphqlService.SendMutation(CarrierMutation.CARRIER_INFORMATION, { carrier: data }).then(() => {
                loading.close();
                toast.addToast('Information saved successfully', {appearance: 'success', autoDismiss: true});

                onSubmit(carrier);
            });
        } catch (ex) {
            loading.close();
        }
    }


    return (
        <>
            <div className="flex-1-container">

                <div className="col-12 mb-3 d-flex row align-items-center">
                    <span className="fs-14 font-medium mb-0">Edit company & account holder info</span>
                </div>


                <div className="flex-1-container">
                    <div className={`row `}>

                        <div className="col-12 mb-3 d-flex row align-items-center">
                            <span className="fs-14 font-medium mb-0">Company info</span>
                            {/* <span onClick={(evt) => setCarrier(new CarrierMain())} className="text-gray fs-10 font-medium cursor-pointer no-select">CLEAR ALL</span> */}
                        </div>

                        <FormGroup name={'Companyname'}
                                   value={carrier?.name}
                                   required={true}
                                   label={'Company name'}
                                   placeholder={"ABC Logistics"}
                                   onTextChange={ (value) => setCarrier({...carrier, name: value}) } />


                        <FormGroup name={'Streetaddress'}
                                   value={""}
                                   required={true}
                                   label={'Street address'}
                                   placeholder={"Street address"}
                                   onTextChange={() => console.log('event')}
                        />


                        <FormGroup name={'city'}
                                   value={""}
                                   required={true}
                                   label={'City'}
                                   placeholder={"City"}
                                   onTextChange={() => console.log('event')}
                        />


                        <FormGroup name={'state'}
                                   label={'State'}
                                   colSize={6}
                                   required={true}
                                   type={'select'}
                                   value={""}
                                   placeholder={'--'}
                            //options={{data: states, value: x => x, label: x => x}}
                            // onTextChange={(value) => setdata({...data, state: value})}
                        />


                        <FormGroup name={'zipCode'}
                                   value={""}
                                   required={true}
                                   colSize={6}
                                   label={'Zip code'}
                                   placeholder={'00000'}
                                   onTextChange={() => console.log('event')}
                        />

                        <FormGroup name={'Terms'}
                                   value={""}
                                   required={true}
                                   label={'Terms'}
                                   placeholder={'Terms'}
                                   onTextChange={() => console.log('event')}
                        />

                        <div className="col-12 mb-3 d-flex row align-items-center">
                            <span className="fs-14 font-medium mb-0">Account holder info</span>
                            {/* <span className="text-gray fs-10 font-medium cursor-pointer no-select">CLEAR ALL</span> */}
                        </div>


                        <FormGroup name={'adminName'}
                                   value={carrier?.contact?.name || '-'}
                                   required={true}
                                   label={'Admin name*'}
                                   placeholder={'Admin name'}
                                   onTextChange={(value) => setCarrier({...carrier, contact: {...carrier.contact, name: value}})}/>

                        <FormGroup name={'jobTitle'}
                                   value={""}
                                   required={true}
                                   label={'Job title'}
                                   placeholder={'Job title'}
                                   onTextChange={() => console.log('event')}/>


                        <FormGroup required hintText={'Phone number must be xxx-xxx-xxxx'}
                                   errorText={'Phone number must be xxx-xxx-xxxx'}
                                   validation={x => x.length == 12}
                                   colSize={12}
                                   name={'phone'}
                                   label={'Phone #*'}
                                   placeholder={"000-000-0000 "}
                                   value={carrier?.contact?.phone || '-'}
                                   onTextChange={(value) => {
                                       let temp = Helper.Masks.USPhone(value);
                                   }}
                        />
                        <FormGroup name={'company_billing_adminEmail'}
                                   label={'Company billing/admin email*'}
                                   required
                                   colSize={12}
                                   value={carrier?.contact?.email || '-'}
                                   className={'mt-3'}
                                   type={'email'}
                                   placeholder={'abc@xyz.com'}
                                   onTextChange={(event) => setEmail(event)}/>


                        <FormGroup name={'password'}
                                   value={""}
                                   required={true}
                                   label={'Password'}
                                   placeholder={'***********'}
                            //onTextChange={()=>console.log('event')}
                        />

                        <ul className="password-requirements">
                            <li><img src={ICONS.IconCircleCheck} alt="icon-circle-check"/> At least 8
                                characters long
                            </li>
                            <li><img src={ICONS.IconCircleCheck} alt="icon-circle-check"/> Any other
                                requirements show up here
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="row mt-3 d-flex ">

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill text-light-blue"
                                onClick={onCancel}>CANCEL
                        </button>
                    </div>


                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill"
                                onClick={handleUpdateCarrier}>SAVE CHANGES
                        </button>

                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyAccountInfoForm