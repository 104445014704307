import React, {createRef, useEffect, useRef, useState} from "react";
import CardDetailDriver from "../../components/CardDetailDriver/CardDetailDriver";
import Modal from '../../components/Modal/Modal';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { GraphqlService } from '../../services/graphql.service';
import gql from 'graphql-tag';
import CarrierQueries from '../../graphql/query/Carrier';
import LegDetail from "../../components/LegDetail/LegDetail";
import DriverForm from "../../components/DriverForm/DriverForm";
import Helper from "../../helper/Helper";
import ReactModal from "react-modal";
import CompanyAccountInfoForm from "../../components/CompanyAccountInfoForm/CompanyAccountInfoForm";
import RegistrationInfoForm from "../../components/RegistrationInfoForm/RegistrationInfoForm";
import InsuranceInfoForm from "../../components/InsuranceInfoForm/InsuranceInfoForm";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

class CarrierContact
{
    name: string = '';
    phone: string = '';
    email: string = '';
    fax?: string = '';

    constructor(props?: CarrierContact)
    {
        if (props)
        {
            this.name = props.name;
            this.phone = props.phone;
            this.email = props.email;
            this.fax = props.fax || '';
        }
    }
}

class CarrierRegistration
{
    mc_number: string = '';

    constructor(props?: CarrierRegistration)
    {
        if (props)
        {
            this.mc_number = props.mc_number;
        }
    }
}

export class CarrierInsurance {
    name: string = '';
    policy_number: string = '';
    agent: CarrierContact = new CarrierContact();
    liability_coverage: number = 0;
    damage_coverage: number = 0;
    deductible: number = 0;
    expiration: Date;

    constructor(props?: CarrierInsurance)
    {
        if (props)
        {
            this.name = props.name;
            this.policy_number = props.policy_number;
            this.agent = new CarrierContact(props.agent);
            this.liability_coverage = props.liability_coverage;
            this.damage_coverage = props.damage_coverage;
            this.deductible = props.deductible;
            if (props.expiration)
                this.expiration = new Date(props.expiration);
        }
    }
}
class Carrier
{
    id: number;
    name: string = '';
    code: string = '';
    liability_disclaimer: string = '';
    internal_note: string = '';
    contact: CarrierContact = new CarrierContact();
    carrier_insurance: CarrierInsurance = new CarrierInsurance();
    carrier_registration: CarrierRegistration = new CarrierRegistration();
    drivers: any[] = [];
    payment_term: {terms: string} = {terms: ''};


    constructor(props?: Carrier)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.code = props.code;
            this.liability_disclaimer = props.liability_disclaimer;
            this.internal_note = props.internal_note;
            this.contact = new CarrierContact(props.contact);
            this.carrier_insurance = new CarrierInsurance(props.carrier_insurance);
            this.carrier_registration = new CarrierRegistration(props.carrier_registration);
            this.drivers = props.drivers.map(x => ({...x}));
            if (props.payment_term)
                this.payment_term = {...props.payment_term};
        }
    }
}

const COMPANY = 'COMPANY'
const INSURANCE = ' INSURANCE'
const REGISTRATION = 'REGISTRATION'


export class Settings
{
    notification_email: string = '';
    bol_terms: string = '';
    liability_disclaimer: string = '';
    phone: string[] = [];
    fax: string[] = [];

    constructor(props?: Settings)
    {
        if (props)
        {
            this.notification_email = props.notification_email;
            this.bol_terms = props.bol_terms;
            this.liability_disclaimer = props.liability_disclaimer;
            this.phone = [...props.phone];
            this.fax = [...props.fax];
        }
    }


    fillFromArray(array) {
        array.forEach(item => {
            
            if (item?.key == 'phone' || item?.key == 'fax')
                this[item?.key] = JSON.parse(item?.value);
            else
                this[item?.key] = item?.value;
            

        })
    }

}

export const CarrierProfilePage = () => {
    const [openModal, setopenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [carrier, setCarrier] = useState <Carrier>(new Carrier());
    const [showDriverModal, setShowDriverModal] = useState(undefined);
    const [dislcaimer, setDisclaimer] = useState('');
    const heightModal = 600;
    const [open,setOpen]=useState(false);
    const [settings, setSettings] = useState(new Settings());
    const [tab,setTab]=useState('')

    const toast = useToasts()
    
    useEffect(() => {
        getCarrierData();
        getSettings();
    }, []);

    async function getSettings() {
        const query = gql`
            query{
                get_all_settings{
                    key
                    value
                }
            } 
        `;
        try {
            const data = await GraphqlService.SendQuery(query);
            setDisclaimer(data?.find(x => x.key == 'liability_disclaimer')?.value || '');
            const x = new Settings();
            x.fillFromArray(data)
            setSettings(x);
        } catch (ex) {
            toast.addToast(ex?.message, {appearance: 'error', autoDismiss: true});
        }
    }

    async function getCarrierData(){
        try {
            setLoading(true);
            const data = await GraphqlService.SendQuery(CarrierQueries.CARRIER_SETTINGS_DATA);
            console.log(data);
            data.drivers = data?.drivers.sort((x, y) => x.name > y.name ? 1 : -1) || [];
            setCarrier(new Carrier(data));

            setLoading(false);
        } catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    }

    async function onSubmitRegistration(item){
        const registrationData ={
            "mc_number": item.carrier_registration.mc_number ,
            "carrier_id": null 
        }

        const mutation = gql`
            mutation ($registration: carrier_registration_input) {
                update_registration(registration:$registration) {
                mc_number
                id
                carrier_id

                }
            }`;

        const variables = {registration: registrationData }

        try {
            setLoading(true);
            const data = await GraphqlService.SendMutation(mutation,variables);
            if (!data.id)
                throw new Error(data.message);
            toast.addToast('Record Updated', {appearance: 'success',autoDismiss: true});
            setCarrier(new Carrier(item));
            setLoading(false);
        } catch (ex) {

            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
            console.log('ex', ex.message);
            setLoading(false);
        }
    }

    async function onSubmitInsurance(item){   
 
        const insuranceData ={
            "name":item.carrier_insurance.name,
            "policy_number":item.carrier_insurance.policy_number,
            "agent": item.carrier_insurance.agent,
            "liability_coverage":item.carrier_insurance.liability_coverage,
            "damage_coverage":item.carrier_insurance.damage_coverage,
            "deductible":item.carrier_insurance.deductible,
            "expiration":item.carrier_insurance.expiration,
            "carrier_id": null
    
        }
        
        const mutation = gql`
        mutation ($insurance: carrier_insurance_input) {
            update_insurance(insurance:$insurance) {
                id
                name
                policy_number
                agent {
                    name
                    phone
                    email
                }
                liability_coverage
                damage_coverage
                deductible
                expiration
                uploaded_documents
                carrier_id
            }
            }
        ` ;
              
        const variables = {insurance: insuranceData }

        try {
            setLoading(true);
            const data = await GraphqlService.SendMutation(mutation,variables);
            if (!data.id)
                throw new Error(data.message);
            toast.addToast("Record Update", {appearance: 'success',autoDismiss: true});
            setCarrier(new Carrier(item));
            setLoading(false);

        } catch (ex) {

            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
            console.log('ex', ex.message);
            setLoading(false);
        }
    }

    return (
        <>
            <div className="flex-1-container">
                <div className="row title-section-base">
                    <div className="col-12">Carrier Profile</div>
                </div>

                <div className='flex-1-container row-container'>
                    <div className="col-4 col-sm-12 d-flex">
                        <div className="flex-1-container">
                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                <div className="row">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section`}>Company</span>
                                        <span onClick={()=>{setOpen(true);setTab(COMPANY)}} className={`edit cursor-pointer no-select`}>Edit</span>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Name*</span>
                                        <p className={'value-p'}>{carrier?.name}</p>
                                    </div>
                                    {/* <div className="col-12 mt-2">
                                        <span className={'label-span'}>Address*</span>
                                        <p className={'value-p'}>1000 Main St, Suite 101 Los Angeles, CA
                                            90000</p>
                                    </div> */}
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Terms*</span>
                                        <p className={'value-p'}>{carrier?.payment_term?.terms || '-'}</p>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Contact*</span>
                                        <p className={'value-p mb-0'}>{carrier?.contact?.name || '-'}</p>
                                    </div>
                                    <div className="col-12">
                                        {/* <span className={'username-span'}>Admin</span> */}
                                        <span className={'phone-span'}>{carrier?.contact?.phone || '-'}</span>
                                        <span className={'email-span'}>{carrier?.contact?.email || '-'}</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section`}>Notification Settings</span>
                                        <span onClick={()=>{setOpen(true);setTab(COMPANY)}} className={`edit cursor-pointer no-select`}>Edit</span>
                                    </div>
                                    <div>
                                        <span className={'label-span'}>Email</span>
                                            <p className={'value-p'}>{settings.notification_email}</p>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <span className={'label-span'}>Phones</span>
                                            <p className={'value-p mb-0'}>{settings.phone.map(x => <li>
                                                    {x}

                                            </li>)}</p>
                                    </div>

                                    <div className="col-12 mt-2">
                                            <span className={'label-span'}>Fax</span>
                                            <p className={'value-p mb-0'}>{settings.fax.map(x => <li>
                                                    {x}

                                            </li>)}</p>
                                    </div>
                            </div>

                            <div className="col-12 bg-white p-3 main-radius mb-2">
                                <div className="row">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section`}>Registration</span>
                                        <span onClick={()=>{setOpen(true);setTab(REGISTRATION)}} className={`edit cursor-pointer no-select`}>Edit</span>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>DOT or MC</span>
                                                <p className={'value-p'}>{carrier.carrier_registration.mc_number || '-'}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* x`x<div className="col-12 mt-2">
                                        <span className={'label-span'}>Tax ID #*</span>
                                        <p className={'value-p'}>{carrier.carrier_registration.tax_number || '-'}</p>
                                    </div> */}
                                    {/* <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section label-span`}>Files</span>
                                        <span className={`upload`}>UPLOAD</span>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-6">
                                                <img
                                                    src="https://www.virginplus.ca/assets/en/images/billexplained-bill2.jpg"
                                                    className="img-div w-100" alt=""/>
                                            </div>
                                            <div className="col-6">
                                                <img
                                                    src="https://www.virginplus.ca/assets/en/images/billexplained-bill2.jpg"
                                                    className="img-div w-100" alt=""/>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 bg-white p-3 main-radius">
                                <div className="row">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <span className={`subtitle-section`}>Insurance</span>
                                        <span onClick={()=>{setOpen(true);setTab(INSURANCE)}} className={`edit cursor-pointer no-select`}>Edit</span>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Insurance company name*</span>
                                                <p className={'value-p'}>{carrier.carrier_insurance.name || '-'}</p>
                                            </div>
                                            <div className="col-6">
                                                <span className={'label-span'}>Policy #*</span>
                                                <p className={'value-p'}>{carrier.carrier_insurance.policy_number || '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <span className={'label-span'}>Agent*</span>
                                        <p className={'value-p mb-0'}>{carrier.carrier_insurance.agent.name || '-'}</p>
                                    </div>
                                    <div className="col-12">
                                        <span className={'phone-span'}>{carrier.carrier_insurance.agent.phone || '-'}</span>
                                        <span className={'email-span'}>{carrier.carrier_insurance.agent.email || '-'}</span>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Liability coverage*</span>
                                                <p className={'value-p'}>{carrier.carrier_insurance.liability_coverage.toCurrency('$',true,false)}</p>
                                            </div>
                                            <div className="col-6">
                                                <span className={'label-span'}>Damage coverage*</span>
                                                <p className={'value-p'}>{carrier.carrier_insurance.damage_coverage.toCurrency('$',true,false)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-2">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Deductible*</span>
                                                <p className={'value-p'}>{carrier.carrier_insurance.deductible.toCurrency('$',true,false)}</p>
                                            </div>
                                            <div className="col-6">
                                                        <span
                                                            className={'label-span'}>Expiration date(dd/mm/yyyy)</span>
                                                <p className={'value-p'}>{carrier.carrier_insurance.expiration ? Helper.FORMAT.USDate(carrier.carrier_insurance.expiration, 'do') : '-'}</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 col-sm-12 d-flex bg-white  p-3 main-radius">
                        <div className="row flex-1-container">
                        <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-3">
                                        <span className={`subtitle-section`}>Liability disclaimer</span>
                                        <span className={`edit`}
                                              onClick={() => {
                                                  setopenModal(true);
                                              }}>
                                            Edit</span>
                                    </div>
                                    <div className="col-12 flex-1-container">
                                        <p className={'value-p font-normal m-0'}>
                                            <div dangerouslySetInnerHTML={{__html: settings.liability_disclaimer}}>

                                            </div>
                                        </p>
                                    </div>
                        </div>
                    </div>
                   
                </div>

            </div>
            
            <Modal
                visiblePopUpModal={openModal}
                handleClickOutPopupModal={() => {
                    setopenModal(false)
                }}
            >
                <div className="modalpopcontainer row w-100" style={{minHeight: `${heightModal}px`}}>
                    <div className="col-12">
                        <span className="font-18 font-medium-500">Liability disclaimer</span>
                    </div>

                    <div className="col-12 font-14 font-normal mt-3">
                        <CKEditor
                            className="h-75"
                            editor={ClassicEditor}
                            onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        'height',
                                        `${(heightModal / 2) + 50}px`,
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                        />
                    </div>

                    <div className="col-12 font-14 font-normal mt-2">
                        <div className="row">
                            <div className="col-6">
                                <button onClick={() => setopenModal(false)}className="btn w-100 text-center btn-mute">Close</button>
                            </div>
                            <div className="col-6">
                                <button className="btn w-100 text-center btn-skyblue">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ReactModal className="modal-fit modal-90" isOpen={open} onRequestClose={()=>setOpen(false)}>
                {
                    tab === COMPANY &&
                    <CompanyAccountInfoForm onCancel={()=>setOpen(false)}
                                            onSubmit={(carrier) => { setCarrier(new Carrier(carrier)); setOpen(false); }}
                                            data={carrier} />
     
                }
                {   
                    tab === REGISTRATION &&
                    <RegistrationInfoForm onCancel={()=>setOpen(false)} onSubmit={value => {onSubmitRegistration(value);setOpen(false)}} data={carrier}/>
                }
                {
                    tab === INSURANCE &&         
                    <InsuranceInfoForm onCancel={()=>setOpen(false)} onSubmit={value => {onSubmitInsurance(value);setOpen(false)} } data={carrier}/>
                }
            </ReactModal>
        </>
    )
}

export default CarrierProfilePage;