import IReducerAction from "../models/IReducerAction";
import loadingTypes from "../types/Loading.type";


export interface ILoading {
    open: boolean,
    text: string
}

const intialState = {
    open: false,
    text: ''
}

export function loadingReducer(state: ILoading = intialState, action: IReducerAction): ILoading
{
    switch (action.type)
    {
        case loadingTypes.OPEN:
            return {
                open: true,
                text: action.payload
            }
        case loadingTypes.CLOSE:
            return {
                open: false,
                text: ''
            }
    }
    return state;
}