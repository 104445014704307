import { gql } from 'apollo-boost';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../assets/svg';
import DriverForm from '../../../components/DriverForm/DriverForm';

import FormGroup from '../../../components/FormGroup/FormGroup';
import Loading from '../../../components/Loading/Loading';
import Searchbar from '../../../components/Searchbar/Searchbar';
import Helper from '../../../helper/Helper';
import useLoading from '../../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../../services/graphql.service';
// import DriverForm from '../DriverForm/DriverForm';

type IProps =
{
    carrier_id: number,
    driver_id: number,
    onDriverSelected: (driver) => any,
    onClose: (evt) => any
}


const DriverSelector: FC<IProps> = (props) => {

    const autoSelectID = useRef<boolean>(null);
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openForm, setOpenForm] = useState(false);
    const ref = useRef<HTMLInputElement>();
    const toast = useToasts();
    const [driverID, setDriverID] = useState<number>(null);
    const [searchTerm, setSearchTerm] = useState('');


    async function loadDrivers() {
        try {
            const query = gql`  
                query($carrier_id: Int){
                    get_drivers_by_carrier_id(carrier_id:$carrier_id){
                        id
                        name
                        contacts{
                            title
                            type
                            value
                        }
                    }
                }
            `;

            Helper.UseApiKey();
            setLoading(true);
            const data = await GraphqlService.SendQuery(query, {carrier_id: props.carrier_id});

            setDrivers(data.sort((x, y) => x.name > y.name ? 1 : -1));
            setOpenForm(data.length == 0);
            setLoading(false);
            
        } catch (ex) {
            autoSelectID.current = false;
            console.log('ex', ex.message);
            setLoading(false);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }


    useEffect(() => {
        setDriverID(props.driver_id);
    }, [props.driver_id]);


  

    useEffect(() => {
        console.log(props.carrier_id)
        if (props.carrier_id)
            loadDrivers();
    }, [props.carrier_id]);

    useEffect(() => {
        if (autoSelectID.current)
        {
            let d = drivers.find(x => x.id == autoSelectID.current);
            if (d)
                props.onDriverSelected(d)
            autoSelectID.current = false;
        }
    }, [drivers]);
    
    return <div className='flex-1-container'>
        <div className="row mb-2">
            <h4 className="font-12">{openForm ? 'Create A Driver' : 'Select A Driver'}</h4>
            <div className='d-flex align-items-center'>
                
                {!openForm && <h4 onClick={evt => setOpenForm(true)} className="text-light-blue font-10 mr-2 cursor-pointer">CREATE DRIVER <i className='fas fa-plus d-inline-block ml-1'></i></h4>}

                <h4 className="ml-2 font-12" onClick={props.onClose}><i className='fas fa-times'></i></h4>
            </div>
            
        </div>

        {!openForm && <div className="mb-3">
            <Searchbar value={searchTerm} onChange={({target: {value}}) => setSearchTerm(value)} reference={ref} />
        </div>}
        

        <div className="flex-1-container">
            {(loading && !openForm) && <div>
                <Loading />    
                <h4 className="font-12 text-center">Loading....</h4>
            </div>}
            {(!loading && !openForm) && drivers.filter(x => JSON.stringify(x).toLowerCase().includes(searchTerm.toLowerCase().trim())).map((driver, index) => {
                return <div key={`driver-pc-${index}`} onClick={evt => setDriverID(driver?.id)} className={`p-3 cursor-pointer mb-${index == drivers.length - 1 ? '' : '3'} bg-${driverID == driver.id ? 'blue' : 'gray'} rounded`}>
                    <h4 className="font-11 mb-1">{driver.name}</h4>
                    <p className="font-10">{driver.contacts.find(x => x.type?.toLowerCase() == 'email')?.value}</p>
                    <p className="font-10">{driver.contacts.find(x => x.type?.toLowerCase() == 'phone')?.value}</p>
                </div>
            })}
            {openForm && <div className='flex-1-container'>
                <DriverForm driver={null} drivers={drivers} carrier_id={props.carrier_id} onSubmit={id => {

                    setOpenForm(false);
                    if (!id) return;
                        autoSelectID.current = id;

                    setDriverID(id?.id);
                    loadDrivers();
}} /></div>}
        </div>

        {!openForm && <div className="row mt-3">
            <button className="btn btn-clear text-light-blue col-6" onClick={props.onClose}>CANCEL</button>
            <button onClick={() => props.onDriverSelected(drivers.find(x => x.id == driverID))} className="btn btn-blue-light col-6">SELECT</button>
        </div>}
    </div>
}

export default DriverSelector;