import React, { Children, useReducer } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import Loading from '../components/Loading/Loading';
import useLoading from '../hooks/useLoading/useLoading';
import { AllStates } from '../redux/reducers';
import { ILoading } from '../redux/reducers/Loading.reducer';





const LoadingProvider = ({children}) => {

    const loading = useSelector<AllStates>(x => x.loading) as ILoading;

    return <>
        {children}
         {loading.open && <ReactModal className={'loading-modal'} isOpen={loading.open} style={{content: {padding: '0 1rem 1rem 1rem', minWidth: '300px', borderRadius: '.5rem', width: 'max-content', maxWidth: '90%', border: 'none', height: 'max-content'}}}>
            <Loading />
            <h4 className='fs-16 text-center'>{loading.text}</h4>

        </ReactModal>}
    </>
}

export default LoadingProvider;