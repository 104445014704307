import {PointClass} from "./PointClass";

export class MarkerClass{
    id: any;
    position: PointClass = new PointClass();
    text: string = '';
    selected: boolean = false;
    ref?: any;
    onClick: (marker: MarkerClass) => void;

    constructor(props) {
        if(props){
            this.id = props.id;
            this.position = new PointClass(props.position);
            this.text = props.text;
            this.selected = props.selected;
            this.ref = props.ref;
            this.onClick = props.onClick;
        }
    }
}
