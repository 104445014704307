import {useState} from "react";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import environments from "../../../environments/environments";
import { Address } from "../../classes/classes";
import CustomerQueries from "../../graphql/query/Customer";
import { GraphqlService } from "../../services/graphql.service";
import {useEffect} from "react";

interface PlaceRes{
    formatted: string;
    address: Address;
}

async function GetPlaceDetails(place_id: string){
    try {
        let res: PlaceRes = await GraphqlService.SendQuery(CustomerQueries.GET_GOOGLE_PLACE, {placeid: place_id});
        return res;
    } catch (error) {
        console.log('Error decoding place with Google API: ',error);
        return null;
    }
}

const AddressSearch = ({className = "", onGotAddress}: {className?: string, onGotAddress: (e: Address) => void}) => {
    const [google, setGoogle] = useState(null);
    const [address, setAddress] = useState<Address>(new Address());

 


     useEffect(() => {;
        document.querySelector('.css-1wy0on6 > div ')?.setAttribute("id", "Clear")

        const handleClearInput=()=>{
            const nodeInput = document.getElementById('react-select-3-input') 
            const nodeValue = document.querySelector('.css-1uccc91-singleValue')
            if(document.querySelector('.css-1wy0on6 > div') && document.querySelector('.css-1wy0on6 > div').classList.contains('css-tlfecz-indicatorContainer') === false){
                nodeInput.setAttribute('value','')
                if (nodeValue != null){
                    nodeValue.textContent = ""
            
                }
        
            }
        }

        document.getElementById('Clear')?.addEventListener("click", handleClearInput)
        
    }, []);  


    return(
        <>
            <p className={`${className} font-14 mb-2`}>Use Google Search</p>

            <div className={`${className} mb-3`} onFocus={(evt) => {
                
            }}>
                <GooglePlacesAutocomplete 
                    apiKey={environments.GOOGLE_API}
                    selectProps={{
                        google,
                        onChange: async (e) =>{
                            setGoogle(e);
                            let res = await GetPlaceDetails(e.value.place_id);
                            if(res){
                                e.value.description = res.formatted;
                                e.label = res.formatted;
                                onGotAddress(res.address);
                                setAddress(res.address);
                                setGoogle(e);
                            }
                        },
                        placeholder: 'Search here...'
                        }}
                />
            </div>
        </>
    );
}

export default AddressSearch;