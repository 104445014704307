import IReducerAction from "../models/IReducerAction";
import NotificationTypes from "../types/Notifications.type";


export interface INotifications
{
    data: { id: number, title: string, message: string, time: Date, type: string }[],
    loading: boolean,
    error: string,
    last_id: number
}

const initialState: INotifications = {
    data: null,
    loading: false,
    error: null,
    last_id: 0
}

export default function NotificationsReducer(state: INotifications = initialState, action: IReducerAction): INotifications
{
    switch (action.type)
    {
        case NotificationTypes.GET_ALL_NOTIFICATIONS_STARTED:
            return {
                ...state,
                loading: action.payload,
                error: null
            }
        case NotificationTypes.GET_ALL_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            }
        case NotificationTypes.GET_ALL_NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case NotificationTypes.SET_LAST_ID:
            return {
                ...state,
                last_id: action.payload
            }
        default:
            return state;
    }
}
