import DispatchTable from "../../components/DispatchTable/DispatchTable";
import DispatchDrivers from "../../components/DispatchDrivers/DispatchDrivers";
import DispatchItinerary from "../../components/DispatchItinerary/DispatchItinerary";
import { useState } from "react";
import useScreenDimension from "../../hooks/ScreenDimension/ScreenDimension";
import DispatchPageMobile from "./DispatchPageMobile";
const DISPATCH_STATUS = {
    ALL: 'ALL',
    NOT_DISPATCHED: 'NOT_DISPATCHED',
    DISPATCHED: 'DISPATCHED'
};

const DispatchPage = () => {
    const [driverSelected, setDriverSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const { isMobile } = useScreenDimension();

    const statusesOptions = [
        {
            label: 'All',
            key: 'ALL',
            value: DISPATCH_STATUS.ALL
        },
        {
            label: 'Not dispatched',
            key: 'NOT_DISPATCHED',
            value: DISPATCH_STATUS.NOT_DISPATCHED
        },
        {
            label: 'Dispatched',
            key: 'DISPATCHED',
            value: DISPATCH_STATUS.DISPATCHED
        }
    ]

    if(isMobile)
        return <DispatchPageMobile statusesOptions={statusesOptions}/>

    return(
        <>
            <div className="col-12 flex-1-container">
                <div className="row title-section-base ">
                        <div className="col-12">
                            Dispatch
                        </div>
                </div>
                <div className="flex-1-container row-container col-12">

                        <div className="col-6 d-flex p-3 bg-white main-radius">
                           <div className="flex-1-container">
                           <DispatchTable 
                           onRowClicked={(evt) => {
                               console.log(evt);
                           }}
                           onRefreshStarted={() => {
                                setLoading(true);
                                setTimeout(() => setLoading(false));
                            }} />
                           </div>
                        </div>

                        <div className="col-3 d-flex bg-white p-3 main-radius">
                           {!loading && <div className='flex-1-container'>
                           <DispatchDrivers driverSelected={driverSelected?.id} onDriverClicked={(evt) => setDriverSelected(evt)} /></div>}
                        </div>

                        <div className="col-3 p-3 d-flex bg-white main-radius">
                            {!loading && <div className='flex-1-container'>
                                    <DispatchItinerary driverSelected={driverSelected} />
                                </div>}
                        </div>
                    </div>
            </div>
        </>
    );
}

export default DispatchPage;
