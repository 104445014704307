import { gql } from "apollo-boost"
import { GraphqlService } from "../../services/graphql.service"
import documentsTypes from "../types/Documents.type";

const query = gql`
    query {
        verify_carrier_documents {
            message
            insurance
            registration
            expiration
            tax_info
        }
    }
`


export function checkDocuments() {
    return async (dispatch) => {
        try {
            dispatch(checkDocumentsStarted());
            const data = await GraphqlService.SendQuery(query);
            dispatch(checkDocumentsSuccess(data));
        } catch (ex) {
            dispatch(checkDocumentsError(ex.message));
        }
    }
}

const checkDocumentsStarted = () => ({type: documentsTypes.CHECK_DOC_STARTED, payload: true});
const checkDocumentsSuccess = (data) => ({type: documentsTypes.CHECK_DOC_SUCCESS, payload: data});
const checkDocumentsError = (error) => ({type: documentsTypes.CHECK_DOC_STARTED, payload: error});