import Helper from '../../helper/Helper';
import React, { useEffect, useRef, useState } from 'react';

import './LoginPage.scss';
import AuthService from '../../services/auth.service';
import { GraphqlService } from '../../services/graphql.service';
import ICONS from '../../../assets/svg';
import environments from '../../../environments/environments';
import { useLocation } from 'react-router';

import logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const LoginPage = () => {
    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const {pathname} = useLocation();
    const userRef = useRef<HTMLInputElement>();
    const passwordRef = useRef<HTMLInputElement>();

    const clear = () => {
        setUserName('')
        setPassword('')
    }

    const array = pathname.split('/');

    array.splice(0, 2);

    // console.log()
    const uri = array.join('/');
    const isValid = () => username.trim() && password.trim();


    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        let data = {
            username,
            password
        }


        if (!isValid() || loading) return;

        setLoading(true);
        fetch(environments.LOGIN_LINK, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'secret'
            },
            method: 'POST',
            body: JSON.stringify({useremail: username, password})
        })
        .then(d => d.json())
        .then((result) => {
            if (result.token)
            {
                setLoading(false);
                AuthService.TOKEN = result.token;
                AuthService.PROFILE = result.profile;

                localStorage.clear();
                localStorage.setItem('carrier_token', AuthService.TOKEN);
                localStorage.setItem('profile', JSON.stringify(result.profile));

                if (result.documents?.message)
                    Helper.Navigation.NavigateTo('/carrier-documents');
                else
                    Helper.Navigation.NavigateTo(`/${uri || 'dispatch'}`);

            }
            else{
                setLoading(false);
                setErrorText(result.message)
            }
        })
        .catch(err => {
            setLoading(false)
            setErrorText(err.message)
        })


    }

    useEffect(() => {

    }, [])

    return (
        <>
        <div className='login-container'>
            <img src={process.env.PUBLIC_URL + '/favicon.png'} style={{width: '100%', display: 'block', margin: '0 auto 1rem auto'}} alt="" />
            <h4>Log In</h4>

            <form onSubmit={handleSubmit.bind(this)}>
                <div className='input-group'>
                    <img src={ICONS.IconUser} className='left' />
                    <input ref={userRef} value={username} onChange={(evt) => setUserName(evt.target.value)} id='name' name='email' inputMode='email' placeholder='Email' />
                    {username != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setUserName(''); userRef.current?.focus()}} /> : null}
                </div>
                <div className='input-group'>
                    <img src={ICONS.IconLocked} className='left' />
                    <input ref={passwordRef} autoComplete='off' type='password' value={password} onChange={(evt) => setPassword(evt.target.value)} id='password' name='password' placeholder='Password' />
                    {password != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setPassword(''); passwordRef.current?.focus()}} /> : null}
                </div>

                {errorText && <p className='wrong'>{errorText}</p>}

                <button disabled={!isValid() || loading}
                        type='submit'>LOG IN{loading && <span className='doing-login'>...<span></span></span>}</button>


                <Link to='/forgot-password' className="font-12 d-block text-light-blue cursor-pointer mt-3">Forgot or don't have a password? Click here</Link>
            </form>
        </div>


        </>
    )
}

export default LoginPage;
