import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { User } from '../../classes/classes';
import DriverSignature from '../../components/DriverSignature/DriverSignature';
//import UserQueries from '../../graphql/query/User.query';
import { GraphqlService } from '../../services/graphql.service';
import Accessories from './pages/Accessories/Accessories';
import Confirmation from './pages/Confirmation/Confirmation';
import Notes from './pages/Notes/Notes';
import RequiredPhotos from './pages/RequiredPhotos/RequiredPhotos';
import VehicleDamages from './pages/VehicleDamages/VehicleDamages';
import VehicleDetails from './pages/VehicleDetails/VehicleDetails'; 


import './PendingInspectionPage.scss';

const PendingInspectionPage = () => {
    const {pathname} = useLocation();
    const [driverID, setDriverID] = useState<number>();

    const [needsSignature, setNeedsSignature] = useState(false);

    useEffect(() => {
        const temp = pathname.split('/').filter(x => x.trim() != '');
        if (temp.length == 3 && temp[0] == 'pending-inspection')
        {
            checkSignature(temp[1]?.toLowerCase() == 'true', temp[2]);
        }
    },[])

  async function checkSignature(is_origin: boolean, token: string) {
    try {
      const qq = gql`
          query($token:String, $is_origin:Boolean){
              get_driver_info_by_inspection_token(is_origin:$is_origin, token:$token){
                  driver_id
                  username
                  code
              }
          }
      `;
      const res: {driver_id: number,username: string,code:string} = await GraphqlService.SendMutation(qq, {is_origin, token});
  
  
      const query = gql`
        query ($driver_id: Int) {
          get_driver_signature(driver_id: $driver_id) {
            message
            success
          }
        }
      `;
      setDriverID(res.driver_id);
      const data = await GraphqlService.SendQuery(query, {driver_id: res.driver_id});
      if (!data?.success) {
        throw new Error(data?.message);
      }
    } catch (ex) {
      console.log(ex.message);
      setNeedsSignature(true);
    }
  }


    return (
        <>
        {needsSignature ? (
          <DriverSignature onSignatureSaved={() => setNeedsSignature(false)} driver_id={driverID} />
        ) : (
    <div className='pending-inspection-page flex-1-container'>
            <Switch>

                <Route exact path='/pending-inspection/:origin/:token' component={VehicleDetails}/>
                <Route path='/pending-inspection/:origin/:token/vehicle-damages' component={VehicleDamages} />
                <Route path='/pending-inspection/:origin/:token/required-photos' component={RequiredPhotos} />
                <Route path='/pending-inspection/:origin/:token/accessories' component={Accessories} />
                <Route path='/pending-inspection/:origin/:token/notes' component={Notes} />
                <Route path='/pending-inspection/:origin/:token/confirmation' component={Confirmation} />
            </Switch>
    </div>)}
 
    </>
    )
}

export default PendingInspectionPage;