import { gql } from 'apollo-boost';
import React, { useRef, useState } from 'react';
import logo from '../../../assets/images/logo.png';
import ICONS from '../../../assets/svg';
import FormGroup from '../../components/FormGroup/FormGroup';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';

const ForgotPasswordPage = () => {

    const userRef = useRef<HTMLInputElement>();
    const [email, setEmail] = useState<string>('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const [successText, setSuccessText] = useState('');

    const isValid = () => email.trim() != '' && Helper.Validators.IsValidEmail(email);
    async function handleSubmit(evt) {
        evt.preventDefault();
        setLoading(true);
        const mutation = gql`mutation($email: String){
            request_carrier_profile_password_reset(email:$email){
              success
              message
            }
          }`;
        try {
            Helper.UseApiKey();
            const data = await GraphqlService.SendQuery(mutation, {email}, false, true);
            console.log(data);
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            setLoading(false);
            setSuccessText(data.message);
        }
        catch (ex) {
            setLoading(false);
            setErrorText(ex.message);
        }
    }
    return   <div className='login-container flex-1-container'>
    <img src={process.env.PUBLIC_URL + '/favicon.png'} style={{width: '100%', display: 'block', margin: '0 auto 1rem auto'}} alt="" />
    <h4>Recover Password</h4>

    {!successText && <form onSubmit={handleSubmit.bind(this)}>
        <FormGroup validation={x => Helper.Validators.IsValidEmail(x)} errorText={'Email format must be abc@xyz.com'} hintText={'Email format must be abc@xyz.com'} name='email' label='Email' required placeholder='abc@xyz.com' value={email} onTextChange={setEmail} />
        
        {errorText && <p className='wrong'>{errorText}</p>}

        <button disabled={!isValid() || loading}
                type='submit'>Recover Password{loading && <span className='doing-login'>...<span></span></span>}</button>



    </form>}
    {successText && <p className="font-12">{successText}</p>}
</div>
}

export default ForgotPasswordPage;