import React, {useState, useRef, useEffect} from 'react'
import './InspectionReport.scss'
import LegDescription from "./components/LegDescription";
import {useParams} from "react-router";
import {GraphqlService} from "../../services/graphql.service";
import InspectionQueries from "../../graphql/query/Inspection";

import Loading from "../../components/Loading/Loading";
import Helper from '../../helper/Helper';


const InspectionDetails = () => {

    const {token} = useParams<{token}>();
    const [inspection, setInspection] = useState(null);
    const [loading, setLoading] = useState(false);



    async function getInspection()
    {
        try
        {
            setLoading(true);
            Helper.UseApiKey();
            const data = await GraphqlService.SendQuery(InspectionQueries.GET_BY_TOKEN, {token: token});
            // console.log(data);
            // data[0].damages = [];
            // data[0].accessories = [];
            // data[0].flags.too_dirty = true;
            // data[0].flags.too_dark = true;
            setInspection(data[0]);
        }
        catch (ex)
        {
            console.log(ex.message);
            setLoading(false);
        }
    }


    useEffect(() => {
        getInspection().then();

        // let z = S3Service.GetTempLink('inspections/inspection_3/photos/damage_order_4_pickup_3_132677583450794790_1623284750678.jpg.thumbnail.jpeg');
        // console.log(z);
    }, [])


    return(
        <>
            {loading && <>
                <Loading />
                <p className={'text-center font-medium font-16 mt-3'}>Loading inspection...</p></>}
            {inspection && <div style={{display: loading ? 'none' : 'block' }} className="col-12 inspection-detail-container">
                <div className="row">
                    <div className="col-12 mb-5">
                        <p className="font-medium font-16">Inspection report</p>
                    </div>

                    <div className="col-6">
                        <p className="font-medium font-12 text-gray mb-3">Vehicle</p>
                        <p className="font-medium font-16 mb-1">{inspection.vehicle.year} {inspection.vehicle.make} {inspection.vehicle.model}</p>
                        <p className="font-14">{inspection.vehicle.vin}</p>

                        <div className="d-flex mt-4 mb-4">
                            <p className="font-14 mr-5">{inspection.vehicle.color}</p>
                            <p className="font-14">{inspection.vehicle.type}</p>
                        </div>
                    </div>

                    {/*<div className="col-6 text-right">*/}
                    {/*    <p className="font-medium font-12 text-gray mb-3">Delivered by</p>*/}
                    {/*    <p className="font-medium font-16 mb-1">{inspection?.driver?.carrier?.name}</p>*/}
                    {/*    <p className="font-14">{inspection.performed_by}</p>*/}
                    {/*</div>*/}

                    <div className="col-12">
                        <LegDescription dataResolved={(evt ) => setLoading(false)} inspection={inspection} />
                    </div>

                    {/*<div className="col-6">*/}
                    {/*    <LegDescription title={'Dropoff'}/>*/}
                    {/*</div>*/}
                </div>

            </div>}
        </>
    )
}

export default InspectionDetails
