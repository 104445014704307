import React, { useState } from  'react'
import { useSelector } from "react-redux";
import { IOrderInternal } from "../../redux/reducers/Order.reducer";
import { AllStates } from "../../redux/reducers";
import ICONS from '../../../assets/svg'

const DISPATCH_STATUS = {
    ALL: 'ALL',
    NOT_DISPATCHED: 'NOT_DISPATCHED',
    DISPATCHED: 'DISPATCHED'
};

const DispatchPageMobile =({statusesOptions})=>{
    const[dispatchStatus,setDispatchStatus]=useState(DISPATCH_STATUS.ALL)
    const ordersOpen = useSelector<AllStates>(x => x.orders.open) as IOrderInternal;

    const getOrdersByStatus = (key = '') => {
        if (!key || key == 'ALL')
            return ordersOpen.data;

        let dispatched = key == 'DISPATCHED';

        return ordersOpen.data.filter(x => x.dispatched == dispatched);
    }

    return(
        <>
             <div className="col-12 bg-white text-center">
                <p className="font-14 font-medium pb-3">Dispatch</p>
            </div>

            <div className="col-12 bg-white d-flex align-items-center justify-content-evenly">
                {
                    statusesOptions.map((e, index) => {
                        return <p key={index}
                                  className={`d-flex m-0 pb-2 px-3 font-10 font-medium order-item ${e.value === dispatchStatus ? 'item-selected' : ''}`}
                                  onClick={() => setDispatchStatus(e.value)}>
                                      {e.label} 
                                    <div className="number">
                                       { getOrdersByStatus(e.value).length }
                                    </div>
                                </p>
                    })
                }
            </div>
            <div className="col-12 flex-1-container mt-3">
                {
                    getOrdersByStatus(dispatchStatus).map((dispatch, key) => {
                        return(
                            <div key={key}
                                 className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="mb-1 font-medium font-10 text-gray">{ dispatch.origin.getDate() }</p>
                                            <p className="mb-1 font-medium font-10 text-gray">{ dispatch.destination.getFormattedDateTime() }</p>
                                        </div>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="mb-1 font-medium font-12">{ dispatch.origin.address.city }</p>
                                            <p className="mb-1 font-medium font-12">{ dispatch.destination.address.city }</p>
                                        </div>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="font-12">{ dispatch.origin.address.state } { dispatch.origin.address.postal_code }</p>
                                            <p className="font-12">{ dispatch.destination.address.state } { dispatch.destination.address.postal_code }</p>
                                        </div>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="mb-3 font-medium font-12">{ dispatch.vehicle.year } { dispatch.vehicle.make } { dispatch.vehicle.model }</p>
                                            <p className="mb-3 font-medium font-12">{ dispatch.price }</p>
                                        </div>

                                        <hr/>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <div>
                                                <p className="mb-1 font-12 font-medium">O-{ dispatch.id }</p>
                                                { dispatch.dispatched && <p className="mb-1 font-10 font-medium blue">Dispatched</p> }
                                            </div>

                                            <div className="d-flex align-items-center justify-content-between">
                                                <img className="mr-2 mb-1" src={ICONS.IconDriver}/>
                                                <p className="mb-1 font-12 font-medium">{ dispatch.driver.name }</p>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default DispatchPageMobile