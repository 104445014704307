import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Content from '../../../components/Content/Content';
import Toolbar from '../../../components/Toolbar/Toolbar';
import InspectionObservable from '../../../observables/InspectionObservable';
import { useToasts } from 'react-toast-notifications';
import {gql} from 'apollo-boost';
import CardHistoryJobs from '../../../components/CardHistoryJobs/CardHistoryJobs';
import CardHistoryInspections from '../../../components/CardHistoryInspections/CardHistoryInspections';
import useScreenDimension from '../../../../../hooks/ScreenDimension/ScreenDimension';
import PullToRefresh from 'react-simple-pull-to-refresh';
import Jobs from '../Jobs/Jobs';
import Inspection from '../Inspections/Inspections';
import DateTimePicker from '../../../../../components/DateTimePicker/DateTimePicker';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { GraphqlService, ObservableQueryT } from '../../../../../services/graphql.service';
import Helper from '../../../../../helper/Helper';
import './driverItinerary.scss';
import { stringify } from 'querystring';

const queryJobs = gql`
    query($from_date: String!, $to_date: String!){
  get_driver_orders(from_date: $from_date, to_date: $to_date){
    id
    vehicle{
      year
      make
      model
      vin
    }
    destination{
      time_completed
      completed
      scheduled
    }
    origin{
      completed
      scheduled
      time_completed
    }
    remaining_driver_payable
    driver_pay
    completed
  }
}
`;

interface ITinerary {
    address: string;
    orders: {
        pending_change_request: boolean;
        change_requests: {
            id: number;
            order_id: number;
            accepted: boolean;
            rejected: boolean;
            time: string;
            payout: number;
            note: string;
            active: boolean;
            expired: boolean;
            expires_at: string;
            weblink: string;
            code: string;
        }[];
        id: number;
        vehicle: {
            id: number;
            vin: string;
            year: string;
            make: string;
            model: string;
            submodel: string;
            type: string;
            color: string;
        }
        order_id: number;
        is_pickup: boolean;
        scheduled: string;
        completed: boolean;
        nofail: boolean;
        timezoneName: string;
        contacts: {
            name: string;
            value: string;
        }[];
        address: string;
    }[];
}

interface IDriverItinerary{
    id: number;
    name: string;
    price: number;
    username: string;
    carrier: string;
    carrier_id: number;
    equipment: string;
    gps: {
        Speed: any;
        Course: any;
        Altitude: any;
        Timestamp: any;
        Coordinate: {
            Latitude: any;
            Longitude: any;
        }
        SpeedAccuracy: any;
        CourseAccuracy: any;
        VerticalAccuracy: any;
        HorizontalAccuracy: any;
    };
    contacts: {
        title: string;
        type: string;
        value: string;
    }
    status: {
        text: string;
        text_color: string;
        background_color: string;
        border_color: string;
    }
    itinerary: ITinerary[];
}

const itineraryQuery = gql`
query{
    driver_schedule{
      id
      name
      price
      username
      carrier
      carrier_id
      equipment
      gps{
        Speed
        Course
        Altitude
        Timestamp
        Coordinate{
          Latitude
          Longitude
        }
        SpeedAccuracy
        CourseAccuracy
        VerticalAccuracy
        HorizontalAccuracy
      }
      contacts{
        title
        type
        value
      }
      status {
        text
        text_color
        background_color
        border_color
      }
      itinerary {
        address
        orders {
          pending_change_request
          change_requests{
            id
            order_id
            accepted
            rejected
            time
            payout
            note
            active
            expired
            expires_at
            weblink
            code
          }
          id
          vehicle {
            id
            vin
            year
            make
            model
            submodel
            type
            color
          }
          order_id
          is_pickup
          scheduled
          completed
          nofail
          timezoneName
          contacts {
            name
            value
          }
          address
        }
      }
    }
  }`

const Tabs =[
    {
        label:"Itinerary",
        value:"ITINERARY"
    },
    {
        label:"Jobs",
        value:"JOBS"
    }
]


const DriverItinerary = () => {
    const {token} = useParams<any>();
    const [itinerary, setItinerary] = useState<IDriverItinerary>();
    const [loading, setLoading] = useState<boolean>(false)
    
    const [selectedTab,setSelectedTab]=useState<'Itinerary'|'Jobs'>('Itinerary')

    const {height} = useScreenDimension();

    const [pickerOpen, setPickerOpen] = useState(false);
    
    const [fromDate, setFromDate] = useState(new Date(moment().add(-1, 'day').format('YYYY/MM/DD 00:00:00')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY/MM/DD 23:59:59')));


    const queryJobsRef = useRef<ObservableQueryT>();
    const queryJobsObsRef = useRef<Subscription>();
    
    const toast = useToasts();

    const [jobs, setJobs] = useState([]);

    async function handleRefresh() {
        fetchItinerary();
        loadJobs();
        return () => {
            queryJobsObsRef.current?.unsubscribe();
            queryJobsRef.current = null;
        }
    }


    useEffect(() => {
        if (token)
        {
            localStorage.removeItem('carrier_token');
            localStorage.setItem('driver_token', token);
            Helper.Navigation.NavigateTo('/driver/itinerary');
        }
        else
        {
            handleRefresh();
        }
    }, [token]);
    
    async function fetchItinerary() {
        setLoading(true);
        try {
            let res: IDriverItinerary = await GraphqlService.SendQuery(itineraryQuery);
            if(!res){
                throw new Error('No response from the server');
            }
            setItinerary(res);
        } catch (error) {
            toast.addToast(error.message, {type: 'error'});
        }
        setLoading(false);
    }

    function loadJobs(from?, to?) {
        if (!from)
        {
            from = new Date(fromDate);
            to = new Date(toDate);
        }
        else
        {
            setFromDate(from);
            setToDate(to);
        }

        if (queryJobsObsRef.current)
        {
            queryJobsObsRef.current.unsubscribe();
            queryJobsRef.current = null;
        }

        queryJobsRef.current = GraphqlService.SendQueryObservable(queryJobs, {from_date: from, to_date: to});
        queryJobsObsRef.current = queryJobsRef.current.onResults.subscribe(({data, error}) => {
            if (error)
                console.log(error.message);
            else
            {
                setJobs(data);
            }
        })

        
    }

    async function navigateToPendingInpection(order_id: number, is_pickup: boolean) {
        setLoading(true);
        try {
            let query = `
            mutation{
                create_pending_${is_pickup?'origin':'destination'}_inspection(order_id:${order_id}){
                    success
                    message
                    link
                }
            }
            `;
            let res: {message: string, success: Boolean, link: string} = await GraphqlService.SendMutation(gql`${query}`);
            
            if(!res?.success){
                throw new Error(res?.message || 'No response from the server');
            }
            Helper.Navigation.NavigateTo('/r/' + res.link.split('/r/')?.[1]);
        } catch (error) {
            toast.addToast(error.message, {type: 'error'});
        }
        setLoading(false);
    }
    

    return <>
                {/* <Toolbar vehicle={currentData.vehicle_data} /> */}
                
               <div className='d-flex' style={{height: `${height}px`, background: 'white'}}>
                   
              
               {loading ? <div>LOADING DATA</div>
               :<Content className=''>
                    <div className="mb-0 p-3 bg-white">
                        <h4 className="font-18 font-bold mb-0">{itinerary?.name || 'DRIVER'}</h4>
                        <div className="font-12 mb-4">{itinerary?.carrier || 'CARRIER'}</div>

                        <div className="col-12 bg-white d-flex align-items-center justify-content-evenly">
                            {
                                Tabs.map((e, index) => {
                                    return <p key={index}
                                            className={`d-flex m-0 py-2 px-4 font-14 font-medium order-item ${e.label == selectedTab ? 'item-selected-red' : ''}`}
                                            onClick={() => setSelectedTab(e.label as any)}>{e.label}</p>
                                })
                            }
                        </div>


                    </div>
                    <div className='flex-1-container'>

                            <PullToRefresh isPullable={false} className='flex-1-container' onRefresh={handleRefresh}>
                                <>
                           
                
                            <div className="flex-1-container">
                                <div className="col-12 flex-1-container">
                                    {
                                        selectedTab == "Jobs" && <>
                                            <div className="flex-1-container">
                                                <div className="d-flex justify-content-end bg-white p-3">
                                                    <h4 onClick={evt => setPickerOpen(true)} className="font-10"><i className="far fa-calendar-alt mr-1"></i> {moment(fromDate).format('MM/DD/YYYY')} - {moment(toDate).format('MM/DD/YYYY')}</h4>
                                                </div>
                                                <div className="flex-1-container py-3 px-2">
                                                    <div className="flex-1-container px-2">
                                                        <Jobs jobs={jobs} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        selectedTab == 'Itinerary' && <>


                                            <div className="flex-1-container">
                                                <div className="driver-itinerary-list">
                                                    {itinerary?.itinerary.map(it => <ItineraryCard
                                                    itinerary={it}
                                                    onOrderSelected={navigateToPendingInpection}/>)}
                                                </div>
                                            </div>
                                        </>

                                    }
                                </div>
                            </div>
                                </>
                            </PullToRefresh>
                    </div>
                </Content>}
               </div>

               {pickerOpen && <DateTimePicker hideTime date={[fromDate, toDate]} modal onCancelClicked={(evt) =>setPickerOpen(false)} onDateSubmitted={(evt) => {
                (evt[0] as Date).setHours(0, 0, 0);
                (evt[1] as Date).setHours(23, 59, 59);
                console.log(evt);
                loadJobs(evt[0], evt[1]);
                setPickerOpen(false);
        }} />}
            </>
}

const ItineraryCard = (props: {itinerary: ITinerary, onOrderSelected: (order_id: number, is_pickup: boolean) => void}) => {

    return <div className='driver-itinerary-card py-1 px-1'>
    <div className="flex-1-container background py-1 px-2 ">
        <div className='dic-address-title justify-content-center d-flex'>{props.itinerary.address}</div>
        <div className='dic-stop-list'>
            {props.itinerary.orders.map(order => (<div className='dic-stop-order-item'>
                <div className='d-flex justify-content-between mb-3'>
                    <div className='dic-stop-order vehicle-title'>VLX-{order.order_id}</div>
                    <div className="d-flex align-items-start">
                        <i className={`ml-1 dic-arrow-${order.is_pickup ? 'up' : 'down'}`} />
                    </div>
                </div>
                <div className='d-flex justify-content-between '>
                    <div className='dic-stop-order vehicle-title'>{Helper.FORMAT.VehicleDescription(order.vehicle, 'ymm')}</div>
                    <div className='dic-stop-order scheduled'>{Helper.FORMAT.USDate(new Date(order.scheduled))}</div>
                </div>
                <div className='dic-stop-order vehicle-title mb-3'>{Helper.FORMAT.VehicleDescription(order.vehicle, 'vin')}</div>
                {order.change_requests?.length>1 && <div className='dic-stop-order change-requests'>
                    CHANGED
                </div>}
                <div className='dic-stop-order inspection-link' onClick={() => props.onOrderSelected(order.order_id, order.is_pickup)}>
                    START INSPECTION
                </div>
            </div>))}
        </div>
    </div>
    </div>
}

export default DriverItinerary;