import {BaseLayout} from "../../layout/BaseLayout/BaseLayout";
import React from "react";

export const CarrierTermsConditionsPage = (props) => {
    return (
        <>
            <BaseLayout>
                <div className='w-100 content-flex-properties'>
                    <div className="row title-section">
                        <div className="col">
                            Complete your account setup
                        </div>
                    </div>

                    <main className={`row velox-form properties-scroll-auto`}>
                        <div className="col-12">
                            <span className={'subtitle-section'}>Your Carrier Terms & Conditions</span>
                        </div>

                        <div className="col-12 d-flex justify-content-between align-items-center mt-4 mb-2">
                            <span className={'info-message-disclainer'}>
                                This will show up as a hyperlink with an "I agree..." checkbox in the driver app along with Velox Terms & Conditions.
                            </span>
                            <span className={`clear-all`}>Clear All</span>
                        </div>

                        <div className="col-12">
                        <textarea name="" id="" cols={30} rows={17} className={'w-100 h-100'}
                                  placeholder={'Your Carrier Terms & Conditions'}>
                        </textarea>
                        </div>
                        <div className="col-12 d-flex justify-content-end mt-3">
                            <button type="submit"
                                    className="btn btn-sm btn-primary-dark btn-regular text-uppercase"
                            >Finish setup
                            </button>
                        </div>
                    </main>
                </div>
            </BaseLayout>
        </>)
}