import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helper from '../../helper/Helper';
import getAllNotifications, { setLastID } from '../../redux/actions/Notifications.action';
import { AllStates } from '../../redux/reducers';
import { INotifications } from '../../redux/reducers/Notifications.reducer';

const NotificationsPage = () => {


    const {data} = useSelector<AllStates>(state => state.notifications) as INotifications;

    const dispatch = useDispatch();

    useEffect(() => {
        if (data)
        {

            const lastID = data[0]?.id || 0;
            console.log(lastID)
            dispatch(setLastID(lastID));
        }
        // dispatch(setLastID(last));

        // dispatch(getAllNotifications());
    }, [data]);

    return <div className='col-12 flex-column flex-nowrap h-100'>
        
        <h4 className='font-18 mb-2'>Notifications</h4>

        <div className='bg-white h-100 mx-auto border' style={{maxHeight: '100%', overflow: 'auto', maxWidth: '800px', width: '90%'}}>
            {(data || []).map((x, i) =>
                <div key={'not-' + i} className='p-3 border-bottom bg-light cursor-pointer'>
                    <div className="d-flex">
                        <div>

                            <h4 className='font-16 mb-1'>{x.title}</h4>
                            <p className='font-14 mb-2'>{x.message}</p>
                            <p className='font-11 text-gray'>{Helper.FORMAT.USDate(x.time, 'do')} {Helper.FORMAT.USDate(x.time, 'to')}</p>
                        </div>
                    </div>
                </div>)}
        </div>

    </div>
}

export default NotificationsPage;