import React, {useEffect, useState} from "react";
import './DriverSchedule.scss'
import Status from "../Status/Status";
import MultilineEllipsis from '../../components/MultilineEllipsis/MultilineEllipsis'
import Helper from "../../helper/Helper";


const DriverScheduleComponent = ({driver, onOrderClicked}) => {
    const [myDriver, setMyDriver] = useState(null);

    useEffect(() => {
        if (driver) {
            setMyDriver(true);
            setTimeout(() => {

                setMyDriver(JSON.parse(JSON.stringify(driver)));
            }, 200);
        } else
            setMyDriver(null);
    }, [driver]);

    if (!myDriver)
        return <div className='p-3'>
            <h4 className={'font-14 mb-1'}>No driver selected</h4>
            <h4 className={'font-12 font-normal'}>Please select a driver to see details</h4>
        </div>

    return (
        <>
            <section id="driver-schedule" style={{transition: '.3s all ease', opacity: myDriver == true ? '0' : '1'}}>
                <article className={`schedule-timeline ps-3`} style={{marginLeft: '2rem'}}>
                    <ul className='px-1 mt-1'>
                        {
                            myDriver?.itinerary?.map((job, i) => {
                                return (
                                    <li key={`j-${job?.id || i}`}>
                                        <div className="job-cnt">
                                            <MultilineEllipsis className={"location-name fs-12"} lineLimit={2}>
                                                {job.orders[0]?.address}
                                            </MultilineEllipsis>

                                            {
                                                job?.orders.map((order) => {
                                                    return (
                                                        <div onClick={(evt) => onOrderClicked(order.order_id)}
                                                             key={`o-${order?.order_id}`}
                                                             className={`mb-2 card-order cursor-pointer`}>
                                                            <div
                                                                className={`d-flex no-wrap align-items-center justify-content-between`}>
                                                                {/*<span>{order?.hour}</span>*/}
                                                                <span>VLX-{order?.order_id}  {order.completed && <i className="fas fa-check-circle text-green"></i>}</span>
                                                                <i
                                                                className={`arrow-${order?.is_pickup ? 'up' : 'down'}`}></i>
                                                            </div>
                                                            <div>
                                                            <MultilineEllipsis className={"title"} lineLimit={1}>
                                                                {order?.vehicle?.vin}
                                                            </MultilineEllipsis>
                                                            </div>
                                                            <div className="info">{order?.vehicle?.year} {order?.vehicle?.make} {order?.vehicle?.model}</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <span className={`number ${job?.line_style}`}>
                                            <span className='fs-12 ms-2'>{job?.orders?.length && <><p>ETA</p><p
                                                className='fs-12'>{job?.orders[0]?.hour}</p>
                                                <p>(PST)</p></> || ''}</span>
                                            </span>
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </article>
            </section>
        </>
    );
}

export default DriverScheduleComponent;

