import {gql, ObservableQuery} from "apollo-boost";
import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import OrderRequestTypes from "../types/OrderRequests.type";
import {OrderRequest} from "../../classes/classes";

const query = gql`
    query($filter:String){
        get_orders_request(filter: $filter){
            id
            accepted
            rejected
            cancelled
            expired
            price
            origin{
                scheduled
                nofail
                contacts{
                    name
                    value
                }
                time_completed
                completed
                address{
                    street_number
                    street
                    city
                    state
                    postal_code
                    country
                }
            }
            destination{
                scheduled
                nofail
                contacts{
                    name
                    value
                }
                time_completed
                completed
                address{
                    street_number
                    street
                    city
                    state
                    postal_code
                    country
                }
            }
            broker{
                id
                name
                company{
                    address{
                        street_number
                        street
                        city
                        state
                        postal_code
                        country
                    }
                }
            }
            vehicle{
                vin
                make
                year
                model
                submodel
                type
            }
        }
    }
`
export default function getOrderRequestByStatus(status = '') {
    return async dispatch => {
        if (status == 'all') {
            const statuses = ['pending', 'cancelled', 'rejected', 'expired'];

            for (const stat of statuses) {
                dispatch(getOrderRequestByStatusAction(true, stat));
                GraphqlService
                    .SendQuery(query, {filter: stat})
                    .then((data) => {
                        dispatch(getAllOrderRequestByStatusSuccessAction(data.map(x => Object.create(new OrderRequest(x))), stat));
                    })
                    .catch((ex) => {
                        dispatch(getAllOrderRequestByStatusErrorAction(ex.message, stat));
                    });
            }

        } else {
            dispatch(getOrderRequestByStatusAction(true, status));
            try {
                let data = await GraphqlService.SendQuery(query, {filter: status});
                dispatch(getAllOrderRequestByStatusSuccessAction(data.map(x => Object.create(new OrderRequest(x))), status));
            } catch (ex) {
                dispatch(getAllOrderRequestByStatusErrorAction(ex.message, status));
            }
        }

    }
}

const getOrderRequestByStatusAction = (payload, status = '') => ({
    type: OrderRequestTypes.GET_ALL_ORDER_REQUESTS_STARTED,
    payload,
    status
});

const getAllOrderRequestByStatusSuccessAction = (payload, status = '') => ({
    type: OrderRequestTypes.GET_ALL_ORDER_REQUESTS_SUCCESS,
    payload,
    status
});

const getAllOrderRequestByStatusErrorAction = (payload, status = '') => ({
    type: OrderRequestTypes.GET_ALL_ORDER_REQUESTS_ERROR,
    payload,
    status
});
