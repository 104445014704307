import React, { useEffect, useRef, useState } from 'react';
import './Signature.scss'
import SignaturePad from 'react-signature-canvas'
import ICONS from '../../../../../assets/svg';
import { useToasts } from 'react-toast-notifications';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';


const Signature = ({onCancel,onSubmit,imgSaved,inputName='', value, showInput = true}) => {
    const canvasref = useRef<any>()
    const [inputValue,setInputValue]=useState('');
    const[savedImg,setsavedImg]=useState()
    const messagebox = useMessageBox();
    const toast = useToasts()

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/jpeg' });
    }


    useEffect(() => {
        setInputValue(value);
        
    }, [value])

    const handleSaveSignature= async ()=>{


        // console.log(canvasref.current.getTrimmedCanvas());
        if(canvasref.current.isEmpty()===false && (inputValue.trim() || !showInput)){
                // let image = new Image();
                // image.src = canvasref.current.toDataURL();
                
                const base64 = canvasref.current.toDataURL();
                // const file = await dataUrlToFile(base64, 'test.jpeg');
                const file = canvasref.current.toData() as any[][];
                const total = file.reduce((p, c) => p + c.length, 0);

                if (total < 16)
                {

                    canvasref.current.clear();

                    return messagebox.open({title: 'Error', message: 'Signature not valid, please try again', buttons: [{text: 'Ok', css: 'btn btn-blue-light col-6'}]})
                    // return alert('');
                }
                    
                    
                const name = inputValue.trim();
                onSubmit(base64, name);
                
        }else toast.addToast('Complete Signature', {appearance: 'error',autoDismiss: true});

    }

    return(
        <>
            <div className=" ps-popup row">

            
                   
                <div className={'container-signature '}>

         

              <div className={'row '}>
                 {showInput && <div className="col-12 ">
                    <FormGroup  name={inputName.split(' ').join('_')}
                                value={inputValue}
                                label={ inputName}
                                onTextChange={value => setInputValue(value)}/>
                </div>}

                        
                <div className="col-12 ">
                    <span className={'main-text '}>{inputName}</span>
                </div>
              { /* <span className={`main-text ${imgSaved?"d-flex ":"v-hidden"}`}>Use saved signature</span>*/}
               
              </div>
                {/*
                    <div className={`w-100 justify-content-end ${imgSaved?"d-flex ":"v-hidden"}`}>
                        <img className={'img-saved-signature '} src={imgSaved} alt="" />
                    </div>
                */
                
                <div className='position-relative' style={{height: '200px'}}>
                    <SignaturePad  ref={canvasref} canvasProps={{
                        className:'canvas-signature position-relative'
                    }}/>
                    <div className={'container-clean'}
                        onClick={()=>{
                            canvasref.current.clear()
                        }}
                        >
                        <img  src={ICONS.IconCloseBig}/>
                    </div>
                </div>}
                     
                            
                            
                     

                    <div className="row mt-3">
                    <div className="col-6 text-right">
                        <button className="btn btn-skyblue-outline bg-white w-100 py-2 rounded-pill fs-12 text-light-blue"
                                onClick={() => onCancel()}>CANCEL
                        </button>
                    </div>
                    <div className="col-6">
                        <button className={"btn w-100 py-2 btn-blue-light rounded-pill fs-12  font-medium-i"}  
                                onClick={()=>handleSaveSignature()}
                        >SAVE</button>
                    </div>
                    </div>

               </div>
            </div>
        </>
    )
}

export default Signature
