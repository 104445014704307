import React,{useState,useRef,useEffect} from 'react'
import './PopUpModal.scss'
import ICONS from "../../../assets/svg";


type PopUpModalProps={
    iconKey:string,
    labelPopUpMpdal:string,
    visiblePopUpModal:boolean,
    handleClickOutPopupModal:()=>void,
    dataPopUpModal:{title:string,img:string}[],
    images: {label: string, code?: string, thumbnail: string, image: string}[],
    indexSelected: number
}

const PopUpModal =(props:PopUpModalProps)=>{
    const nodeContainer = useRef<HTMLDivElement>();
    const[imgKey,setImgKey]=useState(null)

    const currentImgKey = useRef<any>(null);
    const imagesLength = useRef<any>(0);
    const hasListener = useRef(false);

    function handleKeyDown (evt) {
        // evt.stopPropagation();
        if (!props.visiblePopUpModal) return;

        const code = evt.keyCode;
        if (code == 37)
            handlePrev();
        else if (code == 39)
            handleNext();
        else if (code == 27)
            props.handleClickOutPopupModal();
    }

    let handleClickOut = (event: any) => {

        if (!props.visiblePopUpModal) return;

        if (!nodeContainer.current)
            return;

        if (!nodeContainer.current.contains(event.target)) {
            props.handleClickOutPopupModal()
        }
    }





    useEffect(() => {
        setImgKey(props.indexSelected);
        currentImgKey.current = props.indexSelected;


    }, [props.indexSelected]);


    useEffect(() => {
        imagesLength.current = props.images.length;
    }, [props.images])





    const handlePrev=()=>{

        const nkey = currentImgKey.current <=0 ? imagesLength.current-1 : currentImgKey.current - 1;
        setImgKey(nkey);
        currentImgKey.current = nkey;

        document.getElementById('img-min-' + nkey)?.scrollIntoView({behavior: 'smooth'});

    }


    const handleNext=()=>{
        const nkey =  currentImgKey.current ==imagesLength.current-1? 0 : currentImgKey.current + 1;
        setImgKey(nkey);
        currentImgKey.current = nkey;
        document.getElementById('img-min-' + nkey)?.scrollIntoView({behavior: 'smooth'});

    }


    useEffect(() => {
        document.body.classList.toggle('no-scroll', props.visiblePopUpModal);
        if (props.visiblePopUpModal)
        {

            if (hasListener.current) return;

            document.addEventListener("mousedown", handleClickOut);
            document.addEventListener("keydown", handleKeyDown);
            hasListener.current = true;
        }

    }, [props.visiblePopUpModal])



    return(
        <>  
                       
            <div  className={`${props.visiblePopUpModal?"d-flex":"d-none"} modalpopuop`}>
                <div ref={nodeContainer}  className="modalpopcontainer row m-3" style={{position:"relative"}}>

                  
                            {props.images.map((data,key)=>{
                                if(key===imgKey){
                                    return<div
                                        // style={{background: 'rgba(0, 0, 0, .9)'}}
                                        className="col-12 containerImg position-relative" >

                                        <img onError={(evt) => {
                                            console.log(evt);
                                        }
                                        } className={'fade-in'} style={{width: '100%', height: '100%', position: 'absolute', objectFit: 'contain',  backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: '32px' }} src={data.image} />
                                               <div style={{width:"100%",position:"absolute",top:"50%",display:"flex",justifyContent:"space-between"}}>
                                <button onClick={()=>handlePrev()} className="btnModalPopUp ml-4 cursor-pointer" ><img src={ICONS.IconArrowLeft}/></button>
                                <button onClick={()=>handleNext()} className="btnModalPopUp mr-4 cursor-pointer"><img src={ICONS.IconArrowRight}/></button>
                            </div>
                        </div>
                                }

                       
                     

                        })}

                        <div className="col-4 col-md-12 mb-2 d-flex fs-12 pl-md-0 justify-content-md-center font-medium pl-5 align-items-center">

                            {props.images.map((data,key)=>{
                                if(key===imgKey){
                                    return  (<>
                                        {data.code && <div
                                            className={`${props.iconKey === "" ? "" : "active"} inspection-icon mr-3`}>{data.code}</div>}
                                        <span>{data.label}</span>
                                    </>)
                                }

                            })}
                        
                        </div>
                             
                        <div className="miniature-container col-8 col-md-12 mb-2  d-flex pr-md-0 justify-content-end justify-content-md-center align-items-center pr-5">

                    
                           <div className={'d-flex mx-auto'} style={{maxWidth: 'calc(100% - 32px)', overflow: 'auto'}}>
                               {props.images.map((data, key)=>(

                                   <>
                                       <div className={'cursor-pointer' + (key == 0 ? "" : ' ml-3')} id={'img-min-' + key}>
                                           <img  className={`${imgKey===key?"active":""} imgMiniature`} onClick={()=>{setImgKey(key)}} src={data.thumbnail}  />
                                       </div>
                                   </>

                               ))}
                           </div>
                            

                        </div>
                </div>

            </div>

        </>
    )
}

export default PopUpModal