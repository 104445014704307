import React, {useEffect, useRef, useState} from "react";
import GMap from "../libs/side/Map";
import GMarker from "../libs/gmap/Marker";
import {DriverLocation} from "../classes/classes";
import DriverMarkers from "../libs/gmap/DriverMarkers";
import {MarkerClass} from "../libs/side/MarkerClass";
import {PointClass} from "../libs/side/PointClass";
import {RouteClass} from "../libs/side/RouteClass";

interface MaptestProps {
    onBack?: () => void;
    drivers: DriverLocation[]
}

const Maptest: React.FC<MaptestProps> = props => {
    const {onBack} = props;

    const mapRef = useRef<GMap>(null);

    const [map, setMap] = useState(null);
    const [marker, setMarker] = useState(null);
    const [markerDriver, setMarkerDriver] = useState(null);

    useEffect(() => {
        const m = new GMap({
            isDrawable: true,
            onMapInit: () => {
                mapRef.current = m
            }
        });
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.drawMarker(new MarkerClass({
                id: 1,
                position: {lat: 34.052235, lng: -118.243683},
                text: 'Testing',
                selected: false,
                onClick: (marker: MarkerClass) => {
                    console.log('marker clicked');
                    console.log(marker);
                }
            }))

            setTimeout(() => {

                mapRef.current.drawMarker(new MarkerClass({
                    id: 1,
                    position: {lat: 34.152235, lng: -118.343683},
                    text: 'Testing',
                    selected: false,
                    onClick: (marker: MarkerClass) => {
                        console.log('marker clicked');
                        console.log(marker);
                    }
                }))

                mapRef.current.drawMarker(new MarkerClass({
                    id: 2,
                    position: {lat: 34.052235, lng: -118.543683},
                    text: 'Testing 3',
                    selected: false,
                    onClick: (marker: MarkerClass) => {
                        console.log('marker clicked');
                        console.log(marker);
                    }
                }))
            }, 5000)

            setTimeout(() => {
                mapRef.current.removeAllMarkers();
            }, 10000)
        }
    }, [mapRef.current]);


    return (
        <>
            <div className="row">
                <div className="col-2">
                    <button onClick={() => {
                        mapRef.current.toggleTraffic()
                    }} value={`Show traffic`}>Show traffic
                    </button>
                    <button onClick={() => {
                        mapRef.current.drawRoute(new RouteClass({
                            id: 44,
                            startAddress: '11067 Olinda StSun Valley, CA 91352, United States',
                            endAddress: '11582 Sheldon St, Sun Valley, CA 91352, United States',
                            start: {lat: 34.052235, lng: -118.243683},
                            end: {lat: 34.052235, lng: -118.543683},
                            departureDate: new Date(Date.now() + 100000),
                            showTime: false,
                            onClick: () => {
                            }
                        } as any))
                        // mapRef.current.drawRoute(new RouteClass({
                        //     id: 55,
                        //     start: {lat: 34.052235, lng: -118.243683},
                        //     end: {lat: 34.052235, lng: -118.543683},
                        //     showTime: false,
                        //     onClick: () => {
                        //     }
                        // }))
                    }} value={`Show traffic`}>Draw route
                    </button>
                    <button onClick={() => {
                        mapRef.current.removeAllRoutes()
                    }} value={`Show traffic`}>Clear route
                    </button>
                    <button onClick={() => {
                        mapRef.current.removeRoute(new RouteClass({
                            id: 55,
                            start: {lat: 34.052235, lng: -118.243683},
                            end: {lat: 34.052235, lng: -118.543683},
                            showTime: false,
                            onClick: () => {
                            }
                        } as any))
                    }} value={`Show traffic`}>Remove one route
                    </button>
                </div>
                <div className="col-10">
                    <div style={{width: '100%', height: '100vh'}} id="map"></div>
                </div>
            </div>
        </>
    );
};
export default Maptest;
