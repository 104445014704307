import React, {ReactNode} from 'react'
import './MultilineEllipsis.scss'

type MultilineEllipsisProps = {
    lineLimit: number
    children: ReactNode
    className: string
}


const MultilineEllipsis = (props: MultilineEllipsisProps) => {
    return (
        <>
            <span className={props.className}>
                <p className="multiline-ellipse"
                   style={{
                       WebkitLineClamp: props.lineLimit,
                       height: ` calc( 1em * 1.4* ${props.lineLimit} )`,
                   }}
                >{props.children}</p>
            </span>
        </>
    )
}

export default MultilineEllipsis
