import messageBoxTypes from "../types/MessageBox.type";

export interface IMessageBoxButton {
    text: string,
    css: string,
    data: any,
    action: (data) => void
}

export interface IMessageBox {
    open?: boolean,
    title: string,
    message: string,
    buttons: IMessageBoxButton[]
}

let initialState: IMessageBox = {open: false, title: '', message: '', buttons: []};

export function messageBoxReducer(state: IMessageBox = initialState, action): IMessageBox {
         
    switch (action.type) {
        case messageBoxTypes.OPEN:
            initialState = {...action.payload, open: true};
            break;
        case messageBoxTypes.CLOSE:
            initialState = {open: false, title: '', message: '', buttons: []};
            break;
        default:
         return state;
    }
    return initialState;
}