import "./BaseLayout.scss"

export const BaseLayout = (props) => {
    return (
        <>
            <div id="base-layout">
                <div className="container clearfix d-flex" style={{minHeight: '100vh'}}>
                    {props.children}
                </div>
            </div>
        </>
    )
}
