import React from 'react';
import CardHistoryInspections from '../../../components/CardHistoryInspections/CardHistoryInspections';

const Inspection = (props) => {
    return <>
       {props.inspections.map((inspection, index) => <CardHistoryInspections inspection={inspection} key={`insp-${index}`} />)}
       
    </>
}

export default Inspection;