import { Address, Contact } from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import OrderRequestTypes from "../types/OrderRequests.type";

interface IAddress {
    city: string
    state: string
    country: string
    postal_code: string
}

export interface IOrderRequest {
    id: number
    accepted: boolean
    rejected: boolean
    cancelled: boolean
    broker_note: string
    expired: boolean
    price: number
    origin: {
        scheduled: string
        address: Address
        contacts: Contact[]
    }
    destination: {
        scheduled: string
        address: Address
        contacts: Contact[]
    }
    vehicle: {
        make: string
        year: string
        model: string
        submodel: string
        type: string
    }
}

export interface IOrderRequests {
    data: IOrderRequest[],
    loading: boolean,
    error: string,
}

export interface IOrderRequestsKeys {
    cancelled: IOrderRequests,
    rejected: IOrderRequests,
    pending: IOrderRequests,
    expired: IOrderRequests,
}

const initialState: IOrderRequestsKeys = {
    cancelled: {
        data: [],
        loading: false,
        error: '',
    },
    rejected: {
        data: [],
        loading: false,
        error: '',
    },
    pending: {
        data: [],
        loading: false,
        error: '',
    },
    expired: {
        data: [],
        loading: false,
        error: '',
    },
}

export default function OrderRequestsReducer(state: IOrderRequestsKeys = initialState, action: IReducerAction): IOrderRequestsKeys {
    // debugger
    switch (action.type) {
        case OrderRequestTypes.GET_ALL_ORDER_REQUESTS_STARTED:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: action.payload,
                    error: null
                }
            })
        case OrderRequestTypes.GET_ALL_ORDER_REQUESTS_SUCCESS:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    data: action.payload,
                    loading: false,
                    error: null
                }
            })
        case OrderRequestTypes.GET_ALL_ORDER_REQUESTS_ERROR:
            return Object.assign({}, {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: false,
                    error: null
                }
            })
        default:
            return state
    }
}
