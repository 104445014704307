import React, {useEffect, useState} from 'react';
import logo from '../logo.svg';
import {Switch, Route, useHistory} from 'react-router-dom';

import "@szhsin/react-menu/dist/index.css"; // MenuOption style
import './App.scss';
import InspectionReport from "./pages/InspectionReport/InspectionReport";
import Helper from "./helper/Helper";
import LoginPage from "./pages/LoginPage/LoginPage";
import {CarrierRegistrationPage} from "./pages/CarrierRegistrationPage/CarrierRegistrationPage";
import {CarrierRegistrationInsurancePage} from './pages/CarrierRegistrationInsurancePage/CarrierRegistrationInsurancePage';
import {CarrierDisclaimerPage} from "./pages/CarrierDisclaimer/CarrierDisclaimerPage";
import {CarrierTermsConditionsPage} from "./pages/CarrierTermsAndConditions/CarrierTermsConditionsPage";
import {CarrierProfilePage} from "./pages/CarrierProfile/CarrierProfilePage";
import MainPage from "./pages/MainPage/MainPage";
import PendingInspectionPage from './pages/PendingInspectionPage/PendingInspectionPage';
import LoginDriverPage from './pages/LoginDriverPage/LoginDriverPage';
import gql from 'graphql-tag';
import {GraphqlService} from './services/graphql.service';
import DriverSignature from './components/DriverSignature/DriverSignature';
import SuperLoginPage from './pages/SuperLoginPage/SuperLoginPage';
import History from './pages/PendingInspectionPage/pages/HistoryPage/HistoryPage';
import OrderRequestPage from './pages/OrderRequestPage/OrderRequestPage';
import RedirectPage from './pages/RedirectPage/RedirectPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import SignupCarrierPage from './pages/SignupCarrierPage/SignupCarrierPage';
import Maptest from "./pages/Maptest";
import DriverItinerary from './pages/PendingInspectionPage/pages/HistoryPage/DriverItinerary/DriverItinerary';


function App() {


    Helper.SetNavigator(useHistory());


    return (
        <>

            {<Switch>


                <Route path='/r/:token' component={RedirectPage}/>
                <Route path={'/order-request/:token'} render={(props) => <OrderRequestPage {...props} />}/>
                <Route path={'/inspection-report/:token'} render={() => <InspectionReport/>}/>
                <Route path='/history/:token?' component={History}/>
                <Route path='/driver/itinerary/:token?' component={DriverItinerary}/>
                <Route path="/carrier/insurance" component={CarrierRegistrationInsurancePage}/>
                <Route path="/carrier/new" component={CarrierRegistrationPage}/>
                <Route path="/carrier/disclaimer" component={CarrierDisclaimerPage}/>
                <Route path="/carrier/terms_and_conditions" component={CarrierTermsConditionsPage}/>
                <Route path={'/login/driver'} component={LoginDriverPage}/>
                <Route path={'/login'} component={LoginPage}/>
                <Route path={'/superLogin/:code'} component={SuperLoginPage}/>
                <Route path={'/forgot-password'} component={ForgotPasswordPage}/>
                <Route path={'/reset-password/:token'} component={ResetPasswordPage}/>
                <Route path={'/finish-registration/:token'} component={SignupCarrierPage}/>
                <Route path="/maptest" component={Maptest}/>
                <Route path='/pending-inspection/:origin/:token' component={PendingInspectionPage}/>
                <Route path="/" component={MainPage}/>
            </Switch>}
        </>
    );
}

export default App;
