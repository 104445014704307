import SideBar from "../../components/SideMenu/SideMenu";
import "./DashboardLayout.scss"
import useScreenDimension from "../../hooks/ScreenDimension/ScreenDimension";
import ICONS from "../../../assets/svg";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import Helper from "../../helper/Helper";
import AuthService from "../../services/auth.service";
import logo from "../../../assets/images/logo.png";

const options = [
    { url: '/dashboard', icon: ICONS.IconUser, selected: true },
    { url: '/order-requests', icon: ICONS.ORDERS, selected: false },
    { url: '/jobs', icon: ICONS.DISPATCH, selected: false },
    { url: '/dispatch', icon: ICONS.DRIVER, selected: false },
    { url: '/payments', icon: ICONS.IconPayment, selected: false },
]

export const DashboardLayout = (props) => {
    const { isMobile } = useScreenDimension();
    const [menu, setMenu] = useState(options);
    const {height} = useScreenDimension();

    const [isOpen, setIsOpen] = useState(false);

    const handleMenu = (current) => {
        let temp = menu.map(e => {
            return {...e, selected: e.url === current.url };
        })

        setMenu([...temp]);
    }

    function handleClick(evt) {
        // console.log(evt.target.id);
        if (evt.target.id != 'user-label')
            setIsOpen(false);
    }

    useEffect(() => {
        window.addEventListener('click', handleClick);
        
        return () => window.removeEventListener('click', handleClick);
    }, []);

    if (isMobile)
        return(
            <>
                <div className="flex-1-container" style={{height: height + 'px'}}>
                <div className='position-relative bg-white p-3 d-flex flex-column justify-content-center'>
                <img className="d-block mx-auto mb-2" src={logo} alt="" />
                              <div className="position-relative">
                              
                                <h4 id='user-label' style={{textAlign: 'center'}} onClick={(evt) => setIsOpen(true)} className='font-14 cursor-pointer'>{AuthService.PROFILE?.carrier_name}, logged in as: {AuthService.PROFILE?.name} <i className="fas fa-caret-down"></i></h4>
                                    {isOpen && <div className='bg-white rounded' style={{position: 'absolute', boxShadow: '0 3px 6px #00000029', width: '100%',  borderBottomLeftRadius: 16, borderBottomRightRadius: 16}}>
                                        <p onClick={(evt) => Helper.Session.DoLogout()} className="font-12 m-0 cursor-pointer p-3 hover-gray"><i className="mr-1 fas fa-power-off"></i> Log out</p>
                                        
                                    </div>}
                              </div>
                    </div>
                    <div className="flex-1-container">
                        { props.children }
                    </div>

                    <div className="tab-menu">
                        <div className="tab-menu-options">
                            <ul className="list-unstyled mb-0 d-flex align-items-center justify-content-evenly">
                                {
                                    menu.map((current, key) => {
                                        return(
                                            <li key={key}
                                                className="py-2"
                                                onClick={(evt) => evt.stopPropagation()}>

                                                <NavLink className={`position-relative navbar-item ${current.selected ? 'is-active' : ''}`}
                                                    onClick={() => handleMenu(current)}
                                                    to={current.url}>
                                                    <img src={current.icon} alt=""/>
                                                </NavLink>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        );


  



    return (
        <div id="dashboard-layout" className='flex-1-container'>
            <div className='main-container p-3'>
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'stretch'
                }}>
                    <SideBar/>
                    <div className="row p-3 flex-1-container" style={{ marginLeft: "48px"}}>
                        <div className='d-flex mb-3 row'>
                            <img style={{position: 'fixed', left : '24px'}} src={logo} alt="" />
                            <div />
                            <div className='position-relative'>
                                <h4 id='user-label' onClick={(evt) => setIsOpen(true)} className='font-14 cursor-pointer'>{AuthService.PROFILE?.carrier_name}, logged in as: {AuthService.PROFILE?.name} <i className="fas fa-caret-down"></i></h4>
                                {isOpen && <div className='bg-white rounded' style={{position: 'absolute', width: '100%', borderBottomLeftRadius: 16, borderBottomRightRadius: 16}}>
                                    <p onClick={(evt) => Helper.Session.DoLogout()} className="font-12 m-0 cursor-pointer p-3 hover-gray"><i className="mr-1 fas fa-power-off"></i> Log out</p>
                                    
                                </div>}
                            </div>
                        </div>
                     
                        <div className="flex-1-container">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
