import React, {useState, useRef, useEffect} from 'react'
import './DriversPage.scss'
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import DriverMap from "../../components/DriverMap/DriverMap";
import {GraphqlService} from "../../services/graphql.service";
import DriverQueries from "../../graphql/query/Driver";
import {DriverLocation} from "../../classes/classes";
import Maptest from "../Maptest";


const DriversPage = () => {

    const interval = useRef<any>();
    const [driverLocations, setDriverLocations] = useState<DriverLocation[]>([]);
    const [showTraffic, setShowTraffic] = useState<boolean>(true);

    const dispatch = useDispatch();
    const toast = useToasts();

    async function loadDriverLocations()
    {
        try
        {
            const data = await GraphqlService.SendMultipleQuery(DriverQueries.GET_ALL_LIST);
            const locations: DriverLocation[] = data[0].map(x => new DriverLocation(x));

            if(JSON.stringify(driverLocations) !== JSON.stringify(locations)){
                setDriverLocations(locations);
            }
        }
        catch (ex)
        {
            console.log(ex.message);
        }
    }

    useEffect(() => {
        interval.current = setInterval(() => loadDriverLocations(), 5000);
        return () => clearInterval(interval.current);
    }, []);


    useEffect(() => {
        loadDriverLocations().then();
    }, []);

    return (
        <>
            <div className="flex-1-container ">
                <div className="col-12 mb-3">
                    <h4>Drivers locations</h4>
                    <button onClick={(evt) => setShowTraffic(!showTraffic)}>{`Show Traffic is: ${showTraffic ? 'ON' : 'OFF'}`}</button>
                </div>
                <div className="flex-1-container ">
                    {/*<DriverMap drivers={driverLocations} showTraffic={showTraffic}/>*/}
                    <Maptest drivers={driverLocations} />
                </div>
            </div>
        </>
    )
}

export default DriversPage
