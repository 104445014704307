import React, {useEffect, useState} from "react";
import FormGroup from "../FormGroup/FormGroup";
import {Driver} from "../../classes/classes";
import './DriverForm.scss';
import ICONS from "../../../assets/svg";
import CardDetailDriver from "../CardDetailDriver/CardDetailDriver";
import {
    addEquipmentFileSingle,
    createDriver,
    initDriver, removeDriver, removeEquipmentFileSingle,
    updateDriver,
    updateDriverPassword,
    updateDriverUsername
} from "../../redux/actions/Driver.action";
import {useDispatch, useSelector} from "react-redux";
import {useToasts} from "react-toast-notifications";
import useLoading from "../../hooks/useLoading/useLoading";
import {AllStates} from "../../redux/reducers";
import {IDriverState} from "../../redux/reducers/Driver.reducer";
import {gql} from "apollo-boost";
import {GraphqlService} from "../../services/graphql.service";
import InspectionQueries from "../../graphql/query/Inspection";
import DriverQueries from "../../graphql/query/Driver";
import driverTypes from "../../redux/types/Driver.type";
import ReactModal from "react-modal";
import Modal from "../Modal/Modal";
import Helper from "../../helper/Helper";

class Contact {
    type: string = '';
    value: string = '';

    constructor(props?: any) {
        if (props) {
            this.type = props.type;
            this.value = props.value;
        }
    }
}

const DriverForm = ({drivers, driver, onSubmit, carrier_id = null}) => {
    const dispatch = useDispatch();
    const loading = useLoading();
    const toast = useToasts();

    const {
        onDeleteSuccess,
        loadingForm,
        response,
        errorForm,
        equipmentAdded,
        equipmentDeleted } = useSelector<AllStates>(state => state.driver) as IDriverState;

    const [isNew, setIsNew] = useState(true);
    const [email, setEmail] = useState(new Contact());
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [checkSms, setCheckSms] = useState('');
    const [checkEmail, setCheckEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(new Contact());
    const [currentDriver, setCurrentDriver] = useState(new Driver());

    const [equipments, setEquipments] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);

    const handleUsername = (value) => setUsername(value);
    const handlePassword = (value) => setPassword(value);
    const handleConfirmPassword = (value) => setConfirmPassword(value);
    const handleCheckSMS = (evt) => setCheckSms(evt.target.checked);
    const handleCheckEmail = (evt) => setCheckEmail(evt.target.checked);

    const handleEmail = (value) => setEmail({...email, type: 'Email', value: value});
    const handlePhoneNumber = (value) => setPhoneNumber({...phoneNumber, type: 'Phone', value: Helper.Masks.USPhone(value)});

    const handleOnClose = () => onSubmit(undefined);


    useEffect(() => {
        if (onDeleteSuccess) {
            toast.addToast('Driver deleted successfully', {appearance: 'success', autoDismiss: true});
            onSubmit(currentDriver.id);
        }
    }, [onDeleteSuccess]);

    useEffect(() => {
        if (equipmentDeleted) {
            toast.addToast(equipmentDeleted, {appearance: 'success', autoDismiss: true});
        }
    }, [equipmentDeleted]);

    useEffect(() => {
        if (equipmentAdded) {
            let temp = [...equipments];
            // search equipment added recently
            let index = equipments.findIndex(e => e.id == equipmentId);

            // change for correct id and correct uid
            temp[index].id = equipmentAdded.id;
            temp[index].uid = equipmentAdded.link;

            setEquipments([...temp]);
            setEquipmentId(0);

            toast.addToast(equipmentAdded.message, {appearance: 'success', autoDismiss: true});
        }
    }, [equipmentAdded]);

    useEffect(() => {
        if (driver && driver.id !== null) onSetDriver(driver).then();
        else onSetDriver().then();
    }, [driver]);

    useEffect(() => {
        if (loadingForm) loading.open('Submitting driver...');
        else loading.close();

        if (response) {
            if (response.success) {
                toast.addToast(response.message, {appearance: 'success', autoDismiss: true});
                if (isNew) {
                    setEquipments([]);
                    onSubmit(response.id);
                }
            } else {
                toast.addToast(response.message, {appearance: 'error', autoDismiss: true});
            }
        }
    }, [loadingForm, response]);

    useEffect(() => {
        if (errorForm && errorForm != '')
            toast.addToast(errorForm, {appearance: 'error', autoDismiss: true});
    }, [errorForm]);

    const getDriverById = async (driver_id) => {
        try {
            const query = gql`
                query($driver_id: Int) {
                    get_driver(driver_id: $driver_id) {
                        id
                        name
                        username
                        equipment
                        photos {
                          uid
                          name
                        }
                        contacts{
                          title
                          type
                          value
                        }
                    }
                }
            `;
            loading.open('Loading driver...');
            const data = await GraphqlService.SendQuery(query, {driver_id});
            loading.close();
            return data;
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            return onSubmit(undefined);
        }
    }

    const onSetDriver = async (driver: any = undefined) => {
        setIsNew(!driver);
        if (!driver) driver = new Driver();
        else driver = await getDriverById(driver.id);

        setUsername(driver.username);
        setPassword('');
        setConfirmPassword('');

        setCurrentDriver(driver);
        if (driver && driver.contacts && driver.contacts.length > 0) {
            let phone = driver.contacts.filter((e: any) => e.type?.trim() === 'Phone')[0];
            let email = driver.contacts.filter((e: any) => e.type?.trim() === 'Email')[0];

            setEmail(email);
            setPhoneNumber(phone || '');
        } else {
            setEmail(new Contact());
            setPhoneNumber(new Contact());
        }

        if (driver?.photos && driver?.photos?.length) {
            let photos = await Promise.all(driver.photos.map(async (data, index) => {
                return {
                    id: index,
                    saved: true,
                    uid: data.uid,
                    files: undefined,
                    file: await getTempLink(data.uid)
                }
            }));

            setEquipments([...photos]);
        } else {
            setEquipments([]);
        }

        dispatch(initDriver());
    }

    const handleCurrentDriver = (key, value) => {
        let temp = {...currentDriver};
        temp[key] = value;

        setCurrentDriver({...temp});
    }

    const isValid = () => currentDriver?.name != '' && phoneNumber?.value != '';

    const handleOnSubmit = async () => {
        if (!isValid()) return;

        let contacts = [];
        contacts.push({...email});
        contacts.push({...phoneNumber});

        if(!isNew && driver.username !== username || !username) {
            const data = await GraphqlService.SendQuery(DriverQueries.CHECK_USERNAME, {username});

            if(!data.success) {
                return toast.addToast(data.message, {appearance: 'error', autoDismiss: true});
            }
        }

        if (isNew) {

            if (!password || password !== confirmPassword) {
                return toast.addToast('Password and confirm password must match.', {appearance: 'error', autoDismiss: true});
            }

            dispatch(createDriver({
                name: currentDriver.name,
                username: username,
                password: password,
                contacts: contacts,
                equipment: currentDriver.equipment,
                carrier_id: carrier_id
            }, equipments.filter(e => !e.saved)));
        } else {
            dispatch(updateDriver({
                driver: {
                    id: currentDriver.id,
                    name: currentDriver.name,
                    contacts: contacts,
                    equipment: currentDriver.equipment,
                    carrier_id: carrier_id
                }
            }, equipments.filter(e => !e.saved)));

        }
    }

    async function getTempLink(uid, is_thumbnail = false) {
        if (!uid) return null;
        const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link_new_inspections(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;

        const variables = {uid, is_thumbnail};

        try {
            return await GraphqlService.SendQuery(query, variables);
        } catch (ex) {
            return '';
        }
    }

    const onChangeUsername = async () => {
        const data = await GraphqlService.SendQuery(DriverQueries.CHECK_USERNAME, {username});

        if(!data.success){
            return toast.addToast(data.message, {appearance: 'error', autoDismiss: true});
        }

        if (username && driver.username !== username) {
            dispatch(updateDriverUsername({
                driver_id: currentDriver.id,
                new_username: username
            }));
        }
    }

    const onChangePassword = () => {

        if (password && password !== confirmPassword) {
            return toast.addToast('Password and confirm password must match.', {appearance: 'error'});
        }

        if (password) {
            dispatch(updateDriverPassword({
                driver_id: currentDriver.id,
                password: password,
                confirm_password: password
            }));
        }
    }

    const [equipmentId, setEquipmentId] = useState(0);

    const handleAddEquipmentPhoto = (files) => {
        if (files && files.length) {
            let temp = [...equipments];
            // save temporal equipment id to search it later
            setEquipmentId(equipments.length + 1);
            temp.push({
                id: equipments.length + 1,
                files: files,
                uid: undefined,
                saved: false,
                file: URL.createObjectURL(files[0])
            });

            setEquipments([...temp]);

            if (!isNew) {
                dispatch(addEquipmentFileSingle(currentDriver.id, files[0]));
            }
        }
    }

    const handleRemoveEquipmentPhoto = (equipment) => {
        let temp = [...equipments];
        let newArray = temp.filter(e => e.id !== equipment.id);

        setEquipments([...newArray]);

        if (!isNew)
            dispatch(removeEquipmentFileSingle(currentDriver.id, equipment.uid));
    }

    const handleDeleteDriver = () => {
        setShowConfirm(true);
    }

    
    

    return (
        <div className={"flex-1-container card driver-form-card" + (carrier_id ? '' : 'p-3')}>
             {!carrier_id && <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                    <p className="m-0 font-14 font-medium">{isNew ? 'Add' : 'Edit'} driver</p>
                    <i className="font-12 font-medium ti-close cursor-pointer" onClick={handleOnClose}></i>
                </div>}
            <div className="flex-1-container row-container">
               
            <div className="d-flex col-6">
                        {/* <p className="font-12 font-medium">{isNew ? 'New' : 'Edit'} driver</p> */}

                        <div className="col-12 flex-1-container">
                            <FormGroup name={'name'}
                                       value={currentDriver.name}
                                       label={'Driver name*'}
                                       placeholder={'Driver Name'}
                                       onTextChange={(value) => handleCurrentDriver('name', value)}/>

                            <FormGroup name={'equipment'}
                                       type={'select'}
                                       placeholder={'Select one'}
                                       options={{data: ['enclosed', 'flatbed', 'open'], value: x => x, label: x => x}}
                                       value={currentDriver.equipment}
                                       label={'Equipment type (optional)'}
                                       onTextChange={(value) => handleCurrentDriver('equipment', value)}/>

                           

                            <FormGroup name={'phone'}
                                       value={phoneNumber.value}
                                       label={'Phone #*'}
                                       colSize={6}
                                       placeholder={'000-000-0000'}
                                       onTextChange={handlePhoneNumber}/>

                            <FormGroup name={'email'}
                                       value={email?.value}
                                       label={'Email address'}
                                       placeholder={'abc@xyz.com'}
                                       onTextChange={handleEmail}/>

{!isNew &&  <div className="my-3 d-flex justify-content-end">
                <div className="col-6">
                <button disabled={!isValid()}
                                        className="btn btn-blue-light btn-block rounded-pill font-12"
                                        onClick={handleOnSubmit}>{'UPDATE INFO'}
                                </button>
                </div>
                
            </div>}

                          

                            <div className="row">
                                <div className="col-12">
                                    <FormGroup name={'driver_name'}
                                               value={username}
                                               label={'Username*'}
                                               placeholder={'Username'}
                                               onTextChange={handleUsername}/>

                                    { !isNew &&
                                        <div>
                                            <button disabled={!(driver?.username !== username)} className="btn w-100 py-2 btn-blue-light rounded-pill fs-18 "
                                                    onClick={()=>onChangeUsername()} >
                                                Change username
                                            </button>
                                        </div>
                                    }
                                </div>

                                <div className="col-12 mt-3">
                                    <FormGroup name={'driver_password'}
                                               value={password}
                                               type={'password'}
                                               label={'Password*'}
                                               placeholder={'Password'}
                                               onTextChange={handlePassword}/>

                                    <FormGroup name={'driver_password_confirm'}
                                               value={confirmPassword}
                                               type={'password'}
                                               label={'Confirm Password*'}
                                               placeholder={'Confirm password'}
                                               onTextChange={handleConfirmPassword}/>

                                    {!isNew &&
                                        <div>
                                            <button disabled={!(password && password.replaceAll(' ', '') !== '')}
                                                    className="btn w-100 py-2 btn-blue-light rounded-pill fs-18 "
                                                    onClick={() => onChangePassword()}>
                                                Change password
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>


                             

                            {
                                !isNew &&
                                <div className="row mt-4 mb-2">
                                    <div className="col-12 text-center">
                                        <button onClick={handleDeleteDriver} className={`btn btn-block btn-danger`}>DELETE
                                            DRIVER
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>

                    <div className="d-flex col-6">
                            <div className="col-12 flex-1-container">
                                <label className="font-12 mb-1">Upload equipment photo (optional)</label>

                                <div className="col-12 mb-3">
                                    <div className="row col-12">
                                        {
                                            [...equipments]?.map((equipment, key) => {
                                                return (
                                                    <div key={key} className="col-6 mb-3">
                                                        <div className="btn-upload-equipment-image">
                                                            <img src={equipment.file} alt=""/>
                                                            <button
                                                                className="btn-circle btn shadow-sm bg-white btn-sm font-medium p-3 text-light-blue"
                                                                onClick={() => {
                                                                    handleRemoveEquipmentPhoto(equipment);
                                                                }}>
                                                                <i className="ti-close font-16"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                        <div className="col-6">
                                            <button className="btn btn-upload-equipment"
                                                    onClick={() => {
                                                        document.getElementById("input-file").click();
                                                    }}>
                                                <i className="ti-plus mr-2"></i> ADD PHOTO
                                                <input id="input-file"
                                                    type="file"
                                                    multiple={false}
                                                    accept="image/png, image/jpeg"
                                                    className="d-none"
                                                    onChange={(event) => {
                                                        let files = event.target.files;
                                                        handleAddEquipmentPhoto(files);
                                                    }}/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
            </div>


           {isNew &&  <div className={"mt-3 d-flex justify-content-" + (carrier_id ? 'between' : 'end')}>
               {carrier_id && <div className="col-6">
                                <button className="btn btn-clear text-light-blue btn-block rounded-pill" onClick={(evt) => handleOnClose()}>CANCEL</button>
                   </div>}
                <div className="col-6">
                <button disabled={!isValid()}
                                        className="btn btn-blue-light btn-block rounded-pill font-12"
                                        onClick={handleOnSubmit}>{'ADD DRIVER'}
                                </button>
                </div>
                
            </div>}

            <Modal visiblePopUpModal={showConfirm} handleClickOutPopupModal={() => setShowConfirm(false)}>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 text-center mb-3">
                                Are you sure you want to delete this driver ?
                            </div>

                            <div className="col-6">
                                <button className="btn btn-skyblue btn-block" onClick={() => {
                                    setShowConfirm(false);
                                    dispatch(removeDriver(currentDriver.id));
                                }}>YES</button>
                            </div>

                            <div className="col-6">
                                <button className="btn btn-danger btn-block" onClick={() => setShowConfirm(false)}>NO</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default DriverForm;
