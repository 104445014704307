import React, { useReducer } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
//import { closeLoading, openLoading } from '../../redux/actions/Loading.action';
import { AllStates } from '../../redux/reducers';
import messageBoxTypes from '../../redux/types/MessageBox.type';
//import messageBoxTypes from '../../redux/types/MessageBox.type';
// import { reducerLoading } from '../../providers/LoadingProvider';


interface IMessageBoxButton {
    text: string,
    css?: string,
    action?: (data) => void,
    data?: any
}

const useMessageBox = () => {


    // const dispatch = useSelector<AllStates>();
    const dispatch = useDispatch();

    const open = (props: {title: string, message: string, buttons: IMessageBoxButton[]} = {title: '', message: '', buttons: []}) => {
        // dispatch(openLoading(text));
       dispatch({type: messageBoxTypes.OPEN, payload: props});
    }

    const close = () => {
       dispatch({type: messageBoxTypes.CLOSE});
    }
    return  {
        open,
        close
    }

}

export default useMessageBox;