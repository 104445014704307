import React, {useEffect, useState} from 'react'
import ICONS from "../../../assets/svg";
import './CardDetailDriver.scss';
import {gql} from "apollo-boost";
import {GraphqlService} from "../../services/graphql.service";

type DetailCardDriverProp = {
    handleClickDropdown: (driver) => void,
    handleEditClick: (driver) => void,
    selected?: boolean,
    flat?: boolean,
    driver: any
}


const CardDetailDriver = (props: DetailCardDriverProp) => {
    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        if (props?.driver && props?.driver?.photos) {
            loadPhotos().then();
        }
    }, [props]);

    async function loadPhotos() {
        let photos = await Promise.all(props?.driver?.photos?.map(async (data) => {
            return await getTempLink(data.uid);
        }));

        setPhotos([...photos]);
    }

    async function getTempLink(uid, is_thumbnail = false) {
        if (!uid) return null;
        const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link_new_inspections(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;

        const variables = {uid, is_thumbnail};

        try {
            return await GraphqlService.SendQuery(query, variables);
        } catch (ex) {
            return '';
        }
    }

    return (
        <>
            <div id={`driver-card-detail-${props.driver.id}`} className={`detail-card-driver cursor-pointer mt-2 ${props.selected ? 'card-selected' : ''}`}
                 onClick={() => {
                     props.handleClickDropdown(props.driver)
                 }}>
                <div className="p-3">
                    <div className="row">
                        <div className="col-10">
                             <span className="name-driver-span">
                                {props.driver.name}
                            </span>
                            <span
                                className={'phone-span'}>{props.driver?.contacts?.map(contact => contact.type?.trim() === 'Phone' || contact.type?.trim() === 'phone' ? `${contact.value}\n` + '\n' : '')}</span>
                            <span
                                className={'email-span'}>{props.driver?.contacts?.map(contact => contact.type?.trim() === 'Email' || contact.type?.trim() === 'email' ? `${contact.value}\n` + '\n' : '')}</span>
                        </div>
                        <div className="col-2 text-center">
                            {!props.flat && <button type={"button"}>
                                <img src={ICONS.IconCheckUncheck} className={'img-options'}
                                     alt="checkbox_uncheck"/>
                            </button>}
                        </div>
                        <div className={`w-100`}><small>D-{props.driver?.id}</small></div>
                        <div
                            className="d-flex justify-content-between align-items-center mt-2">
                            {!props.flat && <div className="flatbed">
                                <span className={`mr-1`}>Flatbed</span>
                                {photos.map((photo, index) => {
                                    return <img key={index}
                                                src={photo}
                                                className={'mini-picture'}
                                                alt="example icon"/>
                                })
                                }
                            </div>
                            }
                            <span className={'active-span'}>ACTIVE</span>
                        </div>
                        {!props.flat && <div className={`w-100 text-right`}><span onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.handleEditClick(props.driver);
                        }} className={`text-danger fs-16`}>EDIT</span></div>}
                    </div>
                </div>
            </div>


        </>
    )
}

export default CardDetailDriver
