import Status from "../Status/Status";
import DriverScheduleComponent from "../DriverSchedule/DriverSchedule";
import React, {useState} from "react";
import Helper from "../../helper/Helper";
import { useEffect } from "react";

const DispatchItinerary = ({driverSelected}) => {
    const [driver, setDriver] = useState(null);


    useEffect(() => {
        setDriver(driverSelected);
    }, [driverSelected]);

    if (!driver)
        return <div >
            <h4 className={'font-14 mb-1'}>No driver selected</h4>
            <h4 className={'font-12 font-normal'}>Please select a driver to see details</h4>
        </div>

    return(
        <>
            <div className="flex-1-container">
                            <div className="">
                                <p className="font-14 font-medium ">Driver itinerary</p>

                                <div className="pb-3 d-flex justify-content-between align-items-center ">
                                    <div>
                                        <p className="font-14 font-medium m-0">{driver?.name}</p>
                                        <p className="font-12 m-0">{driver?.carrier}</p>
                                    </div>

                                    <div className="text-end">
                                        <Status statusName={'IN TRANSIT' || 'LOADING...'}/>
                                        <p className="font-12 font-medium m-0">{ Helper.Masks.ValidUSMoney(1000) } / { Helper.Masks.ValidUSMoney(350) }</p>
                                    </div>
                                </div>
                            </div>


                            <div className="flex-1-container">
                                <DriverScheduleComponent driver={driver} onOrderClicked={() => {}}/>
                            </div>
            </div>
        </>
    )
}

export default DispatchItinerary;
