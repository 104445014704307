import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Carrier, Driver } from '../../classes/classes';
import CardDetailDriver from '../../components/CardDetailDriver/CardDetailDriver';
import DriverForm from '../../components/DriverForm/DriverForm';
import Modal from '../../components/Modal/Modal';
import Searchbar from '../../components/Searchbar/Searchbar';
import Table from '../../components/Table/Table';
import CarrierQueries from '../../graphql/query/Carrier';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
const query = gql`
query($driver_id: Int, $showclosed:Boolean){
    get_carrier_driver_orders(driver_id:$driver_id, showclosed:$showclosed){
      time
      velox_order_id
      carrier_pay
      driver_pay
      remaining_driver_payable
      remaining_carrier_receivable
      expires
      vehicle{
        id
        year
        make
        model
      }
      driver{
        id
        name
        equipment
      }
      origin{
        nofail
        scheduled
        time_completed
        completed
        address{
          street_number
          street
          city
          state
          postal_code
          country
        }
      }
      destination{
        nofail
        scheduled
        time_completed
        completed
        address{
          street_number
          street
          city
          state
          postal_code
          country
        }
      }
      broker_note
      carrier{
        id
        name
  
      }
  
    }
  }
`;
const COLUMNS: any[] = [
    {
        label: 'ORDER #',
        active: false,
        orderBy: 'DESC',
        name: 'velox_order_column',
        sort_by: 'velox_order_id'
    },
    {
        label: 'VEHICLE',
        active: false,
        orderBy: 'DESC',
        name: 'vehicle',
        sort_by: 'vehicle'
    },
    {
        label: 'TIME IN',
        active: false,
        type: 'date',
        orderBy: 'DESC',
        name: 'time_in',
        sort_by: 'time'
    },
    {
        label: 'BROKER',
        active: false,
        orderBy: 'DESC',
        name: 'broker',
        sort_by: 'broker_sort'
    },

    {
        label: 'ORIGIN',
        active: false,
        orderBy: 'DESC',
        name: 'origin',
        sort_by: 'origin_sort'
    },

    {
        label: 'DESTINATION',
        active: false,
        orderBy: 'DESC',
        name: 'destination',
        sort_by: 'destination_sort'
    },


    {
        label: 'DRIVER PAY',
        active: false,
        orderBy: 'DESC',
        name: 'driver_pay',
        sort_by: 'driver_sort',
    },

    {
        label: 'REMAINING BALANCE',
        active: false,
        orderBy: 'DESC',
        name: 'remainig_balance',
        sort_by: 'remainig_balance_amount',
    },

    {
        label: 'STATUS',
        active: false,
        orderBy: 'DESC',
        name: 'status',
        sort_by: 'status'
    }];

const DriverPage = () => {

    const [drivers, setDrivers] = useState([]);
    const [editDriver, setEditDriver] = useState(null);
    const [showDriverModal, setShowDriverModal] = useState(null);
    const [loading, setLoading] = useState(false);
    const [driverID, setDriverID] = useState(null);
    const [rows, setRows] = useState([]);
    const [loadingTable, setLoadingTable] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    async function getCarrierData(){
        try {
            setLoading(true);
            const data = await GraphqlService.SendQuery(CarrierQueries.CARRIER_SETTINGS_DATA);
            data.drivers = data.drivers.sort((x, y) => x.name > y.name ? 1 : -1);
            setDrivers(data.drivers);

            setLoading(false);
        } catch (ex) {
            console.log(ex);
            setLoading(false);
        }
    }

    async function getDriverJobs(){
        try {
            setLoadingTable(true);
            let data = await GraphqlService.SendQuery(query, { driver_id: driverID, showclosed: false });
            data = data.map(r => {
                return {
                    id: r.driver_id,
                    name: (r.driver?.name || r.name) || '-',
                    velox_order_column: !r.velox_order_id ? '-' : 'VLX-' + r.velox_order_id,
                    velox_order_id: r.velox_order_id,
                    vehicle: !r.vehicle ? '-' : [r.vehicle?.vin || '-', r.vehicle?.year + ' ' + r.vehicle?.make + ' ' + r.vehicle?.model],
                    driver: [r.driver?.name || r.name || '-'],
                    driver_sort: r.driver?.name,
                    broker: [r.broker?.name || '-'],
                    broker_sort: r.broker?.name,
                    time_in: !r.time ? '-' : [Helper.FORMAT.USDate(r.time, 'do'), Helper.FORMAT.USDate(r.time, 'totz')],
                    origin: !r.origin ? '-' : [r.origin?.address?.city, `${r.origin.address?.state} ${r.origin.address?.postal_code}`, Helper.FORMAT.USDate(r.origin.scheduled, 'do'), Helper.FORMAT.USDate(r.origin.scheduled, 'totz')],
                    destination: !r.destination ? '-' : [r.destination.address?.city, `${r.destination.address?.state} ${r.destination.address?.postal_code}`, Helper.FORMAT.USDate(r.destination.scheduled, 'do'), Helper.FORMAT.USDate(r.destination.scheduled, 'totz')],
                    origin_sort: !r.origin ? '-' : r.origin?.address?.city,
                    destination_sort: !r.destination ? '-' : r.destination?.address?.city,
                    status: !r.destination ? '-' : r.origin.completed && r.destination.completed ? 'COMPLETED' : (r.origin.completed ? 'PICKED UP' : 'DISPATCHED'),
                    remainig_balance: !r.destination ? '-' : Helper.FORMAT.USCurrency(r.remaining_driver_payable),
                    remainig_balance_amount: r.remaining_driver_payable,
                    driver_pay: !r.driver_pay ? '-' : Helper.FORMAT.USCurrency((r as any).driver_pay)
                }
            });

            setRows(data);
            setLoadingTable(false);
        } catch (ex) {
            console.log(ex);
            setLoadingTable(false);
        }
    }

    useEffect(() => {
        if (driverID)
            getDriverJobs();
    }, [driverID]);

    useEffect(() => {
        getCarrierData().then();
    }, []);

    const driversFiltered = () => drivers.filter(d => JSON.stringify(d).toLowerCase().includes(searchTerm.toLowerCase()));

    const ref = useRef<HTMLInputElement>();


    return <div className='col-12 flex-1-container row-container'>
        <div className="d-flex col-4 col-sm-12 bg-blue">
                    <div className="flex-1-container main-radius p-3 bg-white">
                                <div className="d-flex flex-1-container">
                                <div
                                            className="d-flex justify-content-between align-items-center mb-3">
                                            <span  className={`font-12 font-bold`}>Drivers {driversFiltered().length} / {drivers?.length}</span>
                                            <span className={`edit`}
                                                onClick={() => {
                                                    setEditDriver(new Driver())
                                                    setShowDriverModal(new Driver());
                                                }}>add driver</span>
                                        </div>


                                        <Searchbar value={searchTerm} onChange={({target: {value}}) => setSearchTerm(value)} reference={ref} />

                                        <div className="flex-1-container mt-3">
                                        {driversFiltered().map((driver, key) => <CardDetailDriver key={key}
                                                                                                selected={driver?.id == driverID}
                                                                                                driver={driver}
                                                                                                handleEditClick={(e) => {
                                                                                                    setShowDriverModal(new Driver(e))
                                                                                                    setEditDriver(e)
                                                                                                } }
                                                                                                handleClickDropdown={(driver) => setDriverID(driver?.id)}/>)}
                                        </div>
                                </div>
                    </div>
        </div>

        <div className="d-flex col-8 col-sm-12 bg-blue">
                    <div className="flex-1-container main-radius p-3 bg-white">
                                <div className="d-flex flex-1-container">
                                    {driverID && <h4 className='font-14 mb-3'>Driver: {drivers.find(x => x.id == driverID)?.name}</h4>}
                                    <Table columns={COLUMNS} rows={rows} rowHeight={80} loading={loadingTable} onRowClicked={() => false} />
                                </div>
                    </div>
        </div>

        <ReactModal className={'modal-1016'} isOpen={showDriverModal}
                   onRequestClose={() => setShowDriverModal(undefined)}>
                <DriverForm drivers={drivers}
                            driver={editDriver}
                            onSubmit={() => {
                                setEditDriver(null)
                                getCarrierData().then();
                                setShowDriverModal(undefined);
                            }} />
            </ReactModal>
    </div>
}


export default DriverPage;
