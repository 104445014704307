import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import logo from '../../../assets/images/logo.png';
import ICONS from '../../../assets/svg';
import FormGroup from '../../components/FormGroup/FormGroup';
import Loading from '../../components/Loading/Loading';
import Helper from '../../helper/Helper';
import AuthService from '../../services/auth.service';
import { GraphqlService } from '../../services/graphql.service';

const SignupCarrierPage = (props) => {

    const {token} = useParams<{token: string}>();

    const userRef = useRef<HTMLInputElement>();
    const [password, setPassword] = useState<string>('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [name, setName] = useState('');
    const [carrier, setCarrier] = useState('JP Logistics');
    const toast = useToasts();

    const isValid = () => Helper.Validators.IsValidPassword(password) && Helper.Validators.IsValidPassword(confirmPassword) && password === confirmPassword;

    


    async function load() {
        try {
            Helper.UseApiKey();
            const data = await GraphqlService.SendQuery(gql`
                query($token: String){
                    get_carrier_profile_signup_info(token: $token){
                    success
                    message
                    }
                }
            `, {token});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            setName(data.message);
        } catch (ex) {
            setErrorText(ex.message);
            // setName('Manolo cabeza de huevo')
        }
    }

    async function handleSubmit(evt) {
        evt.preventDefault();
        setLoading(true);

        try {
            Helper.UseApiKey();
            const data = await GraphqlService.SendMutation(gql`
            mutation($token: String, $password: String, $confirm_password: String){
                set_carrier_profile_password(token: $token, password:$password, confirm_password:$confirm_password){
                  success
                  message
                }
              } 
              
            `, {token, password, confirm_password: confirmPassword});

            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            setLoading(false);
            setSuccess(true);
            console.log(data);
            const result = JSON.parse(data.message);

            AuthService.TOKEN = result.token;
            AuthService.PROFILE = result.profile;
            console.log(result);

            localStorage.setItem('carrier_token', AuthService.TOKEN);
            localStorage.setItem('profile', JSON.stringify(result.profile));

            Helper.Navigation.NavigateTo(`/`);
            toast.addToast(result.message, {appearance: 'success', autoDismiss: true})
            

        } catch (ex) {
            setErrorText(ex.message);
        }
    }


    useEffect(() => {
        if (token)
            load().then();
    }, [token]);
    return   <div className='login-container flex-1-container'>
    <img src={process.env.PUBLIC_URL + '/favicon.png'} style={{width: '100%', display: 'block', margin: '0 auto 1rem auto'}} alt="" />
    {/* <h4>Reset Password</h4> */}



    {(!errorText && !name) && <Loading />}
   
    {(!errorText && name) && <>
    <h4>{name}</h4>
    {/* {!success && <h4>Please enter your new password</h4>} */}

{!success && <form onSubmit={handleSubmit.bind(this)}>
     <FormGroup type='password' validation={Helper.Validators.IsValidPassword} errorText={Helper.Validators.PasswordText} name='password' label='Password' required placeholder='Password' value={password} onTextChange={setPassword} />

     <FormGroup type='password' validation={Helper.Validators.IsValidPassword} errorText={Helper.Validators.PasswordText} name='confirm_password' label='Confirm Password' required placeholder='Confirm Password' value={confirmPassword} onTextChange={setConfirmPassword} />
     {password != confirmPassword && <h4 className="font-12 text-red text-center">Password must match</h4>}
     

     <button disabled={!isValid() || loading}
             type='submit'>Finish Sign Up{loading && <span className='doing-login'>...<span></span></span>}</button>



 </form>}
    </>}

    {errorText && <h4 className='font-12 text-red text-center'>{errorText}</h4>}

    {success && <p className="font-12">The password has been successfully reset, <Link className='d-block' to='/login'>Click here to go to login</Link></p>}
</div>
}

export default SignupCarrierPage;