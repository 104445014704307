import { combineReducers } from "redux";
import { ILoading, loadingReducer } from "./Loading.reducer";
import { IMessageBox, messageBoxReducer } from "./MessageBox.reducer";
import NotificationsReducer, { INotifications } from "./Notifications.reducer";
import OrdersReducer, { IOrder } from "./Order.reducer";
import OrderRequestsReducer, {IOrderRequestsKeys} from "./OrderRequests.reducer";
import driverReducer, { IDriverState } from "./Driver.reducer";
import documentsReducer, { IDocumentsState } from "./Documents.reducer";

const reducers = combineReducers({
    notifications: NotificationsReducer,
    orderRequests: OrderRequestsReducer,
    orders: OrdersReducer,
    loading: loadingReducer,
    messageBox: messageBoxReducer,
    driver: driverReducer,
    documents: documentsReducer
});



export interface AllStates {
    notifications: INotifications,
    orderRequests: IOrderRequestsKeys,
    orders: IOrder,
    loading: ILoading,
    driver: IDriverState,
    messageBox: IMessageBox,
    documents: IDocumentsState
}

export default reducers;
