import driverTypes from "../types/Driver.type";
import {GraphqlService} from "../../services/graphql.service";
import {gql} from "graphql-tag";
import Helper from "../../helper/Helper";

const ADD_DRIVER_QUERY = gql(`
    mutation($name: String, $username: String, $password: String, $equipment: String, $contacts: [driver_contact_input], $carrier_id: Int) {
        create_driver(name: $name, username: $username, password: $password, equipment: $equipment, contacts: $contacts, carrier_id: $carrier_id) {
            id
            message
            success
        }
    }`);

const UPDATE_DRIVER_QUERY = gql(`
    mutation($driver: driver_input) {
        update_driver(driver: $driver) {
            id
            message
            success
        }
    }`);

const UPDATE_DRIVER_USERNAME_QUERY = gql(`
    mutation($driver_id:Int, $new_username:String! ){
      change_driver_username(driver_id:$driver_id, new_username:$new_username){
        id
        message
        success
      }
    }`);

const UPDATE_DRIVER_PASSWORD_QUERY = gql(`
    mutation($driver_id:Int, $password: String, $confirm_password: String ){
      update_driver_password(driver_id:$driver_id, password: $password, confirm_password: $confirm_password){
        id
        message
        success
      }
    }`);

const ADD_EQUIPMENT_FILE = gql(`
    mutation($driver_id: Int!, $file: Upload) {
        upload_driver_equipment_photo(driver_id: $driver_id, file: $file) {
            id
            link
            message
            success
        }
    }`);

const REMOVE_DRIVER = gql(`mutation($driver_id: Int) {
  remove_driver(driver_id: $driver_id) {
    id
    message
    success
  }
}`);

const REMOVE_DRIVER_EQUIPMENT = gql(`mutation($driver_id:Int, $uid:String) {
  remove_driver_equipment(driver_id:$driver_id, uid:$uid) {
    id
    message
    success
  }
}`);

const initDriverForm = () => {
    return {type: driverTypes.INIT_DRIVER_FORM}
}

const updateDriverRequest = () => {
    return {type: driverTypes.UPDATE_DRIVER_REQUEST}
}

const updateDriverSuccess = (response) => {
    return {type: driverTypes.UPDATE_DRIVER_SUCCESS, payload: response}
}

const AddEquipmentSuccess = (response) => {
    return {type: driverTypes.ADD_EQUIPMENT_SUCCESS, payload: response}
}

const removeEquipmentSuccess = (response) => {
    return {type: driverTypes.REMOVE_EQUIPMENT_SUCCESS, payload: response}
}

const updateDriverFailure = (error) => {
    return {type: driverTypes.UPDATE_DRIVER_FAILURE, payload: error}
}

const removeDriverSuccess = () => {
    return {type: driverTypes.REMOVE_DRIVER_SUCCESS, payload: driverTypes.REMOVE_DRIVER_SUCCESS}
}

export const initDriver = () => {
    return async (dispatch) => dispatch(initDriverForm());
}

export const createDriver = (params, equipments) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        if (params.carrier_id)
            Helper.UseApiKey();
        GraphqlService.SendMutation(ADD_DRIVER_QUERY, params).then(async response => {
            if (response && response.success) {
                if (equipments.length) {
                    dispatch(addEquipmentFile(response, response.id, equipments, 0, params.carrier_id != null));
                } else {
                    dispatch(updateDriverSuccess(response));
                }
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error.message));
        });
    }
}

export const updateDriver = (params, equipments) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(UPDATE_DRIVER_QUERY, params).then(response => {
            console.log(response);
            if (response && response.success) {
                // dispatch(addEquipmentFile(response, response.id, equipments));
                dispatch(updateDriverSuccess(response));
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const removeDriver = (driver_id) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(REMOVE_DRIVER, { driver_id }).then(() => {
            dispatch(removeDriverSuccess());
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const updateDriverUsername = (params) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(UPDATE_DRIVER_USERNAME_QUERY, params).then(response => {
            if (response && response.success) {
                dispatch(updateDriverSuccess(response));
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const updateDriverPassword = (params) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(UPDATE_DRIVER_PASSWORD_QUERY, params).then(response => {
            if (response && response.success) {
                dispatch(updateDriverSuccess(response));
            } else {
                dispatch(updateDriverFailure(response.message));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

const addEquipmentFile = (parentResponse, driver_id, equipments, index = 0, useApiKey = false) => {
    return async (dispatch) => {
        if (useApiKey)
            Helper.UseApiKey();
        GraphqlService.SendMutation(ADD_EQUIPMENT_FILE, {driver_id, file: equipments[index]?.files}).then(response => {
            if (response && index < equipments.length - 1) {
                index = index + 1;
                dispatch(addEquipmentFile(parentResponse, driver_id, equipments, index, useApiKey));
            } else {
                dispatch(updateDriverSuccess(parentResponse));
            }
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}


export const addEquipmentFileSingle = (driver_id, file) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(ADD_EQUIPMENT_FILE, {driver_id, file}).then(response => {
            dispatch(AddEquipmentSuccess(response));
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}

export const removeEquipmentFileSingle = (driver_id, uid) => {
    return async (dispatch) => {
        dispatch(updateDriverRequest());
        GraphqlService.SendMutation(REMOVE_DRIVER_EQUIPMENT, {driver_id, uid}).then(response => {
            dispatch(removeEquipmentSuccess(response.message));
        }, (error) => {
            dispatch(updateDriverFailure(error));
        });
    }
}
