import {useEffect, useRef, useState} from "react";

import './SideActionManager.scss';
import * as React from "react";
import ICONS from "../../../assets/svg";


const SideActionManager = props => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(props.opened);
    const [title, setTitle] = useState(props.title);

    useEffect(
        () => {
            setIsSidebarOpen(props.opened)
        }, [props.opened],
    );
    useEffect(
        () => {
            setTitle(props.title)
        }, [props.title],
    );

    return (
        <>
           
            {
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: '100vh',
                    height:'100vh',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                    overflow: 'hidden'
                }} id="sidebarAction" className={`w-100 flex-1-container ${isSidebarOpen ? `active` : ''}`}>

                      {
                          props.title &&
                          <header className={`d-flex justify-content-between align-items-center`}>
                              <h5 className="fs-16">{title}</h5>
                              <span className={`cursor-pointer`} onClick={() => {
                                  setIsSidebarOpen(false);
                                  props.onCloseManager(false);
                              }}><img src={ICONS.IconCloseBig} alt=""/></span>
                          </header>
                      }
               
                        <div className="flex-1-container">
                            
                        {props.children}
                        </div>
            
                </div>
            }
         
            
        </>
    );
};

export default SideActionManager;
