import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import FormGroup from '../../components/FormGroup/FormGroup';
import Helper from '../../helper/Helper';
import { GraphqlService } from '../../services/graphql.service';
import styles from './SuperLoginPage.module.scss';

const SuperLoginPage = () => {
    const {code} = useParams<any>();
    const [password, setPassword] = useState('');
    const [carrierName, setCarrierName] = useState('');
    const [error, setError] = useState('');

    async function verify() {
        const mutation = gql`
            mutation ($code: String!, $password: String!){
                verify_super_login(code:$code, password:$password){
                    message
                    redirect_link
                    token
                    profile{
                    id
                    name
                    email
                    role
                    contacts{
                        name
                        value
                    }
                    carrier_id
                    carrier_name
                    }
                }
            }
        `;

        try {
            Helper.UseApiKey();
            const data = await GraphqlService.SendMutation(mutation, {code, password});

            // console.log(data);

            localStorage.setItem('carrier_token', data.token);
            localStorage.setItem('profile', JSON.stringify(data.profile));
            window.location.href = data.redirect_link;
        } catch (ex) {
            console.log('ex', ex.message);
            setError(ex.message.replace('GraphQL error: ', ''));

            
        }
    }

    async function decode()
    {
        const mutation = gql`
            query ($code: String!){
                decode_super_login(code:$code)
            }
        `;

        try {
            Helper.UseApiKey();
            const data = await GraphqlService.SendMutation(mutation, {code});
            setCarrierName(data);
        } catch (ex) {
            console.log(ex.message);
            setError('This link is no longer valid');
        }
    }

    useEffect(() => {
        decode();
    }, []);

    return <div className={styles.superlogincontainer}>
        {carrierName && <>
            <h4 className="font-16 mb-3">Log in as: {carrierName}</h4>
        <FormGroup type='password' value={password} onTextChange={(evt) => {
            setPassword(evt);
            setError('')
        }} name='password' placeholder='Password' label='Password' />

        <button onClick={(evt) => verify()} disabled={!password} className={"btn btn-shy-light col-12 mt-3 mb-2 py-2 font-bold" + (error ? ' mb-3' : '')}>LOG IN</button>
        </>}

        {error && <h4 className='text-center text-red font-16'>{error}</h4>}
    </div>
}

export default SuperLoginPage;