import {useEffect, useRef, useState} from "react";
import ICONS from "../../../assets/svg";
import { NavLink,useHistory } from "react-router-dom";

import './SideMenu.scss';
import { AllStates } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { IOrderInternal } from "../../redux/reducers/Order.reducer";
import {IOrderRequests} from "../../redux/reducers/OrderRequests.reducer";
import getAllNotifications from "../../redux/actions/Notifications.action";
import { useToasts } from "react-toast-notifications";
import { INotifications } from "../../redux/reducers/Notifications.reducer";
import Helper from "../../helper/Helper";
import { IDocumentsState } from "../../redux/reducers/Documents.reducer";
import Status from "../Status/Status";

class MenuOption {
    title: string = '';
    icon: string = '';
    uri: string = '';

    constructor(props) {
        this.title = props.title;
        this.icon = props.icon;
        this.uri = props.uri;
    }
}

const baseMenuOptions: MenuOption[] = [
    {
        title: 'Dispatch',
        icon: ICONS.DRIVER,
        uri: '/dispatch'
    },
    {
        title: 'Order Requests',
        icon: ICONS.ORDERS,
        uri: '/order-requests'
    },
    {
        title: 'Jobs',
        icon: ICONS.DISPATCH,
        uri: '/jobs'
    },
    {
        title: 'Map',
        icon: ICONS.NETWORK,
        uri: '/map'
    },
    {
        title: 'Drivers',
        icon: ICONS.DRIVER,
        uri: '/drivers'
    },
    {
        title: 'Accounting',
        icon: ICONS.IconPayment,
        uri: '/payments'
    },
    {
        title: 'Documents',
        icon: ICONS.IconUpload,
        uri: '/carrier-documents'
    },
    {
        title: 'Carrier profile',
        icon: ICONS.PROFILE,
        uri: '/carrier/profile'
    },
    {
        title: 'Notifications',
        icon: ICONS.IconClock,
        uri: '/notifications'
    }
];


const SideBar = () => {

    const ordersOpen = useSelector<AllStates>(x => x.orders.open) as IOrderInternal;
    const ordersRequestsPending = useSelector<AllStates>(x => x.orderRequests.pending) as IOrderRequests;
    const [menuOptions, setMenuOptions] = useState(baseMenuOptions.map(menu => new MenuOption(menu)));
    const [collapsed, setCollapsed] = useState(false);
    const hasLoaded = useRef(false);
    const [myNotifications, setMyNotifications] = useState([]);
    const dispatch = useDispatch();
    const [notifications, setNotifications] = useState(0);
    const documents = useSelector<AllStates>(x => x.documents) as IDocumentsState;

    const {data, last_id} = useSelector<AllStates>(state => state.notifications) as INotifications;

    const toast = useToasts();


    const refreshNotifications = () =>
    {
        if (hasLoaded.current)
        {
            console.log('last_id', last_id);
            let diff = data.filter(x => x.id > last_id);
            setNotifications(diff.length);
        }
    }

    useEffect(() => {

        if (hasLoaded.current)
        {

            let diff = data.filter(x => x.id > last_id);
            console.log(diff, last_id);
            diff.forEach(x => {

                toast.addToast(<div className='cursor-pointer' onClick={(evt) => Helper.Navigation.NavigateTo('/notifications')}><h2 className='font-14'>{x.title}</h2><p className='font-11'>{x.message}</p></div>, {appearance: 'info', autoDismiss: true, id: 'notification'});
            });
            console.log(diff)
            setNotifications(diff.length);

        }
        if (data != null)
            hasLoaded.current = true;


            refreshNotifications();





    }, [data]);

    useEffect(() => {
        refreshNotifications();
    }, [last_id]);
    useEffect(() => {
    }, []);

    const currentRoute = useHistory().location.pathname;


    const getCount = (key) => {
        if (key == 'Jobs')
            return ordersOpen.data.length;
        if (key == 'Order Requests')
            return ordersRequestsPending.data.length;
        if (key == 'Notifications')
            return notifications;
        return '';
    }

    const getStatusName = () => {
        if (documents.registration && documents.insurance && new Date(documents.expiration).getTime() > new Date().getTime())
            return 'WARNING';
        return 'MISSING';

    }
    return (
        <>
            <nav id="sidebar" className={collapsed && 'collapsed' || ''} >
                <div className="sidebar-header position-relative">
                    <button onClick={(evt) => setCollapsed(!collapsed)} className="position-absolute p-0 btn btn-clear text-light-blue text-center" style={{width: '32px', height: '32px', borderRadius: '50%', right: '-16px', top: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><i className={`fas fa-chevron-${collapsed ? 'right' : 'left'}`}></i></button>
                </div>

                <ul className="list-unstyled components">
                    {

                        menuOptions.map((menu: MenuOption, i) => {
                            return(
                                <li key={`menu-${i}`} onClick={(evt) => evt.stopPropagation()}>
                                    <NavLink
                                        className={` position-relative row navbar-item ${currentRoute===menu.uri || (currentRoute.startsWith('/order/') && i == 0) ? "is-active":""}`}
                                        to={menu.uri}>
                                        <img src={menu.icon} alt=""/><span className="sliderbar-lable">{collapsed ? '' : <>{menu.title}<span  style={{position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '2rem'}}>{getCount(menu.title)}</span></>}</span>
                                        {(menu.title == 'Documents' && documents.message) && <div style={{position: 'absolute', right: '1rem', top: '50%', transform: `translateY(-50%)`}}>
                                            <Status statusName={getStatusName()} />
                                        </div>}
                                    </NavLink>
                                </li>
                            );
                        })
                    }
                </ul>
            </nav>
        </>
    );
};

export default SideBar;
