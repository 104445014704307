import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import ICONS from '../../../../../assets/svg';
import { User, Vehicle } from '../../../../classes/classes';
import Helper from '../../../../helper/Helper';
//import UserQueries from '../../../../graphql/query/User.query';
import { GraphqlService } from '../../../../services/graphql.service';
import './Toolbar.scss';

const driverToken = gql`
    mutation ($origin: Boolean, $inspection_token: String){
        create_driver_token(origin:$origin, inspection_token:$inspection_token)
    }
`

const Toolbar = ({vehicle,driver_name}:{vehicle: Vehicle, driver_name: string}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToasts();
    useEffect(() => {

       // GraphqlService.SendQuery(UserQueries.GET_DATA)
        //.then(d => setUser(new User(d)))
       // .catch(err => console.log(err.message));
    }, []);

    async function handleSeeItinerary(){
        setLoading(true);
        try {
            let path = Helper.Navigation.GetCurrentLocation();
            let inspection_token = path.split('/')[3];
            let origin = path.split('/')[2] == 'true';
            let token = await GraphqlService.SendQuery(driverToken, {origin, inspection_token});
            Helper.Navigation.NavigateTo('/driver/itinerary/'+token)
        } catch (error:any) {
            toast.addToast(error.message, {type: 'error'});
        }
        setLoading(false);
    }
    
    return <div className='p-3 bg-gray-dark toolbar-component'>
        <div className="d-flex justify-content-between w-100">
            <div>
                <h4 className="font-11">{vehicle.vin}</h4>
                <h4 className="font-11 font-light">{vehicle.year} {vehicle.make} {vehicle.model}</h4>
            </div>
            <div>
                <h4 className="font-11 font-light text-right">{driver_name||'DRIVER'}</h4>
                <h4 className="font-14 font-medium text-right" style={{color:'blue'}}
                onClick={handleSeeItinerary}>{loading?'...FETCHING':'SEE ITINERARY'}</h4>
            </div>
        </div>

    </div>
}

export default Toolbar;