import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useToasts } from 'react-toast-notifications';
import useLoading from '../../hooks/useLoading/useLoading';
import Signature from '../../pages/PendingInspectionPage/components/Signature/Signature';
import { GraphqlService } from '../../services/graphql.service';

const DriverSignature = ({onSignatureSaved,driver_id}) => {


    const [signatureOpened, setSignatureOpened] = useState(false);
    const [text, setText] = useState(null);
    const loading = useLoading();
    const toast = useToasts();

    async function getDriverText() 
    {
        const query = gql`
            query ($driver_id:Int){
                get_driver_signature_text(driver_id:$driver_id)
            }
        `;

        try {
            const t = await GraphqlService.SendQuery(query, {driver_id});
            console.log(t);
            setText(t);
        } catch (ex) {
            console.log(ex.message);
        }
    }

    async function handleSubmitSignature(evt) {
        try {
            const mutation = gql`
                mutation($driver_id:Int, $data:String){
                    update_driver_signature(driver_id:$driver_id, data:$data){
                        message
                        success
                    }
                }
            `;
            
            loading.open('Submitting Signature...');
            const data = await GraphqlService.SendMutation(mutation, {driver_id, data: evt});

            if(!data?.success)
            {
                throw new Error(data?.message);
            }
            loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            onSignatureSaved();
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    useEffect(() => {
        getDriverText();
    }, []);

    return <div className='bg-white mx-auto mt-5 p-3 rounded' style={{maxWidth: '600px', width: '90%'}}>
          <h4 className="fs-32 mb-3">Signature required</h4>

          <p className='fs-16 mb-4' dangerouslySetInnerHTML={{__html: text}} />
          <button className='btn btn-blue-light col-12' onClick={(evt) => setSignatureOpened(true)}>Agree & Sign</button>


          <ReactModal isOpen={signatureOpened} className='loading-modal bg-white' >
            <Signature imgSaved={null} onCancel={() => setSignatureOpened(false)} onSubmit={handleSubmitSignature} inputName={'Your Signature'} value={''} showInput={false} />
          </ReactModal>
    </div>
}

export default DriverSignature;