import React,{useState,useRef} from 'react'
import './CardServiceCarrier.scss'
import {IOrderRequest} from "../../redux/reducers/OrderRequests.reducer";
import Helper from '../../helper/Helper';
import BrokerCarrierAgreement from '../BrokerCarrierAgreement/BrokerCarrierAgreement';


type CardServiceCarrierProps={
    data?:IOrderRequest[]
}

const CardServiceCarrier =(props:CardServiceCarrierProps)=>{
    return(
            <>
            {
            props.data.map(item=>{
                return<>
                        <div className="row mt-3">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <span className="font-12 font-medium-500">For carrier</span>
                                </div>
                        </div>

                    <div className="row mt-3">
                        
                        <div className="col-12 card bg-light-gray ">
                            <div className="card-body d-flex flex-column">
                
                                    <div className="row">
                                        <div className="col-6 d-flex flex-column">
                                            <span className="title-ServiceRequested">Carrier pay</span>
                                            <span className="data-ServiceRequested">{Helper.FORMAT.USCurrency(item.price)}</span>
                                        </div>
                                        <div className="col-6 d-flex flex-column">
                                            <span className="title-ServiceRequested">COD</span>
                                            <span className="data-ServiceRequested">$0.00</span>
                                        </div>
                                        {/* <div className="col-12 mt-4 d-flex flex-column">
                                            <span className="title-ServiceRequested" >Carrier Payment Terms</span>
                                            <span className="data-ServiceRequested">15 days after signed BOL and invoice received</span>
                                        </div> */}
                                    </div>
                            </div>
                        </div>
                    </div>
            
                </>})     
            
            }

        </>
    )
}

export default CardServiceCarrier