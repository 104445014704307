import React, {useState} from "react";
import {Order} from "../../classes/classes";
import JobsPageMobileDetail from "./JobsPageMobileDetail";
import JobsLegDetails from "../../components/JobsLegDetail/JobsLegDetails";
import ReactModal from "react-modal";

enum JobStatuses {
    OPEN,
    COMPLETED,
}

const JobsPageMobile = ({statusesOptions, ordersOpen, ordersCompleted}) => {
    const [order, setOrder] = useState<Order>(undefined);
    const [jobStatus, setJobStatus] = useState(JobStatuses.OPEN);
    const [showDetailModal, setShowDetailModal] = useState(false);

    const getCount = (key = '') => {
        if (!key)
            return ordersOpen.data.length + ordersCompleted.data.length;
        return key == 'OPEN' ? ordersOpen.data.length : ordersCompleted.data.length;
    }

    if (order)
        return <JobsPageMobileDetail order={order}
                                     onBack={() => setOrder(undefined)}/>

    return(
        <>
            <div className="col-12 bg-white text-center">
                <p className="font-14 font-medium pb-3">Jobs</p>
            </div>

            <div className="col-12 bg-white d-flex align-items-center justify-content-evenly">
                {
                    statusesOptions.map((e, index) => {
                        return <p key={index}
                                  className={`d-flex m-0 pb-2 px-3 font-10 font-medium order-item ${e.value === jobStatus ? 'item-selected' : ''}`}
                                  onClick={() => setJobStatus(e.value)}>{e.label} <div className="number">{ getCount(e.key) }</div></p>
                    })
                }
            </div>

            <div className="col-12 flex-1-container mt-3">
                {
                    (jobStatus == JobStatuses.OPEN ? ordersOpen : ordersCompleted).data.map((job, key) => {
                        return(
                            <div key={key}
                                 className="card cursor-pointer mb-3"
                                 onClick={() => setShowDetailModal(true)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="mb-1 font-medium font-10 text-gray">{ job.origin.getDate() }</p>
                                            <p className="mb-1 font-medium font-10 text-gray">{ job.destination.getFormattedDateTime() }</p>
                                        </div>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="mb-1 font-medium font-12">{ job.origin.address.city }</p>
                                            <p className="mb-1 font-medium font-12">{ job.destination.address.city }</p>
                                        </div>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="font-12">{ job.origin.address.state } { job.origin.address.postal_code }</p>
                                            <p className="font-12">{ job.destination.address.state } { job.destination.address.postal_code }</p>
                                        </div>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="mb-3 font-medium font-12">{ job.vehicle.year } { job.vehicle.make } { job.vehicle.model }</p>
                                            <p className="mb-3 font-medium font-12">{ job.price }</p>
                                        </div>

                                        <hr/>

                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <div>
                                                <p className="mb-1 font-12 font-medium">LG-{ job.id }</p>
                                                { job.dispatched && <p className="mb-1 font-10 font-medium blue">Dispatched</p> }
                                            </div>

                                            <p className="mb-1 font-12 font-medium">{ job.driver.name }</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <ReactModal onAfterClose={() => setShowDetailModal(false)}
                        isOpen={showDetailModal} className={'leg-detail-modal'}>
                <JobsLegDetails order_id={null} onClose={() => setShowDetailModal(false)} onChangeDriver={() => {}}/>
            </ReactModal>
        </>
    );
}

export default JobsPageMobile;