const prod = true;

const SERVER_LINK = prod ? 'https://api.velox.solutions' : 'http://localhost:24000';

const environments = {
    // SERVER_LINK: prod ? 'https://api.velox.solutions' : 'http://192.168.10.9:24000',
    // SERVER_LINK: prod ? 'https://api.velox.solutions' : 'http://192.168.10.9:24000',
    enableNotificationsListener: prod,
    SERVER_LINK: SERVER_LINK,
    LOGIN_LINK: SERVER_LINK + '/authenticate/carrier',
    LOGIN_DRIVER_LINK: SERVER_LINK + '/authenticate/driver',
    GOOGLE_API: 'AIzaSyCrvUVLjfkK04rzVHVyDHmx6Ry-24qZEVM',
    API_KEY: 'ogOMnKZQeXZ6j6GSCg1MOrI2'
}

export default environments;
