import React, { FC } from 'react';
import { Destination, Vehicle } from '../../classes/classes';
import Helper from '../../helper/Helper';
import Status from '../Status/Status';

interface IChangeRequestOrder
{
    isVehicleDifferent?: boolean,
    isOiriginDifferent?: boolean,
    isDestinationDifferent?: boolean,
    isPayoutDifferent?: boolean,
    carrierMessage?: string,
    vehicle: Vehicle,
    origin: Destination,
    destination: Destination,
    payout: number
}


const ChangeRequestOrder: FC<IChangeRequestOrder> = (props) => {
    return <div>
        <div className='mb-3'>
            <h6 className="font-12 mb-2">Vehicle Info</h6>
            <p className={"font-11" + (props.isVehicleDifferent ? ' text-red' : '')}>{props.vehicle?.vin || '-'}</p>
            <p className={"font-11" + (props.isVehicleDifferent ? ' text-red' : '')}>{props.vehicle?.year} {props.vehicle?.make} {props.vehicle?.model}</p>
        </div>

        <div className='mb-3'>
            <h6 className="font-12 mb-2">Trip Info</h6>
            <div className="row">
                <div className="col-6">
                    <h6 className={"font-11 font-bold mb-2" + (props.isOiriginDifferent ? ' text-red' : ' text-gray')}>ORIGIN</h6>
                    <p className={"font-11 font-medium" + (props.isOiriginDifferent ? ' text-red' : '')}>{props.origin.address.street}</p>   
                    <p className={"font-11 font-medium" + (props.isOiriginDifferent ? ' text-red' : '')}>{props.origin.address.city}, {props.origin.address.state} {props.origin.address.postal_code}</p> 
                    <p className={'font-11 mt-1' + (props.isOiriginDifferent ? ' text-red' : '')}>{Helper.FORMAT.USDate(new Date(props.origin.scheduled))}</p>
                    {props.origin.nofail && <p className={'text-red font-11 mt-1 font-bold'}>NO FAIL</p>}
                </div>    


                <div className="col-6">
                    <h6 className={"font-11 font-bold mb-2" + (props.isDestinationDifferent ? ' text-red' : ' text-gray')}>DESTINATION</h6>
                    <p className={"font-11 font-medium" + (props.isDestinationDifferent ? ' text-red' : '')}>{props.destination.address.street}</p>   
                    <p className={"font-11 font-medium" + (props.isDestinationDifferent ? ' text-red' : '')}>{props.destination.address.city}, {props.destination.address.state} {props.destination.address.postal_code}</p> 
                    <p className={'font-11 mt-1' + (props.isDestinationDifferent ? ' text-red' : '')}>{Helper.FORMAT.USDate(new Date(props.destination.scheduled))}</p>
                    {props.destination.nofail && <p className={'text-red font-11 mt-1 font-bold'}>NO FAIL</p>}
                </div>    
            
            </div>
        </div>

        <div>
            <h6 className="font-12 mb-2">Payout</h6>
            <h6 className={"font-11" + (props.isPayoutDifferent ? ' text-red' : '')}>{props.payout.toCurrency()}</h6>
        </div>

        {props.carrierMessage && <div className='mt-3'>
            <h6 className="font-12 mb-2">Carrier Message</h6>
            <p className={"font-11"}>{props.carrierMessage}</p>
        </div>}
    </div>
}

export default ChangeRequestOrder;