import { gql } from 'apollo-boost';
import React, { FC, useEffect, useState } from 'react';
import { GraphqlService } from '../../services/graphql.service';
import Loading from '../Loading/Loading';

interface IProps {
    order_id: number;
    className?: string;
}

const query = gql`
    query($order_id:Int){
        get_broker_carrier_agreement(order_id:$order_id)
    }
`;

const BrokerCarrierAgreement: FC<IProps> = (props) => {

    const [text, setText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);

    async function load() {
        try {
            setLoading(true);
            const data = await GraphqlService.SendQuery(query, {order_id: props.order_id});
            setText(data);
            setLoading(false);
        } catch (ex) {
            
            setErrorText(ex.message);
            setLoading(false);
        }
    }


    useEffect(() => {
        load();
    }, [props.order_id]);


    if (loading)
        return <Loading />

    return <>
        {text && <p className={props.className || 'font-12'}>{text}</p>}
        {errorText && <p className={`text-red ${!props.className ? 'font-12' : props.className}`}>{errorText}</p>}
        
    </>
}

export default BrokerCarrierAgreement;