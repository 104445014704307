import gql from "graphql-tag";

/**
 * GET ALL CUSTOMERS LIST
 * @param none This does not need any param
 */
const GET_ALL_LIST = gql`query{
    get_customer_search_list{
      id
      key
      name
      address
      corporate_client
    }
  }`



/**
 * GET ALL CUSTOMER PROFILE LIST BY customerId
 * @param customerId takes this value to search profiles by customer ID
 */
const GET_ALL_PROFILE_LIST = gql`
    query ($customerId: Int){
        get_customer_profile_search_list(customerID: $customerId){
            id
            name
            email
            title
            numbers
            contact{
                type
                title
                value
            }
        }
    }
`

const GET_ALL_LOCATIONS = gql`
    query ($customer_id: Int){
        all_locations_by_customer(customer_id: $customer_id){
            id
            address{
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
            }
            title
            internal_note
            driver_note
        }
    }
`

const GET_GOOGLE_PLACE = gql`
    query ($placeid: String){
        get_google_place_details(placeid: $placeid){
            formatted
            address{
                city
                state
                street
                country
                postal_code
                street_number
                postal_code_suffix
            }
        }
    }
`

const CustomerQueries = {
    GET_ALL_LIST,
    GET_ALL_PROFILE_LIST,
    GET_ALL_LOCATIONS,
    GET_GOOGLE_PLACE
}

export default CustomerQueries;