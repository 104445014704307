import React, {useEffect, useRef, useState} from "react";
import {Circle, GoogleApiWrapper, Map, Marker, Polyline} from 'google-maps-react';
import googleMapStyles from "./Map.style";

// const SymbolPath = window.google.maps.SymbolPath;

import moment from "moment";
import environments from "../../../../../environments/environments";


// eslint-disable-next-line @typescript-eslint/no-unused-vars


const mapStyles = {
    width: '100%',
    height: '100%'
};


// (Map as any).defaultProps = googleMapStyles;

const InspectionMap = (props) => {
    // const contextTypes = { [MAP]: any };

    const map = useRef<any>();
    const [coords, setCords] = useState([]);
    useEffect(() => {
        convertAddresses();

        // console.log('here inside x2');
        // alert('here')
    }, [props.location]);

    async function convertAddresses()
    {
        try
        {
            // const address1 = await Helper.Maps.AddresToLatLng(props.pickupLocation);
            // const address2 = await Helper.Maps.AddresToLatLng(props.dropoffLocation);
            // console.log([address1, address2]);
            // setCords([address1, address2]);

            setCords([props.location])
            // const bounds = new google.maps.LatLngBounds();
            //
            // bounds.extend(props.location);
            // // bounds.extend(address2);
            // console.log(map);
            // map.current?.map?.fitBounds(bounds)
            map.current?.map?.setCenter(props.location);
        }
        catch (ex)
        {
            console.log('error', ex.message);
        }
    }


    // console.log('here inside');

    // return <h2>{moment(new Date()).format('hh:mm:ss A')}</h2>

    function renderMap()
    {
        return <Map
            ref={map} google={props.google}
            styles={googleMapStyles as any}
            style={mapStyles}
            zoomControl={true}
            zoom={14}
            center={props.location}
            scrollwheel={false}
            fullscreenControl={false}
            streetViewControl={false}
            mapTypeControl={false}
            centerAroundCurrentLocation={false}
            gestureHandling={'cooperative'}
        >
            {/*   MAP CODE HERE     */}

            {
                <Marker
                    position={coords[0]}

                    zIndex={5}
                />
            }




        </Map>
    }

    return renderMap();

};

export default  GoogleApiWrapper({
    apiKey: environments.GOOGLE_API
})(React.memo(InspectionMap));