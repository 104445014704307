import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import ChangeRequestOrder from '../../components/ChangeRequestOrder/ChangeRequestOrder';
import Loading from '../../components/Loading/Loading';
import Status from '../../components/Status/Status';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';
import { GraphqlService } from '../../services/graphql.service';

const query = gql`
    query($code: String!){
  get_change_request(code:$code){
    order{
      id
      price
      completed
      accepted
      cancelled
      expired
      rejected
      expires
      driver_pay
      remaining_driver_payable
      remaining_carrier_receivable
      change_requests{
        id
        order_id
        accepted
        rejected
        time
        payout
        note
        active
        expired
        expires_at
        weblink
        code
      }
      vehicle{
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
      }
      driver{
        id
        name
      }
      carrier{
        id
        name
      }
      origin{
        scheduled
        time_completed
        completed
        nofail
        address{
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
      destination{
        scheduled
        time_completed
        completed
        nofail
        address{
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
    }
    change_request{
      id
      order_id
      accepted
      rejected
      time
      payout
      note
      active
      expired
      expires_at
      weblink
      code
      vehicle{
        id
        vin
        year
        make
        model
        submodel
        type
        no_vin
        incomplete_vin
        color
        license_plate
        internal_note
        time_created
      }
      origin{
        scheduled
        time_completed
        completed
        nofail
        address{
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
      destination{
        scheduled
        time_completed
        completed
        nofail
        address{
          street_number
          street
          city
          state
          postal_code
          postal_code_suffix
          country
        }
      }
    }
  }
}

`;

const ChangeRequestPage = () => {

    const {code} = useParams<{code: string}>();
    const [data, setData] = useState(null);
    const loading = useLoading();
    const toast = useToasts()

    const getStatusText = () => {
      if (data?.change_request?.expired)
        return 'Expired';
      if (data?.change_request?.rejected)
        return 'Rejected';
      if (data?.change_request?.accepted)
        return 'Accepted';


      return 'PENDING';
    }

    const [error, setError] = useState('');

    async function loadData()
    {
        setData(null);
        try {
            const data = await GraphqlService.SendQuery(query, {code});

            setData(data);



            if (!data?.order?.accepted)
              return Helper.Navigation.NavigateTo('/order-requests');

            setError(getMessage(data?.change_request));
        } catch (ex) {
           setData({});
           console.log('ex', ex.message?.replace('GraphQL error: ', ''));
           setError(ex.message?.replace('GraphQL error: ', ''));
        }
    }


    function getMessage(change_request) {
        if (!change_request)
            return '';
        const {accepted, rejected, active, expired, expires_at} = change_request;

        if (accepted)
            return 'This change request has been accepted';
        if (rejected)
            return 'This change request has been rejected';
        if (!active)
            return 'This change request is not longer active';
        if (expired)
            return `This change request is expired since: ${moment(new Date(expires_at)).format('MM/DD/YYYY hh:mm A')}`

        return '';
    }

    const messageBox = useMessageBox();


    async function approve() {
      const mutation = gql`
      mutation($change_request_id: Int)
      {
        accept_change_request(change_request_id: $change_request_id) {
          id
          message
          success
        }
      }
      
    `;

      submitAction(mutation, {change_request_id: data?.change_request?.id}, true);
    }
    async function reject() {
      const mutation = gql`
        mutation($change_request_id: Int)
        {
          reject_change_request(change_request_id: $change_request_id) {
            id
            message
            success
          }
        }
        
      `;

      submitAction(mutation, {change_request_id: data?.change_request?.id}, false);
    }


    async function submitAction(mutation, variables, accept)
    {
      messageBox.open(
        {
          title: 'Confirm',
          message: `Are you sure you want to ${accept ? 'accept' : 'reject'} this change request?`,
          buttons: [
            {
              text: 'Cancel',
              css: 'btn btn-clear text-light-blue'
            },
            {
              text: 'Accept',
              css: 'btn btn-blue-light',
              action: () => {
                onActionSubmitted(mutation, variables);
              }
            }
          ]
        }
      )
    }

    async function onActionSubmitted(mutation, variables) {
      try {
        loading.open('Submitting...');
        const data = await GraphqlService.SendMutation(mutation, variables);
        if (!data?.success)
          throw new Error(data?.message || 'Something went wrong');
        loadData();

        loading.close();
        toast.addToast(data?.message, {appearance: 'success', autoDismiss: true});
      } catch (ex) {
        loading.close();
        toast.addToast(ex?.message, {appearance: 'error', autoDismiss: true});
      }
    }

    useEffect(() => {
        loadData().then();
    }, []);


    return <div className='flex-1-container'>
        {(data?.order && !error) && <h4 className="font-16 mb-3 d-flex aling-items-center">Change Request For Order #{data?.order?.id} <Status className='ml-2' statusName={getStatusText()} /> </h4>}
        {(data?.order && !error) && <h4 className='font-16 mb-3 text-center text-green font-bold'>Expires in: {moment(new Date(data?.change_request?.expires_at)).diff(moment(), 'minutes')} min</h4>}
        {error && <h4 className="font-16 mb-3 text-center text-red">{error}</h4>}

        <div className='flex-1-container mx-auto' style={{maxWidth: '1000px', width: '90%'}}>
            <div className="flex-1-container row-container">
                <div className="col-6 d-flex bg-white p-3">
                    <div className="flex-1-container">
                        <h6 className="font-12 mb-3">Original</h6>
                        {!data && <Loading />}
                        {data?.order && <div className="flex-1-container">
                            <ChangeRequestOrder
                                vehicle={data?.order?.vehicle} origin={data?.order?.origin} destination={data?.order?.destination} payout={data?.order?.price}
                            />
                        </div>}
                    </div>
                </div>
                <div className="col-6 d-flex bg-white p-3">
                    <div className="flex-1-container">
                        <h6 className="font-12 mb-3">Request</h6>
                        {!data && <Loading />}
                        {data?.order && <div className="flex-1-container">
                            <ChangeRequestOrder
                                vehicle={data?.change_request?.vehicle || data?.order?.vehicle} origin={data?.change_request?.origin || data?.order?.origin} destination={data?.change_request?.destination || data?.order?.destination} payout={data?.change_request?.payout != null ? data?.change_request?.payout : data?.order?.price}
                                isVehicleDifferent={data?.change_request?.vehicle != null} isOiriginDifferent={data?.change_request?.origin != null} isDestinationDifferent={data?.change_request?.destination != null}  isPayoutDifferent={data?.change_request?.payout != null}
                                carrierMessage={data?.change_request?.note}
                            />
                        </div>}
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <button onClick={(evt) => reject()} disabled={!data?.order || getStatusText() != 'PENDING'} className="btn btn-danger rounded-pill col-6 py-2">REJECT REQUEST</button>
                <button onClick={(evt) => approve()} disabled={!data?.order || getStatusText() != 'PENDING'} className="btn btn-blue-light rounded-pill col-6 py-2">APPROVE REQUEST</button>
            </div>
        </div>




    </div>
};

export default ChangeRequestPage;
