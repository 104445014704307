import { useState,useRef } from "react";
import {BaseLayout} from "../../layout/BaseLayout/BaseLayout";
import React from "react";

export interface ICarrierRegistrationPageProps {
    carrier: {
        id: number;
        name: string;
        code: string;
        internal_note: string;
        active: boolean;
        multi_carrier: boolean;
    }
}

export const CarrierRegistrationInsurancePage = (props: any) => {

    const [visible,setVisible]=useState(false)

    const radioButton = useRef<HTMLInputElement>(null);


    const handletoggle=(e:any)=>{

        setVisible(!e.target.checked)

    }

    return (
        <>
            <BaseLayout>
                <div className="row title-section">
                    <div className="col">
                        Complete your account setup
                    </div>
                </div>

                {/*main content*/}
                <main className={`row velox-form`}>

                    {/*Left side*/}
                    <div className="col-12 col-md-6">
                        <div className="row form-title">
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <span>Registration info</span>
                                <span  className={`clear-all`}>Clear All</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">DOT or MC
                                    </label>
                                    <input type="text" className="form-control" id=""
                                           placeholder="000000"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">DOT #*
                                        </label>
                                    <input type="text" className="form-control" id=""
                                           placeholder="CA0000000"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Business type*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="name@example.com"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">TAX ID #*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="00-0000000"/>
                                </div>
                            </div>
    
                        </div>
                    </div>

                    {/*Right side*/}
                    
                    <div className="col-12 col-md-6 scrollX" style={{height:"calc(100vh - 15rem)"}}>
                        <div className="row form-title">
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <span>Liability coverage</span>
                                <span className={`clear-all`}>Clear All</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Liability coverage
                                    </label>
                                    <input type="text" className="form-control" id=""
                                           placeholder="0,000,000.00"/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Expiration date*
                                    </label>
                                    <input type="text" className="form-control" id=""
                                           placeholder="mm/dd/yyyy"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Insurance company name*
                                     </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="Insurance Company Name"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Policy #*
                                    </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="Policy #"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Agent name*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="Agent Name"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Agent phone #*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="000-000-0000"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Agent FAX # (optional)
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="000-000-0000"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Agent email address (optional)
                                        </label>
                                    <input type="email" className="form-control" id="exampleFormControlInput1"
                                           placeholder="abc@xyz.com"/>
                                </div>
                            </div>
                        </div>

                        <div className={`row form-title ${visible?"d-none":""}`}>
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <span>Cargo coverage</span>
                                <span className={`clear-all`}>Clear All</span>
                            </div>
                        </div>

                        <div className={`row ${visible?"d-none":""}`}>
                            <div className="col-6">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Cargo coverage*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="0,000,000.00"/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Expiration date*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="mm/dd/yyyy"/>
                                </div>
                            </div>

                        </div>


                        <div className="row">
                            <div className="col-12 ">
                                <div className="fs-14 align-items-center font-normal d-flex " >
                                    
                                    <input  onClick={(e)=>handletoggle(e)} checked={!visible} type="checkbox"  id="terms" name=""  />
                                    <label role="button"  htmlFor="terms" style={{marginLeft:"0.5rem"}}> Same insurance company & agent info as liability coverage</label>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5 form-title">
                            <div className="col-12 d-flex align-items-center justify-content-between">
                                <span>Deductible</span>
                                <span className={`clear-all`}>Clear All</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <div className="">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Deductible*
                                        </label>
                                    <input type="text" className="form-control" id="exampleFormControlInput1"
                                           placeholder="0,000,000.00"/>
                                </div>
                            </div>
                        </div>


                    </div>

                        <div className="col-12 col-md-9 mt-4">
                              
                        </div>
                        <div className="col-12 col-md-3 mt-4">
                            <button className="btn btn-skyblue w-100">ADD YOUR DISCLAIMER</button>
                        </div>
                    
                </main>
            </BaseLayout>
        </>
    )
}
