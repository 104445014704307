import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {GraphqlService} from "./app/services/graphql.service";
import client from "./app/config/graphql.client";
import store from "./app/redux/store";
import {Provider} from "react-redux";

import { ToastProvider } from 'react-toast-notifications';
import LoadingProvider from './app/providers/LoadingProvider';

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import MessageBoxProvider from './app/providers/MessageBoxProvider';

declare global {
    interface Number {
        toCurrency(): string;
        toCurrency(currency): string;
        toCurrency(currency,useCommaSeparator, useDecimalSeparator): string;
    }
}

Number.prototype.toCurrency = function(currency: string = '$', useCommaSeparator: boolean = true, useDecimalSeparator: boolean = true){
    if(currency == '$' && useCommaSeparator && useDecimalSeparator){
        let value = isNaN(this) ||this as any == '-0' ? 0 : this;

        return new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD' }
            ).format(value)
    }

    if(this < 0){
        currency = '-' + currency;
    }

    let txt = '', res = '';
    let temp: string = this.toString();
    for(let i = 0; i< temp.length; i++){
        if('0123456789'.includes(temp[i])){
            txt += temp[i];
        }
    }
    txt = (+txt).toString();
    if(useDecimalSeparator){
        if(!txt){
            return currency + '0.00';
        }
        else if(txt.length == 1){
            return currency + '0.0' + txt;
        }
        else if(txt.length == 2){
            return currency + '0.' + txt;
        }
        else{
            res = '.' + txt.substring(txt.length - 2);
            txt = txt.substring(0, txt.length - 2);
        }
    }
    if(useCommaSeparator){
        if(!txt){
            return currency + '0' + res;
        }
        
        while (txt.length > 3){
            res = ',' + txt.substring(txt.length - 3) + res;
            txt = txt.substring(0, txt.length - 3);
        }
    }
    return currency + txt + res;
}


GraphqlService.SetClient(client);
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <ToastProvider>
                   <LoadingProvider>
                        <MessageBoxProvider>
                            <App/>
                        </MessageBoxProvider>
                   </LoadingProvider>
                </ToastProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
