import React, { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import CompanyAccountInfoForm from '../../components/CompanyAccountInfoForm/CompanyAccountInfoForm';
import InsuranceInfoForm from '../../components/InsuranceInfoForm/InsuranceInfoForm';
import Modal from '../../components/Modal/Modal';
import RegistrationInfoForm from '../../components/RegistrationInfoForm/RegistrationInfoForm';
import CarrierQueries from '../../graphql/query/Carrier';
import { GraphqlService } from '../../services/graphql.service';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useToasts } from 'react-toast-notifications';
import { gql } from 'apollo-boost';
import useLoading from '../../hooks/useLoading/useLoading';
import Loading from '../../components/Loading/Loading';
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';
import S3Queries from '../../graphql/query/S3';
import Helper from '../../helper/Helper';
import { Settings } from '../CarrierProfile/CarrierProfilePage';
import { Address } from '../../classes/classes';
import TaxInfo from '../../components/TaxInfo/TaxInfo';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { IDocumentsState } from '../../redux/reducers/Documents.reducer';
import { AllStates } from '../../redux/reducers';
import { checkDocuments } from '../../redux/actions/Documents.action';

const mutations = {
    'registration': gql`mutation($carrier_id: Int, $file: Upload) {
        upload_carrier_registration_file(
          carrier_id: $carrier_id
          registration_file: $file
        ) {
          id
          message
          success
        }
      }`,
    'insurance': gql`mutation($carrier_id: Int, $file: Upload) {
        upload_carrier_insurance_file(
          carrier_id: $carrier_id
          insurance_file: $file
        ) {
          id
          message
          success
        }
      }`,
      'tax info': gql`mutation($carrier_id:Int, $file:Upload){
        upload_carrier_tax_info_file(carrier_id:$carrier_id, tax_info_file:$file){
          id
          message
          success
        }
      }`
}

const deleteMutations = {
    'registration': gql`mutation($carrier_id:Int, $uid:String){
        remove_carrier_registration_file(carrier_id:$carrier_id, uid:$uid){
          message
          success
        }
      } 
      `,
      'insurance': gql`mutation($carrier_id:Int, $uid:String){
        remove_carrier_insurance_file(carrier_id:$carrier_id, uid:$uid){
          message
          success
        }
      } 
      `,
      'tax info': gql`mutation($carrier_id:Int, $uid:String){
        remove_carrier_tax_info_file(carrier_id:$carrier_id, uid:$uid){
          id
          message
          success
        }
      }`
}

class CarrierContact
{
    name: string = '';
    phone: string = '';
    email: string = '';
    fax?: string = '';

    constructor(props?: CarrierContact)
    {
        if (props)
        {
            this.name = props.name;
            this.phone = props.phone;
            this.email = props.email;
            this.fax = props.fax || '';
        }
    }
}

export class Document {
    uid: string = '';
    name: string = '';
    constructor(props?: Document) {
        if (props) {
            this.uid = props.uid;
            this.name = props.name;
        }
    }
}

class _CarrierRegistration
{
    mc_number: string = '';
    dot_number: string = '';
    
    uploaded_documents: Document[] = [];

    constructor(props?: _CarrierRegistration)
    {
        if (props)
        {
            this.mc_number = props.mc_number;
            this.dot_number = props.dot_number || '';
            this.uploaded_documents = props.uploaded_documents?.map(x => new Document(x)) || [];
        }
    }
}

export class _CarrierInsurance {
    name: string = '';
    policy_number: string = '';
    agent: CarrierContact = new CarrierContact();
    liability_coverage: number = 0;
    damage_coverage: number = 0;
    deductible: number = 0;
    expiration: Date;
    uploaded_documents: Document[] = [];

    constructor(props?: _CarrierInsurance)
    {
        if (props)
        {
            this.name = props.name;
            this.policy_number = props.policy_number;
            this.agent = new CarrierContact(props.agent);
            this.liability_coverage = props.liability_coverage;
            this.damage_coverage = props.damage_coverage;
            this.deductible = props.deductible;
            if (props.expiration)
                this.expiration = new Date(props.expiration);
            this.uploaded_documents = props.uploaded_documents?.map(x => new Document(x)) || [];
        }
    }
}
export class _CarrierTaxInfo {
    name: string = '';
    business_name: string = '';
    type: string = '';
    address: Address = new Address();
    ssn: string = '';
    ein: string = '';
    signature: string = '';
    time_signed: Date  = new Date();
    uploaded_documents: Document[] = [];

    constructor(props?: _CarrierTaxInfo)
    {
        if (props)
        {
            this.name = props.name ?? '';
            this.business_name = props.business_name ?? '';
            this.type = props.type || '';
            if (props.address?.street)
                this.address = new Address(props.address);
            this.ssn = props.ssn || '';
            this.ein = props.ein || '';
            this.signature = props.signature;
            if (props.time_signed)
                this.time_signed = new Date(props.time_signed);
            this.uploaded_documents = props.uploaded_documents?.map(x => new Document(x)) || [];
        }
    }
}
class Carrier
{
    id: number;
    name: string = '';
    code: string = '';
    liability_disclaimer: string = '';
    internal_note: string = '';
    contact: CarrierContact = new CarrierContact();
    carrier_insurance: _CarrierInsurance = new _CarrierInsurance();
    carrier_registration: _CarrierRegistration = new _CarrierRegistration();
    carrier_tax_info: _CarrierTaxInfo = new _CarrierTaxInfo();
    drivers: any[] = [];


    constructor(props?: Carrier)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.code = props.code;
            this.liability_disclaimer = props.liability_disclaimer;
            this.internal_note = props.internal_note;
            this.contact = new CarrierContact(props.contact);
            this.carrier_insurance = new _CarrierInsurance(props.carrier_insurance);
            this.carrier_registration = new _CarrierRegistration(props.carrier_registration);
            this.carrier_tax_info = new _CarrierTaxInfo(props.carrier_tax_info);
            this.drivers = props.drivers.map(x => ({...x}));
        }
    }
}

const CarrierDocumentsPage = () => {

    
    const COMPANY = 'COMPANY';
    const INSURANCE = ' INSURANCE';
    const REGISTRATION = 'REGISTRATION';
    const TAX_INFO = 'TAX_INFO';

    const [openModal, setopenModal] = useState(false);
    const [open,setOpen]=useState(false);
    const [tab,setTab]=useState('')
    const [loadingData, setLoadingData] = useState(false);
    const [loadingTaxInfo, setLoadingTaxInfo] = useState(false);
    const [loadingRegistration, setLoadingRegistration] = useState(false);
    const [loadingInsurance, setLoadingInsurance] = useState(false);
    const [loadingDisclaimer, setLoadingDisclaimer] = useState(false);

    const heightModal = 600;
    const toast = useToasts();
    const messageBox = useMessageBox();
    const [settings, setSettings] = useState(new Settings());
    const loading = useLoading();
    const [carrierObj, setCarrierObj] = useState(new Carrier());
    const [carrier_insurance, setCarrierInsurance] = useState(new _CarrierInsurance());
    const [carrier_registration, setCarrierRegistration] = useState(new _CarrierRegistration());
    const [carrier_tax_info, setCarrierTaxInfo] = useState(new _CarrierTaxInfo());
    const [tempLiabilityDisclaimer, setTempLiabilityDisclaimer] = useState('');

    const registrationRef = useRef<HTMLInputElement>();
    const insuranceRef = useRef<HTMLInputElement>();
    const taxRef = useRef<HTMLInputElement>();

    const documents = useSelector<AllStates>(x => x.documents) as IDocumentsState;
const dispatch = useDispatch();
    useEffect(() => {
        loadData();
        getSettings();
    }, []);

    async function loadCarrier() {
        const query = gql`
            get_carrier_by_id {
                id
                name
                code
            }
        `;

        try {
            const data = await GraphqlService.SendQuery(query);
            setCarrierObj(new Carrier(data));
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    async function getSettings() {
        const query = gql`
            query{
                get_all_settings{
                    key
                    value
                }
            } 
        `;
        try {
            setLoadingDisclaimer(true)
            const data = await GraphqlService.SendQuery(query);
            // setDisclaimer(data?.find(x => x.key == 'liability_disclaimer')?.value || '');
            const x = new Settings();
            x.fillFromArray(data)
            setSettings(x);
        } catch (ex) {
            toast.addToast(ex?.message, {appearance: 'error', autoDismiss: true});
        }
        finally {
            setLoadingDisclaimer(false)
        }
    }

    async function onSubmitRegistration(item){
        const registrationData ={
            "mc_number": item.carrier_registration.mc_number ,
            "dot_number": item.carrier_registration.dot_number,
            "carrier_id": null 
        }

        const mutation = gql`
            mutation ($registration: carrier_registration_input) {
                update_registration(registration:$registration) {
                mc_number
                id
                carrier_id

                }
            }`;

        const variables = {registration: registrationData }

        try {
            loading.open('Saving Registration...');
            const data = await GraphqlService.SendMutation(mutation,variables);
            // if (!data.id)
            //     throw new Error(data.message);
            toast.addToast('Record updated', {appearance: 'success',autoDismiss: true});
            loadData('registration');
            
            
        } catch (ex) {

            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
            
        }
        finally {
            loading.close();
        }
    }

    async function previewFile(doc: Document) {
        try {
            const key = doc.uid;
            const ext = doc.name.split('.').pop()?.toLocaleLowerCase() || '';
            const acceptedImg = ['jpg', 'jpeg', 'png', 'gif'];;
            const isImage = acceptedImg.includes(ext);
            const isDocument = ext == 'pdf';

            
            const link = await GraphqlService.SendQuery(S3Queries.GET_TEMP_LINK, {key, minutes: 5, is_thumbnail: false, uid: key});
            if (isImage)
            {
                const html = `
                        <html><head></head><body><img src="${link}" /></body></html>
                               

                        `


                        var tab = window.open(link, '_blank');
                            tab.document.write(html);
            }
            else{
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: link,
                }).click();
            }
        } catch (ex) {
            
        }
    }

    async function deleteFile(key: 'registration' | 'insurance' | 'tax info' | 'liability disclaimer', document: Document) {
        try {
            loading.open(`Deleting ${key} document: ${document.name}...`);
            console.log(document);
            const data = await GraphqlService.SendMutation(deleteMutations[key], {carrier_id: carrierObj.id, uid: document.uid});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            toast.addToast(data.message, {appearance: 'success',autoDismiss: true});
            loadData(key);
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        }
    }

    async function removeFile(key, document: Document) {
        messageBox.open({
            title: 'Delete file: ' + document.name,
            message: `Are you sure you want to delete this file?, you won't be able to revert this!`,
            buttons: [
                {
                    text: 'Cancel',
                    css: 'btn btn-clear text-light-blue'
                },
                {
                    text: 'Confirm',
                    css: 'btn btn-blue-light',
                    action: (data) => deleteFile(key, document)
                }
            ]
        })
    }

    async function onSubmitInsurance(item){   
 
        const insuranceData ={
            "name":item.carrier_insurance.name,
            "policy_number":item.carrier_insurance.policy_number,
            "agent": item.carrier_insurance.agent,
            "liability_coverage":item.carrier_insurance.liability_coverage,
            "damage_coverage":item.carrier_insurance.damage_coverage,
            "deductible":item.carrier_insurance.deductible,
            "expiration":item.carrier_insurance.expiration,
            "carrier_id": null
    
        }
        
        const mutation = gql`
        mutation ($insurance: carrier_insurance_input) {
            update_insurance(insurance:$insurance) {
                id
            }
            }
        ` ;
              
        const variables = {insurance: insuranceData }

        try {
            // setLoadingData(true);
            loading.open('Saving Insurance...');
            const data = await GraphqlService.SendMutation(mutation,variables);
            if (!data.id)
                throw new Error(data.message);
            toast.addToast("Record Updated", {appearance: 'success',autoDismiss: true});
            loadData('insurance');
            // setLoadingData(false);

        } catch (ex) {

            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
            console.log('ex', ex.message);
            // setLoadingData(false);
        }
        finally {
            loading.close();
        }
    }

    async function getCarrierData(){
        try {
            setLoadingData(true);
            // const data = await GraphqlService.SendQuery(CarrierQueries.CARRIER_SETTINGS_DATA);
            // data.drivers = data.drivers.sort((x, y) => x.name > y.name ? 1 : -1);
            // setCarrier(new Carrier(data));

            setLoadingData(false);
        } catch (ex) {
            console.log(ex);
            setLoadingData(false);
        }
    }

    function loadData(key: 'registration' | 'insurance' | 'tax info' | 'liability disclaimer' | '' = '')
    {
        if (key == 'registration' || key == '')
            loadCarrierRegistration();
        if (key == 'insurance' || key == '')
            loadCarrierInsurance();
        if (key == 'tax info' || key == '')
            loadCarrierTaxInfo();
        if (key == 'liability disclaimer' || key == '')
            getSettings();
        dispatch(checkDocuments());
        
    }

    async function loadCarrierTaxInfo() {
        try {
            const query = gql`
            query {
                get_carrier_tax_info {
                  carrier_id
                  id
                  name
                  ssn
                  ein
                  business_name
                  type 
                  signature
                  time_signed
                  address {
                    street
                    city
                    state
                    postal_code
                    country
                    street_number

                  }
                  uploaded_documents{
                    uid
                    name
                  }
                }
              }
              
            `;
            setLoadingTaxInfo(true);
            const data = await GraphqlService.SendQuery(query);
            setCarrierTaxInfo(new _CarrierTaxInfo(data));
        }
        catch (ex) {

        }
        finally {
            setLoadingTaxInfo(false);
        }
    }

    async function loadCarrierRegistration() {
        try {
            const query = gql`
            query {
                get_carrier_registration{
                  id
                  mc_number
                  dot_number
                  carrier_id
                  uploaded_documents{
                    uid
                    name
                  }
                }
              } 
              
            `;
            setLoadingRegistration(true);
            const data = await GraphqlService.SendQuery(query);
            setCarrierRegistration(new _CarrierRegistration(data));
        }
        catch (ex) {

        }
        finally {
            setLoadingRegistration(false);
        }
    }

    async function loadCarrierInsurance() {
        try {
            const query = gql`
            query {
                get_carrier_insurance{
                  id
                  name
                  policy_number
                  agent{
                    name
                    email
                    phone
                    fax
                  }
                  liability_coverage
                  damage_coverage
                  deductible
                  expiration
                  carrier_id
                  uploaded_documents{
                    uid
                    name
                  }
                }
              }
              
            `;
            setLoadingInsurance(true);
            const data = await GraphqlService.SendQuery(query);
            setCarrierInsurance(new _CarrierInsurance(data));
        }
        catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
        finally {
            setLoadingInsurance(false);
        }
    }  

    async function handleInputChange(key: 'registration' | 'insurance' | 'tax info' | 'liability disclaimer', evt: ChangeEvent<HTMLInputElement>) {
        try {
            console.log('trigger');
            const file = evt.target.files[0];
            if (key == 'registration')
                registrationRef.current.value = '';
            else if (key == 'insurance')
                insuranceRef.current.value = '';
            else if (key == 'tax info')
                taxRef.current.value = '';
            loading.open(`Uploading ${key} file: ${file.name}`);
            const data = await GraphqlService.SendMutation(mutations[key], {carrier_id: carrierObj.id, file});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            toast.addToast(data?.message, {appearance: 'success',autoDismiss: true});
            loadData(key);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error',autoDismiss: true});
        }
        finally {
            loading.close();
        }
    }

    const CarrierTaxInfo = () => {
        
        return <>
            <input type='file' style={{display: 'none'}} ref={taxRef} onChange={evt => handleInputChange('tax info', evt)} />
            <div className="col-12 ">
                                <div className="">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <h2 className={`font-12`}>Tax Info</h2>
                                        <button onClick={()=>{setOpen(true);setTab(TAX_INFO)}} className={`btn btn-blue-light py-1 font-10`}>Edit</button>
                                    </div>
                                    {!loadingTaxInfo && <div className="">
                                   

                                    <div className='cursor-pointer' onClick={(evt) => {setOpen(true); setTab(TAX_INFO)}}>
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Name*</span>
                                                {carrier_tax_info.name ? <p className={'value-p'}>{carrier_tax_info.name}</p>
                                                : <h4 className='text-red font-12 text-left cursor-pointer'>Enter Name</h4>}
                                            </div>
                                            <div className="col-6">
                                                <span className={'label-span'}>Business Name</span>
                                                <p className={'value-p'}>{carrier_tax_info.business_name || '-'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <span className={'label-span'}>Type*</span>
                                        {carrier_tax_info.type.trim() ? <p className={'value-p mb-0'}>{carrier_tax_info.type}</p>
                                                : <h4 className='text-red font-12 text-left cursor-pointer'>Enter Business Type</h4>}
                                    </div>
                                    <div className="col-12 mb-4">
                                        <span className={'label-span'}>TIN*</span>
                                        {(!carrier_tax_info.ssn && !carrier_tax_info.ein) ? 
                                            <h4 className='text-red font-12 text-left cursor-pointer'>Enter EIN/SSN</h4>
                                            :
                                            <>{(carrier_tax_info.ssn) ?
                                                <p className={'value-p mb-0'}>{'SSN: xxx-xx-' + carrier_tax_info.ssn.substring(carrier_tax_info.ssn?.length - 4)}</p>
                                                :
                                                <p className={'value-p mb-0'}>{'EIN: xx-xxx' + carrier_tax_info.ein.substring(carrier_tax_info.ein?.length - 4)}</p>
                                            }</>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <span className={'label-span'}>Address*</span>
                                        {(carrier_tax_info.address.street?.trim() || '') != '' ? <>
                                            <p className={'value-p mb-0'}>{carrier_tax_info.address.street_number} {carrier_tax_info.address.street}</p>
                                        <p className={'value-p mt-1'}>{carrier_tax_info.address.city} {carrier_tax_info.address.state}, {carrier_tax_info.address.postal_code}</p>
                                        {(carrier_tax_info.address.country != 'United States' && carrier_tax_info.address.country != 'USA') && <p className={'value-p mt-1'}>{carrier_tax_info.address.country}</p>}
                                        </> : <h4 className='text-red font-12 text-left cursor-pointer'>Enter Address</h4>}
                                        
                                    </div>
                                    </div>
                                   
                                    <div
                                            className="col-12 d-flex justify-content-between align-items-center my-2">
                                            <span className={`label-span`}>Files</span>
                                    </div>
                                    <div className="col-12">
                                        {carrier_tax_info.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => taxRef.current?.click()}>Upload a document showing your tax info</h4>}
                                        {carrier_tax_info.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier_insurance.uploaded_documents.length - 1 ? '' : ' mb-3')}> 
                                            <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} onClick={(evt) => previewFile(document)} className="font-12 cursor-pointer">{document.name}</h4>
                                            <div className="d-flex">
                                                {/* <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                                    <i className='fas fa-eye'></i>
                                                </h4> */}
                                                <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('tax info', document)}>
                                                    <i className='fas fa-times'></i>
                                                </h4>
                                            </div>
                                        </div>)}
                                        <div className='justify-content-end align-items-center cursor-pointer row' onClick={(evt) => taxRef.current?.click()}>
                                            <i className='fas fa-plus edit font-light mr-2'></i>
                                            <span  className={`edit`}>Upload</span>
                                        </div>
                                    </div>
                                    
                                </div>}
                                    {loadingTaxInfo && <Loading />}
                                </div>
                            </div>
        </>
    }

    function checkIfExpired(day: Date){
        return new Date(day).getTime() < new Date().getTime();
    }

    function checkIfExpiringSoon(day: Date){
        return moment(day).diff(moment(), 'days') < 15;
    }

    const CarrierRegistration = () => {
        
        
        return <>
        <input style={{display: 'none'}} multiple={false} onChange={evt => handleInputChange('registration', evt)} type='file' ref={registrationRef} />
             <div
                                className="col-12 d-flex justify-content-between align-items-center mb-2">
                                <h2 className={`font-12`}>Registration</h2>
                                <button onClick={()=>{setOpen(true);setTab(REGISTRATION)}} className={`btn btn-blue-light py-1 font-10`}>Edit</button>
                            </div>
                            {loadingRegistration && <Loading />}
                            {!loadingRegistration && <div className="">
                                        
                                        <div  onClick={(evt) => {setOpen(true); setTab(REGISTRATION)}} className="col-12 mb-4 cursor-pointer">
                                            <div className="row mb-4">
                                                <div className="col-12 mb-4">
                                                    <span className={'label-span'}>DOT</span>
                                                    {(carrier_registration.dot_number?.trim() || '') == '' && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => {setOpen(true);setTab(REGISTRATION)}}>Enter DOT (Department of Transportation) number</h4>}
                                                    <p className={'value-p'}>{carrier_registration.dot_number }</p>
                                                </div>
                                                <div className="col-12 my-1">
                                                    <div className="col-6">
                                                        <span className={'label-span'}>MC</span>
                                                        {(carrier_registration.mc_number?.trim()) == '' && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => {setOpen(true);setTab(REGISTRATION)}}>Enter MC (Motor Carrier) number</h4>}
                                                        <p className={'value-p'}>{carrier_registration.mc_number }</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <div
                                            className="col-12 d-flex justify-content-between align-items-center mb-1">
                                            <span className={`label-span`}>Files</span>
                                        </div>
                                        <div className="col-12">
                                            {carrier_registration.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => registrationRef.current?.click()}>Upload a document showing your DOT (Department of Transportation) or MC (Motor Carrier) number</h4>}
                                            {carrier_registration.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier_registration.uploaded_documents.length - 1 ? '' : ' mb-3')}> 
                                                <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} onClick={(evt) => previewFile(document)} className="font-12 cursor-pointer">{document.name}</h4>
                                                <div className="d-flex">
                                                    {/* <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                                        <i className='fas fa-eye'></i>
                                                    </h4> */}
                                                    <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('registration', document)}>
                                                        <i className='fas fa-times'></i>
                                                    </h4>
                                                </div>
                                            </div>)}
                                        <div className='justify-content-end align-items-center cursor-pointer row' onClick={(evt) => registrationRef.current?.click()}>
                                            <i className='fas fa-plus edit font-light mr-2'></i>
                                            <span  className={`edit`}>Upload</span>
                                        </div>
                                        </div>
                            </div>}
        </>
    }

    const CarrierInsurance = () => {
        
        return <>
            <input type='file' style={{display: 'none'}} ref={insuranceRef} onChange={evt => handleInputChange('insurance', evt)} />
            <div className="col-12 bg-white main-radius ">
                                <div className="">
                                    <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-2">
                                        <h2 className={`font-12`}>Insurance</h2>
                                        <button onClick={()=>{setOpen(true);setTab(INSURANCE)}} className={`btn btn-blue-light py-1 font-10`}>Edit</button>
                                    </div>
                                    {!loadingInsurance && <div className="">
                                        <div className='cursor-pointer' onClick={(evt) => {setOpen(true); setTab(INSURANCE)}}>
                                        <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Insurance company name*</span>
                                                {(carrier_insurance.name) ?
                                                <p className={'value-p' + (!carrier_insurance?.name ? ' text-red' : '')}>{carrier_insurance.name}</p>
                                                :
                                                <h4 className='text-red font-12 text-left cursor-pointer'>Enter Insurance Name</h4>
                                                }
                                            </div>
                                            <div className="col-6">
                                                <span className={'label-span'}>Policy #*</span>
                                                {(carrier_insurance.policy_number) ?
                                                <p className={'value-p' + (!carrier_insurance?.name ? ' text-red' : '')}>{carrier_insurance.policy_number}</p>
                                                :
                                                <h4 className='text-red font-12 text-left cursor-pointer'>Enter Policy Number</h4>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {(!carrier_insurance.agent.name || !carrier_insurance.agent.phone || !carrier_insurance.agent.email) ? 
                                    <>
                                        <div className="col-12 mb-4">
                                        <span className={'label-span'}>Agent*</span>
                                        <h4 className='text-red font-12 text-left cursor-pointer'>Enter insurance Agent's info</h4>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-12 mb-0">
                                            <span className={'label-span'}>Agent*</span>
                                            <p className={'value-p mb-0'}>{carrier_insurance.agent.name}</p>
                                        </div>
                                        <div className="col-12">
                                            <span className={'phone-span'}>{carrier_insurance.agent.phone}</span>
                                            <span className={'email-span'}>{carrier_insurance.agent.email}</span>
                                            <span className={'email-span'}>{carrier_insurance.agent.fax || '-'}</span>
                                        </div>
                                    </>}
                                    <div className="col-12 my-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Liability coverage*</span>
                                                <p className={'value-p' + (carrier_insurance.liability_coverage  > 0 ? '' : ' text-red')}>{carrier_insurance.liability_coverage > 0 ? carrier_insurance.liability_coverage.toCurrency('$', true, false) : 'Value must be greater than zero'}</p>
                                            </div>
                                            <div className="col-6">
                                                <span className={'label-span'}>Damage coverage*</span>
                                                <p className={'value-p' + (carrier_insurance.damage_coverage  > 0 ? '' : ' text-red')}>{carrier_insurance.damage_coverage > 0 ? carrier_insurance.damage_coverage.toCurrency('$', true, false) : 'Value must be greater than zero'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <span className={'label-span'}>Deductible*</span>
                                                <p className={'value-p' + (carrier_insurance.deductible  > 0 ? '' : ' text-red')}>{carrier_insurance.deductible > 0 ? carrier_insurance.deductible.toCurrency('$', true, false) : 'Value must be greater than zero'}</p>
                                            </div>
                                            <div className="col-6">
                                                        <span
                                                            className={'label-span'}>Expiration date(dd/mm/yyyy)</span>
                                                <p className={'value-p' + (checkIfExpiringSoon(carrier_insurance.expiration) ? ` text-${checkIfExpired(carrier_insurance.expiration)? 'red':'gold'}` : '')}>{carrier_insurance.expiration ? Helper.FORMAT.USDate(carrier_insurance.expiration, 'do') + (checkIfExpiringSoon(carrier_insurance.expiration) ? ` (${checkIfExpired(carrier_insurance.expiration) ? 'Expired' : 'Expiring soon'})` : '') : '-'}</p>
                                            </div>
                                        </div>
                                    </div> 
                                        </div>
                                    <div
                                            className="col-12 d-flex justify-content-between align-items-center my-2">
                                            <span className={`label-span`}>Files</span>
                                        </div>
                                        <div className="col-12">
                                            {carrier_insurance.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => insuranceRef.current?.click()}>Upload a document showing your insurance</h4>}
                                            {carrier_insurance.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier_insurance.uploaded_documents.length - 1 ? '' : ' mb-3')}> 
                                                <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} onClick={(evt) => previewFile(document)} className="font-12 cursor-pointer">{document.name}</h4>
                                                <div className="d-flex ">
                                                    {/* <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                                        <i className='fas fa-eye'></i>
                                                    </h4> */}
                                                    <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('insurance', document)}>
                                                        <i className='fas fa-times'></i>
                                                    </h4>
                                                </div>
                                            </div>)}
                                           {/* <div className="row">
                                                  <div className="col-6">
                                                     
                                                        <div onClick={(evt) => fileRef.current?.click()} className="img-div cursor-pointer mb-2 position-relative">
                                                            <h4 className="font-16" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                                                <i className='fas fa-plus'></i>
                                                            </h4>
                                                        </div>
                                                </div>
                                                <div className="col-6">
                                                    <img
                                                        src="https://www.virginplus.ca/assets/en/images/billexplained-bill2.jpg"
                                                        className="img-div w-100 cursor-pointer mb-2" alt=""/>
                                                </div>
                                                <div className="col-6">
                                                    <img
                                                        src="https://www.virginplus.ca/assets/en/images/billexplained-bill2.jpg"
                                                        className="img-div w-100 cursor-pointer mb-2" alt=""/>
                                                </div>
                                            </div>  */}
                                        </div>
                                        <div className='justify-content-end align-items-center cursor-pointer row' onClick={(evt) => insuranceRef.current?.click()}>
                                        <i className='fas fa-plus edit font-light mr-2'></i>
                                            <span  className={`edit`}>Upload</span>
                                        </div>
                                </div>}
                                    {loadingInsurance && <Loading />}
                                </div>
                            </div>
        </>
                                    
    }

    async function updateLiabilityDisclaimer() {
        try {
            const mutation = gql`
            mutation($settings: [SettingsInput]!) {
                    update_all_settings(settings: $settings) {
                        success
                        message
                    }
                }
            `;
            loading.open('Updating Disclaimer...');
            const data = await GraphqlService.SendMutation(mutation, {settings: [ {key: 'liability_disclaimer', value: tempLiabilityDisclaimer}]});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            setopenModal(false);
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setTempLiabilityDisclaimer('');
            loadData('liability disclaimer');
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            loading.close();
        }
    }

    const CarrierLibality = () => {
        return <>
        <div className="">
                        <div
                                        className="col-12 d-flex justify-content-between align-items-center mb-3 ">
                                        <h2 className={`font-12`}>Inspection Liability Disclaimer</h2>
                                        <button className={`btn btn-blue-light py-1`}
                                              onClick={() => {
                                                setTempLiabilityDisclaimer(settings.liability_disclaimer);
                                                setopenModal(true);
                                              }}>
                                            View/Edit</button>
                                    </div>
                                    <div className="col-12 ">
                                        <p className={'value-p font-10 font-normal m-0'}>
                                            {loadingDisclaimer && <Loading />}
                                            {!loadingDisclaimer && <div dangerouslySetInnerHTML={{__html: settings.liability_disclaimer}}>
                                                
                                            </div>}
                                            {(settings.liability_disclaimer?.trim() == '' && !loadingDisclaimer) && <h4 className='font-12 text-red cursor-pointer' onClick={(ev) => {
                                                setTempLiabilityDisclaimer(settings.liability_disclaimer);
                                                setopenModal(true);
                                            }}>Enter Liability Disclaimer</h4>}
                                        </p>
                                    </div>
                        </div>   
        </>
    }


    const hasSomethingMissing = () => {
        return carrier_insurance.expiration == null || carrier_insurance.uploaded_documents.length == 0
            || carrier_registration.uploaded_documents.length == 0
            || (settings.liability_disclaimer?.trim() || '') == ''
            || (carrier_tax_info?.name?.trim() || '') == ''
            || (carrier_tax_info?.type?.trim() || '') == ''
            || (carrier_tax_info?.address?.street || '') == ''
            || (carrier_tax_info?.ssn || carrier_tax_info?.ein || '') == ''
            || carrier_tax_info.uploaded_documents.length == 0
            || !carrier_insurance?.policy_number
            || !carrier_insurance?.agent?.name
            || !carrier_insurance?.agent?.phone
            || !carrier_insurance?.agent?.email
            || !carrier_insurance?.name
            || carrier_insurance.expiration == null
            || new Date(carrier_insurance.expiration) < new Date()
        }

    async function onSubmitTaxInfo(taxInfo) {
        setOpen(false);
        loadData('tax info');
    }
    return <div className='flex-1-container mx-auto sm-p3 ' style={{maxWidth: '1200px', width: '90%'}}>
        <div className="row title-section-base">
            <div className="col-12">Carrier Documents</div>
            {documents?.message && <div className="col-12 text-left text-red">
            {documents.message}
            </div>}
        </div>
        <div className="flex-1-container" style={{width: '100%', margin: '0 auto'}}>
        <div className="row col-12">
            <div className="col-6 col-sm-12">
                <div className="">
                    <div className=" bg-white rounded-12 p-3 ">
                        <div className="">
                           <CarrierRegistration />
                        </div>
                    </div>
                    <div className="my-3"></div>
                    <div className=" bg-white rounded-12 p-3 mb-3">
                        <CarrierTaxInfo />
                    </div>
                </div>
            </div>
            
            <div className="col-6 col-sm-12">
                <div className="">
                    <div className=" bg-white rounded-12 p-3">
                        <CarrierInsurance />
                    </div>
                    <div className="my-3"></div>
                    {false && <div className=" bg-white rounded-12 p-3">
                        <CarrierLibality />
                    </div>}
                </div>
            </div>
        </div>
        </div>

        <Modal
                visiblePopUpModal={openModal}
                handleClickOutPopupModal={() => {
                    setopenModal(false)
                }}
            >
                <div className="modalpopcontainer row w-100" style={{minHeight: `${heightModal}px`}}>
                    <div className="col-12 p-3 row">
                        <span className="font-18 font-medium-500">Liability disclaimer</span>
                        <h4 onClick={(evt) => setopenModal(false)} className="font-18 cursor-pointer"><i className='fas fa-times'></i></h4>
                    </div>

                    <div className="col-12 font-14 font-normal px-3">
                        <CKEditor
                            className="h-75"
                            editor={ClassicEditor}
                            data={tempLiabilityDisclaimer}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setTempLiabilityDisclaimer(data);
                                // console.log(data);
                            } }
                            onReady={(editor) => {

                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        'height',
                                        `${(heightModal / 2) + 50}px`,
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                        />
                    </div>

                    <div className="col-12 font-14 font-normal">
                        <div className="row p-3">
                            <div className="col-6">
                                <button onClick={() => setopenModal(false)}className="btn w-100 text-center btn-clear text-light-blue">Cancel</button>
                            </div>
                            <div className="col-6">
                                <button onClick={(evt) => {
                                    // setopenModal(false);
                                    updateLiabilityDisclaimer();

                                }} className="btn w-100 text-center btn-skyblue">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <ReactModal className="modal-496" isOpen={open} onRequestClose={()=>setOpen(false)}>
                {
                    // tab === COMPANY &&
                    // <CompanyAccountInfoForm onCancel={()=>setOpen(false)}
                    //                         onSubmit={(carrier) => { setCarrier(new Carrier(carrier)); setOpen(false); }}
                    //                         data={carrier} />
     
                }
                {   
                    tab === REGISTRATION &&
                    <RegistrationInfoForm 
                    previewFile={previewFile}
                    removeFile={removeFile}
                    uploadFile={() => registrationRef.current?.click()}
                    onCancel={()=>setOpen(false)} onSubmit={value => {onSubmitRegistration(value);setOpen(false)}} data={{
                        carrier_registration,
                        id: carrierObj.id
                    }}/>
                }
                {
                    tab === INSURANCE &&         
                    <InsuranceInfoForm 
                    previewFile={previewFile}
                    removeFile={removeFile}
                    uploadFile={() => insuranceRef.current?.click()}
                    onCancel={()=>setOpen(false)} onSubmit={value => {onSubmitInsurance(value);setOpen(false)} } data={{
                        carrier_insurance,
                        id: carrierObj.id
                    }}/>
                }
                {
                    tab === TAX_INFO &&         
                    <TaxInfo
                    previewFile={previewFile}
                    removeFile={removeFile}
                    uploadFile={() => taxRef.current?.click()}
                    data={carrier_tax_info} carrier_id={carrierObj?.id} onClose={(evt) => setOpen(false)}
                    onSubmitted={evt => {
                            onSubmitTaxInfo(evt);
                        }}
                    />
                }
            </ReactModal>
    </div>
}

export default CarrierDocumentsPage;