export class PointClass{
    id?: any;
    lat: number = 0;
    lng: number = 0;

    constructor(props?) {
        if(props){
            this.id = props.id;
            this.lat = props.lat;
            this.lng = props.lng;
        }
    }
}
