import React,{useState,useRef,useEffect} from 'react'
import './Modal.scss'
import ICONS from "../../../assets/svg";


type ModalProps={
    visiblePopUpModal:boolean,
    handleClickOutPopupModal:()=>void,
    children: React.ReactNode
}

const Modal =(props:ModalProps)=>{
    const nodeContainer = useRef<HTMLDivElement>();
    const hasListener = useRef(false);

    let handleClickOut = (event: any) => {

        if (!props.visiblePopUpModal) return;

        if (!nodeContainer.current)
            return;

        if (!nodeContainer.current.contains(event.target)) {
            props.handleClickOutPopupModal()
        }
    }



    useEffect(() => {
        document.body.classList.toggle('no-scroll', props.visiblePopUpModal);
        if (props.visiblePopUpModal)
        {

            if (hasListener.current) return;

            document.addEventListener("mousedown", handleClickOut);
            //document.addEventListener("keydown", handleKeyDown);
            hasListener.current = true;
        }

    }, [props.visiblePopUpModal])


    return(
        <>  
                       
            <div className={`${props.visiblePopUpModal?"d-flex":"d-none"} modalpopuop justify-content-center align-items-center`}>
                <div ref={nodeContainer} className="" style={{position:"relative"}}>

                        {props.children}

                </div>
            </div>

        </>
    )
}

export default Modal