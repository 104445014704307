import {PointClass} from "./PointClass";

export class RouteClass {
    id: any;
    start: PointClass = new PointClass();
    departureDate?: Date;
    arrivalDate?: Date;
    private arrivalAt?: Date;
    private arrivalTrafficAt?: Date;
    private departureAt?: Date;
    private departureTrafficAt?: Date;
    end: PointClass = new PointClass();
    startAddress?: string = '';
    endAddress?: string = '';
    showTime: boolean = false;
    ref?: any;
    arrivalTime?: Date;
    departureTime?: Date;

    onClick: (marker: RouteClass) => void;

    constructor(props?: RouteClass) {
        if(props){
            this.id = props.id;
            this.start = new PointClass(props.start);
            this.end = new PointClass(props.end);
            this.showTime = props.showTime;
            this.ref = props.ref;
            this.departureDate = props.departureDate;
            this.arrivalDate = props.arrivalDate;
            this.startAddress = props.startAddress;
            this.endAddress = props.endAddress;
            this.onClick = props.onClick;
            if (props.arrivalTime)
                this.arrivalTime = new Date(props.arrivalTime)
            if (props.departureTime)
                this.departureTime = new Date(props.departureTime);
        }
    }

    setArrivalAt(seconds: number) {
        this.arrivalAt = new Date(this.arrivalDate.getTime());
        this.arrivalAt.setSeconds(this.arrivalAt.getSeconds() + seconds);
    }

    setArrivalTrafficAt(seconds: number) {
        this.arrivalTrafficAt = new Date(this.arrivalDate.getTime());
        this.arrivalTrafficAt.setSeconds(this.arrivalTrafficAt.getSeconds() + seconds);
    }

    setDepartureAt(seconds: number) {
        this.departureAt = new Date(this.departureDate.getTime());
        this.departureAt.setSeconds(this.departureAt.getSeconds() + seconds);
    }

    setDepartureTrafficAt(seconds: number) {
        this.departureTrafficAt = new Date(this.departureDate.getTime());
        this.departureTrafficAt.setSeconds(this.departureTrafficAt.getSeconds() + seconds);
    }

    get getStartCoordinates() {
        if (this.startAddress) {
            return this.startAddress
        }
        return {lat: this.start.lat, lng: this.start.lng}
    }

    get getEndCoordinates() {
        if (this.endAddress) {
            return this.endAddress
        }
        return {lat: this.end.lat, lng: this.end.lng}
    }
}
