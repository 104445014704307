const driverTypes = {
    INIT_DRIVER_FORM: 'INIT_DRIVER_FORM',
    UPDATE_DRIVER_REQUEST: 'UPDATE_DRIVER_REQUEST',
    UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
    ADD_EQUIPMENT_SUCCESS: 'ADD_EQUIPMENT_SUCCESS',
    UPDATE_DRIVER_FAILURE: 'UPDATE_DRIVER_FAILURE',
    REMOVE_DRIVER_SUCCESS: 'REMOVE_DRIVER_SUCCESS',
    REMOVE_EQUIPMENT_SUCCESS: 'REMOVE_EQUIPMENT_SUCCESS',
}

export default driverTypes;
