/* eslint-disable */
import {PlainList} from 'flatlist-react';
import React, {ComponentType, MutableRefObject, useEffect, useState} from 'react';
import Helper from '../../helper/Helper';
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { BooleanModel } from 'aws-sdk/clients/gamelift';


function formatMoneyString(labelText: string, useCommaSeparator: boolean = true, useDecimalSeparator: boolean = true){
    let negative = labelText.split('').filter(x => x == '-').length == 1 ? '-':'';
    let txt = '', res = '';
    let temp: string = labelText.toString();
    for(let i = 0; i< temp.length; i++){
        if('0123456789'.includes(temp[i])){
            txt += temp[i];
        }
    }
    txt = (+txt).toString();
    if(useDecimalSeparator){
        if(!txt){
            return negative + '0.00';
        }
        else if(txt.length == 1){
            return negative + '0.0' + txt;
        }
        else if(txt.length == 2){
            return negative + '0.' + txt;
        }
        else{
            res = '.' + txt.substring(txt.length - 2);
            txt = txt.substring(0, txt.length - 2);
        }
    }
    if(useCommaSeparator){
        if(!txt){
            return negative + '0' + res;
        }
        else if (txt.length <= 3){
            return negative + txt + res
        }
        else{
            while (txt.length > 3){
                res = ',' + txt.substring(txt.length - 3) + res;
                txt = txt.substring(0, txt.length - 3);
            }
        }
    }
    return negative + txt + res;
}

interface IFormGroup {
    label?: string,
    name: string,
    hintText?: string,
    disabled?: boolean,
    validation?: (value: string) => boolean,
    value?: string,
    errorText?: string,
    required?: boolean,
    colSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    checked?: boolean,
    onCheckChanged?: (value: boolean) => void,
    isValid?: (valid: boolean) => void,
    placeholder?: string,
    type?: 'text' | 'password' | 'email' | 'select' | 'checkbox' | 'textarea' | 'combobox' | 'money' | 'number',
    options?: { value: (x, index: number) => any, label: (x, index: number) => any, data: any[] },
    onTextChange?: (text: string, data?: any) => void,
    className?: string,
    onFocus?: (evt) => void,
    onBlur?: (evt) => void,
    fullTextarea?: boolean,
    moneySymbol?: boolean,
    noDecimal?: boolean,
    inputClassName?: string,
    onClick?: (evt) => void,
    readOnly?: boolean,
    textAreaHeight?: string,
    innerRef?: any,
    onEnter?: (evt) => void,
    autoComplete?: string,
    noHintText?: boolean
}
const defaultMaskOptions = (allowDecimal: boolean) => {return {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal,
    decimalSymbol: '.',
    decimalLimit: allowDecimal ? 2 : 0, // how many digits allowed after the decimal
    allowNegative: false,
    allowLeadingZeroes: true
  }
}
  const currencyMask = (noDec: boolean) => {
      return createNumberMask({
    ...defaultMaskOptions(!noDec)
  });
}
const FormGroup: ComponentType<IFormGroup> =
    ({
        inputClassName = '',
         label,
         moneySymbol = false,
         noDecimal = false,
         type = 'text',
         value = '',
         disabled = false,
         fullTextarea = false,
         className = '',
         textAreaHeight = null,
         onFocus,
         onCheckChanged,
         onBlur,
         onClick,
         options = {value: null, label: null, data: []},
         placeholder = '',
         isValid,
         name,
         checked = false,
         hintText,
         errorText,
         validation,
         required = false,
         colSize = 12,
         onTextChange,
         children,
         readOnly = false,
         innerRef = null,
         onEnter,
         autoComplete = 'off',
         noHintText = false
     }) => {
        const [dirty, setDirty] = useState(false);
        const hasError = !required && (type == 'checkbox' ? !checked : (value || '').trim().length == 0)
            ? false
            :  required && (type == 'checkbox' ? !checked : (value || '').trim().length == 0)
                ? true
                :  validation && !validation(value);


        const [shown, setShown] = useState(false);

        if (isValid)
            isValid(!hasError);

        const optionsFiltered = () => options.data.filter((x, i) => options.value(x, i).toString().toLowerCase().startsWith(value?.trim().toLowerCase()))
        const getHeight = () => !shown ? '0' : (2 * (optionsFiltered().length >= 5 ? 5 : optionsFiltered().length)) + 'rem';

        return (
            <div
            onClick={(evt) => onClick && onClick(evt)}
                className={'form-group  ' + ('col-' + colSize) + (type == 'combobox' || moneySymbol ? ' position-relative' : '') + (` ${type == 'checkbox' ? 'form-checkbox' : ''}`) + (onClick ? ` cursor-pointer` : '') + ' ' + className}>
                {type != 'checkbox' && label && <label className={(hasError ? 'error-label' : '')}
                                                       htmlFor={name}>{label + (required ? '*' : '')}</label>}

                <div className={'position-relative' + (type == 'checkbox' ? ' d-flex' : '')}>
                {type == 'select' &&
                <select
                        disabled={disabled}
                        name={name}
                        id={name}
                        className={'form-field' + (hasError ? ' error-field' : '') + (placeholder && !hasError && (value || '').trim() == '' ? ' placeholder' : '')}
                        placeholder={moneySymbol ? (noDecimal ? '0':'0.00') : placeholder}
                        onBlur={(evt) => {
                            setShown(false);
                           // setDirty(true)
                           {!evt.target.value || hintText ? setDirty(true): setDirty(false) }

                        }}
                        value={value}
                        onChange={(evt) => {
                            onTextChange && onTextChange(evt.target.value);
                            //setDirty(true);
                        }}
                >
                    {placeholder && <option className='placeholder' value=''>{placeholder}</option>}
                    {options.data.map((o, i) => <option disabled={options.data[i].disabled} key={i} value={options.value(o, i)}>{options.label(o, i)}</option>)}

                </select>}
                {type != 'select' && type != 'textarea' &&

                <div className='position-relative d-flex'>
                    {moneySymbol && <span className='position-absolute font-14'
                                          style={{top: '50%', transform: 'translateY(-50%)', left: '.65rem'}}>$</span>}
                    {/* {moneySymbol && <MaskedInput mask={currencyMask(noDecimal)} onKeyPress={(evt) => {
                        if (evt.key == 'Enter' && onEnter)
                            onEnter(evt);

                    }} ref={innerRef} readOnly={readOnly} onKeyDown={(evt) => {
                        if (evt.key == 'ArrowDown' && type == 'combobox') {
                            if (optionsFiltered().length > 0) {
                                onTextChange(options.value(optionsFiltered()[0], 0), optionsFiltered()[0]);
                            }
                        }
                    }} onFocus={(evt) => {
                        setShown(true);
                        setDirty(false)
                        onFocus && onFocus(evt);
                    }} autoComplete={autoComplete} checked={checked} type={type == 'combobox' ? 'text' : type}
                           placeholder={placeholder} onBlur={(evt) => {
                            {!evt.target.value || hintText ?setDirty(true): setDirty(false) }
                        // if (type != 'combobox')

                        if (type != 'combobox')
                            return onBlur && onBlur(evt);

                        setTimeout(() => {
                            setShown(false)
                            onBlur && onBlur(evt);
                        }, 150);


                        //setDirty(true)
                    }} value={moneySymbol ? formatMoneyString(value, true, !noDecimal) : value} onChange={(evt) => {
                        if (type == 'checkbox')
                            onCheckChanged && onCheckChanged(evt.target.checked);
                        else{
                            console.log('target: ',evt.target.value);
                            onTextChange && onTextChange(evt.target.value);
                        }

                        //setDirty(true);
                    }} className={(moneySymbol ? 'pl-4 ' : '') + 'form-field ' + (hasError && 'error-field')}
                           disabled={disabled} name={name} id={name}/>} */}

                    {<input onKeyPress={(evt) => {
                        if (evt.key == 'Enter' && onEnter)
                            onEnter(evt);

                    }} ref={innerRef} readOnly={readOnly} onKeyDown={(evt) => {
                        if (evt.key == 'ArrowDown' && type == 'combobox') {
                            if (optionsFiltered().length > 0) {
                                onTextChange(options.value(optionsFiltered()[0], 0), optionsFiltered()[0]);
                            }
                        }
                    }} onFocus={(evt) => {
                        setShown(true);
                        setDirty(false)
                        onFocus && onFocus(evt);
                    }} autoComplete={autoComplete} checked={checked} type={type == 'combobox' ? 'text' : type}
                           placeholder={placeholder} onBlur={(evt) => {
                            {!evt.target.value || hintText ?setDirty(true): setDirty(false) }
                        // if (type != 'combobox')

                        if (type != 'combobox')
                            return onBlur && onBlur(evt);

                        setTimeout(() => {
                            setShown(false)
                            onBlur && onBlur(evt);
                        }, 150);


                        //setDirty(true)
                    }} value={moneySymbol ? formatMoneyString((noDecimal ? Number(+value).toFixed(0).toString() : Number(+value).toFixed(2).toString()), true, !noDecimal) : value} onChange={(evt) => {
                        if (type == 'checkbox')
                            onCheckChanged && onCheckChanged(evt.target.checked);
                        else{
                            onTextChange && onTextChange(moneySymbol ? formatMoneyString(evt.target.value, true, !noDecimal) : evt.target.value);
                        }

                        //setDirty(true);
                    }} className={(moneySymbol ? 'pl-4 ' : '') + 'form-field ' + (hasError && 'error-field')}
                           disabled={disabled} name={name} id={name}/>}
                </div>
                }

                {type == 'textarea' &&
                <textarea ref={innerRef} disabled={disabled} style={{minHeight: textAreaHeight ? textAreaHeight : (fullTextarea ? '100%' : ''), height: textAreaHeight ? textAreaHeight : 'auto'}} placeholder={placeholder}
                          onBlur={(evt) => setDirty(true)} value={value} onChange={(evt) => {

                    onTextChange && onTextChange(evt.target.value);

                    //setDirty(true);
                }} className={'form-field ' + (hasError && 'error-field') + ' ' + (inputClassName ? inputClassName : '')} name={name} id={name}/>}
                {type == 'checkbox' && <label className={'checkbox ' + (hasError ? 'error-label' : '')}
                                              htmlFor={name}>{label + (required ? '*' : '')}</label>}
                
                {type == 'combobox' && <div className='position-absolute bg-white combobox ' style={{
                    overflow: 'auto',
                    zIndex: 2,
                    width: '100%',
                    border:  getHeight() != '0' ? '1px solid #CCC' : 'none',
                    height: getHeight()
                }}>
                    <PlainList
                        list={optionsFiltered()}
                        renderItem={(data, i) => <p
                            style={{zIndex: 10}}
                            onClick={(evt) => onTextChange(options.value(data, i), data)}>{options.label(data, i)}</p>}
                        keyExtractor={(item, index) => index.toString()}
                        renderOnScroll
                        renderWhenEmpty={() => null}/>

                </div>}

                <div style={{position: 'absolute', top: '50%', right: '1rem', transform: 'translateY(-50%)'}}>
                    {children}
                </div>
            </div>
                {!noHintText && <div>
                    { (hintText || (errorText && hasError) || (required && (value || '').trim().length == 0) && (!shown || type != 'combobox')) &&
                    <p style={{whiteSpace: 'break-spaces'}} className={'hint-text ' + (hasError  && 'error-text')}>{hasError ? (required && (value || '')?.trim()?.length == 0 ? label + ' is required' : errorText) : (hintText || '\n')}</p>}
                </div>}
                
            </div>)
    }

export default FormGroup;
