import React, {useState, useRef} from 'react'
import './CardServiceNote.scss'


const CardServiceNote = (props: {notes: string}) => {
    return (
        <>
            <div className="row mt-3 ">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <span className="font-12 font-medium">Notes</span>
                </div>
            </div>

            <div className="row mt-3">

                <div className="col-12 card bg-light-gray">
                    <div className="card-body font-14 font-normal">
                        <span>{props.notes}</span>
                    </div>
                </div>
            </div>


        </>
    )
}

export default CardServiceNote
