

import moment from 'moment';
import { CSSProperties } from 'react';
import AuthService from '../services/auth.service';
// import Geocode from 'react-geocode';
// import environments from "../../environments/environments";

// Geocode.setApiKey(environments.GOOGLE_API);

interface INavigator
{
    push: (url: string) => void,
    goBack: () => void,
    location: {pathname: string}
}

interface IModalStyle {
    ContentStyle: {
        [key: string]: CSSProperties
    },
    OverlayStyle: {
        [key: string]: CSSProperties
    }
}

export default class Helper  {
    static UseApiKey() {
        localStorage.setItem('use_api_key', 'true');
        setTimeout(() => {
            localStorage.removeItem('use_api_key');
        });
    }

    private static Navigator: INavigator;
    static SetNavigator(navigator: INavigator)
    {
        this.Navigator = navigator;
    }

    static Session = {
        DoLogout: (redirect = false) => {
            AuthService.USER = {
                id: -1,
                name: ''
            }

            let url = (redirect ? Helper.Navigation.GetCurrentLocation() : '')
            if (url == '/')
                url = '';

            if (url.toLocaleLowerCase().startsWith('/login'))
                return;

            const url2 = url.toLowerCase().includes('/pending-inspection') || url.toLowerCase().includes('/history') ? '/driver' : '';
                
            Helper.Navigation.NavigateTo('/login' + url2 + url);

            localStorage.clear();
        }
    }


    static Navigation = {
        NavigateTo: (url: string) => {
            Helper.Navigator.push(url)
        },
        GoBack: () => {
            Helper.Navigator.goBack();
        },
        GetCurrentLocation: () => {
            return Helper.Navigator.location.pathname;
        }
    }


    static Maps = {
        AddresToLatLng: async (address) => {

            // const data = await Geocode.fromAddress(address);
            //
            // const { lat, lng } = data.results[0].geometry.location;
            //
            // return {lat, lng};
        }
    }





    

    static FORMAT = {
        VehicleDescription: (vehicle: {year: string, make: string, model: string, vin?:string, submodel?:string}|undefined|null, format: 'ymm'|'yaer-model'|'full'|'year-make'|'vin' = 'ymm') => {
            if(!vehicle) return '-';
            switch (format){
                case 'full': return `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''} ${vehicle.submodel || ''} ${vehicle.vin ||''}`.trim();
                case 'ymm': return `${vehicle.year || ''} ${vehicle.make || ''} ${vehicle.model || ''}`.trim();
                case 'year-make': return `${vehicle.year || '-'} ${vehicle.make || '-'}`.trim();
                case 'yaer-model': return `${vehicle.year || '-'} ${vehicle.model || '-'}`.trim();
                case 'vin': return `${vehicle.vin?.substring(0, 10).trim()||'-'} ${vehicle.vin?.substring(10, 17)}`.trim();
            }
        },
        USCurrency: (value: number, symbol = '$') => {
            if (isNaN(value) || value as any == '-0')
                value = 0;

            return new Intl.NumberFormat('en-US',
                    { style: 'currency', currency: 'USD' }
                  ).format(value).replace('$', symbol);
        },
        USDate: (value: Date, formatType: 'n' | 'dd' | 'st' | 'do' | 'to' | 'ld' | 'ds' | 'totz' = 'n') => {
            
            if (!formatType)
                formatType = 'n';
            if (!value)
                value = new Date();

            const isToday = () => moment(value).format('YYYY/MM/DD') == moment().format('YYYY/MM/DD');
            const getDaysDiff = () => {
                let diff = moment(new Date(value).setHours(0, 0, 0, 0)).diff(moment(new Date().setHours(0, 0, 0, 0)), 'day');
                
                
                
                if (diff == -1)
                    return 'Yesterday';
                else if (diff == 1)
                    return 'Tomorrow';
                else if (diff == 0)
                    return 'Today'


                return diff < -1 ? `${-diff} days ago` : `In ${diff} days`;
            }
            


            const format = {
                'n': 'MM/DD/YYYY hh:mm A',
                'dd': `(${isToday() ? 'Today' : `${getDaysDiff()}`})`,
                'st': (isToday() ? '' : 'MM/DD/YYYY ') + ' hh:mm A',
                'do': 'MM/DD/YYYY',
                'to': 'hh:mm A',
                'ld': 'dddd, MM/DD/YYYY',
                'ds': 'M/D',
                'totz': 'h:mm A'
            }
            
            
            return formatType == 'dd' ? format[formatType] : (moment(value).format(format[formatType]) + (formatType == 'totz' ? ` (${new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]})` : ''));
        }
    }

    static Masks = {
        NoSpecialCharacters: (value: string, allowedChars: string = ' ') => {
            let cleanText = 'abcdefghijklmnopqrstuvwxyz0123456789';
            
            cleanText += allowedChars;
            
            let text = '';
            let letters = value.split('');
            for(let i = 0; i < letters.length; i++){
                if (cleanText.includes(letters[i].toLowerCase())){
                    text += letters[i];
                }
            }
            return text;
        },
        Code: (value: string) => {
            return value
              .replace(/\d+/g, '')
              .replace(' ', '')
              .substring(0, 5)
              .toUpperCase();
        },
        USPhone: (value: string) => {
            
            let v = Helper.Masks.IntNumbers(value);
            if (v.length > 10)
                return v;
            let phone = '';
        let hcount = value.split('').filter(x => x == '-').length;
        
        value = value.replace('-', '');
        for (let i = 0; i < value.length; i++)
        {
            const char = value.substring(i, i + 1);

            if (char >= '0' && char <='9')
                phone += char;
            if (phone.length == 12)
                break;
            
            if (phone.length == 3 || phone.length == 7)
                phone += '-';
        }

        let array = phone.split('-');

        let first = array[0] || '';
        let second = array[1] || '';
        let third = array[2] || '';



        return first + ((hcount > 0 || second.length >= 1) && first.length > 2 ? '-' : '') + second + ((hcount > 1 || third.length >= 1) && second.length > 2 ? '-' : '') + third;
        },
        
        IntNumbers: (value: string, maxLength?: number) => {
            console.log('\n\nintText: ', value);
            let negative = value.split('').filter(x => x == '-').length == 1 ? '-':'';
            let newString = '';
            for (let i = value.length - 1; i >= 0 && (!maxLength || newString.length < maxLength); i--) {
                if('0123456789'.includes(value[i])){
                    newString = value[i] + newString;
                }
            }

            console.log('\n\nreturnText: ', newString ? (negative + newString) : '0');
            return newString ? (negative + (+newString).toString()) : '0';
        },
        DecimalNumbers: (value: string, allowNegative: boolean = false) => {
                let negative = value.split('').filter(x => x == '-').length == 1 ? '-':'';
                let newString = '';
                let decimalCount = 0;
                for (let i = 0; i < value.length; i++) {
                    const char = value.substring(i, i + 1);
                    if (((char >= '0' && char <= '9') || (char == '.' && !newString.includes('.'))) && decimalCount < 2)   
                    {
                        if (newString.includes('.'))
                            decimalCount++;
                        newString += char;
                    }
                    else if (i == 0 && char == '-' && allowNegative)
                        newString += char;
                    
                }
                newString = newString == '.' ? '0.' : newString;


                if (!newString)
                    return '0';
                const x = newString.split('.');

                return negative + (+x[0] || 0).toString() + (x.length > 1 ? '.' + x[1].toString() : '');
        },
        CreditCardExp: (value: string) => {
            const hasSlash = value.includes('/');
            value = value.trim().replace('/', '');
            let exp = '';
            
            for (let i = 0; i < value.length; i++)
            {
    
                
                const char = value.substring(i, i + 1);
    
                if (exp.length == 5)
                    break;
    
                if (exp.length == 2)
                {
                    exp += '/';
                    if (char == '/')
                        continue;
                }
                
                
                if (char >='0' && char <='9')
                    exp += char;
            }
    
            let month = exp.split('/')[0] || '';
            let year = exp.split('/')[1] || '';
    
            if (month.length == 1)
            {
                if (Number(month) > 1)
                    month = '';
            }
            else if (month.length == 2)
            {
                if (Number(month) == 0)
                    month = '0';
                if (Number(month) > 12)
                    month = '0' + month.substring(1, 2);
            }
    
    
    
    
            return month + (hasSlash || year.length >= 1 ? '/' : '') + year;
        },
        ValidUSMoney: (value: number, skip = false) => {
            return new Intl.NumberFormat('en-US',
                { style: 'currency', currency: 'USD' }
            ).format(value).replace('$', skip ? '' : '$').trim();
        },
        TimeFormat: (value: string) => {
            let time = '';
            let hcount = value.split('').filter(x => x == ':').length;
            value = value.replace(':', '');
            for (let i = 0; i < value.length; i++)
            {
                const char = value.substring(i, i + 1);
                
                if (time.length === 0  ) 
                {
                    if(char >= '0' && char <='1' ){
                    time += char;    
               
                    }
                }
               else if (time.length === 1 ) 
                    {
                        if(char >= '0' && char <='2' && value[0] === '1'){
                        time += char;    
                   
                        }else if(char >= '0' && char <='9' &&  value[0] === '0'){
                        time += char;
                        }
                    }

                else if (time.length === 3  ) 
                    {
                        if(char >= '0' && char <='5' ){
                        time += char;    
                   
                        }
                    }

                else if (time.length === 4  ) 
                    {
                        if(char >= '0' && char <='9' ){
                        time += char;    
                   
                        }
                    }

                 if (time.length == 5)
                    break;
                 if (time.length == 2  )
                    time += ':';
            }
            let array = time.split(':');
            let first = array[0] ;
            let second = array[1] || '';
    
            return first + ((hcount > 0 || second.length >= 1) && first.length >1  ? ':' : '') + second
      ;
        }
    
    }

    static Validators = {
        PasswordText: 'Password must be at least 7 characters long and contain at least one number, one uppercase letter, and one lowercase letter.',
        IsValidPassword: (password: string) => {
            // var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})");
            var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})");
            return regix.test(password);
        },
        IsValidEmail: (email: string) => {
            if (!email)
                return false;

            let emails = email.split(';').filter(x => x != '');
            
            
            for (let email of emails)
            {
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim()))
                    return false;
                
            }
            return true;
            
        },
        IsValidUSPhone: (phone: string) => {
            return Helper.Masks.USPhone(phone).length == 12;            
        },
        MinLength: (length: number) => {
            return length > 0;
        }
    }
    
    static ModalStyles: IModalStyle = {
        ContentStyle: {
            Regular: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '800px',
                padding: '0',
                minHeight: '80%'
            }
        },
        OverlayStyle: {
            Regular: {
                padding: '0'
            } 
        }
    }

    static States = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];
}
