import React,{useState,useRef} from 'react'
//import Status from '../../components/Status/Status';
import ICONS from "../../../assets/svg";
import './CardServiceVehicle.scss'
import {IOrderRequest} from "../../redux/reducers/OrderRequests.reducer";
import moment from "moment";


type CardServiceVehicleProps={
    data?:IOrderRequest[]
}

const CardServiceNotes = ( notes = '') => {
    return <>
        <div className="row mt-3 ">
            <div className="col-12 d-flex justify-content-between align-items-center">
                <span className="font-12 font-medium-500">Notes</span>
            </div>
        </div>

        <div className="row mt-3">
            <div className="col-12 card bg-light-gray">
                <div className="card-body">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row d-flex align-items-center">
                                <div className="col-sm-12 d-flex flex-column">
                                    <p>{notes}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const CardServiceVehicle =(props:CardServiceVehicleProps)=>{

    return(
        <>
         {
             props.data.map(item=>{

                 return (
                   <>
                     <div className="row mt-3 ">
                       <div className="col-12 d-flex justify-content-between align-items-center">
                         <span className="font-12 font-medium-500">
                           Vehicles
                         </span>
                       </div>
                     </div>

                     <div className="row mt-3">
                       <div className="col-12 card bg-light-gray">
                         <div className="card-body">
                           <div className="d-flex justify-content-between align-items-center">
                             <span className="font-14 font-medium d-flex align-items-center">
                               {`${item.vehicle.year} ${item.vehicle.make} ${item.vehicle.model}`}
                               <span className="me-2 "></span>{" "}
                               <div className="ml-2 circle-red"></div>
                             </span>
                           </div>

                           <div className="d-flex font-12 justify-content-between align-items-center">
                             <p className="font-normal mb-3">
                               {(item.vehicle as any).vin}
                             </p>
                             {/* <p className="font-medium-500">Runs <img style={{height:"12px",width:"12px"}} src={ICONS.IconVerifiedGreen}/></p> */}
                           </div>

                           <div className="card mb-3">
                             <div className="card-body">
                               <div className="row d-flex">
                                 <div className="col-5 col-sm-12 d-flex flex-column">
                                   <span className="font-12 font-medium mb-4">
                                    ORIGIN
                                   </span>
                                   <span className="font-12 font-medium">
                                     {moment(item.origin.scheduled).format(
                                       " l, h:mm a"
                                     )}
                                   </span>
                                   <span className="font-12 font-medium">
                                    {`${item.origin.address.street_number}, ${item.origin.address.street}`}
                                   </span>
                                   <span className="font-12">
                                    {`${item.origin.address.city}`}
                                   </span>
                                   <span className="font-12">
                                    {`${item.origin.address.state}, ${item.origin.address.postal_code}`}
                                   </span>
                                   <span className="font-12 font-medium mt-4">
                                    CONTACTS
                                   </span>
                                   {item.origin.contacts.map(contact => <>
                                        <span className="font-12 font-medium mt-2">
                                            {contact.name}
                                        </span>
                                        {contact.value.filter(x=>x).map(val => 
                                        <span className="font-12 mt-1">
                                            {val.toLowerCase()}
                                        </span>)}
                                   </>)}
                                 </div>

                                 <div className="col-2 col-sm-12">
                                   <i className="">-</i>
                                 </div>

                                 <div className="col-5 col-sm-12 d-flex flex-column">
                                   <span className="font-12 font-medium mb-4">
                                    DESTINATION
                                   </span>
                                   <span className="font-12 font-medium">
                                     {moment(item.destination.scheduled).format(
                                       " l, h:mm a"
                                     )}
                                   </span>
                                   <span className="font-12 font-medium">
                                    {`${item.destination.address.street_number}, ${item.destination.address.street}`}
                                   </span>
                                   <span className="font-12">
                                    {`${item.destination.address.city}`}
                                   </span>
                                   <span className="font-12">
                                    {`${item.destination.address.state}, ${item.destination.address.postal_code}`}
                                   </span>
                                   <span className="font-12 font-medium mt-4">
                                    CONTACTS
                                   </span>
                                   {item.destination.contacts.map(contact => <>
                                        <span className="font-12 font-medium mt-2">
                                            {contact.name}
                                        </span>
                                        {contact.value.filter(x=>x).map(val => 
                                        <span className="font-12 mt-1">
                                            {val.toLowerCase()}
                                        </span>)}
                                   </>)}
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>

                     {!item.accepted && <span className="font-10 font-normal mt-3 mb-3">
                       *Further details will be provided after carrier agreement
                       is approved by driver.
                     </span>}
                   </>
                 );

             })
         }


        </>
    )
}

export default CardServiceVehicle
