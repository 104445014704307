import gql from "graphql-tag";


/**
 * GET ALL DRIVER LOCATIONS
 * @param none This does not need any param
 */
const GET_ALL_LIST = gql`
    query{
        get_locations{
            id
            name
            devices{
                time
                speed
                course
                device
                altitude
                coordinate{
                    Latitude
                    Longitude
                }
                device_info{
                    name
                    type
                    idiom
                    model
                    version
                    platform
                    manufacturer
                }
                background_mode
            }
            username
        }
    }
`;

const GET_SCHEDULED_LIST = gql`
    query {
  get_carrier_drivers {
     driver {
      id
      name
      price
      carrier
      status {
        text
        text_color
        background_color
        border_color
      }
      equipment
      itinerary {
        orders {
          id
          vehicle {
            id
            vin
            year
            make
            model
            submodel
            type
            color
          }
          order_id
          is_pickup
          scheduled
          completed
          nofail
          contacts {
            name
            value
          }
          address
        }
      }
    }
  }
}

`

const CHECK_USERNAME = gql`
    query($username:String){
        get_valid_driver_username(username:$username){
            success
            message
        }
    }
`

const DriverQueries = {
    GET_SCHEDULED_LIST,
    GET_ALL_LIST,
    CHECK_USERNAME
}

export default DriverQueries;
