import Status from "../Status/Status";
import Map from "../../../assets/images/map.jpg";
import ICONS from "../../../assets/svg";
import { gql } from "apollo-boost";
import { useEffect, useState } from "react";
import { Destination, Order } from "../../classes/classes";
import { GraphqlService } from "../../services/graphql.service";
import { useToasts } from "react-toast-notifications";
import Loading from "../Loading/Loading";
import ReactModal from "react-modal";
import useLoading from "../../hooks/useLoading/useLoading";
import FormGroup from "../FormGroup/FormGroup";
import Helper from "../../helper/Helper";

const query = gql`
query($order_id: Int) {
    order_details_info(order_id: $order_id) {
      id
      price
      remaining_carrier_receivable
      driver_pay
      remaining_driver_payable
      expires
      broker_note
      accepted
      rejected
      expired
      cancelled
      driver {
          id
          name
      }
      change_requests {
        id
        active
        expired
        expires_at
        accepted
        rejected
        payout
      }
      broker {
        id
        name
      }
      vehicle {
        vin
        year
        make
        model
        submodel
        color
      }
      origin {
        nofail
        completed
        time_completed
        contacts {
          name
          value
        }
        scheduled
        address {
          street_number
          street
          city
          state
          postal_code
          country
        }
      }
      destination {
        nofail
        completed
        time_completed
        contacts {
          name
          value
        }
        scheduled
        address {
          street_number
          street
          city
          state
          postal_code
          country
        }
      }
    }
  }
  
`;

const queryMap = gql`
query($first_address: address_input, $second_address: address_input){
    get_google_static_image(first_address:$first_address, second_address:$second_address)
  }
`;

const mutationInspection = gql`
mutation($order_id:Int, $is_pickup:Boolean){
    create_inspection_report_link(order_id:$order_id, is_pickup:$is_pickup)
  }
`;

const mutationInspectionLink = gql`
    mutation($velox_order_id: Int, $velox_driver_id: Int, $phones: [String]) {
        create_velox_pending_origin_inspection(
        velox_order_id: $velox_order_id
        velox_driver_id: $velox_driver_id
        phones: $phones
        ) {
            id
            message
            success
        }
    }
`;

const JobsLegDetails = ({onClose, order_id, onChangeDriver}:{onClose: () => void, order_id: number, onChangeDriver: (row: {id: number, price: number, driver_pay: string}) => void}) => {

    const [loadingOrder, setLoadingOrder] = useState(false);
    const [mapIMG, setMapIMG] = useState('');
    const [order, setOrder] = useState(new Order());
    const toast = useToasts();
    const [phones, setPhones] = useState<string[]>([]);
    const [isOrigin, setIsOrigin] = useState<boolean>(null);
    const loading = useLoading();

    async function getInspectionLink(is_pickup) {
        try {
            loading.open('Getting Inspection Link...');
            const link = await GraphqlService.SendMutation(mutationInspection, {order_id: order.id, is_pickup});
            Object.assign(document.createElement('a'), {
                target: '_blank',
                href: link,
            }).click();
            loading.close();
            toast.addToast(<p className="font-10">
            Link Created: 
            <br />
            <a href={link} target="_blank">{link}</a>
        </p>, {appearance: 'success', autoDismiss: true});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    async function sendInspectionLink(is_pickup) {
        try {
            const mutation = gql`
            mutation($velox_order_id: Int, $velox_driver_id: Int) {
                create_pending_${is_pickup ? 'origin' : 'destination'}_inspection(
                order_id: $velox_order_id
                driver_id: $velox_driver_id
                ) {
                    id
                    message
                    link
                    success
                }
            }
            `;
            loading.open('Sending Inspection Link...');
            const data = await GraphqlService.SendMutation(mutation, {
                velox_order_id: order.id,
                velox_driver_id: order.driver?.id
            });
            
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
            loading.close();
            toast.addToast(<p className="font-10">
                {data.message}
                <br />
                <a href={data.link} target="_blank">{data.link}</a>
            </p>, {appearance: 'success', autoDismiss: true});
            
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    
    

    async function handleAction(stop: Destination, is_pickup: boolean) {
        if (stop.completed)
            return getInspectionLink(is_pickup);
        
        sendInspectionLink(is_pickup);
        
    }

    async function loadMap(first_address, second_address) {
        try {
            const data = await GraphqlService.SendQuery(queryMap, {first_address, second_address});
            
            const x = await getBase64Image(data);
            
            setMapIMG(x);
        } catch (ex) {
            console.log(ex.message);
            setMapIMG(null);
        }
    }

   async function loadOrder() {
       try {
           setLoadingOrder(true);
           setMapIMG('');
           let data: Order = await GraphqlService.SendQuery(query, {order_id});
           
           data = new Order(data);
           
           setOrder(data);
           loadMap(data.origin.address, data.destination.address);
           
           setLoadingOrder(false);
       } catch (ex) {
        setLoadingOrder(false);
        toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
       }
   }

    useEffect(() => {
        if (order_id)
            loadOrder().then();
    }, [order_id]);

    async function getBase64Image(img): Promise<string> {
        var res = await fetch(img);
    var blob = await res.blob();
    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result as string);
      }, false);
      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
      }


      const isValid = () => {
          return phones.length > 0 && phones.every(x => Helper.Validators.IsValidUSPhone(x));
      }
      

    return(
        <div className="flex-1-container">
            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex align-items-center justify-content-start">
                        <p className="m-0 font-16 font-medium mr-3">Order #{order_id}</p>
                        <Status statusName={loadingOrder ? 'Loading...' : (order.destination.completed ? 'COMPLETED' : (order.origin.completed ? 'IN TRANSIT' : (order.driver?.id ? 'DISPATCHED' : 'BOOKED')))}/>
                    </div>
                    <img src={ICONS.IconCloseBig} alt="close" className="cursor-pointer" onClick={onClose}/>
                </div>
            </div>

            {!loadingOrder && <div className="flex-1-container">
            <div className="row">
                <div className="col-12 mb-4">
                    <p className="font-12 font-medium mb-3">Requested by</p>
                    <div className="card bg-gray">
                        <div className="card-body p-3">
                            <p className="font-14 font-medium">{order.broker?.name}</p>
                            {/* <p className="font-12">000-000-0000</p>
                            <p className="font-12">longlongemail@emaildomain.com</p> */}
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <p className="font-12 font-medium mb-3">Driver</p>
                    <div className="card bg-gray">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="font-14 font-medium">{order.driver?.name || '-'}</p>
                                    {/* <p className="font-12">000-000-0000</p>
                                    <p className="font-12">longlongemail@emaildomain.com</p> */}
                                </div>

                                <button className="btn btn-blue-light font-10 px-4" onClick={() => onChangeDriver({id: order_id, price: order.price, driver_pay: Helper.FORMAT.USCurrency(order.driver_pay)})}>CHANGE</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <p className="font-12 font-medium mb-3">Vehicle</p>
                    <div className="card bg-gray">
                        <div className="card-body p-3">
                            <p className="font-14 font-medium">{order.vehicle.getName()}</p>
                            <div className="d-flex align-items-center justify-content-between">
                                <p className="font-12">{order.vehicle.vin || '-'}</p>
                                {/* <p className="font-12 font-medium">Runs</p> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <p className="font-12 font-medium mb-3">Locations</p>
                    <div className="card">
                        {mapIMG && <img src={mapIMG} alt="map" className='rounded' />}
                        {mapIMG == '' && <Loading />}
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="font-12 font-medium">Origin</p>
                        <button onClick={(evt) => handleAction(order.origin, true)} className="btn btn-blue-light font-10 px-4">{order.origin.completed ? 'VIEW INSPECTION' : 'SEND INSPECTION LINK'}</button>
                    </div>

                    <div className="card bg-gray">
                        <div className="card-body">
                            <p className="font-10 font-medium text-gray mb-1">Name & address</p>
                            {/* <p className="font-14 font-medium">Auto Service Center Beverly Hills</p> */}
                            <div className="font-12" dangerouslySetInnerHTML={{__html: order.getOrigin()}}></div>
                            {/* <p className="font-12 mb-4">Beverly Hills, CA 90000</p> */}

                            <p className="font-10 font-medium text-gray mb-1">Contact</p>
                            <p className="font-14 font-medium">{order.origin.contacts[0]?.name}</p>
                            {order.origin.contacts[0]?.value.map(x => <p className="font-12">{x}</p>)}
                            {/* {order.origin.contacts.length > 1  && <p className="font-12">+{order.origin.contacts.length - 1}</p>} */}
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <p className="font-12 font-medium">Destination</p>
                        <button onClick={(evt) => handleAction(order.destination, false)} className="btn btn-blue-light font-10 px-4">{order.destination.completed ? 'VIEW INSPECTION' : 'SEND INSPECTION LINK'}</button>
                    </div>

                    <div className="card bg-gray">
                        <div className="card-body">
                            <p className="font-10 font-medium text-gray mb-1">Name & address</p>
                            {/* <p className="font-14 font-medium">Auto Service Center Beverly Hills</p> */}
                            <div className="font-12" dangerouslySetInnerHTML={{__html: order.getDestination()}}></div>
                            {/* <p className="font-12 mb-4">Beverly Hills, CA 90000</p> */}

                            <p className="font-10 font-medium text-gray mb-1">Contact</p>
                            <p className="font-14 font-medium">{order.destination.contacts[0]?.name}</p>
                            {order.destination.contacts[0]?.value.map(x => <p className="font-12">{x}</p>)}
                            {/* {order.origin.contacts.length > 1  && <p className="font-12">+{order.origin.contacts.length - 1}</p>} */}
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <p className="font-12 font-medium mb-3">For carrier</p>
                    <div className="card bg-gray">
                        <div className="card-body">
                            <p className="font-10 font-medium text-gray">Carrier pay</p>
                            <p className="font-14 font-medium mb-4">{order.price.toCurrency()}</p>

                            {/* <p className="font-10 font-medium text-gray">Carrier Payment Terms</p>
                            <p className="font-14 font-medium">15 days after signed BOL and invoice received</p> */}
                        </div>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <p className="font-12 font-medium mb-3">Notes</p>
                    <div className="card bg-gray">
                        <div className="card-body">
                            <p className="font-12">{order.broker_note}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>}

            {loadingOrder && <Loading />}


        </div>
    );
}

export default JobsLegDetails;