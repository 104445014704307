import React,{useState,useRef,useEffect} from "react";
import FormGroup from "../../../../components/FormGroup/FormGroup";
import './Camera.scss'


const Camera=({onSetImage,onCancel,formOdometer=false})=>{
    const[hasPhoto,setHasPhoto]=useState(false  )
    const [imgPart,setImgPart]=useState([])
    const [open,setOpen]=useState(false)
    const videoref = useRef<any>(null);
    const photoref =useRef(null)
    const[valueOdometer,setValueOdometer]=useState('')

    

    const getVideo =()=>{
        navigator.mediaDevices.getUserMedia({
            video :{ 
                facingMode: { ideal: 'environment' },
                width: { ideal: 4096 },
                height: { ideal: 2160 } 
            }
        }).then(stream => {
            let video = videoref.current;
            video.srcObject = stream;
            video.play()
        })
        .catch(err =>{
            console.log(err)
        })
    }

    const takePhoto= ()=>{
        const width = 500
        const height = 500
        let video = videoref.current;
        let photo = photoref.current

        photo.height = height
        photo.width = width

        let ctx = photo.getContext('2d');
        //ctx.fillStyle = "blue";
        //ctx.fillRect(0, 0, width, height);
        ctx.drawImage(video,0,0,width,height);

        let image = new Image();
        image.src = photo.toDataURL();

        //let hola= photo.toDataUrl('image/jpeg', 0.5)
        setHasPhoto(true)
        console.log(image.src)
        setImgPart( imgPart =>[...imgPart,image.src])
   
    }


    const handleClose =()=>{
        let localstream
        setHasPhoto(false)
        //videoref.current.stop()
        videoref.current.pause();
        videoref.current.src = "";
        videoref.current.srcObject.getTracks()[0].stop();
        onCancel()
    
    }


    const handleSubmitImage=()=>{
      
        console.log(imgPart)
        onSetImage(imgPart[0],valueOdometer)
        handleClose()

    }



     useEffect(()=>{
         
        getVideo()
      
    },[videoref])

    return(
        <>
            <div className="w-100 h-100" style={{height:"100%"}}>
               <div className="position-relative  h-100 p-0">
                   <div className={`position-absolute w-100 h-100 d-flex p-0 ${hasPhoto?"d-none":""}`}>
                   <video ref={videoref} ></video>
                   <button className="btn-camera" onClick={()=>takePhoto()} ></button>
                   </div>

                  
          

                <div className={`result h-100 ${hasPhoto?'':'d-none'}`}>
                    <canvas ref={photoref}></canvas>
                    <div className="d-flex barbottom">
                        <div onClick={()=>handleClose()} className="col-2 ml-3 cursor-pointer">X</div>
                        <div onClick={()=>setHasPhoto(false)} className="cursor-pointer col-5">RETAKE</div>
                        <div onClick={()=>{
                            //formOdometer ?setOpen(true):
                            handleSubmitImage()
                            
                            }} className="cursor-pointer col-5">USE THIS PHOTO</div>
                    </div>


                    {/*
                        open && <div className={'container-odometer'}>
                            <FormGroup  name={'odometer*'}
                                    value={valueOdometer}
                                    required={false}
                                    label={'Enter the odometer reading'}
                                    className={'mt-3'}
                                    onTextChange={value => setValueOdometer(value)}/>


                            <div className={'w-100 '}>
                                <button 
                                disabled={valueOdometer === '' && true} 
                                className={"btn w-100 py-2 btn-blue-light rounded-pill fs-12 mt-4 font-medium-i"}
                                onClick={(e)=>{
                                    handleSubmitImage()
                                    handleClose()
                                }}
                                >SAVE</button>
                            </div>
                        </div>
                    */}
                </div>

               
               </div>

            

            </div>
        </>
    )
}
export default Camera