import React from 'react';

export const Loading = (props) => {
    const size = props.size || '24px';
    return (
        <div className="loading-component">
            <img style={{display: 'block', margin: '1rem auto', width: size, height: size}} src="https://media0.giphy.com/media/l4FGKbWgkhHVGXzTW/giphy.gif" alt=""/>
        </div>
    )
}

export default Loading;