import {gql} from "apollo-boost";

const GET_BY_ID = gql`
    query($inspection_id: Int){
        get_inspection_by_id(inspection_id: $inspection_id){
            id
            performed_by
            signed_by
            device_type
            additional_emails
            time
            note
            odometer
            vehicle{
                id
                vin
                year
                make
                model
                submodel
                type
                no_vin
                special_vin
                incomplete_vin
                color
                license_plate
                internal_note
                time_created
            }
            accessories{
                name
                count
            }
            damages{
                stop_id
                point{
                    x
                    y
                }
                code
                uid
                is_pickup
            }
            stop{
                nofail
                address{
                    city
                    state
                    street
                    country
                    postal_code
                    street_number
                    postal_code_suffix
                }
                contacts{
                    name
                    value
                }
                scheduled
                time_completed
                completed
                stop_inspection{
                    origin_id
                    destination_id
                    driver_name
                    driver_signature
                    contact_name
                }
            }
            flags{
                too_dirty
                too_dark
                raining
            }
            photos{
                type
                uid
            }
        }
    
    }
    

`;

const GET_BY_TOKEN = gql`
    query($token:String){
        get_inspections_by_token(token:$token){
            id
            performed_by
            driver {
                name
                carrier {
                    name
                }
            }
            signed_by
            device_type
            additional_emails
            driver_signature
            contact_signature
            time
            note
            odometer
            vehicle{
                id
                vin
                year
                make
                model
                submodel
                type
                no_vin
                special_vin
                incomplete_vin
                color
                license_plate
                internal_note
                time_created
            }
            accessories{
                name
                count
            }
            damages{
                stop_id
                point{
                    x
                    y
                }
                code
                uid
                is_pickup
            }
            stop{
                nofail
                address{
                    city
                    state
                    street
                    country
                    postal_code
                    street_number
                    postal_code_suffix
                }
                contacts{
                    name
                    value
                }
                scheduled
                time_completed
                completed
                id
                is_pickup
            }
            flags{
                too_dirty
                too_dark
                raining
            }
            gps{
                Timestamp
                Coordinate{
                    Latitude
                    Longitude
                }
            }
            photos{
                type
                uid
            }
        }

    }
`;

const InspectionQueries = {
    GET_BY_ID,
    GET_BY_TOKEN
}

export default InspectionQueries;