import {gql} from "graphql-tag";

/**
    get carrier settings data
 */

const CARRIER_SETTINGS_DATA = gql`query {
  get_carrier_by_id {
    id
    name
    code
    internal_note
    contact {
      name
      email
      phone
    }
    payment_term {
      carrier_id
      broker_id
      terms
    }

    carrier_registration {
      mc_number
      uploaded_documents {
        uid
        name
      }
    }
    carrier_insurance {
      name
      policy_number
      agent {
        name
        phone
        email
        fax
      }
      liability_coverage
      damage_coverage
      deductible
      expiration
      uploaded_documents {
        uid
        name
      }
    }
    drivers {
      id
      name
      username
      equipment
      photos {
        uid
        name
      }
      contacts {
        title
        type
        value
      }
    }
  }
}`;

  const CarrierQueries = {
      CARRIER_SETTINGS_DATA
  }

  export default CarrierQueries;
