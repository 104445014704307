import React, {useEffect, useState} from "react";
import OrderRequestMobileDetail from "./OrderRequestMobileDetail";
import gql from "graphql-tag";
import {GraphqlService} from "../../services/graphql.service";
import Helper from "../../helper/Helper";

const OrderRequestMobile = ({
                                OrderStatuses,
                                statusesOptions,
                                orderRequestsPending,
                                orderRequestsCancelled,
                                orderRequestsRejected,
                                orderRequestsExpired
                            }) => {

    const [order, setOrder] = useState(undefined);
    const [orderStatus, setOrderStatus] = useState(OrderStatuses.PENDING);

    const getCount = (key = '') => {
        try {
            const keys = {
                'PENDING': orderRequestsPending,
                'CANCELLED': orderRequestsCancelled,
                'REJECTED': orderRequestsRejected,
                'EXPIRED': orderRequestsExpired,
            }
            return keys[key].data.length;
        } catch (ex) {
            return 0;
        }
    }

    async function getOrderLink(orderID) {
        const query = gql`
            query($order_id:Int){
                get_order_redirect_link(order_id: $order_id)
            }
        `;

        try {
            const t = await GraphqlService.SendQuery(query, {order_id: orderID});
            console.log(t);
            window.location.href = t;
        } catch (ex) {
            console.log(ex.message);
        }
    }

    useEffect(() => {
        if (order && order.id) {
            getOrderLink(order.id).then();
        }
        return () => {
        };
    }, [order]);


    // if (order != undefined)
    //     return <OrderRequestMobileDetail onBack={() => setOrder(undefined)} />

    return (
        <>
            <div className="col-12 bg-white text-center">
                <p className="font-14 font-medium pb-3">Order request</p>
            </div>

            <div className="col-12 bg-white d-flex align-items-center justify-content-evenly">
                {
                    statusesOptions.map((e, index) => {
                        return <p key={index}
                                  className={`d-flex m-0 pb-2 px-3 font-10 font-medium order-item ${e.value === orderStatus ? 'item-selected' : ''}`}
                                  onClick={() => setOrderStatus(e.value)}>{e.label}
                            <div className="number">{getCount(e.key)}</div>
                        </p>
                    })
                }
            </div>

            <div className="col-12 flex-1-container mt-3">
                {
                    orderStatus == OrderStatuses.PENDING && orderRequestsPending.data.map((order, key) => {
                        return (
                            <>
                                <div key={`px${key}`}  className="card mb-3 cursor-pointer" onClick={() => setOrder(order)}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                <p className="mb-1 font-10 font-medium text-gray">{Helper.FORMAT.USDate(order.origin.scheduled)}</p>
                                                <p className="mb-1 font-10 font-medium text-gray">{Helper.FORMAT.USDate(order.destination.scheduled)}</p>
                                            </div>

                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                <p className="mb-0 font-12 font-medium">{order.origin.address.city}</p>
                                                <p className="mb-0 font-12 font-medium">{order.destination.address.city}</p>
                                            </div>

                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                <p className="mb-3 font-12">{order.origin.address.state} {order.origin.address.postal_code}</p>
                                                <p className="mb-3 font-12">{order.destination.address.state} {order.destination.address.postal_code}</p>
                                            </div>

                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                <p className="mb-3 font-12 font-medium">{order.vehicle.year} {order.vehicle.make} {order.vehicle.model}</p>
                                                <p className="mb-3 font-12 font-medium">{order.price.toCurrency()}</p>
                                            </div>

                                            <hr/>

                                            <div className="col-12 d-flex align-items-center justify-content-between">
                                                <p className="mb-0 font-12 font-medium">R-{order.id}</p>
                                                {/*<p className="mb-0 font-12 font-medium"></p>*/}
                                            </div>
                                        </div>
                                        {/*<div className="row">*/}
                                        {/*    <div className="col-12 d-flex align-items-center justify-content-between">*/}
                                        {/*        <p className="font-12 font-medium">{order.vehicle.year} {order.vehicle.make} {order.vehicle.model}</p>*/}
                                        {/*        <p className="font-12 font-medium">{order.price.toCurrency()}</p>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </>
                        );
                    })
                }
                {
                    orderStatus == OrderStatuses.EXPIRED && orderRequestsExpired.data.map((order, key) => {
                        return (
                            <div key={key} className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="font-12 font-medium">{order.vehicle.year} {order.vehicle.make} {order.vehicle.model}</p>
                                            <p className="font-12 font-medium">{order.price.toCurrency()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {
                    orderStatus == OrderStatuses.CANCELLED && orderRequestsCancelled.data.map((order, key) => {
                        return (
                            <div key={key} className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="font-12 font-medium">{order.vehicle.year} {order.vehicle.make} {order.vehicle.model}</p>
                                            <p className="font-12 font-medium">{order.price.toCurrency()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
                {
                    orderStatus == OrderStatuses.REJECTED && orderRequestsRejected.data.map((order, key) => {
                        return (
                            <div key={key} className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <p className="font-12 font-medium">{order.vehicle.year} {order.vehicle.make} {order.vehicle.model}</p>
                                            <p className="font-12 font-medium">{order.price.toCurrency()}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
}

export default OrderRequestMobile;
