import {BaseLayout} from "../../layout/BaseLayout/BaseLayout";
import React from "react";
import ICONS from "../../../assets/svg";

export interface ICarrierRegistrationPageProps {
    carrier: {
        id: number;
        name: string;
        code: string;
        internal_note: string;
        active: boolean;
        multi_carrier: boolean;
    }
}

export const CarrierRegistrationPage = (props: ICarrierRegistrationPageProps) => {

    return (
        <>
            <BaseLayout>
                <div className='w-100 content-flex-properties'>
                    <div className="row title-section">
                        <div className="col">
                            Create your account
                        </div>
                    </div>

                    {/*main content*/}
                    <main className={`row velox-form properties-scroll-auto`}>

                        {/*Left side*/}
                        <div className="col-12 col-md-6 scroll-height-auto">
                            <div className="row form-title">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <span className={`subtitle-section`}>Company</span>
                                    <span className={`clear-all`}>Clear All</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div>
                                        <label htmlFor="companyName" className="form-label">
                                            Company name*
                                        </label>
                                        <input type="text" className="form-control" id="companyName"
                                               placeholder="Company Name"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="streetAddress" className="form-label">
                                            Street address*
                                        </label>
                                        <input type="text" className="form-control" id="streetAddress"
                                               placeholder="100 Main St"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="city" className="form-label">
                                            City
                                        </label>
                                        <input type="text" className="form-control" id="city"
                                               placeholder="City"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="">
                                        <label htmlFor="state" className="form-label">
                                            State
                                        </label>
                                        <select name="state" id="state" className="form-control">
                                            <option value="CA">CA</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="">
                                        <label htmlFor="zipCode" className="form-label">
                                            Zip code*
                                        </label>
                                        <input type="text" className="form-control" id="zipCode"
                                               placeholder="00000"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*Right side*/}
                        <div className="col-12 col-md-6 scroll-height-auto">
                            <div className="row form-title">
                                <div className="col-12 d-flex justify-content-between align-items-center">
                                    <span className={`subtitle-section`}>Account holder Info</span>
                                    <span className={`clear-all`}>Clear All</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="adminName" className="form-label">Admin name*</label>
                                        <input type="text" className="form-control" id="adminName"
                                               placeholder="Admin Name"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="jobTitle" className="form-label">Job title*</label>
                                        <input type="text" className="form-control" id="jobTitle"
                                               placeholder="Ex) Assistant, dispatcher, etc"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="phone" className="form-label">Phone #*</label>
                                        <input type="text" className="form-control" id="phone"
                                               placeholder="000-000-000"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="">
                                        <label htmlFor="emailAddress" className="form-label">Email
                                            address*</label>
                                        <input type="email" className="form-control" id="emailAddress"
                                               placeholder="abc@xyz.com"/>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="">
                                        <label htmlFor="password" className="form-label">Password*</label>
                                        <input type="password" className="form-control" id="password"
                                               placeholder="Password"/>
                                    </div>
                                    <ul className="password-requirements">
                                        <li><img src={ICONS.IconCircleCheck} alt="icon-circle-check"/> At least 8
                                            characters long
                                        </li>
                                        <li><img src={ICONS.IconCircleCheck} alt="icon-circle-check"/> Any other
                                            requirements show up here
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </main>


                    <div className="col-12 d-flex justify-content-end py-4">
                        <button type="submit"
                                className="btn btn-sm btn-primary-down-opacity btn-regular text-uppercase"
                        >
                            CREATE ACCOUNT
                        </button>
                    </div>
                </div>
            </BaseLayout>
        </>
    )
}
