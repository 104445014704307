import moment from 'moment';
import Helper from '../helper/Helper';
import { Document } from '../pages/CarrierDocumentsPage/CarrierDocumentsPage';

export class Employee {
    id: number = null;
    name: string = '';
    address: Address = new Address();
    type: string = '';
    note: string = '';
    department: string = '';
    phone: string = '';
    email: string = '';
    tax_info: TaxInfo = new TaxInfo();

    constructor(props?: any) {
        if (props) {
            this.name = props.name ?? '';
            this.type = props.type ?? '';
            this.note = props.note ?? '';
            this.department = props.department ?? '';
            this.phone = props.phone ?? '';
            this.email = props.email ?? '';

            this.tax_info = new TaxInfo(props.tax_info);
            this.address = new Address(props.address);
        }
    }
}

class TaxInfo {
    type: string = '';
    value: string = '';

    constructor(props?) {
        if(props){
            this.type = props.type;
            this.value = props.value;
        }
    }
}

export class User {
    id: number = null;
    name: string = '';
    password: string = '';
    level: number = 0;
    employee: Employee = new Employee();

    constructor(x?: User) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.password = x.password;
            this.level = x.level;
            this.employee = new Employee(x.employee);
        }
    }
}

export class OrderRequest {
    id: number = null;
    accepted: boolean = false;
    rejected: boolean = false;
    cancelled: boolean = false;
    expired: boolean = false;
    price: number = 0;
    broker_note = '';
    origin: Destination = new Destination();
    destination: Destination = new Destination();
    vehicle: Vehicle = new Vehicle();
    broker: Broker = new Broker();
    driver_pay: number = 0;


    constructor(x?: OrderRequest) {
        if (x) {
            this.id = x.id;
            this.accepted = x.accepted;
            this.rejected = x.rejected;
            this.cancelled = x.cancelled;
            this.expired = x.expired;
            this.price = x.price;
            this.broker_note = x.broker_note;
            this.origin = new Destination(x.origin);
            this.destination = new Destination(x.destination);
            this.vehicle = new Vehicle(x.vehicle);
            this.broker = new Broker(x.broker);
            this.driver_pay = x.driver_pay;
        }
    }

    getBrokerName?() {
        return this.broker.getFormattedName();
    }

    getBrokerAddress?() {
        return this.broker.getFormattedAddress();
    }

    getFormattedID?() {

        return `O-${this.id}`;
    }

    getDestination?() {
        return `
                <div>${this.destination.address.getName()}</div>
                <div><small>${this.destination.getFormattedDateTime()}</small></div>
        `
    }

    getOrigin?() {
        return `
                <div>${this.origin.address.getName()}</div>
                <div><small>${this.origin.getFormattedDateTime()}</small></div>
        `
    }

    getVehicleName?() {
        return this.vehicle.getName().trim() || '-';
    }

    getDriverPay() {
        return Helper.FORMAT.USCurrency(this.driver_pay);
    }

    getPrice() {
        return [Helper.FORMAT.USCurrency(this.price), `(${Helper.FORMAT.USCurrency(this.driver_pay || 0)})`];
    }
}

export class Carrier
{
    id: number = null;
    name: string = '';

    constructor(props?: Carrier)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
        }
    }
}

export class DriverPhoto {
    uid: string;
    name: string;

    constructor(props?: any) {
        if (props) {
            this.uid = props.uid;
            this.name = props.name;
        }
    }
}

export class Driver
{
    id: number = null;
    name: string = '';
    code: string = '';
    terms: string = '';
    username: string = '';
    equipment?: string = '';
    photos?: DriverPhoto[] = [];
    contacts: Contact[] = [];

    constructor(props?: Driver)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.code = props.code;
            this.terms = props.terms;
            this.username = props.username;
            this.equipment = props.equipment ?? '';
            this.photos = props.photos ? props.photos.map(e => new DriverPhoto(e)) : [];
            this.contacts = props.contacts ? props.contacts.map(e => new Contact(e)) : [];
        }
    }


}

export class Contact
{
    name: string = '';
    value: string[] = [];

    constructor(props?: Contact)
    {
        if (props)
        {
            this.name = props.name;
            this.value = props.value;
        }
    }
}

export class Destination {
    scheduled: string;
    nofail: boolean = false;
    address: Address = new Address();
    time_completed: Date = new Date();
    completed: boolean = false;
    contacts: Contact[] = [];

    constructor(x?: Destination) {
        if (x) {
            this.scheduled = x.scheduled;
            this.address = new Address(x.address);
            this.nofail = x.nofail;
            this.completed = x.completed;
            this.time_completed = x.time_completed;
            if (x.contacts){
                this.contacts = x.contacts.map(c => new Contact(c));
            }
        }
    }

    getFormattedDateTime?(){
        return `${this.getDate()} ${this.getTime()}`
    }

    getDate?() {
        return moment(this.scheduled).format('MM/DD/YYYY');
    }

    getTime?() {
        return moment(this.scheduled).format('hh:mm a');
    }

}


export class Address {
    city: string = '';
    state: string = '';
    street: string = '';
    country: string = '';
    postal_code: string = '';
    street_number: string = '';

    constructor(x?: Address) {
        if (x) {
            this.city = x.city;
            this.state = x.state;
            this.street_number = x.street_number;
            this.street = x.street;
            this.country = x.country;
            this.postal_code = x.postal_code;
        }
    }

    public getName?() {
        return `${this.city}, ${this.state}`;
    }
}


export class FullAddress {
    city: string = '';
    state: string = '';
    street: string = '';
    country: string = '';
    street_number: string = '';
    postal_code: string = '';

    constructor(x?: FullAddress) {
        if (x) {
            this.city = x.city;
            this.state = x.state;
            this.street = x.street;
            this.country = x.country;
            this.street_number = x.street_number;
            this.postal_code = x.postal_code;
        }
    }

    public getName?() {
        return `${this.city}, ${this.state}`;
    }
}


export class Vehicle {
    id: number = null;
    vin: string = '';
    no_vin: boolean = false;
    special_vin: boolean = false;
    incomplete_vin: boolean = false;
    not_running: boolean = false;
    year: string = '';
    make: string = '';
    model: string = '';
    submodel: string = '';
    type: string = '';
    color: string = '';
    license_plate: string = '';
    internal_note: string = '';
    time_created: Date = new Date();
    time: Date = new Date();
    user_id: number = null;

    constructor(x?: Vehicle) {
        if (x) {
            this.id = x.id;
            this.vin = x.vin;
            this.no_vin = x.no_vin;
            this.special_vin = x.special_vin;
            this.incomplete_vin = x.incomplete_vin;
            this.year = x.year;
            this.make = x.make;
            this.model = x.model;
            this.submodel = x.submodel;
            this.type = x.type;
            this.color = x.color;
            this.license_plate = x.license_plate;
            this.internal_note = x.internal_note;
            this.time_created = new Date(x.time_created);
            this.time = new Date(x.time);
            this.user_id = x.user_id;
        }
    }

    getName?() {
        return `${this.year} ${this.make} ${this.model}`
    }

    fillData?(x: Vehicle) {
        this.year = x.year || this.year;
        this.make = x.make || this.make;
        this.model = x.model || this.model;
        this.submodel = x.submodel || this.submodel;
        this.type = x.type || this.type;
    }
}



export class Broker {
    id: number
    name: string
    company?: Company

    constructor(x?: Broker) {
        if (x) {
            this.id = x.id;
            this.name = x.name;
            this.company = new Company(x.company);
        }
    }

    getFormattedName?() {
        return `${this.name}`
    }

    getFormattedAddress?() {
        return `${this.name}`
    }

}


export class Company {
    address: FullAddress

    constructor(x?: Company) {
        if (x) {
            this.address = new FullAddress(x.address)
        }
    }
}
export class ChangeRequest
{
    id: number = null;
    order_id: number = null;
    accepted: boolean = false;
    rejected: boolean = false;
    time: Date = new Date();
    payout: number = 0;
    note: string = '';
    active: boolean = false;
    expired: boolean = false;
    expires_at: Date = new Date();
    weblink: string = '';
    code: string = '';

    constructor(props?: ChangeRequest)
    {
        if (props)
        {
            this.id = props.id;
            this.order_id = props.id;
            this.accepted = props.accepted;
            this.rejected = props.rejected;
            this.time = new Date(props.time);
            this.payout = props.payout;
            this.note = props.note;
            this.active = props.active;
            this.expired = props.expired;
            this.expires_at = new Date(props.expires_at)
            this.weblink = props.weblink;
            this.code = props.code;
        }
    }
}

export class Order extends OrderRequest
{
    driver: Driver = new Driver();
    carrier: Carrier = new Carrier();
    broker: Broker = new Broker();
    status: string = '';
    dispatched = false;
    pending_change_request: boolean = false;
    change_requests: ChangeRequest[] = [];

    driver_name?: string;
    vehicle_name?: string;

    origin_name?: string;
    destination_name?: string;

    constructor(props?: Order)
    {
        super(props);
        if (props)
        {
            this.driver = new Driver(props.driver);
            this.carrier = new Carrier(props.carrier);
            this.broker = new Broker(props.broker);

            this.driver_name = this.driver.name;
            this.vehicle_name = this.vehicle.getName();

            this.origin_name = this.origin.address.getName();
            this.destination_name = this.destination.address.getName();

            this.pending_change_request = props.pending_change_request;
            this.change_requests = props.change_requests?.map(x => new ChangeRequest(x)) || [];
            if (this.driver.id)
                this.dispatched = true;

            if (this.pending_change_request)
            {
                const x = this.change_requests.find(x => x.active) || new ChangeRequest();

                if (x.expired)
                    this.status = 'EXPIRED';
                else if (x.rejected)
                    this.status = 'REJECTED';
                else
                    this.status = 'PENDING';
            }

            // else if ((!this.origin.completed && new Date() > new Date(this.origin.scheduled)) || (!this.destination.completed && new Date() > new Date(this.destination.scheduled)))
            //     this.status = 'LATE';
            // else if (!this.origin.completed && new Date() > new Date(this.origin.scheduled))
            //     this.status = 'LATE';
            // else if (!this.destination.completed && new Date() > new Date(this.destination.scheduled))
            //     this.status = 'LATE DELIVERY';
            else if (this.origin.completed && this.destination.completed)
                this.status = 'COMPLETED';
            else if (this.origin.completed)
                this.status = 'IN TRANSIT';
            else if (this.driver.id)
                this.status = 'DISPATCHED';
            else
                this.status = 'BOOKED';
        }
    }

    getDriver?()
    {
        return this.driver.name || '-';
    }

    getBrokerName?()
    {
        return this.broker.name;
    }
}


class Coord {
    Latitude: number;
    Longitude: number;

    constructor(props) {
        this.Latitude = props.Latitude;
        this.Longitude = props.Longitude;
    }
}


class Device {
    time: string;
    speed: string;
    course: string;
    device: string;
    altitude: string;
    coordinate: Coord;
    location: Coord;
    background_mode: boolean = false;

    constructor(props) {
        this.time = props.time;
        this.speed = props.speed;
        this.course = props.course;
        this.altitude = props.altitude;
        this.coordinate = new Coord(props.coordinate);
        this.location = new Coord(props.coordinate);
        this.background_mode = props.background_mode;
    }
}

export class DriverLocation {
    id: number
    name: string
    devices: Device[];
    username: string;

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.username = props.username;
        this.devices = props.devices.map((x) => new Device(x));
    }
}

export class QuoteVehicleInfo {
    make: string = '';
    model: string = '';
    year: string = '';
    vin: string = '';
    equipment: string = '';
    price: number = 0;
    nofail_pickup_price: number = 0;
    nofail_dropoff_price: number = 0;

    constructor(x?: QuoteVehicleInfo) {
        if (x) {
            this.make = x.make;
            this.model = x.model;
            this.year = x.year;
            this.vin = x.vin;
            this.equipment = x.equipment;
            this.price = x.price;
            this.nofail_pickup_price = x.nofail_pickup_price;
            this.nofail_dropoff_price = x.nofail_dropoff_price;
        }
    }
}



export class Flag
{
    too_dirty:  boolean = false;
    too_dark: boolean = false;
    raining: boolean = false;

    constructor(props?: Flag)
    {
        if (props)
        {
            this.too_dirty = props.too_dirty;
            this.too_dark = props.too_dark;
            this.raining = props.raining;
        }
    }
}

export class Signature
{
    time: Date = new Date();
    name: string = '';
    uid: string = '';
    user_id: number = null;

    constructor(props?: Signature)
    {
        if (props)
        {
            this.time = new Date(props.time);
            this.name = props.name;
            this.uid = props.uid;
            this.user_id = props.user_id;
        }
    }
}

export class RequiredPhoto
{
    uid: string = '';
    type: string = '';
    user_id: string  = '';

    constructor(props?: RequiredPhoto)
    {
        if (props)
        {
            this.uid = props.uid;
            this.type = props.type;
            this.user_id = props.user_id;
        }
    }
}

export class Point
{
    x: number = 0;
    y: number =  0;

    constructor(props?: Point) {
        if (props)
        {
            this.x = props.x;
            this.y = props.y;
        }
    }
}

export class Damage
{
    point: Point = new Point();
    code: string = '';
    uid: string = '';
    stop_id: number = null;
    is_pickup: boolean = null;

    constructor(props?: Damage)
    {
        if (props)
        {
            this.point = new Point(props.point);
            this.code = props.code;
            this.uid = props.uid;
            this.stop_id = props.stop_id;
            this.is_pickup = props.is_pickup;
        }
    }
}

export class Accessorie
{
    name: string = '';
    count: number = 0;
    user_id: number = null;

    constructor(props?: Accessorie)
    {
        if (props)
        {
            this.name = props.name;
            this.count = props.count;
            this.user_id = props.user_id;
        }
    }
}


export class PendingInspection
{
    id: number = null;
    pending_inspection_id: number = null;
    note: string = '';
    flags: Flag[] = [];
    odometer: number = 0;
    performed_by: string = '';
    signed_by: string = '';
    time: Date = new Date();
    vehicle_data: Vehicle = new Vehicle();
    photos: RequiredPhoto[] = [];
    damages: Damage[] = [];
    accessories: Accessorie[] = [];
    is_origin: boolean = false;
    stop_id: number = null;
    is_pickup: boolean = false;
    additional_emails: string[] = [];
    driver_name: string = '';

    constructor(props?: PendingInspection) {
        if (props)
        {
            this.id = props.id;
            this.pending_inspection_id = (props as any).pending_origin_inspection_id || (props as any).pending_destination_inspection_id;
            this.stop_id = (props as any).origin_id || (props as any).destination_id;
            this.is_pickup = (props as any).origin_id != null;
            this.note = props.note;
            this.flags = props.flags.map(x => new Flag(x));
            this.odometer = props.odometer;
            this.performed_by = props.performed_by;
            this.signed_by = props.signed_by;
            this.vehicle_data = new Vehicle(props.vehicle_data);
            this.photos = props.photos.map(photo => new RequiredPhoto(photo));
            this.damages = props.damages.map(d => new Damage({...d, is_pickup: this.is_pickup}));
            this.accessories = props.accessories.map(a => new Accessorie(a));
            this.is_origin = props.is_origin;
            this.additional_emails = props.additional_emails ? [...props.additional_emails] : [];
            this.driver_name = props.driver_name || '';
        }
    }
}



export class CarrierContact
{
    name: string = '';
    phone: string = '';
    email: string = '';
    fax?: string = '';

    constructor(props?: CarrierContact)
    {
        if (props)
        {
            this.name = props.name;
            this.phone = props.phone;
            this.email = props.email;
            this.fax = props.fax || '';
        }
    }
}

export class CarrierRegistration
{
    mc_number: string = '';
    dot_number: string = '';
    uploaded_documents: Document[] = [];

    constructor(props?: CarrierRegistration)
    {
        if (props)
        {
            this.mc_number = props.mc_number || '';
            this.dot_number = props.dot_number || '';
            this.uploaded_documents = props.uploaded_documents?.map(x => new Document(x)) || [];
        }
    }
}

export class CarrierInsurance {
    name: string = '';
    policy_number: string = '';
    agent: CarrierContact = new CarrierContact();
    liability_coverage: number = 0;
    damage_coverage: number = 0;
    deductible: number = 0;
    expiration: Date;
    uploaded_documents: Document[] = [];

    constructor(props?: CarrierInsurance)
    {
        if (props)
        {
            this.name = props.name;
            this.policy_number = props.policy_number;
            this.agent = new CarrierContact(props.agent);
            this.liability_coverage = +props.liability_coverage;
            this.damage_coverage = +props.damage_coverage;
            this.deductible = +props.deductible;
            if (props.expiration)
                this.expiration = new Date(props.expiration);
            this.uploaded_documents = props.uploaded_documents?.map(x => new Document(x)) || [];
        }
    }
}

export class CarrierMain
{
    id: number;
    name: string = '';
    code: string = '';
    liability_disclaimer: string = '';
    internal_note: string = '';
    contact: CarrierContact = new CarrierContact();
    carrier_insurance: CarrierInsurance = new CarrierInsurance();
    carrier_registration: CarrierRegistration = new CarrierRegistration();
    drivers: any[] = [];


    constructor(props?: CarrierMain)
    {
        if (props)
        {
            this.id = props.id;
            this.name = props.name;
            this.code = props.code;
            this.liability_disclaimer = props.liability_disclaimer;
            this.internal_note = props.internal_note;
            this.contact = new CarrierContact(props.contact);
            this.carrier_insurance = new CarrierInsurance(props.carrier_insurance);
            this.carrier_registration = new CarrierRegistration(props.carrier_registration);
            this.drivers = props.drivers?.map(x => ({...x})) || [];
        }
    }
}
