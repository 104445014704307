import { gql } from 'apollo-boost';
import React, { FC, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Address, OrderRequest } from '../../classes/classes';
import CardServiceCarrier from '../../components/CardServiceCarrier/CardServiceCarrier';
import CarrierServiceRequestsDetails from '../../components/CarrierServiceRequestsDetails/CarrierServiceRequestsDetails';
import CarrierServiceRequestsSign from '../../components/CarrierServiceRequestsSign/CarrierServiceRequestsDetails';
import FormGroup from '../../components/FormGroup/FormGroup';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import Status from '../../components/Status/Status';
import Helper from '../../helper/Helper';
import useScreenDimension from '../../hooks/ScreenDimension/ScreenDimension';
import useLoading from '../../hooks/useLoading/useLoading';
import useMessageBox from '../../hooks/useMessageBox/useMessageBox';
import getOrderRequestByStatus from '../../redux/actions/OrderRequests.action';
import { GraphqlService } from '../../services/graphql.service';
import ConfirmationForm from '../PendingInspectionPage/components/ConfirmationForm/ConfirmationForm';
import DriverSelector from './DriverSelector/DriverSelector';

// let or = new OrderRequest();



const query  = gql`
    query($token: String){
        order_by_token(token: $token){
            id
            price
            completed
            accepted
            cancelled
            expired
            broker_note
            rejected
            expires
            driver_pay
            remaining_driver_payable
            remaining_carrier_receivable
            pending_change_request
            change_requests{
                id
                order_id
                accepted
                rejected
                time
                payout
                note
                active
                expired
                expires_at
                weblink
                code
            }
            vehicle{
                id
                vin
                year
                make
                model
                submodel
                type
                no_vin
                incomplete_vin
                color
                license_plate
                internal_note
                time_created
            }
            driver {
                id
                name
                contacts{
                    title
                    type
                    value
                }
            }
            broker {
                id
                name
            }
            carrier {
                id
                name
                contact{
                    name
                    email
                    phone
                }
            }
            origin{
                scheduled
                time_completed
                completed
                nofail
                address{
                    street_number
                    street
                    city
                    state
                    postal_code
                    postal_code_suffix
                    country
                }
                contacts{
                    name
                    value
                }
            }
            destination{
                scheduled
                time_completed
                completed
                nofail
                address{
                    street_number
                    street
                    city
                    state
                    postal_code
                    postal_code_suffix
                    country
                }
                contacts{
                    name
                    value
                }
            }
        }
}

`;

enum ORScreen {
    DETAILS,
    SIGN
}


const OrderRequestPage : FC<any> = (props) => {

    const {height} = useScreenDimension();

    const [currentScreen, setCurrentScreen] = useState(ORScreen.DETAILS);
    const _loading = useLoading();
    const [visible, setVisible] = useState(0);
    const [loading, setLoading] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [name, setName] = useState('');
    const [loadingData, setLoadingData] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const {token} = useParams<any>();
    const toast = useToasts();
    const [driver, setDriver] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [driverPay, setDriverPay] = useState('0');
    const messageBox = useMessageBox();
    const [driverPickerOpened, setDriverPickerOpened] = useState(false);
    const [carrierID, setCarrierID] = useState(null);

    async function onSubmitRequest()
    {
        setLoading(true);
        const mutation = visible == 1 ? gql`
                    mutation($order_id: Int, $name: String){
            accept_order_request(order_id:$order_id, name:$name){
                    id
                    message
                    success
                }
                }
        ` : gql`
                    mutation($order_id: Int){
                        reject_order_request(order_id:$order_id){
                            id
                            message
                            success
                        }
                    }
        `;

        const variables: any = {
            order_id: itemSelected[0]?.id
        }

        if (visible == 1)
            variables.name = name;
        try {
            Helper.UseApiKey();
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data.success)
                throw new Error(data.message);

            setItemSelected([]);

            setLoading(false);
            setVisible(0);

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            setCurrentScreen(ORScreen.DETAILS);

            load();

        } catch (ex) {
            setLoading(false);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }



    async function load() {
        try {
            setLoadingData(true);
            Helper.UseApiKey();
            const data = await GraphqlService.SendQuery(query, {token});
            // if (data.accepted && data.driver?.id)
            // {
            //     setLoadingData(false);
            //     return Helper.Navigation.NavigateTo(`/dispatch`);
            // }
            setItemSelected([new OrderRequest(data)]);
            // loadDrivers(data?.carrier?.id);
            setCarrierID(data?.carrier?.id);
            if (data.accepted && !data.driver?.id)
                messageBox.open({
                    title: 'Select Driver',
                    message: `You need to dispatch this order`,
                    buttons: [ {
                        text: 'OK',
                        css: 'btn btn-blue-light'
                    }]
                })
            setLoadingData(false);
        } catch (ex) {
            setLoadingData(false);
            setErrorMsg(ex.message?.replace('GraphQL error: ', '') || 'Something went wrong');
        }
    }


    async function assignDriver() {
        const mutation = gql` mutation($order_id: Int, $driver_id: Int, $driver_pay: Float){
            assign_driver(order_id: $order_id, driver_id: $driver_id, driver_pay: $driver_pay){
                message
                success
            }
        }`;

        try {
            _loading.open('Assigning Driver...')
            Helper.UseApiKey();
            const data = await GraphqlService.SendMutation(mutation, {order_id: itemSelected[0]?.id, driver_id: driver?.id, driver_pay: +driverPay});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');

            _loading.close();
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
            load();
        } catch (ex) {
            _loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    useEffect(() => {
        load();
    }, [token]);

    const getStatus = () => {
        const item = itemSelected[0];
        if (!item)
            return '';

        const {accepted, cancelled, expired, rejected} = item as OrderRequest;

        if (accepted)
            return 'ACCEPTED';

        if (cancelled)
            return 'CANCELLED';

        if (expired)
            return 'EXPIRED';

        if (rejected)
            return 'REJECTED';

        return 'PENDING';
    }


    return <div className='flex-1-container py-3 mx-auto' style={{height: `${height}px`, width: '90%', maxWidth: '600px'}}>
        <div className="row mb-3">
            <h4 className="font-16">Order Request #{itemSelected[0]?.id}</h4>
            {getStatus() != '' && <Status statusName={getStatus()} />}
        </div>
        {(!itemSelected[0]?.driver_id && itemSelected[0]?.accepted) && <div className="mb-3 bg-white p-3">
            <div className="row mb-2">
            <h4 className="font-14">
                Dispatch Order To Driver
            </h4>
            {driver != null && <button className="btn btn-danger-light" onClick={evt => setDriver(null)}>REMOVE</button>}
            </div>

            <button onClick={evt => setDriverPickerOpened(true)} className={`btn btn-${driver?.id ? 'orange-light' : 'danger-light'} col-12 mb-2`}>{driver?.name || '-- Select Driver --'}</button>
            {/* <FormGroup
            required
            label='Driver' name='driver' type='select' placeholder='-- Select --'
            value={(driver?.id || '').toString()}
            onTextChange={(id) => setDriver(drivers.find(x => x.id== id))}
            options={{data: drivers, label: x=>x?.name, value: x=>x?.id}} /> */}

            <FormGroup name='driver_pay' label='Driver Payout' placeholder='0.00' moneySymbol value={driverPay} onTextChange={text => setDriverPay(Helper.Masks.DecimalNumbers(text))} />

            <div className="d-flex justify-content-end">
                <button onClick={evt => assignDriver()} disabled={!driver?.id} className="btn btn-danger-light col-6">DISPATCH TO DRIVER</button>
            </div>
       </div>}

       <div className="flex-1-container bg-white p-3">
            {(!loadingData && itemSelected.length > 0 && !errorMsg) && <>
                {ORScreen.DETAILS == currentScreen && <CarrierServiceRequestsDetails
                assignDriver={itemSelected[0]?.accepted}
            data={itemSelected}
            handleClickToggle={() => {
                setCurrentScreen(ORScreen.SIGN)
            }}
            handleClickDecline={() => {

                setVisible(2);
            }} />}

        {ORScreen.SIGN == currentScreen && <CarrierServiceRequestsSign
        order_id={itemSelected[0]?.id}
                            handleClickBackDetails={()=>setCurrentScreen(ORScreen.DETAILS)}
                            handleClickAccept={(t)=> {
                                setName(t);

                                setVisible(1);

                            }}
                            handleClickDecline={()=> {

                                setVisible(2);
                            }}/>}
            </>}

            {loadingData && <div>
                <Loading />
                <h4 className="font-14 text-center">Loading...</h4>
            </div>}

            {(!loadingData && errorMsg) && <div>
                <h4 className="font-16 text-red">Error: {errorMsg}</h4>
            </div>}

       </div>


       <Modal visiblePopUpModal={visible != 0}
                   handleClickOutPopupModal={()=>false}
            >


                <div style={{background: '#FFF', borderRadius: '1rem', padding: '1.5rem', minWidth: '300px'}}>
                    <h4 className='font-24 mb-3'>Confirmation</h4>

                    {!loading && <h5 className='font-16' style={{fontWeight: 'normal'}}>Do you want to <span className={`text-${visible == 1 ? 'blue' : 'red'} fw-bold`}>{visible == 1 ? 'ACCEPT' : 'DECLINE'}</span> this order request #{itemSelected[0]?.id}?</h5>}


                    {loading && <div>
                        <Loading />
                        <h6 className='font-14 text-center'>ACCEPTING ORDER REQUEST #{itemSelected[0]?.id}...</h6>
                    </div>}

                    <div className="row mt-4">
                        <div className="col-6">
                            <button disabled={loading} className="btn btn-danger w-100" onClick={(evt) => setVisible(0)}>CANCEL</button>
                        </div>

                        <div className="col-6">
                            <button disabled={loading} className="btn btn-primary-dark w-100" onClick={(evt) => onSubmitRequest()}>CONFIRM</button>
                        </div>
                    </div>
                </div>


            </Modal>

            <ReactModal isOpen={driverPickerOpened}>
                    <DriverSelector carrier_id={carrierID} driver_id={driver?.id}
                    onClose={(evt) => {
                        setDriverPickerOpened(false);
                    }}
                    onDriverSelected={(evt) => {
                        setDriver({...evt});
                        setDriverPickerOpened(false);
                    }} />
            </ReactModal>
    </div>
}

export default OrderRequestPage;
