import {gql} from 'apollo-boost';
import { GraphqlService, ObservableQueryT } from '../../../services/graphql.service';

const query = `
    query($token:String){
        get_pending_xxxxx_inspection(token:$token){
            id
            performed_by
            signed_by
            time
            note
            pending_origin_inspection_id
            pending_destination_inspection_id
            origin_id
            destination_id
            flags{
                too_dirty
                too_dark
                raining
            }
            odometer
            vehicle_data{
                id
                vin
                no_vin
                special_vin
                incomplete_vin
                year
                make
                model
                submodel
                type
                color
                license_plate
                internal_note
            }
            driver_name
            driver_signature
            contact_name
            contact_signature
            damages{
            stop_id
            point{
                x
                y
            }
            code
            uid
            is_pickup
            }
            photos{
            type
            uid
            }
            accessories{
            name
            count
            }
        }
        }
`;

export default class InspectionObservable
{
    static GetObservable(token: string, origin: boolean): ObservableQueryT {
        const q = query.replace('xxxxx', origin ? 'origin' : 'destination').replace('yyyyy', origin ? 'origin' : 'destination');
        return GraphqlService.SendQueryObservable(gql`${q}`, {token});
    }
}