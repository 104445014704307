import {mapStyles} from "./map.type";
import {CustomLatLng, CustomOverlayView, DrawPath, gmapConfig} from "./mapTypes";
import {MarkerClass} from "./MarkerClass";
import {RouteClass} from "./RouteClass";

const loadGoogleMapsApi = require('load-google-maps-api');
const GMAP_KEY = 'AIzaSyCrvUVLjfkK04rzVHVyDHmx6Ry-24qZEVM';
const MAP_ID = 'cec19bbaa71f8ff8';

const defaultConfig: gmapConfig = {
    zoom: 12,
    center: {lat: 34.052235, lng: -118.243683},
    mapType: 'roadmap',
    isDrawable: false,
    elementMapID: 'map',
    mapStyle: 'gray',
    polyColor: "#27AE60",
    disableDefaultUI: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    loadPlaces: false,
    loadDistance: false,
    showTraffic: false,
    onMapInit: () => {
    },
    onMapDraw: () => {
    },
}

export default class GMap {
    private map: google.maps.Map;
    elementMapID = 'map';

    google = null;
    private readonly mapNodeElement: HTMLElement;
    private infoWindow: google.maps.InfoWindow;
    geocoder: google.maps.Geocoder;
    trafficLayer: google.maps.TrafficLayer;
    drawingManager: google.maps.drawing.DrawingManager;
    path: google.maps.Polyline;
    overlay: CustomOverlayView;
    directionsService: google.maps.DirectionsService;

    librariesParams = '';
    styles = mapStyles;

    infoLabels: google.maps.InfoWindow[] = [];
    markersCollection: CustomOverlayView[] = [];
    routesCollection: (google.maps.DirectionsRenderer & RouteClass)[] = [];

    //Animations
    numDeltas = 100;
    delay = 10; //milliseconds
    deltaLat = '';
    deltaLng = '';

    // Draw paths
    coordsCollection = [];

    config: gmapConfig = defaultConfig;

    constructor(config?: gmapConfig) {

        this.config = {...defaultConfig, ...config}; // Merge defaults with custom config

        this.elementMapID = this.config.elementMapID;
        this.mapNodeElement = document.getElementById(`${this.elementMapID}`)

        if (document.querySelectorAll(`#${this.elementMapID}`).length > 1) {
            throw new Error(`Multiple elements with ID: ${this.elementMapID}`);
        }

        if (!this.mapNodeElement) {
            throw new Error(`Element with ID: ${this.elementMapID} not found`);
        }

        if (this.config.isDrawable)
            this.librariesParams += "&libraries=drawing";

        if (this.config.loadPlaces)
            this.librariesParams += "&libraries=places";

        // INITIALIZE GMAPS
        loadGoogleMapsApi({'key': `${GMAP_KEY}${this.librariesParams}`}).then((googleMaps) => {
            this.map = new window.google.maps.Map(this.mapNodeElement, {
                mapId: MAP_ID,
                zoom: this.config.zoom,
                center: this.config.center,
                styles: this.styles[this.config.mapStyle],
                disableDefaultUI: this.config.disableDefaultUI,
                zoomControl: this.config.zoomControl,
                mapTypeControl: this.config.mapTypeControl,
                scaleControl: this.config.scaleControl,
                streetViewControl: this.config.streetViewControl,
                rotateControl: this.config.rotateControl,
                fullscreenControl: this.config.fullscreenControl,
                mapTypeId: this.config.mapType
            });

            this.trafficLayer = new googleMaps.TrafficLayer();

            this.directionsService = new googleMaps.DirectionsService();

            if (this.config.showTraffic) {
                if (this.trafficLayer) {
                    this.trafficLayer.setMap(this.map);
                }
            }

            this.google = window.google.maps;
            this.geocoder = new googleMaps.Geocoder();
            this.infoWindow = new this.google.InfoWindow;

            this.drawingManager = null;

            this.google.event.addListenerOnce(this.map, 'tilesloaded', () => {
                //this part runs when the mapobject is created and rendered
                this.config.onMapDraw();
            });

            // On map init
            this.config.onMapInit();
        }).catch(function (error) {
            console.log(error);
            // throw new Error(error)
        });
    }

    /**
     * Toogle traffic mode
     * @param status
     */
    toggleTraffic(status: boolean = !this.config.showTraffic) {
        this.config.showTraffic = status;
        this.trafficLayer.setMap(status ? this.map : null);
    }

    /**Create a info label in map
     * https://developers.google.com/maps/documentation/javascript/reference?hl=es#InfoWindow
     * @param content string
     * @param config object
     * */
    createInfoLabel(content, config) {
        config = Object.assign({content: content}, config);
        if (this.google) {
            this.infoWindow = new this.google.InfoWindow(config);
            this.infoLabels.push(this.infoWindow);
            return this.infoWindow;
        }
    };

    /**
     * DrawMarker
     * draws the marker to a point, if it's new then create new and add to internal array collection,
     * if it exists then move to a new position
     */

    drawMarker(marker: MarkerClass) {
        const item = this.markersCollection.find(x => x.marker.id === marker.id);
        if (!item) {
            this.createRadioMarker(marker);
        } else {
            this.transition({
                latitude: marker.position.lat,
                longitude: marker.position.lng
            } as CustomLatLng, item).then(({marker}) => {
                this.markersCollection.find(x => x.marker.id === marker.marker.id).setPosition(marker.getPosition());
            });
        }
    }

    drawMarkers(markers: MarkerClass[]) {
        markers.map((marker) => {
            this.drawMarker(marker);
        })
    }

    removeMarker(marker: MarkerClass) {
        const item = this.markersCollection.find(x => x.marker.id === marker.id);
        if (item) {
            item.info.close();
            item.flush();
        }
    }

    removeAllMarkers() {
        this.markersCollection.forEach((v) => {
            v.info.close();
            v.flush();
        });
    };

    drawRoute(x: RouteClass) {
        let directionsRenderer = new google.maps.DirectionsRenderer();

        const _this = this;

        directionsRenderer.setMap(this.map);
        const route = {
            origin: x.getStartCoordinates,
            destination: x.getEndCoordinates,
            travelMode: 'DRIVING',
            waypoints: [],
            optimizeWaypoints: true,
            ...((x.arrivalDate || x.departureDate) && {
                drivingOptions: {
                    ...(x.arrivalDate && {arrivalTime: x.arrivalDate}),
                    ...(x.departureDate && {departureTime: x.departureDate}),
                    trafficModel: 'pessimistic'
                }
            }),
        } as any

        console.log(route);

        this.directionsService.route(route,
            function (response, status) { // anonymous function to capture directions
                if (status !== 'OK') {
                    window.alert('Directions request failed due to ' + status + ' ' + JSON.stringify(response));
                    return;
                } else {

                    var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
                    if (!directionsData) {
                        window.alert('Directions request failed');
                        return;
                    } else {
                        console.log(response);
                        console.log('time');
                        console.log((directionsData.duration.text));
                        console.log('distance');
                        console.log(directionsData.distance.text);

                        if(x.departureDate){
                            x.setDepartureTrafficAt(directionsData.duration_in_traffic.value)
                            x.setDepartureAt(directionsData.duration.value)
                            console.log(x);
                        }

                        directionsRenderer.setDirections(response);
                        // document.getElementById('msg').innerHTML += " Driving distance is " + directionsData.distance.text + " (" + directionsData.duration.text + ").";

                        _this.appendRoutes(Object.assign(directionsRenderer, x));
                    }
                }
            });
    }

    appendRoutes(item: google.maps.DirectionsRenderer & RouteClass) {
        this.routesCollection.push(item);
    }

    removeRoute(x: RouteClass) {
        this.routesCollection.find(y => x.id == y.id)?.setMap(null)
    }

    removeAllRoutes() {
        this.routesCollection.forEach((v) => {
            v.setMap(null);
        });
    }

    /**Create Radio Maker
     * */
    createRadioMarker(marker: MarkerClass) {

        const center = this.checkAndParseLiteralLatLng({
            latitude: marker.position.lat,
            longitude: marker.position.lng
        } as CustomLatLng);

        const _this = this;

        //to use it
        this.overlay = new this.google.OverlayView();
        this.overlay.lat = center.lat();
        this.overlay.lng = center.lng();
        this.overlay.pos = center;
        let google = this.google;
        this.overlay.info = this.createInfoLabel(marker.text, {position: center});
        this.overlay.info_open = false;

        // Clear overlay
        this.overlay.flush = function () {
            if (this.div) {
                this.div.remove();
            }
        };

        // Add overlay
        this.overlay.mainInstance = this;

        this.overlay.setPosition = function (position) {
            // debugger
            this.latlng_ = position;
            this.pos = position;

            if (!(position instanceof window.google.maps.LatLng)) {
                this.latlng_ = this.pos = new window.google.maps.LatLng({
                    lat: position.lat,
                    lng: position.lng
                })
            }
            // Position the overlay
            var point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
            if (point) {
                this.div.style.left = (point.x - 47) + 'px';
                this.div.style.top = (point.y - 48) + 'px';
            }
        };

        this.overlay.getPosition = function () {
            var position = this.pos;
            // debugger

            if (position instanceof window.google.maps.LatLng) {
                return position;

            } else if ("object" === typeof (position)) {
                return new window.google.maps.LatLng(
                    position.lat,
                    position.lng
                );
            }
        };

        this.overlay.draw = function () {
            if (!this.div) {
                // If not div set
                let panes = this.getPanes();
                // Set templates
                this.div = document.createElement('div');
                this.div.classList.add("marker-driver");
                this.div.innerHTML = GMap.getMarkerTpl(this.color);

                panes.overlayImage.appendChild(this.div);

                // MARKER CLICK EVENT
                google.event.addDomListener(this.div, "click", (event) => {
                    if (this.info) {

                        this.info_open = !this.info_open;

                        if (!this.info_open) {
                            _this.closeInfoWindow();
                        } else {
                            this.info.open(_this.map);
                        }

                        marker.onClick(marker); // trigger click

                    }

                    google.event.trigger(this, "click", event);
                    event.stopPropagation();
                });
            }

            // Get the point in the overlay projection map
            let points_in_map = this.getProjection(
                // Love spaces :)
            ).fromLatLngToDivPixel(this.pos);

            // Set position for div in map
            if (points_in_map) {
                this.div.style.left = (points_in_map.x - 47) + 'px';
                this.div.style.top = (points_in_map.y - 48) + 'px';
            }
        };

        // Set map
        this.overlay.setMap(this.map);
        this.appendRadioMarker(this.overlay);

        this.overlay.marker = marker;
        return this.overlay;
    }

    /**
     * Close info window
     * */
    closeInfoWindow() {
        this.infoWindow.close();
    };

    appendRadioMarker(overlay: CustomOverlayView) {
        this.markersCollection.push(overlay);
    };

    transition(newPosition, marker) {

        newPosition = this.checkAndParseLiteralLatLng(newPosition);

        // console.log('inside');
        return new Promise((res, rej) => {
            let i = 0;

            let deltaLat = (newPosition.lat() - marker.getPosition().lat()) / this.numDeltas;
            let deltaLng = (newPosition.lng() - marker.getPosition().lng()) / this.numDeltas;
            this.moveMarker(deltaLat, deltaLng, i, marker, res);
        }).catch(err => console.log(err))
    }

    private moveMarker(deltaLat, deltaLng, i, marker, res) {

        // Move infoWindow
        marker.info.setPosition({
            lat: (
                marker.getPosition().lat() + deltaLat
            ),
            lng: (
                marker.getPosition().lng() + deltaLng
            )
        })

        // Move custom Overlay
        marker.setPosition({
            lat: (
                marker.getPosition().lat() + deltaLat
            ),
            lng: (
                marker.getPosition().lng() + deltaLng
            )
        })

        if (i !== this.numDeltas) {
            i++;
            setTimeout(() => {
                this.moveMarker(deltaLat, deltaLng, i, marker, res)
            }, this.delay);
        } else {
            res({marker: marker})
        }
    }

    /**
     * Check and parse literal lat,lng to valid LatLng object
     * @param center
     */
    checkAndParseLiteralLatLng(center: CustomLatLng): google.maps.LatLng {
        if (!center.lat) {
            return new this.google.LatLng(center.latitude, center.longitude);
        }
        return center as google.maps.LatLng;
    }

    static getMarkerTpl(color) {
        return `<div class="pin-driver" style='background-color: ${color}'></div>
				<div class="pin-effect-driver" style='background-color: ${color}'></div>`;
    }
}
