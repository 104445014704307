import {BaseLayout} from "../../layout/BaseLayout/BaseLayout";
import React from "react";

export const CarrierDisclaimerPage = (props) => {
    return (
        <>
            <BaseLayout>
                <div className='w-100 content-flex-properties'>
                    <div className="row title-section">
                        <div className="col">
                            Complete your account setup
                        </div>
                    </div>

                    <main className={`row velox-form properties-scroll-auto`}>
                        <div className="col-12 ">
                            <span className={'subtitle-section'}>Your Liability Disclaimer</span>
                        </div>

                        <div className="col-12 d-flex justify-content-between align-items-center mt-4 mb-2">
                        <span className={'info-message-disclainer'}>This will show up below the inspection section on your
                            BOL.</span>
                            <span className={`clear-all`}>Clear All</span>
                        </div>

                        <div className="col-12">
                        <textarea name="" id="" cols={30} rows={17} className={'w-100 h-100'}
                                  placeholder={'Your Liability Disclaimer'}>
                        </textarea>
                        </div>
                        <div className="col-12 d-flex justify-content-end mt-3">
                            <button type="submit"
                                    className="btn btn-sm btn-primary-down-opacity btn-regular text-uppercase"
                            >Add your T&C
                            </button>
                        </div>
                    </main>
                </div>
            </BaseLayout>
        </>)
}