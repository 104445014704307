import React, {useRef, useState} from 'react';
import OrderRequests from "../../components/OrderRequests/OrderRequests";
import CarrierAccountCreated from '../../components/CarrierAccountCreated/CarrierAccountCreated';
import CarrierAccountCompleted from '../../components/CarrierAccountCompleted/CarrierAccountCompleted'
import Modal from '../../components/Modal/Modal'


const DashboardPage = () => {

    const [visible,setVisible] =useState(true)

    return (
        <>
            <div className="col-12">
                <div className="row title-section-base">
                    <div className="col-12">
                        Dashboard
                    </div>
                </div>
                {/* <Modal visiblePopUpModal={visible}
                handleClickOutPopupModal={()=>setVisible(false)}
                >
                    

                <CarrierAccountCompleted handleClickGoDashboard={()=>setVisible(false)}
                    />
                </Modal> */}

                <main className="row w-100 bg-white p-3">
                </main>
            </div>
        </>
    )
}

export default DashboardPage;
