import React, {ReactNode} from 'react'
import './MenuOptionItem.scss'

type MenuOptionItemProps = {
    icon: string
    handleItemClick: (ev) => any,
    children: ReactNode
}

const MenuOptionItem = (props: MenuOptionItemProps) => {
    return (
        <>
            <li onClick={props.handleItemClick} className="list-item font-14 font-normal py-2 px-4 cursor-pointer no-select">
                <img style={{width: "24px", height: "24px"}} src={props.icon} alt=""/>
                <span className="ms-2">{props.children}</span>
            </li>
        </>
    )
}

export default MenuOptionItem
