const Status = ({statusName, className, onClick}: {statusName: string, className?: string, onClick?: (evt: any) => void}) => {

    return(
        <div onClick={(evt) => onClick && onClick(evt)}
             className={"badge badge-" + statusName?.toLowerCase().split(' ').join('-') + ' ' + className}>
            { statusName?.toUpperCase() }
        </div>

    );
}

export default Status;
