import React, {useEffect, useRef, useState} from "react";
import Searchbar from "../../components/Searchbar/Searchbar";
import Table from "../../components/Table/Table";
import SideActionManager from "../../components/SideActionManager/SideActionManager";
import OrderRequestsActionManager from "../../components/OrderRequestsActionManager/OrderRequestsActionManager";
import CardServiceVehicle from "../../components/CardServiceVehicle/CardServiceVehicle";
import CardServiceRequested from "../../components/CardServiceRequested/CardServiceRequested";
import CardServiceCarrier from "../../components/CardServiceCarrier/CardServiceCarrier";
import CardServiceNote from "../../components/CardServiceNote/CardServiceNote";
import CarrierServiceRequestsDetails from "../../components/CarrierServiceRequestsDetails/CarrierServiceRequestsDetails";
import CarrierServiceRequestsSign from "../../components/CarrierServiceRequestsSign/CarrierServiceRequestsDetails";
import {useDispatch, useSelector} from "react-redux";
import getOrderRequestByStatus from "../../redux/actions/OrderRequests.action";
import {AllStates} from "../../redux/reducers";
import {IOrderRequests,IOrderRequest} from "../../redux/reducers/OrderRequests.reducer";
import Modal from '../../components/Modal/Modal'
import Loading from "../../components/Loading/Loading";
import { GraphqlService } from "../../services/graphql.service";
import gql from "graphql-tag";
import { useToasts } from "react-toast-notifications";
import LegDetail from "../../components/LegDetail/LegDetail";
import useScreenDimension from "../../hooks/ScreenDimension/ScreenDimension";
import './OrderRequests.scss';
import OrderRequestMobile from "./OrderRequestMobile";
import { OrderRequest } from "../../classes/classes";
import JobsLegDetails from "../../components/JobsLegDetail/JobsLegDetails";
import IColumn from "../../components/Table/models/IColumn";

enum OrderStatuses {
    PENDING,
    CANCELLED,
    REJECTED,
    EXPIRED,
}
enum OrderStatusesString {
    'pending',
    'cancelled',
    'rejected',
    'expired',
}

const DETAILS = 'DETAILS';
const SIGN = 'SIGN';
const ORDER_DETAILS = 'ORDER_DETAILS'

const OrderRequestsPage = () => {
    const searchBarRef = useRef();
    const toast = useToasts();
    const { isMobile } = useScreenDimension();
    const [orderStatus, setOrderStatus] = useState(OrderStatuses.PENDING);

    const [tabs,setTabs] =useState(DETAILS)
    const [actionManagerOpened, setActionManagerOpened] = useState(false);
    const [itemSelected,setItemSelected] = useState<IOrderRequest[]>([]);
    const [name, setName] = useState('');

    // const [loading, setLoading] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [showLegDetailModal, setShowLegDetailModal] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const orderRequestsPending = useSelector<AllStates>(x => x.orderRequests.pending) as IOrderRequests;
    const orderRequestsCancelled = useSelector<AllStates>(x => x.orderRequests.cancelled) as IOrderRequests;
    const orderRequestsRejected = useSelector<AllStates>(x => x.orderRequests.rejected) as IOrderRequests;
    const orderRequestsExpired = useSelector<AllStates>(x => x.orderRequests.expired) as IOrderRequests;


    const [visible, setVisible] = useState(0);

    const columns: IColumn[] = [
        { name: 'formattedId',          label: 'ORDER #',       orderBy: null, sort_by: 'id',                      active: false, type: 'html' },
        { name: 'formattedBrokerName',  label: 'REQUESTED BY',  orderBy: null, sort_by: 'formattedBrokerName',     active: false, type: 'html' },
        { name: 'formattedVehicleName', label: 'VEHICLE',       orderBy: null, sort_by: 'formattedVehicleName',    active: false, type: 'html' },
        { name: 'formattedOrigin',      label: 'ORIGIN',        orderBy: null, sort_by: 'originSort',              active: false, type: 'html' },
        { name: 'formattedDestination', label: 'DESTINATION',   orderBy: null, sort_by: 'destinationSort',         active: false, type: 'html' },
        { name: 'formattedPrice',       label: 'PAYOUT',        orderBy: null, sort_by: 'priceSort',               active: false, type: 'html' },
    ];

    const statusesOptions = [
        {
            label: 'Pending ',
            key: 'PENDING',
            value: OrderStatuses.PENDING
        },
        {
            label: 'Cancelled ',
            key: 'CANCELLED',
            value: OrderStatuses.CANCELLED
        },
        {
            label: 'Rejected ',
            key: 'REJECTED',
            value: OrderStatuses.REJECTED
        },
        {
            label: 'Expired ',
            key: 'EXPIRED',
            value: OrderStatuses.EXPIRED
        }
    ]

    const getCount = (key = '') => {
        try{
            const keys = {
                'PENDING': orderRequestsPending,
                'CANCELLED': orderRequestsCancelled,
                'REJECTED': orderRequestsRejected,
                'EXPIRED': orderRequestsExpired,
            }
            return keys[key].data.length;
        }catch (ex){
            return 0;
        }
    }

    const getOpens = (item) => {
        if (!item) return [];
        
        let arrayItem = item.data;
        
        return arrayItem.map((element: OrderRequest) => {
            const x = new OrderRequest(element);

            return {
                ...x,
                formattedId: element.getFormattedID(),
                formattedBrokerName: element.getBrokerName(),
                formattedVehicleName: element.getVehicleName(),
                formattedOrigin: element.getOrigin(),
                formattedDestination: element.getDestination(),
                formattedPrice: element.getPrice(),

                originSort: element.origin.address.getName(),
                destinationSort: element.destination.address.getName(),
                priceSort: element.price
            };
        });

    }



    // DISPATCH
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrderRequestByStatus('all'));
    }, []);


    async function onSubmitRequest()
    {
        setLoading(true);
        const mutation = visible == 1 ? gql`
                    mutation($order_id: Int, $name: String){
            accept_order_request(order_id:$order_id, name:$name){
                    id
                    message
                    success
                }
                }
        ` : gql`
                    mutation($order_id: Int){
                        reject_order_request(order_id:$order_id){
                            id
                            message
                            success
                        }
                    }
        `;

        const variables: any = {
            order_id: itemSelected[0]?.id
        }

        if (visible == 1)
            variables.name = name;
        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data.success)
                throw new Error(data.message);
            setActionManagerOpened(false);
            setItemSelected([]);
            setTabs(DETAILS);
            setLoading(false);
            setVisible(0);
            dispatch(getOrderRequestByStatus('all'));
            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
        } catch (ex) {
            setLoading(false);
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }



    if (isMobile)
        return <OrderRequestMobile OrderStatuses={OrderStatuses}
                                   statusesOptions={statusesOptions}
                                   orderRequestsPending={orderRequestsPending}
                                   orderRequestsExpired={orderRequestsExpired}
                                   orderRequestsRejected={orderRequestsRejected}
                                   orderRequestsCancelled={orderRequestsCancelled} />



    return(
        <>
            <div className="flex-1-container col-12">
                <div className="row title-section-base">
                    <div className="col-12">
                        Order Requests
                    </div>
                </div>

                <div className="flex-1-container bg-white p-3 main-radius">
                    <div className="row d-flex justify-content-between align-items-center mb-3">
                        <div className="col-6">
                            <p className="font-14 font-medium">{orderRequestsPending.data.length} orders</p>
                        </div>

                        <div className="col-6">
                        <Searchbar  reference={searchBarRef}
                                        background={'#F8F8F8'}
                                        placeholder={'Search...'}
                                        value={searchTerm}
                                        onChange={(evt) => setSearchTerm(evt.target.value)}
                                        />
                        </div>

                        <div className="col-12 d-flex my-3 cursor-pointer">
                            {
                                statusesOptions.map((opt, index) =>
                                    <div className={'me-3'} key={'key-' + index}>
                                        <p onClick={(evt) => {setOrderStatus(opt.value);setSearchTerm('')}} className={`font-12 cursor-pointer mb-1 font-medium${opt.value != orderStatus ? ' text-gray' : ''}`}>{opt.label} ({getCount(opt.key)})</p>
                                        <div style={{width: '24px', height: '2px', background: orderStatus == opt.value ? '#42ACDE' : 'transparent'}} />
                                    </div>
                                )
                            }

                        </div>
                    </div>


                    {
                        orderStatus == OrderStatuses.PENDING && <Table loading={orderRequestsPending.loading} tableHeight={'70vh'} onRowClicked={(event) => {setActionManagerOpened(true);
                            setItemSelected([event])
                            console.log(event);
                        }} columns={columns} rows={getOpens(orderRequestsPending)}/>
                    }
                    {
                        orderStatus == OrderStatuses.EXPIRED && <Table loading={orderRequestsExpired.loading} tableHeight={'70vh'} onRowClicked={(event) => {

                            setTabs(ORDER_DETAILS);
                            setActionManagerOpened(true);
                            setItemSelected([event]);
                        }} columns={columns} rows={getOpens(orderRequestsExpired)}/>
                    }
                    {
                        orderStatus == OrderStatuses.CANCELLED && <Table loading={orderRequestsCancelled.loading} tableHeight={'70vh'} onRowClicked={(event) => {
                            setTabs(ORDER_DETAILS);
                            setActionManagerOpened(true);
                            setItemSelected([event]);
                        }} columns={columns} rows={getOpens(orderRequestsCancelled)}/>
                    }
                    {
                        orderStatus == OrderStatuses.REJECTED && <Table loading={orderRequestsRejected.loading} tableHeight={'70vh'} onRowClicked={(event) => {
                            setTabs(ORDER_DETAILS);
                            setActionManagerOpened(true);
                            setItemSelected([event]);
                        }} columns={columns} rows={getOpens(orderRequestsRejected)}/>
                    }
                </div>

                {/*Side Action Manager*/}
                <SideActionManager opened={actionManagerOpened} title={tabs == ORDER_DETAILS ? '' : 'Order request #' + itemSelected[0]?.id}
                                   onCloseManager={(ev) => {
                                       setActionManagerOpened(ev);
                                       setTabs(DETAILS)
                                   }}>

                    {

                        tabs===DETAILS?<CarrierServiceRequestsDetails
                            data={itemSelected}
                            handleClickToggle={()=>setTabs(SIGN)}
                            handleClickDecline={()=>setVisible(2)}/>:null

                    }

                    {

                        tabs===SIGN?<CarrierServiceRequestsSign
                            order_id={itemSelected[0]?.id}
                            handleClickBackDetails={()=>setTabs(DETAILS)}
                            handleClickAccept={(t)=> {
                                setName(t);

                                setVisible(1);
                            }}
                            handleClickDecline={()=>setVisible(2)}/>:null

                    }

                    {
                        tabs===ORDER_DETAILS  && <JobsLegDetails order_id={itemSelected[0]?.id} onClose={() => {
                            setTabs(DETAILS);
                            setActionManagerOpened(false);
                        }} onChangeDriver={() => {}}/>
                    }

                </SideActionManager>
            </div>


            <Modal visiblePopUpModal={showLegDetailModal != undefined}
                   handleClickOutPopupModal={() => setShowLegDetailModal(undefined)}>
                <LegDetail order={showLegDetailModal} onClose={() => setShowLegDetailModal(undefined)}/>
            </Modal>

            <Modal visiblePopUpModal={visible != 0}
                   handleClickOutPopupModal={()=>false}
            >


                <div style={{background: '#FFF', borderRadius: '1rem', padding: '1.5rem', minWidth: '300px'}}>
                    <h4 className='font-24 mb-3'>Confirmation</h4>

                    {!loading && <h5 className='font-16' style={{fontWeight: 'normal'}}>Do you want to <span className={`text-${visible == 1 ? 'blue' : 'red'} fw-bold`}>{visible == 1 ? 'ACCEPT' : 'DECLINE'}</span> this order request #{itemSelected[0]?.id}?</h5>}


                    {loading && <div>
                        <Loading />
                        <h6 className='font-14 text-center'>ACCEPTING ORDER REQUEST #{itemSelected[0]?.id}...</h6>
                    </div>}

                    <div className="row mt-4">
                        <div className="col-6">
                            <button disabled={loading} className="btn btn-danger w-100" onClick={(evt) => setVisible(0)}>CANCEL</button>
                        </div>

                        <div className="col-6">
                            <button disabled={loading} className="btn btn-primary-dark w-100" onClick={(evt) => onSubmitRequest()}>CONFIRM</button>
                        </div>
                    </div>
                </div>


            </Modal>


        </>
    );
}

export default OrderRequestsPage;
