import React,{useState,useEffect, useRef} from 'react'
import FormGroup from '../FormGroup/FormGroup';   
import  {CarrierMain} from '../../classes/classes'

const RegistrationInfoForm =({onSubmit,onCancel,data, uploadFile = (key) => {}, removeFile = (key, document) => {}, previewFile = (document) => {}})=>{
    const [carrier, setCarrier] = useState <CarrierMain>(new CarrierMain());
    // const fileRef = useRef<HTMLInputElement>();

    useEffect(() => {
        setCarrier(data);
    }, [data]);


    const onHandelSubmit=()=>{
        onSubmit(carrier)
    }

    return(
        <>
            <div className="flex-1-container" style={{minWidth: '300px'}}>

                <div className="col-12 mb-3 d-flex row align-items-center">
                    <span className="font-14 font-medium mb-0" >Edit registration info</span> 
                    {/* <span className="text-gray fs-10 font-medium cursor-pointer no-select">CLEAR ALL</span> */}
                    <span onClick={onCancel} className="font-14 cursor-pointer font-medium mb-0" ><i className='fas fa-times'></i></span> 
                </div>

                <div className={`flex-1-container`} >
                    <div className={`row `} >

                    <FormGroup name={'dot_number '}
                        value={carrier.carrier_registration.dot_number }
                        required={true}
                        label={'DOT'}
                        placeholder={"DOT..."}
                        onTextChange={(value) => setCarrier({...carrier, carrier_registration : { ...carrier?.carrier_registration , dot_number: value} })}
                        />
                        <FormGroup name={'mc_number '}
                        value={carrier.carrier_registration.mc_number }
                        required={true}
                        label={'MC'}
                        placeholder={"MC..."}
                        onTextChange={(value) => setCarrier({...carrier, carrier_registration : { ...carrier?.carrier_registration , mc_number: value} })}
                        />


                                        <div
                                            className="col-12 d-flex justify-content-between align-items-center my-2">
                                            <span className={`label-span`}>Files</span>
                                        </div>
                                        <div className="col-12">
                                            {carrier.carrier_registration.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => uploadFile('registration')}>Upload a document showing your DOT (Department of Transportation) or MC (Motor Carrier) number</h4>}
                                            {carrier.carrier_registration.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == carrier.carrier_registration.uploaded_documents.length - 1 ? '' : ' mb-3')}> 
                                                <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} onClick={(evt) => previewFile(document)} className="font-12 cursor-pointer">{document.name} </h4>
                                                <div className="d-flex">
                                                    {/* <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => previewFile(document)}>
                                                        <i className='fas fa-eye'></i>
                                                    </h4> */}
                                                    <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => removeFile('registration', document)}>
                                                        <i className='fas fa-times'></i>
                                                    </h4>
                                                </div>
                                            </div>)}
                                            <div className='justify-content-end align-items-center cursor-pointer row' onClick={(evt) => uploadFile('registration')}>
                                                <i className='fas fa-plus edit font-light mr-2'></i>
                                                <span  className={`edit`}>Upload</span>
                                            </div>
                                        </div>






                      



                    </div>
                </div>


                <div className="row mt-3 d-flex ">

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 bg-white rounded-pill text-light-blue"
                        onClick={onCancel} >CANCEL</button>
                    </div>

                    <div className="col-6 text-right">
                        <button className="btn w-100 py-2 btn-blue-light rounded-pill"
                        onClick={()=>onHandelSubmit()} >SAVE CHANGES</button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default  RegistrationInfoForm