import {ControlledMenu, MenuItem, SubMenu} from '@szhsin/react-menu';
import { FixedSizeList } from "react-window";
import React, {useEffect, useRef, useState} from 'react';
import IColumn from './models/IColumn';
import IRow from './models/IRow';
import './styles/Table.scss';
import IMenuOption from "./models/IMenuOption";
import Status from '../Status/Status';
import {useVirtual} from "react-virtual";
import Loading from "../Loading/Loading";
import SanitizeHTML from "../SanitizeHTML";

interface ITableProps {
    columns: IColumn[],
    rows: any,
    rowHeight?: number,
    onRowClicked: (data: any) => void;
    menuOptions?: IMenuOption[],
    tableHeight?: string;
    loading?: boolean,
    onDoubleClick?: (data: any) => void,
    showCheckbox?: boolean,
    overflowAuto?: boolean,
    tableGridStyle?: any,
    firstColumnNerrow?: boolean,
    backgroundColor?: (rowData: any) => string;
}


const Table = ({
                   columns,
                   rows,
                   rowHeight,
                   menuOptions,
                   onRowClicked,
                   tableHeight,
                   onDoubleClick,
                   loading = false,
                   showCheckbox = false,
                   overflowAuto = true,
                   firstColumnNerrow,
                   tableGridStyle = { overflow: "auto", position: "relative", flex: 1 },
                   backgroundColor = (x) => ''
               }: ITableProps) => {

    const [myRows, setMyRows] = useState<IRow[]>();
    const [selectedAll, setSelectedAll] = useState(false);
    const [myColumns, setMyColumns] = useState<IColumn[]>(columns);
    const [myMenuOptions, setMyMenuOptions] = useState<IMenuOption[]>(menuOptions);
    const [submenuOpened, setSubmenuOpened] = useState(false);
    const [myRow, setMyRow] = useState<{row: any, index: number, anchorPoint: {x: number, y: number}}>(null);

    const tableRef = useRef<HTMLDivElement>();
    const parentRef = React.useRef();

    const handleRowClicked = (obj: any) => {
        onRowClicked(obj);
    }

    useEffect(() => {
        setSelectedAll(myRows?.every(x => x.selected) || false)
    }, [myRows]);

    const handleColumnHeaderClick = (index: number) => {
        const temp = [...myColumns];
        const tempRows = [...myRows];

        if (temp[index].active)
            temp[index].orderBy = temp[index].orderBy == 'DESC' ? 'ASC' : 'DESC';
        else {
            temp.map(x => x.active = false);
            temp[index].active = true;
        }


        doSort(index, temp, tempRows)
    }

    const doSort = (index: number, tempColumns: IColumn[] = [...myColumns], tempRows: IRow[] = [...myRows]) => {
        tempRows = tempRows.sort((x, y) => {
            let newX, newY;
            if(tempColumns[index].sort_by){
                switch(tempColumns[index].type){
                    case 'date':
                        newX = new Date(x.row[tempColumns[index].sort_by]).getTime();
                        newY = new Date(y.row[tempColumns[index].sort_by]).getTime();
                        break;
                    default:
                        newX = x.row[tempColumns[index].sort_by];
                        newY = y.row[tempColumns[index].sort_by];
                        break;
                }
            }
            else{
                newX = Array.isArray(x.row[tempColumns[index].name]) ? x.row[tempColumns[index].name][0] : x.row[tempColumns[index].name];
                newY = Array.isArray(y.row[tempColumns[index].name]) ? y.row[tempColumns[index].name][0] : y.row[tempColumns[index].name];
            }

            if (!isNaN(newX))
                newX = +newX

            if (!isNaN(newY))
                newY = +newY

            return newX == newY ? 0 : newX > newY ? (tempColumns[index].orderBy == 'ASC' ? 1 : -1) : (tempColumns[index].orderBy == 'ASC' ? -1 : 1);
        });
        setMyColumns(tempColumns);
        setMyRows([...tempRows]);
    }

    useEffect(() => {
        if (columns) setMyColumns(columns.length != 0 ? columns : []);

        if (menuOptions) {
            let temp = [...menuOptions].map(e => {
                e.condition = e.condition ? e.condition : () => true;
                return e;
            });
            setMyMenuOptions(temp);
        } else
            setMyMenuOptions([]);
    }, [])

    useEffect(() => {
        let r = rows.map(row => {
            return { row: row, selected: row.selected, menuOpened: false }
        })

        let index = myColumns.findIndex(x => x.active);
        if (index > -1){

            doSort(index, myColumns, r);
        }
        else{
            setMyRows(r);
        }
    }, [rows, loading]);


    let interval: any = null;

    const onTouchStart = (evt: React.TouchEvent<any>, index: number) => {
        interval = setInterval(() => {

            if (myMenuOptions.length > 0)
                setMyRow({row: myRows[index]?.row, index, anchorPoint: {x: evt.touches[0].clientX, y: evt.touches[0].clientY}});

            clearInterval(interval);
        }, 500)
    }

    const onTouchEnd = () => {
        clearInterval(interval);
    }

    const onContextMenuHandled = (event, index) => {
        event.preventDefault();

        if (myMenuOptions.length > 0)
            setMyRow({row: myRows[index]?.row, index, anchorPoint: { x: event.clientX, y: event.clientY }});
    }

    const onRowChecked = (event, index) => {
        let temp = [...myRows];

        temp[index].selected = event.target.checked;
        setMyRows(temp);
    }

    const currencyFormat = (num) => {
        return '$ ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    const renderArrayCellType = (column, text, index) => {
        switch (column.type) {
            case 'text':
                return <p key={index} className={(index == 0 ? 'font-medium' : 'fw-300')}>{ text }</p>;
            case 'currency':
                return <p key={index} className={(index == 0 ? 'font-medium' : 'fw-300')}>{ currencyFormat(text) }</p>;
            case 'badge':
                return <Status key={index} statusName={ text } />
            default:
                return <p key={index} className={(index == 0 ? 'font-medium' : 'mt-0-5')}>{ text }</p>;
        }
    }

    const renderCellType = (column, row) => {
        let rowVal = typeof row[column?.name] === 'function' ? row[column?.name]() : row[column?.name];

        switch (column.type) {
            case 'html':
                return <div dangerouslySetInnerHTML={{__html: rowVal}}></div>;
            case 'text':
                return <p className={'fw-600 ' + (column?.name?.includes('balance') ? (+row[column?.name]?.replace('$', '') > 0 ? 'text-red' : 'text-green') : '')}>{ row[column?.name] }</p>;
            case 'currency':
                return <p className={'fw-600 ' + (column?.name?.includes('balance') ? (+row[column?.name]?.replace('$', '') > 0 ? 'text-red' : 'text-green') : '')}>{ currencyFormat(row[column?.name]) }</p>;
            case 'badge':
                return <Status statusName={ row[column?.name] } />
            default:
                return <p className={'fw-600 ' + (column?.name?.includes('balance') ? (+row[column?.name]?.replace('$', '') > 0 ? 'text-red' : 'text-green') : '')}>{ row[column?.name] }</p>;
        }
    }

    const renderCell = (row, column) => {
        let rowVal = typeof row[column?.name] === 'function' ? row[column?.name]() : row[column?.name];
        return(
          Array.isArray(rowVal)
            ? rowVal.map((text, index) => { return renderArrayCellType(column, text, index); })
            : renderCellType(column, row)
        );
    }

    const areMultipleRowSelected = () => {
        return myRows.filter(e => e.selected).length >= 2;
    }

    const getMultipleRowSelected = () => {
        return myRows.filter(e => e.selected);
    }

    const Row = ({ index, style }) => {
        let row = myRows[index].row;
        let selected = myRows[index].selected;

        const getRowFiltered = (column) => {
            let r = {...row};

            r[column?.name] = r[column?.name].filter((x,i) => i > 0);
            return r;
        }

        return(
          <div key={ index }
               onTouchStart={(evt) => onTouchStart(evt, index)}
               onTouchEnd={onTouchEnd}
               style={{...style, height: `${rowHeight || 60}px`}}
               onClick={() => handleRowClicked(row)}
               onDoubleClick={() => {if (onDoubleClick) onDoubleClick(row) }}
               className={`tr ${myRow?.index == index ? 'bg-gold ' : (backgroundColor(myRows[index].row) || '')} ${selected ? 'selected' : ''}`}
               onContextMenu={(e) => onContextMenuHandled(e, index) }>
              {
                  myColumns?.map((column, i) => {
                      let borderLeft = i == 0 && row.hasOwnProperty('border_left') && row.border_left;

                      let rowVal = typeof row[column?.name] === 'function' ? row[column?.name]() : row[column?.name];

                      return (
                        <div key={column.name}
                             style={{borderLeft: borderLeft ? '3px solid #C50532' : ''}}
                             className={`td ${(i == 0 && firstColumnNerrow ? ' narrow-column' : '')} ${column?.hideOnSmall ? 'no-mobile' : ''}`}>

                            <div className="wrapper">
                                {
                                    column?.name?.includes('status') ?
                                      <>
                                          <Status className={Array.isArray(rowVal) && 'mb-0-5'}
                                                  statusName={Array.isArray(rowVal) ? rowVal[0] : rowVal} />
                                          {
                                              Array.isArray(rowVal) && renderCell(getRowFiltered(column), column)
                                          }
                                      </> : renderCell(row, column)
                                }
                            </div>
                        </div>
                      );
                  })
              }

              {
                  showCheckbox &&
                  <div key={'td-ch-' + myColumns.length} className="td td-check text-right">
                      <input type="checkbox"
                             className="custom-check"
                             checked={ selected }
                             onChange={ (event) => onRowChecked(event, index) } />
                  </div>
              }
          </div>
        );
    }

    const Header = () => {
        return(
          <div className="table-grid-header">
              {
                  myColumns?.map((column, index) => {
                      return(
                        <div key={index}
                             className={'th ' + (index == 0 && firstColumnNerrow ? ' narrow-column' : '')  + (column.active ? ' th-active' : '') + (column.hideOnSmall ? ' no-mobile' : '')}
                             onClick={() => handleColumnHeaderClick(index)}>

                            <div className="d-flex justify-content-start align-items-center">
                                { column.label }
                                <i className={"ml-1 fas fa-sort-" + (column.orderBy == 'ASC' ? 'up' : 'down')}></i>
                            </div>
                        </div>
                      );
                  })
              }

              {
                  showCheckbox &&
                  <div className="th th-check" key={'ch-' + myColumns.length}>
                      <input type="checkbox"
                             className="custom-check"
                             checked={ selectedAll }
                             onChange={ (event) => {
                                 setSelectedAll(!selectedAll);

                                 let rows = myRows;
                                 rows.forEach(e => {
                                     e.selected = event.target.checked;
                                 });
                                 setMyRows([...rows]);
                             } } />
                  </div>
              }
          </div>
        );
    }

    const getHeight = () => {
        if (tableHeight == 'auto') return 100;

        if (tableHeight.indexOf('vh') !== -1) {
            let h = +tableHeight.replace('vh', '');
            return h * 7;
        }

        if (tableHeight.indexOf('px') !== -1) {
            return +tableHeight.replace('px', '');
        }

        return 0;
    }

    const rowVirtualizer = useVirtual({
        parentRef,
        size: myRows?.length || 0,
        estimateSize: React.useCallback(() => rowHeight || 60, []),
        overscan: 5
    });

    function RowVirtualizerFixed() {
        return (
          <>
              <div ref={parentRef}
                   className="List table-grid"
                   style={{...tableGridStyle}}>
                  {
                      rowVirtualizer.virtualItems.map(virtualRow => Row({index: virtualRow.index, style: {
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: `${rowHeight || 60}px`,
                              transform: `translateY(${(rowHeight || 60) * virtualRow.index}px)`
                          }}))
                  }

                  {/*<div className="table-grid" style={{*/}
                  {/*    height: `${getHeight()}px`,*/}
                  {/*    width: "100%",*/}
                  {/*    position: "relative"*/}
                  {/*}}>*/}
                  {/*    {*/}
                  {/*        rowVirtualizer.virtualItems.map(virtualRow => Row({index: virtualRow.index, style: {*/}
                  {/*                position: "absolute",*/}
                  {/*                top: 0,*/}
                  {/*                left: 0,*/}
                  {/*                width: "100%",*/}
                  {/*                height: `${rowHeight || 60}px`,*/}
                  {/*                transform: `translateY(${(rowHeight || 60) * virtualRow.index}px)`*/}
                  {/*            }}))*/}
                  {/*    }*/}
                  {/*</div>*/}
              </div>
          </>
        );
    }

    return (
      <>
          <div ref={tableRef} className="table">
              <Header />
              { loading && <div><Loading size='3rem' /> </div>}
              { !loading && RowVirtualizerFixed() }

              <ControlledMenu boundingBoxRef={tableRef}
                              onContextMenu={(evt) => {
                                  evt.stopPropagation();
                                  evt.preventDefault()
                              }}
                              anchorPoint={ myRow?.anchorPoint }
                              isOpen={ myRow != null }
                              animation={ false }
                              onClose={() => {
                                  setMyRow(null);
                              }}>
                  {
                      myRow != null &&
                      myMenuOptions?.filter(e => (!areMultipleRowSelected() && e.condition(myRow?.row)) || (areMultipleRowSelected() && e.multipleRows && e.condition(getMultipleRowSelected()))).map(x =>
                        submenuOpened ? (x.submenu || []).filter(y => y.condition && ((areMultipleRowSelected() && y.condition(getMultipleRowSelected())) || (y.condition(myRow?.row && !areMultipleRowSelected())))).map((z, ind) => <div key={ind}><MenuItem onClick={(evt) => z.action && z.action(myRow?.row)} style={{paddingLeft: '0'}}>
                              <Status className='w-100 text-center' statusName={z.label}></Status>
                          </MenuItem></div>) :
                          (x.submenu ? <SubMenu id={'submenu-' + myRow?.index} className='status' itemStyles={{fontSize: '.9rem', paddingLeft: '2.2rem'}}  label={x.label}>
                                {
                                    x.submenu.filter(y => y.condition ? y.condition(myRow?.row) : true).map((y, ind) => <div key={ind}><MenuItem onClick={(evt) => y.action && y.action(myRow?.row)} style={{paddingLeft: '0'}}>
                                        <Status className='w-100 text-center' statusName={y.label}></Status></MenuItem></div>)
                                }
                            </SubMenu> :
                            <MenuItem className='my-menuitem' onClick={() => {
                                if (x.action) {
                                    if (myRows.filter(e => e.selected).length >= 2) {
                                        x.action(myRows.filter(e => e.selected));
                                    } else x.action(myRow?.row)
                                }
                            }} style={{paddingLeft: '0'}}>
                                <img style={{marginLeft: '.1rem', marginRight: '.45rem', width: '20px'}} src={x.icon} alt=""/>{x.label}
                            </MenuItem>))
                  }
              </ControlledMenu>
          </div>
      </>
    );
}

export default React.memo(Table);
