import {gql} from "graphql-tag";

const CARRIER_INFORMATION = gql`
mutation($carrier: carrier_update_input) {
  update_carrier(carrier: $carrier) {
    id
    message
    success
  }
}`;

const CarrierMutation = {
    CARRIER_INFORMATION,
}

export default CarrierMutation;