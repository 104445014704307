import React from 'react';
import ICONS from '../../../assets/svg';

import './Searchbar.scss';

const Searchbar = (
    {
        placeholder = 'Search...',
        onFocus,
        onBlur,
        onChange,
        value,
        reference,
        background
    }:
        {
            background?: string,
            placeholder?: string,
            onFocus?: any, onBlur?: any,
            onChange?: any,
            value?: string, reference: any
        }) => {

    return (
        <div style={{position: 'relative'}} className='searchbar-component'>
            <img onClick={(evt) => reference.current.focus()}
                 style={{position: 'absolute', top: '50%', left: '0', transform: 'translateY(-46%)', cursor: 'pointer'}}
                 src={ICONS.IconSearch} alt=""/>
            <input style={{background: background ? background : ''}} onFocus={onFocus} onBlur={onBlur}
                   className='search-input' ref={reference} value={value} onChange={(evt) => onChange && onChange(evt)}
                   type="text" placeholder={placeholder}/>
            <img onClick={(evt) => {
                if (reference?.current) {
                    reference.current.value = '';
                    reference.current.focus();
                }
                onChange && onChange({target: {value: ''}})
            }
            } style={{
                position: 'absolute',
                top: '50%',
                right: '0.45rem',
                transform: 'translateY(-46%)',
                cursor: 'pointer'
            }} src={ICONS.IconDelete} alt=""/>
        </div>
    )
}
export default Searchbar;
