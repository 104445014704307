import loadingTypes from "../types/Loading.type";

const openLoadingAction = (text: string = '') => {
    return {
        type: loadingTypes.OPEN,
        payload: text || ''
    }
}

const closeLoadingAction = () => {
    return {
        type: loadingTypes.CLOSE
    }
}


export function openLoading(text: string = '') {
    return dispatch => dispatch(openLoadingAction(text));
}

export function closeLoading() {
    return  dispatch => dispatch(closeLoadingAction());
}
