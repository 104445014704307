import React, {useState} from "react";
import {Order} from "../../classes/classes";

const options = [
    { label: 'Leg info', selected: true },
    { label: 'BOL', selected: false },
    { label: 'Origin Inspection', selected: false },
    { label: 'Destination Inspection', selected: false },
    { label: 'Payment', selected: false },
];

const JobsPageMobileDetail = ({order, onBack}: {order: Order, onBack: any}) => {
    const [menuOptions, setMenuOptions] = useState(options);

    const handleMenuOption = menu => {
        let temp = menuOptions.map(e => {
            return {...e, selected: e.label == menu.label};
        });

        setMenuOptions([...temp]);
    }


    const legInfo = () => {
        return(
            <>
                <p className="font-12 font-medium mt-3">Driver</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-12 font-medium mb-1">{ order?.driver?.name }</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Vehicle</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-10 mb-1 text-gray">Year / make / model</p>
                        <p className="font-12 font-medium mb-1">{ order?.vehicle?.year } { order?.vehicle?.make } { order?.vehicle?.model }</p>
                        <p className="font-12 mb-1">{ order?.vehicle?.vin }</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Customer</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-10 mb-1 text-gray">Name & address</p>
                        <p className="font-12 font-medium mb-1">{ order?.broker?.name }</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Origin</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-10 mb-1 text-gray">Origin</p>
                        <p className="font-10 font-medium mb-1">{ order?.origin?.getFormattedDateTime() }</p>
                        <p className="font-12 font-medium mb-1">{ order?.origin?.address?.street }</p>
                        <p className="font-12 mb-3">{ order?.origin?.address?.city } { order?.origin?.address?.state } { order?.origin?.address?.postal_code }</p>

                        <p className="font-10 mb-1 text-gray">Contact</p>
                        <p className="font-12 font-medium mb-1">{ order?.origin?.contacts[0]?.name }</p>
                        <p className="font-12 font-medium mb-1">{ order?.origin?.contacts[0]?.value }</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Destination</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-10 mb-1 text-gray">Destination</p>
                        <p className="font-10 font-medium mb-1">{ order?.destination?.getFormattedDateTime() }</p>
                        <p className="font-12 font-medium mb-1">{ order?.destination?.address?.street }</p>
                        <p className="font-12 mb-3">{ order?.destination?.address?.city } { order?.destination?.address?.state } { order?.destination?.address?.postal_code }</p>

                        <p className="font-10 mb-1 text-gray">Contact</p>
                        <p className="font-12 font-medium mb-1">{ order?.destination?.contacts[0]?.name }</p>
                        <p className="font-12 font-medium mb-1">{ order?.destination?.contacts[0]?.value }</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Services</p>

                <div className="card">
                    <div className="card-body">

                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Notes</p>

                <div className="card mb-6">
                    <div className="card-body">
                        <p className="font-12 font-medium m-0">note...</p>
                    </div>
                </div>
            </>
        );
    }

    const bol = () => {
        return(
            <>
                <p className="font-12 font-medium mt-3">BOL</p>
            </>
        );
    }

    const inspection = (inspection, isOrigin) => {
        return(
            <>
                <p className="font-12 font-medium mt-3">{ isOrigin ? 'Origin' : 'Destination' }</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-10 mb-1 font-medium text-gray">{ isOrigin ? 'Origin' : 'Destination' }</p>
                        <p className="font-12 mb-1 font-medium">{ inspection.getFormattedDateTime() }</p>

                        <p className="font-12 mb-1 font-medium">{ inspection.address.street }</p>
                        <p className="font-12 mb-1">{ inspection.address.city } { inspection.address.state } { inspection.address.postal_code }</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Damages</p>
            </>
        );
    }

    const payment = () => {
        return(
            <>
                <p className="font-12 font-medium mt-3">Pay</p>

                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <p className="font-10 mb-1 font-medium text-gray">Carrier pay</p>
                                <p className="font-12 mb-3 font-medium">$ 500.00</p>
                            </div>

                            <div className="col-6">
                                <p className="font-10 mb-1 font-medium text-gray">COD</p>
                                <p className="font-12 mb-3 font-medium">$ 0.00</p>
                            </div>

                            <div className="col-12">
                                {/* <p className="font-10 mb-1 font-medium text-gray">Carrier Payment Terms</p>
                                <p className="font-12 mb-1 font-medium">15 days after signed BOL and invoice received</p> */}
                            </div>
                        </div>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Requested By</p>

                <div className="card">
                    <div className="card-body">
                        <p className="font-12 mb-1 font-medium">JP Logistics</p>
                        <p className="font-10 mb-1">000-000-0000</p>
                        <p className="font-10 mb-1">longlongemail@longdomain.com</p>
                    </div>
                </div>

                <p className="font-12 font-medium mt-3">Invoice</p>

                <button className="upload-btn btn mb-6"><i className="ti-plus"></i> Upload Invoice</button>
            </>
        );
    }

    return(
        <>
            <div className="col-12 bg-white text-center">
                <div className="d-flex align-items-center justify-content-between pb-3">
                    <i className="ti-angle-left font-12 cursor-pointer" onClick={onBack}></i>
                    <p className="m-0 font-14 font-medium">LG-{order?.id}</p>
                    <i className=""></i>
                </div>

                <div className="tabs">
                    {
                        menuOptions.map((e, key) => {
                            return(
                                <p key={key}
                                   onClick={() => handleMenuOption(e)}
                                   className={`m-0 font-10 font-medium d-inline-block px-3 py-2 ${e.selected ? 'item-selected' : ''}`}>
                                    { e.label }
                                </p>
                            );
                        })
                    }
                </div>
            </div>

            <div className="col-12 flex-1-container">
                { menuOptions[0]?.selected && legInfo() }
                { menuOptions[1]?.selected && bol() }
                { menuOptions[2]?.selected && inspection(order.origin, true) }
                { menuOptions[3]?.selected && inspection(order.destination, false) }
                { menuOptions[4]?.selected && payment() }
            </div>
        </>
    );
}

export default JobsPageMobileDetail;