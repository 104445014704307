import React,{useState,useRef} from 'react'
import './CarrierServiceRequestsDetails.scss'
import CardServiceVehicle from "../../components/CardServiceVehicle/CardServiceVehicle";
import CardServiceRequested from "../../components/CardServiceRequested/CardServiceRequested";
import CardServiceCarrier from "../../components/CardServiceCarrier/CardServiceCarrier";
import CardServiceNote from "../../components/CardServiceNote/CardServiceNote";
import {IOrderRequest} from "../../redux/reducers/OrderRequests.reducer";

type CarrierServiceRequestsDetailsProps={
    handleClickToggle:()=>void,
    handleClickDecline:()=>void,
    data?:IOrderRequest[],
    assignDriver?: boolean
}


const CarrierServiceRequestsDetails =(props:CarrierServiceRequestsDetailsProps)=>{
    return(
        <div className='flex-1-container'>
            <div className="flex-1-container">
                <CardServiceRequested data={props.data}/>
                <CardServiceCarrier data={props.data}/>
                <CardServiceVehicle data={props.data}/>
                {props.data.filter(x=>x.broker_note).map(x => <CardServiceNote notes={x.broker_note}/>)}
            </div>
            {/* <CardServiceNote/> */}


            {!props.data[0]?.accepted && <div className="row mt-3">

                <div className="col-6 ">
                    <button onClick={()=>props.handleClickDecline()}  className="btn btn-skyblue-outline w-100">
                    DECLINE
                    </button>
                </div>

                <div className="col-6 ">
                    <button onClick={()=>props.handleClickToggle()} className="btn btn-skyblue w-100">
                    SIGN TO ACCEPT
                    </button>
                </div>

            </div>}
        </div>
    )
}

export default CarrierServiceRequestsDetails
