import { gql } from 'apollo-boost';
import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { PendingInspection } from '../../../../classes/classes';
import Helper from '../../../../helper/Helper';
import useLoading from '../../../../hooks/useLoading/useLoading';
import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';
//import useLoading from '../../../../hooks/useLoading/useLoading';
//import useMessageBox from '../../../../hooks/useMessageBox/useMessageBox';
import { GraphqlService } from '../../../../services/graphql.service';
import ConfirmationForm from '../../components/ConfirmationForm/ConfirmationForm';
import Content from '../../components/Content/Content';
import Signature from '../../components/Signature/Signature';
import Toolbar from '../../components/Toolbar/Toolbar';
import InspectionObservable from '../../observables/InspectionObservable';
import platform from 'platform';

const Confirmation = () => {
    const {token, origin} = useParams<any>();

    const [signature, setSignature] = useState(null);

    const messageBox = useMessageBox();

    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());
    
    const originalDataRef = useRef<PendingInspection>(new PendingInspection());

    const [signatures, setSignatures] = useState({});

    const toast = useToasts();
    //const messageBox = useMessageBox();

    const loading = useLoading();

    const backLink = `/pending-inspection/${origin}/${token}/notes`;

    async function getTempLink(uid) {
        const query = gql`
            query($uid:String){
                get_temp_link(uid:$uid)
            }
        `;
        const variables = {uid}

        try {
            console.log(variables)
            const data = await GraphqlService.SendQuery(query, variables);
            return data;

        } catch (ex) {
            return '';
        }
    }

    async function loadSignatures() {
        try {
            const sign: any = {};
            // if (originalData.signed_by)
            // {
            //     sign.origin = await getTempLink(originalData.signed_by);
            // }
            // if (originalData.performed_by)
            // {
            //     sign.driver = await getTempLink(originalData.performed_by.uid);
            // }

            console.log(sign);
            setSignatures(sign);
        } catch (ex) {
            
        }
    }

    useEffect(() => {
        
        originalDataRef.current.vehicle_data = originalData.vehicle_data;
        originalDataRef.current.note = originalData.note;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
        originalDataRef.current.signed_by = originalData.signed_by;
        originalDataRef.current.performed_by = originalData.performed_by;
        loadSignatures();
            
    }, [originalData]);

    async function getConfirmation(title, message)
    {
        return new Promise((res, rej) => {
           messageBox.open({title, message, buttons: [{text: 'Cancel', css: 'btn btn-clear text-light-blue', action: () => res(false)}, {text: 'Acept', action: () => res(true)}]});
        })
    }


    async function handleSubmitSignature(signature) {
        // console.log(signature)
       setSignature(signature);
    }

    async function handleSubmitEmail(emails,action) {
        if(action === 'new'){
            let temp = currentData.additional_emails.concat(emails)
            setCurrentData({...currentData,additional_emails:temp});
        }else{
            setCurrentData({...currentData,additional_emails:emails});
        }
    }


    async function isValidToSubmit()
    {
        if (originalData.vehicle_data.vin.trim().length != 17 && (!originalData.vehicle_data.special_vin && !originalData.vehicle_data.special_vin))
            {toast.addToast('Vin must be 17 digits', {appearance: 'error', autoDismiss: true}); return false;}
        else if (originalData.vehicle_data.vin.trim().length == 0 && originalData.vehicle_data.special_vin)
            { toast.addToast('Vin is required', {appearance: 'error', autoDismiss: true}); return false;}
        if (originalData.vehicle_data.year.trim() == '' || originalData.vehicle_data.make.trim() == '' || originalData.vehicle_data.model.trim() == '')
            {toast.addToast('Vehicle data is required', {appearance: 'error', autoDismiss: true}); return false;}

        if (originalData.photos.some(x => x.uid == ''))
            {toast.addToast('Mandatory photos are missing', {appearance: 'error', autoDismiss: true}); return false;}

        if (originalData.odometer == null)
            { toast.addToast('Odometer is required', {appearance: 'error', autoDismiss: true}); return false;}

        if (!signature)
            {toast.addToast('Customer Name and Customer signature are required', {appearance: 'error', autoDismiss: true}); return false;}
            
        if (originalData.damages.length == 0 && !(await getConfirmation('Warning', 'This vehicle has been marked with no damages, do you want continue?')))
            return false;
        if (originalData.accessories.length == 0 && !(await getConfirmation('Warning', 'This vehicle has been marked with no accessories, do you want continue?')))
            return false;


        
        return true;

    }

    useEffect(() => {
        let query = InspectionObservable.GetObservable(token, origin?.toLowerCase() == 'true');

        let subs = query.onResults.subscribe(result => {
            if (result.error)
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            else
            {
                console.log('something changed');
                let o = new PendingInspection(result.data);
                o.is_origin = origin?.toLowerCase() == 'true';
                
                
                


                

                setCurrentData(new PendingInspection(result.data));

                setOriginalData(o);

            }
        });

        return () => {
            subs.unsubscribe();
            query.stopPolling();
        }
    }, []);

    async function getGPS() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation)
            {
                const options = {
                    enableHighAccuracy: true,
                    timeout: 5000
                }
                
                navigator.geolocation.getCurrentPosition(async (position) => {
                    
                    let obj = {
                        "Altitude":position.coords.altitude,
                        "Coordinate":{
                           "Latitude":position.coords.latitude,
                           "Longitude":position.coords.longitude
                        },
                        "Course":null,
                        "CourseAccuracy":null,
                        "HorizontalAccuracy":null,
                        "Speed":position.coords.speed,
                        "SpeedAccuracy":null,
                        "Timestamp": position.timestamp,
                        "VerticalAccuracy":null
                     }
                     
                    resolve(JSON.stringify(obj));
                }, err => {
                    
                    resolve('{}');
                }, options);
            }
            else
                resolve('{}');
        })
        
    }

    function fnBrowserDetect(){
                 
        let userAgent = navigator.userAgent;
        let browserName;
        
        if(userAgent.match(/chrome|chromium|crios/i)){
            browserName = "chrome";
          }else if(userAgent.match(/firefox|fxios/i)){
            browserName = "firefox";
          }  else if(userAgent.match(/safari/i)){
            browserName = "safari";
          }else if(userAgent.match(/opr\//i)){
            browserName = "opera";
          } else if(userAgent.match(/edg/i)){
            browserName = "edge";
          }else{
            browserName="No browser detection";
          }
        
         return browserName;
 }

    const deviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "Tablet";
        }
        else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
            return "Mobile";
        }
        return "Desktop";
    };

    async function handleSubmit() {
        try {   
            const isValid = await isValidToSubmit();
    
            if (!isValid) return;
    
            const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';
            console.log(word);
            const mutation = gql`
                mutation(
                        $pending_${word}_inspection_id: Int
                        $gps: String
                        $device_type: String
                        $device_info: String
                        $signature: Signature_input!
                        $additional_emails: [String]
                        ) {
                        finish_${word}_inspection(
                            pending_${word}_inspection_id: $pending_${word}_inspection_id
                            gps: $gps
                            device_info: $device_info
                            device_type: $device_type
                            signature: $signature
                            additional_emails: $additional_emails
                        ) {
                            id
                            message
                            success
                            link
                        }
                        }
            `;
            const gps = await getGPS();
            const variables = {[`pending_${word}_inspection_id`]: originalData.pending_inspection_id, gps, device_info: JSON.stringify(platform), device_type: `${deviceType()}`, signature, additional_emails: currentData.additional_emails};
            loading.open('Submitting inspection...');
            const data = await GraphqlService.SendMutation(mutation, variables)
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');

            toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
           loading.close();
            setTimeout(() => {
                
                window.location.href = data.link;
            }, 1000);

        } catch (ex) {
           loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    return <>
    <Toolbar vehicle={currentData.vehicle_data} driver_name={currentData.driver_name} />

                <Content>
                    <div className="mb-3">
                        <h4 className="font-11">Inspection Confirmation</h4>
                    </div>
                    <div className='flex-1-container'>
                           <ConfirmationForm onSubmitEmail={(data,action)=>handleSubmitEmail(data,action)} onSubmitSignature={handleSubmitSignature} signatures={signatures} data={originalData}/>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <button onClick={(evt) => Helper.Navigation.NavigateTo(backLink)} className="btn btn-back w-100"><i className="fas fa-chevron-left"></i> NOTES</button>
                        </div>
                        <div className="col-6">
                            <button onClick={handleSubmit} className="btn btn-skyblue  w-100">SUBMIT</button>
                        </div>
                    </div>
                </Content>
            </>
}

export default Confirmation;
