import { Order } from "../../classes/classes";
import IReducerAction from "../models/IReducerAction";
import OrderTypes from "../types/Order.type";
import OrderRequestTypes from "../types/OrderRequests.type";


export interface IOrderInternal
{
    loading: boolean,
    error: string,
    data: Order[]   
}

export interface IOrder {
    open: IOrderInternal,
    completed: IOrderInternal
}

const initialState: IOrder = {
    open: {
        loading: false,
        error: null,
        data: []
    },
    completed: {
        loading: false,
        error: null,
        data: []
    }
}

export default function OrdersReducer(state: IOrder = initialState, action: IReducerAction): IOrder {
    // debugger
    switch (action.type) {
        case OrderTypes.GET_ALL_ORDER_STARTED:
            return {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: action.payload,
                    error: null
                }
            }
        case OrderTypes.GET_ALL_ORDER_SUCCESS:
            
            return {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    loading: false,
                    data: action.payload
                }
            }
        case OrderTypes.GET_ALL_ORDER_ERROR:
            return {
                ...state,
                [action.status]: {
                    ...state[action.status],
                    error: action.payload,
                    loading: false
                }
            }
        default:
            return state;
    }
}
