import { gql } from 'apollo-boost';
import React, { FC, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Address } from '../../classes/classes';
import Helper from '../../helper/Helper';
import useLoading from '../../hooks/useLoading/useLoading';
import { Document, _CarrierTaxInfo } from '../../pages/CarrierDocumentsPage/CarrierDocumentsPage';
import { GraphqlService } from '../../services/graphql.service';
import FormGroup from '../FormGroup/FormGroup';
import AddressSearch from '../Google/AddressSearch';

interface IProps {
    onClose: (evt) => any;
    onSubmitted: (evt) => any;
    data: _CarrierTaxInfo;
    carrier_id: number;
    uploadFile: (key: string) => any,
    removeFile: (key: string, document: Document) => any,
    previewFile: (document) => any
}

const mutation = gql`
    mutation($tax_information:carrier_tax_info_input){
        update_carrier_tax_info(tax_information:$tax_information){
            carrier_id
            id
            name
            ssn
            ein
            business_name
            type 
            signature
            time_signed
            address {
                street
                city
                state
                postal_code
                country
                street_number
            }
            uploaded_documents{
                uid
                name
            }
        }
    }
`;

const TaxInfo: FC<IProps> = (props) => {
    const [info, setInfo] = useState(new _CarrierTaxInfo());
    const loading = useLoading();
    const toast = useToasts();
    const [businessType, setBusinessType] = useState<string>(props.data.type || '-- Select --');

    useEffect(() => {
        setInfo(new _CarrierTaxInfo(props.data));
    }, [props.data]);


    const states = Helper.States;
    const countries = ['United States','Canada'];

    async function handleSubmit(evt) {
        try {
            
            loading.open('Updating...');
            const data = await GraphqlService.SendMutation(mutation, {
                "tax_information": {
                  "carrier_id": props.carrier_id,
                  "name": info.name,
                  "business_name": info.business_name,
                  "type": info.type,
                  "address": info.address,
                  "ssn": (businessType == types[0]) ? info.ssn : '',
                  "ein": info.ein
                }
              });

            if (!data)
              throw new Error('No reply from the server');


            loading.close();
            

            toast.addToast('Updated Tax Info', {appearance: 'success', autoDismiss: true});
            props.onSubmitted(info);

            
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const isValid = () => {
        return info.name.trim() != '' && info.type.trim() != ''
            && info.address.street.trim() != '' && info.address.street_number.trim() != '' && info.address.city.trim() != ''
            && info.address.state.trim() != '' && info.address.postal_code.trim() != ''
            && info.address.country.trim() != '';

    }

    const types = 
    [
        'Individual/sole proprietor or single-member LLC',
        'C corporation',
        'S Corporation',
        'Partnership',
        'Trust/estate',
        'LLC (C corporation)',
        'LLC (S corporation)',
        'LLC (Partnership)'
    ];

    

    function handleUpdateInfo(key, value) {
        const c = new _CarrierTaxInfo(info);
        c[key] = value;
        if(key == 'type'){
            setBusinessType(value);
        }
        setInfo(c);
    }

    function updateAddress(address) {
        const c = new _CarrierTaxInfo(info);
        c.address = new Address(address);
        setInfo(c);
    }

    function ssnFormat(ssn) {
        var val = ssn.replace(/[^\d-]/g, '');

  // add the first dash if number from the second group appear
  val = val.replace(/^(\d{3})-?(\d{1,2})/, '$1-$2');

  // add the second dash if numbers from the third group appear
  val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

  // remove misplaced dashes
  val = val.split('').filter((val, idx) => {
    return val !== '-' || idx === 3 || idx === 6;
  }).join('');
  

        // enforce max length
        return val.substring(0, 11);
    }

    function einFormat(ein) {
        var val = ein.replace(/[^\d-]/g, '');

  // add the first dash if number from the second group appear
  val = val.replace(/^(\d{2})-?(\d{1,2})/, '$1-$2');

  // add the second dash if numbers from the third group appear
  val = val.replace(/^(\d{3})-?(\d{2})-?(\d{1,4})/, '$1-$2-$3');

  // remove misplaced dashes
  val = val.split('').filter((val, idx) => {
    return val !== '-' || idx === 2;
  }).join('');
  

        // enforce max length
        return val.substring(0, 10);
    }
    return (
        <div className='flex-1-container'>
            <div className="mb-3 row">
                <h2 className='font-14'>Carrier Tax Info</h2>
                <h4 onClick={props.onClose} className="font-14 cursor-pointer"><i className='fas fa-times'></i></h4>
            </div>

            <div className="flex-1-container">
                <div className="row">
                    <FormGroup placeholder='Name...' colSize={12} name={'name'} label={'Name (as shown on your income tax return). Name is required on this line; do not leave this line blank.'} required value={info.name} onTextChange={(evt) => handleUpdateInfo('name', evt)} />
                    <FormGroup placeholder='Bussines Name...' colSize={12} name={'bussines_name'} label={'Business name/disregarded entity name, if different from above'} value={info.business_name} onTextChange={(evt) => handleUpdateInfo('business_name', evt)} />
                    <div className="col-12 row">
                        <FormGroup options={{data: types, label: x=>x, value:x=>x}} placeholder='-- Select --' type='select' colSize={12} name={'bussines_type'} label={'Bussines Type'} required value={businessType} onTextChange={(evt) => handleUpdateInfo('type', evt)} />
                    </div>
                    { (types.includes(businessType)) && 
                    <>
                    <FormGroup placeholder='XX-XXXXXXX' type='text' colSize={6} name={'ein'} label={'EIN'} value={info.ein} onTextChange={(evt) => handleUpdateInfo('ein', einFormat(evt))} />
                    {(businessType == types[0]) && <FormGroup placeholder='XXX-XX-XXXX' type='text' colSize={6} name={'ssn'} label={'SSN'} value={info.ssn} onTextChange={(evt) => handleUpdateInfo('ssn', ssnFormat(evt))} />}
                    </>
                    }
                    
                </div>

                <h4 className="font-16 text-gray font-bold">Address Information</h4>

                <AddressSearch onGotAddress={(address ) => {
                    const i = new _CarrierTaxInfo(info);
                    i.address = new Address(address);
                    setInfo(i);
                }} />

<div className="row col-12">
                        <FormGroup label={'Street #'}
                                   colSize={3}
                                   value={info.address?.street_number}
                                   name={'streetNumber'}
                                   placeholder={'Street #'}

                                   required
                                   onTextChange={value => updateAddress({...info.address, street_number: value})}/>

                        <FormGroup name={'street'}
                                   colSize={9}
                                   label={'Street address'}
                                   value={info.address?.street}
                                   required
                                   placeholder={'Street'}
                                   onTextChange={(event) => updateAddress({...info.address, street: event})}/>
                    </div>
                    

                <div className="row">
                    <div className="row col-12">
                        <FormGroup name={'city'}
                                   colSize={12}
                                   label={'City'}
                                   required
                                   value={info.address?.city}
                                   placeholder={'City'}
                                   onTextChange={(event) => updateAddress({...info.address, city: event})}/>

                        <FormGroup name={'state'}
                                   label={'State'}
                                   colSize={6}
                                   required
                                   value={info.address?.state}
                                   type='select'
                                   placeholder={'--'}
                                   options={{data: states, value: x => x, label: x => x}}
                                   onTextChange={(event) => updateAddress({...info.address, state: event})}/>

                        <FormGroup name={'postalCode'}
                                   label={'Postal Code'}
                                   colSize={6}
                                   value={info.address?.postal_code}
                                   required
                                   placeholder={'00000'}
                                   onTextChange={(event) => updateAddress({...info.address, postal_code: Helper.Masks.IntNumbers(event, 5)})}/>

<FormGroup label={'Country'}
                                name={'country'}
                                colSize={6}
                                value={info.address.country}
                                type={'select'}
                                placeholder={'-- Select a country --'}
                                options={{data: countries, value: x => x, label: x => x}}
                                onTextChange={value => updateAddress({...info.address, country: value})}/>
                    </div>




                </div>

                <div
                                            className="col-12 d-flex justify-content-between align-items-center my-2">
                                            <span className={`label-span`}>Files</span>
                                        </div>
                                        <div className="col-12">
                                            {info.uploaded_documents.length == 0 && <h4 className='text-red font-12 text-left cursor-pointer' onClick={(evt) => props.uploadFile('tax info')}>Upload a document showing your tax info</h4>}
                                            {info.uploaded_documents.map((document, index) =>  <div style={{maxWidth: '100%'}} className={"document-row align-items-center" + (index == info.uploaded_documents.length - 1 ? '' : ' mb-3')}> 
                                                <h4 style={{textOverflow: 'clip', overflow: 'auto', whiteSpace: 'nowrap'}} className="font-12 cursor-pointer" onClick={(evt) => props.previewFile(document)}>{document.name}</h4>
                                                <div className="d-flex">
                                                    {/* <h4 className="font-16 mr-2 cursor-pointer text-light-blue text-right" onClick={(evt) => props.previewFile(document)}>
                                                        <i className='fas fa-eye'></i>
                                                    </h4> */}
                                                    <h4 className="font-16 cursor-pointer text-right" onClick={(evt) => props.removeFile('tax info', document)}>
                                                        <i className='fas fa-times'></i>
                                                    </h4>
                                                </div>
                                            </div>)}
                                            <div className='justify-content-end align-items-center cursor-pointer row' onClick={(evt) => props.uploadFile('tax info')}>
                                                <i className='fas fa-plus edit font-light mr-2'></i>
                                                <span  className={`edit`}>Upload</span>
                                            </div>
                                        </div>
            </div>

            <div className="mt-3 row">
                <button className="btn btn-clear text-light-blue col-6" onClick={props.onClose}>Cancel</button>
                <button className="btn btn-blue-light col-6" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
}

export default TaxInfo;