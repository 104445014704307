import IReducerAction from "../models/IReducerAction"
import documentsTypes from "../types/Documents.type"

export interface IDocumentsState {
    message: string;
    insurance: boolean;
    registration: boolean;
    expiration: Date;
    tax_info: boolean;
    error: string;
    loading: boolean;
}

const initialState: IDocumentsState = {
    message: '',
    insurance: false,
    registration: false,
    expiration: null,
    tax_info: false,
    error: null,
    loading: false
}


const documentsReducer = (state = initialState, action: IReducerAction): IDocumentsState => {
    switch (action.type) {
        case documentsTypes.CHECK_DOC_STARTED:
            return { ...state, loading: action.payload}
        case documentsTypes.CHECK_DOC_SUCCESS:
            return { ...state, ...action.payload, error: null, loading: false}

        case documentsTypes.CHECK_DOC_ERROR:
            return { ...state, error: action.payload, loading: false}
            
        default:
            return state;
    }
}

export default documentsReducer;