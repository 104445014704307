import React, {useEffect, ReactNode} from 'react'
import './MenuOption.scss'

type MenuOptionProps = {
    visible: boolean;
    nodeContainer: any;
    handleMenuOptionClick: () => void;
    children: ReactNode;
}

const MenuOption = (props: MenuOptionProps) => {
    useEffect(() => {
        let handleClickOut = (event: any) => {
            if (!props.nodeContainer.current.contains(event.target)) {
                props.handleMenuOptionClick()
            }
        }
        document.addEventListener("mousedown", handleClickOut)

        return () => {
            document.removeEventListener("mousedown", handleClickOut)
        }
    }, [props]);

    return (
        <>
            <div className={`${props.visible ? "d-block" : "d-none"} menu-option`}>
                <ul className="list-unstyled m-0">
                    { props.children }
                </ul>
            </div>
        </>
    )
}

export default MenuOption
