import IReducerAction from "../models/IReducerAction";
import driverTypes from "../types/Driver.type";

export interface IDriverState {
    response: any;
    errorForm: string;
    loadingForm: boolean;
    equipmentAdded: any;
    onDeleteSuccess: boolean;
    equipmentDeleted: string;
}

const initialState: IDriverState = {
    errorForm: '',
    loadingForm: false,
    response: undefined,
    equipmentAdded: undefined,
    onDeleteSuccess: false,
    equipmentDeleted: undefined,
}

const driverReducer = (state = initialState, action: IReducerAction) => {
    switch (action.type) {
        case driverTypes.UPDATE_DRIVER_REQUEST:
            return { ...state, errorForm: '', onDeleteSuccess: false, equipmentAdded: undefined, equipmentDeleted: undefined, loadingForm: true }
        case driverTypes.UPDATE_DRIVER_SUCCESS:
            return { ...state, errorForm: '', response: action.payload, loadingForm: false }
        case driverTypes.ADD_EQUIPMENT_SUCCESS:
            return { ...state, errorForm: '', equipmentAdded: action.payload, loadingForm: false }
        case driverTypes.REMOVE_EQUIPMENT_SUCCESS:
            return { ...state, errorForm: '', equipmentDeleted: action.payload, loadingForm: false }
        case driverTypes.UPDATE_DRIVER_FAILURE:
            return { ...state, errorForm: action.payload, equipmentAdded: undefined, equipmentDeleted: undefined, loadingForm: false }
        case driverTypes.REMOVE_DRIVER_SUCCESS:
            return { ...state, errorForm: '', onDeleteSuccess: true, loadingForm: false }
        case driverTypes.INIT_DRIVER_FORM:
            return initialState;
        default:
            return state;
    }
}

export default driverReducer;
