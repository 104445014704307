import Helper from '../../helper/Helper';
import React, { useEffect, useRef, useState } from 'react';

import './LoginDriverPage.scss';
import AuthService from '../../services/auth.service';
import { GraphqlService } from '../../services/graphql.service';
import ICONS from '../../../assets/svg';
import environments from '../../../environments/environments';
import { useLocation } from 'react-router';
import logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { gql } from 'apollo-boost';

const LoginDriverPage = () => {
    const [username, setUserName] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [code, setCode] = useState('');
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const {pathname} = useLocation();
    const userRef = useRef<HTMLInputElement>();
    const passwordRef = useRef<HTMLInputElement>();
    const codeRef = useRef<HTMLInputElement>();
    

    const clear = () => {
        setUserName('')
        setPassword('')
    }

    const array = pathname.split('/');

    array.splice(0, 3);

    // console.log()
    const uri = array.join('/');
    const isValid = () => username.trim() && password.trim();

    async function loadDriverData(is_origin, token) {

        try {
            Helper.UseApiKey();
            const query = gql`
                query($token:String, $is_origin:Boolean){
                    get_driver_info_by_inspection_token(is_origin:$is_origin, token:$token){
                        username
                        code
                    }
                }
            `;
            const data = await GraphqlService.SendMutation(query, {is_origin, token});
            setUserName(data.username);
            setCode(data.code);
            setTimeout(() => {
                setPassword('')
            }, 1000);
        } catch (ex) {
            console.log(ex.message);
        }

    }
    useEffect(() => {
        const temp = uri.split('/').filter(x => x.trim() != '');
        if (temp.length == 3 && temp[0] == 'pending-inspection')
        {
            loadDriverData(temp[1]?.toLowerCase() == 'true', temp[2]);
        }
}, []);

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        let data = {
            username,
            password
        }


        if (!isValid() || loading) return;

        setLoading(true);
        fetch(environments.LOGIN_DRIVER_LINK, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'secret'
            },
            method: 'POST',
            body: JSON.stringify({username, password, carrier_code: code})
        })
        .then(d => d.json())
        .then((result) => {
            if (result.token)
            {
                setLoading(false);
                AuthService.TOKEN = result.token;

                localStorage.clear();
                localStorage.setItem('driver_token', AuthService.TOKEN);

                Helper.Navigation.NavigateTo(`/${uri.replace('/driver', '') || 'history'}`);

            }
            else{
                setLoading(false);
                setErrorText(result.message)
            }
        })
        .catch(err => {
            setLoading(false)
            setErrorText(err.message)
        })


    }

    useEffect(() => {

    }, [])

    return (
        <>
        <div className='login-container'>
            <img src={process.env.PUBLIC_URL + '/favicon.png'} style={{width: '100%', display: 'block', margin: '0 auto 1rem auto'}} alt="" />
            <h4>Log In</h4>

            <form onSubmit={handleSubmit.bind(this)} autoComplete='false'>
                <div className='input-group'>
                    <img src={ICONS.IconUser} className='left' />
                    <input ref={userRef} value={username} onChange={(evt) => setUserName(evt.target.value)} id='name' name='username' placeholder='Username' />
                    {username != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setUserName(''); userRef.current?.focus()}} /> : null}
                </div>
                <div className='input-group'>
                    <img src={ICONS.IconLocked} className='left' />
                    <input ref={passwordRef} autoComplete='false' type='password' value={password} onChange={(evt) => setPassword(evt.target.value)} id='password' name='password' placeholder='Password' />
                    {password != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setPassword(''); passwordRef.current?.focus()}} /> : null}
                </div>

                <div className='input-group'>
                    <img src={ICONS.IconBusiness} className='left' />
                    <input ref={codeRef} autoComplete='off' type='text' value={code} onChange={(evt) => setCode(evt.target.value)} id='code' name='code' placeholder='Business Code' />
                    {code != '' ? <img src={ICONS.IconDelete} className='right'  onClick={(evt) => {setCode(''); codeRef.current?.focus()}} /> : null}
                </div>

                {errorText && <p className='wrong'>{errorText}</p>}

                <button disabled={!isValid() || loading}
                        type='submit'>LOG IN{loading && <span className='doing-login'>...<span></span></span>}</button>

<Link to='/forgot-password' className="font-12 d-block text-light-blue cursor-pointer mt-3">Forgot or don't have a password? Click here</Link>
            </form>
        </div>


        </>
    )
}

export default LoginDriverPage;
