import React, {useState, useRef, useEffect} from 'react';
import {useParams} from 'react-router';
import Helper from '../../../../helper/Helper';
import Content from '../../components/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import InspectionCar from '../../../../../assets/images/inspection_car.png'
import './VehicleDamages.scss'
import ICONS from '../../../../../assets/svg';
import ReactModal from 'react-modal';
import Camera from '../../components/Camera/Camera';
import { GraphqlService } from '../../../../services/graphql.service';
import { gql } from 'apollo-boost';
import {Damage, PendingInspection} from '../../../../classes/classes';
//import useLoading from '../../../../hooks/useLoading/useLoading';
import { useToasts } from 'react-toast-notifications';
import InspectionObservable from '../../observables/InspectionObservable';
import Loading from '../../../../components/Loading/Loading';
import useLoading from '../../../../hooks/useLoading/useLoading';

const DamageTypeList = [
    'Broken', 'Cracked', 'Dented', 'Faded', 'Flat Tire', 'Foreing Fluid', 'Gouge', 'Hail Damege', 'Loose Contents', 'Missing', 'Major Damege', 'Multiple Scratches', 'Paint Chip', 'Rubbed', 'Rust', 'Scratched', 'Scuffed', 'Other'
]

const DAMAGE_TYPE = "DamageType"
const TAKE_PHOTO = "TakePhoto"
const CAMERA = "Camera"
const PREVIEW_DAMAGE='PreviewDamage'


const VehicleDamages = ({onCancel, onSubmit}) => {
    const imgWidth = 403;
    const imgHeight = 611;

    const [previewDamageData, setPreviewDamageData] = useState(null);
    const {token, origin} = useParams<any>();
    const [vehicle, setVehicle] = useState('')
    const [open, setOpen] = useState(false)
    const [sty, setSty] = useState<any>()
    const [zoom, setZoom] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [tab, setTab] = useState('')
    const [lastTap,setLastTap]=useState(0)
    const [device, setDevice] = useState(false)
    const [plusP,setPlusP]=useState(0)
    const [previewDamage,setPreviewDamage]=useState<any>()
    const [position, setPosition] = useState({
        front: false,
        left: false,
        right: false,
        back: false
    })

    const [curDivPosition, setCurDivPosition] = useState(undefined);
    const [divPosition, setDivPosition] = useState([]);
    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());

    const originalDataRef = useRef<PendingInspection>(new PendingInspection());

    const backLink = `/pending-inspection/${origin}/${token}`;
    const forwardLink = `/pending-inspection/${origin}/${token}/required-photos`;

    const canv = useRef<any>()
    const im = useRef<any>()
    const divscrollref = useRef<any>()

    const loading = useLoading();
    const toast = useToasts();

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }


    async function submitDamage(photo, photoName, point, code)
    {

        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';

        const mutation = gql`
        
                ${`
                    
                mutation(
                    $pending_${word}_inspection_id: Int
                    $file: Upload
                    $code: String
                    $point: Point_damage_input
                    ) {
                    upload_pending_${word}_inspection_damage_photo(
                        pending_${word}_inspection_id: $pending_${word}_inspection_id
                        code: $code
                        point: $point
                        file: $file
                    ) {
                        id
                        message
                        success
                    }
                    } 


                `}
        `;
        try {
           loading.open('Submitting Damage...');
           console.log(await dataUrlToFile(photo, photoName));
            const data = await GraphqlService.SendMutation(mutation, {[`pending_${word}_inspection_id`]: originalData.pending_inspection_id, code, point, file: await dataUrlToFile(photo, photoName)});
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong')
            loading.close();
            setCurDivPosition(undefined);
            // setImgName('');
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }



    const radius = () => zoom ? 25 : 16;

    const TAP_TIMEOUT = 750;

    const handleZoom = (e) => {
        let timeOut
        let firstTap =new Date().getTime()
        let lasTapp = firstTap - lastTap;
        if(lasTapp < TAP_TIMEOUT && lasTapp > 0){
            if (zoom === false) {
                setZoom(true)
                const canvas = canv.current
                const img = im.current
                let size1 = canvas.offsetWidth;
                let size2 = canvas.offsetHeight;
                let x = e.pageX - canvas.offsetLeft;
                let y = e.pageY - canvas.offsetTop;
                //console.log(size1 , x , y ,size2)
                y=y+plusP
                if ((x < (size1 / 2)) && (y < (size2 / 2))) {
                    // alert('font-left')
                    setSty({transform: 'scale(2) translate(25%, calc(25% + 1px)'})
                    setPosition({...position, front: true, left: true, right: false, back: false})
                } else if ((x > (size1 / 2)) && (y < (size2 / 2))) {
                    // alert('font-right')
                    setSty({transform: 'scale(2) translate(-25%, calc(25% + 1px)'})
                    setPosition({...position, front: true, left: false, right: true, back: false})
                } else if ((x > (size1 / 2)) && (y > (size2 / 2))) {
                    // alert('right-back')
                    setSty({transform: 'scale(2) translate(-25%, -25%)'})
                    setPosition({...position, front: false, left: false, right: true, back: true})
                } else if ((x < (size1 / 2)) && (y > (size2 / 2))) {
                    // alert('back-left')
                    setSty({transform: 'scale(2) translate(25%, -25%)'})
                    setPosition({...position, front: false, left: true, right: false, back: true})
                }

            }

        }else{
            timeOut =setTimeout(()=>{
                clearTimeout(timeOut)
            },TAP_TIMEOUT )
        }
        setLastTap(firstTap)


    }


    const handleNextZoom = (e, ps) => {
        e.stopPropagation()

        if (position.front === true && position.left === true && ps === 'back') {
            setSty({transform: 'scale(2) translate(25%, -25%)'})
            setPosition({...position, front: false, left: true, right: false, back: true})
        } else if (position.front === true && position.left === true && ps === 'right') {
            setSty({transform: 'scale(2) translate(-25%, calc(25% + 1px)'})
            setPosition({...position, front: true, left: false, right: true, back: false})
        } else if (position.front === true && position.right === true && ps === 'back') {
            setSty({transform: 'scale(2) translate(-25%, -25%)'})
            setPosition({...position, front: false, left: false, right: true, back: true})
        } else if (position.front === true && position.right === true && ps === 'left') {
            setSty({transform: 'scale(2) translate(25%, calc(25% + 1px)'})
            setPosition({...position, front: true, left: true, right: false, back: false})
        } else if (position.back === true && position.left === true && ps === 'front') {
            setSty({transform: 'scale(2) translate(25%, calc(25% + 1px)'})
            setPosition({...position, front: true, left: true, right: false, back: false})
        } else if (position.back === true && position.left === true && ps === 'right') {
            setSty({transform: 'scale(2) translate(-25%, -25%)'})
            setPosition({...position, front: false, left: false, right: true, back: true})
        } else if (position.back === true && position.right === true && ps === 'front') {
            setSty({transform: 'scale(2) translate(-25%, calc(25% + 1px)'})
            setPosition({...position, front: true, left: false, right: true, back: false})
        } else if (position.back === true && position.right === true && ps === 'left') {
            setSty({transform: 'scale(2) translate(25%, -25%)'})
            setPosition({...position, front: false, left: true, right: false, back: true})
        }
    }


    const handleSelectPartDamege = (e) => {
        let img = document.getElementById('img');
        let width = img.scrollWidth;
        let height = img.scrollHeight;

        if (zoom === true) {
            e.stopPropagation();
            const img = im.current
            const canvas = canv.current
            let x = e.pageX - canvas.offsetLeft;
            let y = e.pageY - canvas.offsetTop;

            let pos = 1;
            if (position.left && position.back) pos = 2;
            if (position.right && position.back) pos = 3;
            if (position.right && position.front) pos = 4;

            let posDiv = calculateCoordinates(width, height, x, y+plusP, pos);
            console.log('calculateCoordinates');
            console.log(JSON.stringify(posDiv));
            setCurDivPosition(posDiv);
            setDivPosition([...divPosition, posDiv]);

            setTab(DAMAGE_TYPE)

            setTimeout(() => {
                setOpenModal(true)
            }, 400)
        }
    }

    const takePhoto2 = (e) => {
        var image = new Image();
        var reader = new FileReader();
        console.log(e.target.files[0].name)
        reader.onloadend = function() {
            setCurDivPosition({...curDivPosition, photo: reader.result.toString(), photoName: e.target.files[0].name});
        }
        reader.readAsDataURL(e.target.files[0]);

    }

    const MobileC = () => {
        if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) {
            return setDevice(true)
        } else {
            return setDevice(false)
        }
    }

    const getCoordinates = (divPosition) : any => {
        if (zoom) {
            let pos = 1;
            if (position.left && position.back) pos = 2;
            if (position.right && position.back) pos = 3;
            if (position.right && position.front) pos = 4;

            return {
                top: `${divPosition.zoomY - radius()}px`,
                left: `${divPosition.zoomX - radius()}px`,
                visibility: pos === divPosition.position ? 'visible' : 'hidden'
            }
        }

        return { top: `${divPosition.originalY - radius()}px`, left: `${divPosition.originalX - radius()}px`, visibility: 'visible' };
    }

    const refactorPositions = (newDamages = divPosition) => {
        if (newDamages.length === 0) return;

        let img = document.getElementById('img');
        let width = img.scrollWidth;
        let height = img.scrollHeight;

        let temp = [...newDamages];
        let newTemp = temp.map(point => {
            let originalX = (point.x * width) / 100;
            let originalY = (point.y * height) / 100;

            return {...point, originalX, originalY};
        });

        setDivPosition([...newTemp]);
    }

    const calculateCoordinates = (width, height, zoomX, zoomY, position) => {
        let originalX = (zoomX * width) / 100;
        let originalY = (zoomY * height) / 100;

        // calculate original x, y
        let x = (zoomX / width) * 50;
        let y = (zoomY /height) * 50;

        switch (position) {
            case 4:
                x += 50;
                break;
            case 3:
                x += 50;
                y += 50;
                break;
            case 2:
                y += 50;
                break;
        
            default:
                break;
        }

        return { x, y, zoomX, zoomY, originalX, originalY, position, code: '', photo: '', photoName: '' }
    }

    const showDamages = (damages: Damage[]) => {
        let img = document.getElementById('img');
        let width = img.scrollWidth;
        let height = img.scrollHeight;

        // to know in what position (top-left, top-right, bot-left, bot-right) dots needs to be show
        let leftLimit = 50;
        let topLimit = 50;

        let zoomWidth = width * 2;
        let zoomHeight = height * 2;

        let temp = damages.map(damage => {
            let position = 1;

            let originalX = (damage.point.x * width) / 100;
            let originalY = (damage.point.y * height) / 100;

            let multiplierX = 2 * (width / 100);
            let multiplierY = 2 * (height / 100);
            // calculate position:
            // (top-left: 1, top-right: 4, bot-left: 2, bot-right: 3)
            if (damage.point.y > topLimit && damage.point.x <= leftLimit) position = 2;
            if (damage.point.y > topLimit && damage.point.x > leftLimit) position = 3;
            if (damage.point.y <= topLimit && damage.point.x > leftLimit) position = 4;

            let zoomX = damage.point.x, zoomY = damage.point.y;
            switch (position) {
                case 4:
                    zoomX = damage.point.x - 50;
                    break;
                case 3:
                    zoomX = damage.point.x - 50;
                    zoomY = damage.point.y - 50;
                    break;
                case 2:
                    zoomY = damage.point.y - 50;
                    break;
                default:
                    break;
            }

            zoomX = (zoomX) * multiplierX;
            zoomY = (zoomY) * multiplierY;

            return {
                zoomX, zoomY, x: damage.point.x, y: damage.point.y, originalX, originalY,
                code: damage.code,photo: damage.uid, photoName: '', position, color: damage.stop_id == originalDataRef.current.stop_id ? 'color-red' : 'color-blue'
            }
        });

        setDivPosition(temp);
        return temp;
    }

    useEffect(() => {
        MobileC();
    }, []);

    useEffect(() => {
        originalDataRef.current.vehicle_data = originalData.vehicle_data;
        originalDataRef.current.note = originalData.note;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
        originalDataRef.current.damages = originalData.damages;
        originalDataRef.current.flags = originalData.flags;
    }, [originalData]);

    useEffect(() => {
        let query = InspectionObservable.GetObservable(token, origin?.toLowerCase() == 'true');

        let subs = query.onResults.subscribe(result => {
            if (result.error)
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            else
            {
                console.log('something changed');
                let o = new PendingInspection(result.data);
                o.is_origin = origin?.toLowerCase() == 'true';
                let newDamages = showDamages(o.damages);
                refactorPositions(newDamages);
                window.addEventListener("resize", () => refactorPositions(newDamages));
                const damagesDiff = JSON.stringify(originalDataRef.current.damages) != JSON.stringify(o.damages);

                const flagsDiff = JSON.stringify(originalDataRef.current.flags) != JSON.stringify(o.flags);

                if (damagesDiff || flagsDiff || originalDataRef.current.pending_inspection_id == null)
                {
                    setCurrentData(new PendingInspection(result.data));

                    setOriginalData(o);

                    
                    if (originalDataRef.current.pending_inspection_id != null)
                    {
                        if (damagesDiff)
                            toast.addToast(`USER has updated inspection damage`, {appearance: 'warning', autoDismiss: true});
                        if (flagsDiff)
                            toast.addToast(`USER has updated inspection flag`, {appearance: 'warning', autoDismiss: true});

                    }
                        
                }
            }
        });

        return () => {
            window.removeEventListener("resize", () => refactorPositions(divPosition));
            subs.unsubscribe();
            query.stopPolling();
        }
    }, []);

    const handleOnSelectDamageCode = code => {
        let temp = {...curDivPosition};
        temp.code = code.replace(' ', '');

        setCurDivPosition({...temp});
        setTab(TAKE_PHOTO);
    }

    async function handleSaveFlag(key, checked) {
        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination'
        const mutation = gql`
        
            ${`
                mutation($pending_${word}_inspection_id:Int, $flags:Flags_input){
                    update_flag_pending_${word}_inspection(pending_${word}_inspection_id:$pending_${word}_inspection_id, flags:$flags){
                        id
                        message
                        success
                    }
                }

            `}
          
        `;
            const variables = {[`pending_${word}_inspection_id`]: originalData.pending_inspection_id,
                flags: {
                    ...originalData.flags[0],
                    [key]: checked,
                }
            };



        console.log(variables);

        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            console.log(data);
            if (!data?.message)
                throw new Error(data?.message || 'Something went wrong');
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    const handleOnSaveDamagePhoto = () => {
        let temp = [...divPosition];

        let index = temp.findIndex(e =>
            e.x === curDivPosition.x &&
            e.y === curDivPosition.y &&
            e.originalX === curDivPosition.originalX &&
            e.originalY === curDivPosition.originalY &&
            e.position === curDivPosition.position);

        if (index !== -1) {
            temp[index] = {...curDivPosition};
            //temp[index] = {...curDivPosition, photo};
            setDivPosition([...temp]);

            setOpenModal(false);
            console.log(curDivPosition);
            submitDamage(curDivPosition.photo, curDivPosition.photoName, {x: temp[index].x, y: temp[index].y}, temp[index].code);
        }
    }

    const handleOnCloseModal = () => {
        if (curDivPosition) {
            removeDivPosition();
        }

        setOpenModal(false);
    }
    const ScrollPlus =()=>{
        let scrollTopDiv = divscrollref.current.scrollTop
        setPlusP(scrollTopDiv)
      }

      useEffect(() => {
        divscrollref.current.addEventListener('scroll', ScrollPlus);
      }, );

    const removeDivPosition = () => {
        let temp = [...divPosition];

        let index = temp.findIndex(e =>
            e.x === curDivPosition.x &&
            e.y === curDivPosition.y &&
            e.originalX === curDivPosition.originalX &&
            e.originalY === curDivPosition.originalY &&
            e.position === curDivPosition.position);

        if (index !== -1) {
            temp.splice(index, 1);
            setDivPosition([...temp]);
            setCurDivPosition(undefined);
        }
    }

    async function getTempLink(is_thumbnail = true,image) {
        const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link_new_inspections(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;
        const variables = {uid: image, is_thumbnail}

        try {
            console.log(variables);
            const data = await GraphqlService.SendQuery(query, variables);
            console.log(data);
            return data;

        } catch (ex) {
            return '';
        }
    }

    const deleteDamage = async (uid) =>{
        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';
        console.log(word);
        const mutation = gql`
            ${`mutation($pending_${word}_inspection_id:Int, $uid:String){
                    delete_damage_pending_${word}_inspection(pending_${word}_inspection_id:$pending_${word}_inspection_id, uid:$uid){
                        id
                        message
                        success
                    }
            }`}
        `;

        const variables = {
            [`pending_${word}_inspection_id`]: originalDataRef.current.pending_inspection_id,
            uid
        };




        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data.success)
            {
                throw new Error(data?.message || 'Something went wrong');
            }
            console.log(data);
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    return <>
    <Toolbar vehicle={currentData.vehicle_data} driver_name={currentData.driver_name} />

        <Content>
            <div className="mb-3 d-flex justify-content-between">
                <div>
                    <h4 className="font-11">Vehicle Damages</h4>
                    <h5 className="font-10 font-medium" style={{color:'red'}}>Double tap on a quadrant to focus</h5>
                </div>

                {zoom &&

                <div onClick={() => {
                    setSty({transform: 'scale(1) translate(0, 0'});
                    setPosition({...position, front: false, left: false, right: false, back: false})
                    setZoom(false)
                }}
                className="zoom-out-button">
                    ZOOM OUT
                </div>

                }
            </div>
            <div ref={divscrollref} className='flex-1-container'>
                <div className="row gx-0">
                    <div className="col-sm-4 col-12"></div>

                    <div className="col-sm-4 col-12 d-flex justifty-content-center flex-column">
                        <div className="d-flex justify-content-between ">
                            <p className="font-10 text-gray font-medium ">Front Left</p>
                            <p className="font-10 text-gray font-medium ">Front Right</p>
                        </div>

                        <div ref={canv} style={{overflow: 'hidden'}} className="position-relative image-zoom-wrapper">
                            <img
                                id={`img`}
                                //onDoubleClick={(e) => handleZoom(e)}
                                onClick={(e) => {handleZoom(e);handleSelectPartDamege(e)}}
                                ref={im} style={sty} src={InspectionCar}
                                className="  image-zoom"/>
                            {
                                divPosition.map((x, key) => {
                                    return (<div onClick={()=>{
                                        const tempIndex = divPosition.indexOf(x) 
                                        if(originalData.damages[tempIndex]){
                                            setPreviewDamage('')
                                            setTab(PREVIEW_DAMAGE);
                                            getTempLink(false,originalData.damages[tempIndex].uid).then(x => setPreviewDamage(x))
                                            setPreviewDamageData(originalData.damages[tempIndex]);
                                            setOpenModal(true)
                                        }
                                    }} key={key} className={`partDamege-Select position-absolute ${x.color} no-select ${zoom ? '' : 'small'}`}
                                                 style={getCoordinates(divPosition[key])}>
                                        { divPosition[key]?.code }
                                    </div>)
                                })
                            }

                            <>
                                {
                                    position.back && position.left &&
                                    <button onClick={(e) => handleNextZoom(e, 'front')}
                                            className={'position-absolute zoom-btn front'}
                                            style={{top: '80px', left: '42%'}}><img src={ICONS.IconArrowRight}/>
                                    </button>
                                }
                                {
                                    position.back && position.right &&
                                    <button onClick={(e) => handleNextZoom(e, 'front')}
                                            className={'position-absolute zoom-btn front'}
                                            style={{top: '80px', left: '42%'}}><img src={ICONS.IconArrowRight}/>
                                    </button>
                                }

                                {
                                    position.front && position.left &&
                                    <button onClick={(e) => handleNextZoom(e, 'back')}
                                            className={'position-absolute zoom-btn back'}
                                            style={{bottom: '80px', left: '42%'}}><img src={ICONS.IconArrowLeft}/>
                                    </button>
                                }
                                {
                                    position.front && position.right &&
                                    <button onClick={(e) => handleNextZoom(e, 'back')}
                                            className={'position-absolute zoom-btn back'}
                                            style={{bottom: '80px', left: '42%'}}><img src={ICONS.IconArrowLeft}/>
                                    </button>
                                }
                                {
                                    position.right && <button onClick={(e) => handleNextZoom(e, 'left')}
                                                              className={'position-absolute zoom-btn '}
                                                              style={{top: '42%', left: '20px'}}><img
                                        src={ICONS.IconArrowLeft}/></button>
                                }
                                {
                                    position.left && <button onClick={(e) => handleNextZoom(e, 'right')}
                                                             className={'position-absolute zoom-btn '}
                                                             style={{top: '42%', right: '20px'}}><img
                                        src={ICONS.IconArrowRight}/></button>
                                }
                            </>
                        </div>

                        <div className="d-flex justify-content-between ">
                            <p className="font-10 text-gray font-medium">Back Left</p>
                            <p className="font-10 text-gray font-medium">Back Right</p>
                        </div>
                    </div>
                    <div className="col-sm-4 col-12"></div>
                </div>

                <div className="col-12 mb-3 mt-3">
                    <p className="font-12 font-medium">Inspection not performed</p>

                    <div className="row gx-0 mt-3">
                        <FormGroup name={'too_dirty'}
                                    onCheckChanged={(evt) => handleSaveFlag('too_dirty', evt)}
                                   checked={originalData?.flags[0]?.too_dirty}
                                   colSize={6}
                                   type={'checkbox'}
                                   className={"d-flex "}
                                   label={'Too dirty'}
                                   onTextChange={value => console.log(value)}/>

                        <FormGroup name={'too_dark'}
                                    onCheckChanged={(evt) => handleSaveFlag('too_dark', evt)}
                                   checked={originalData?.flags[0]?.too_dark}
                                   colSize={6}
                                   type={'checkbox'}
                                   className={"d-flex"}
                                   label={'Too dark'}
                                   onTextChange={value => console.log(value)}/>


                        <FormGroup name={'raining'}
                                    onCheckChanged={(evt) => handleSaveFlag('raining', evt)}
                                   checked={originalData?.flags[0]?.raining}
                                   colSize={6}
                                   type={'checkbox'}
                                   className={"d-flex"}
                                   label={'raining'}
                                   onTextChange={value => console.log(value)}/>
                    </div>
                </div>
            </div>

            <div className="row  mt-3">
                <div className="col-6">
                <button onClick={(evt) => Helper.Navigation.NavigateTo(backLink)} className="btn btn-back w-100"><i
                    className="fas fa-chevron-left "></i> VEHICLE DETAILS
                </button>
                </div>
                <div className="col-6">
                <button onClick={(evt) => Helper.Navigation.NavigateTo(forwardLink)}
                        className="btn btn-skyblue w-100">NEXT: 10 PHOTOS
                </button>
                </div>
            </div>

            <ReactModal className={`${tab === CAMERA ? '' : 'modal-fit modal-90'}`} isOpen={openModal}
                        onRequestClose={evt => {handleOnCloseModal()}}>
                <div className={`mb-3 pl-3 ${tab === CAMERA || tab === PREVIEW_DAMAGE ? 'd-none' : 'row'}`}>
                    <div className="col-12 ">
                        <h4 className="font-14">{tab === DAMAGE_TYPE ? 'Damage Type' : 'Damage Photo'}</h4>
                    </div>
                </div>
                {
                    tab === PREVIEW_DAMAGE && 
                    <>
                        <div>
                            {
                               !previewDamage?<Loading/>: <img  style={{height: '300px', width: '100%'}}src={previewDamage}/>
                            }
                        </div>

                        <div className="row mt-4">
                            <div className="col-6 text-right">
                                <button className="btn w-100 py-2 bg-white rounded-pill font-12 text-light-blue"
                                        onClick={() => {
                                            setOpenModal(false);
                                            setPreviewDamageData(null);
                                        }}>CANCEL
                                </button>
                            </div>
                            <div className="col-6">
                     
                                <button className={" w-100 py-2 btn btn-skyblue rounded-pill fs-12  font-medium-i"}
                                onClick={()=>{
                                    setOpenModal(false);
                                    deleteDamage(previewDamageData.uid)
                                    setPreviewDamageData(null);
                                }}>
                                        DELETE DAMAGE
                                </button>
                            </div>
                            </div>

                    </>
                }

                {
                    tab === DAMAGE_TYPE &&
                    <>

                        <div className="row">
                            {
                                DamageTypeList.map((x, key) => {
                                    return (<>
                                        <div key={key}
                                             className="col-3 mt-2 flex-column d-flex justify-content-center align-items-center">
                                            <div onClick={() => handleOnSelectDamageCode(x.replace(/[a-z]/g, ''))}
                                                 className="partDamege-Select color-blue">
                                                <span className={'text-center no-select'}>{x.replace(/[a-z]/g, '')}</span>
                                            </div>
                                            <span className={'fs-12 font-normal mt-2 text-center no-select'}>{x}</span>
                                        </div>
                                    </>)
                                })
                            }
                        </div>
                    </>

                }
                {
                    tab === TAKE_PHOTO &&
                    <>
                        <div>
                            <img className={`${curDivPosition?.photo ? '' : 'invisible'}`} style={{height: '300px', width: '100%'}}
                                 src={curDivPosition?.photo} alt=""/>
                        </div>

                        <div className="row mt-4">
                            <div className="col-6 text-right">
                                <button className="btn btn-skyblue-outline w-100 py-2 bg-white rounded-pill  fs-12"
                                        onClick={() => {
                                            removeDivPosition();
                                            setOpenModal(false);
                                        }}>CANCEL
                                </button>
                            </div>
                            <div className="col-6">
                                {!curDivPosition?.photo && <input onChange={(e) => takePhoto2(e)}
                                                      className={`input-capture ${device ? '' : 'd-none'}`} type="file"
                                                      accept="image/*" capture="environment"/>}
                                <button className={"btn btn-skyblue w-100 py-2 rounded-pill fs-12  font-medium-i"}
                                        onClick={() => {
                                            if (!curDivPosition?.photo) setTab(CAMERA);
                                            else handleOnSaveDamagePhoto();
                                        }}>
                                    {curDivPosition?.photo ? 'Save' : 'Take Photo'}
                                </button>
                            </div>
                        </div>
                    </>
                }
                {
                    tab === CAMERA && <Camera onCancel={() => {
                        setOpen(false);
                        setTab(TAKE_PHOTO)
                    }} onSetImage={(data, value) => {
                        setCurDivPosition({...curDivPosition, photo: data});
                        setTab(TAKE_PHOTO)
                    }}/>
                }
            </ReactModal>
        </Content>
    </>
}

export default VehicleDamages;
