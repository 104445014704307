import React,{useState,useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import Helper from '../../../../helper/Helper';
import Content from '../../components/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
import CardInspection from '../../components/CardInspection/CardInspection';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import Camera from '../../components/Camera/Camera';
import ReactModal from 'react-modal';
import { PendingInspection, RequiredPhoto } from '../../../../classes/classes';
import InspectionObservable from '../../observables/InspectionObservable';
import { useToasts } from 'react-toast-notifications';
import {gql} from 'apollo-boost';
import useLoading from '../../../../hooks/useLoading/useLoading';
import { GraphqlService } from '../../../../services/graphql.service';
import Loading from '../../../../components/Loading/Loading';
import { Console } from 'console';

const partVehicleList =[
    {
        name:'Front',
        image:'',

    },
    {
        name:'Front_Left',
        image:''
    
    },
    {
        name:'Left',
        image:''
    
    },
    {
        name:'Left_Back',
        image:''
    
    },
    {
        name:'Back',
        image:''
    
    },
    {
        name:'Back_Right',
        image:''
    
    },
    {
        name:'Right',
        image:''
    
    },
    {
        name:'Right_Front',
        image:''
    
    },
    {
        name:'Top',
        image:''
    
    },
]


const CAMERA = "Camera"
const PREVIEW_PHOTO='PreviewPhoto'

const RequiredPhotos = () => {
    const {token, origin} = useParams<any>();
    const [vehicle,setVehicle]=useState('')
    const[open,setOpen]=useState(false)
    const [openOdometer,setOpenOdometer]=useState(false)
    const[images,setImges]=useState('')
    const [data,setData]=useState({})
    const[para,setPara]=useState<string>('')
    const[device,setDevice]=useState(false)
    const[valueOdometer,setValueOdometer]=useState(null);
    const [tab,setTab]=useState('')
    const [previewPhoto,setPreviewPhoto]=useState('')
    const [ImageTemp,setImageTemp]=useState(undefined)
    const [tempChecked,setTempChecked]=useState(false)

    const loading = useLoading();
    const toast = useToasts();

    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());
    const originalDataRef = useRef<PendingInspection>(new PendingInspection());

    //const loading = useLoading();


    const backLink = `/pending-inspection/${origin}/${token}/vehicle-damages`;
    const forwardLink = `/pending-inspection/${origin}/${token}/accessories`;

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: 'image/png' });
    }


    const MobileC =()=>{
        if(navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)){
            return setDevice(true)
        }else {
            return setDevice(false)
        }
    }

    useEffect(()=>{
         
        
        MobileC();

        let query = InspectionObservable.GetObservable(token, origin?.toLowerCase() == 'true');
        
        let subs = query.onResults.subscribe(result => {
            if (result.error)
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            else
            {
                let o = new PendingInspection(result.data);
                o.is_origin = origin?.toLowerCase() == 'true';
                if (originalDataRef.current.pending_inspection_id != null)
                {
                    const msg = originalDataRef.current.odometer != o.odometer ? `A user has changed odometer to: ${o.odometer == -1 ? 'CANNOT READ' : o.odometer}` : `A user uploaded mandatory photo`
                    toast.addToast(msg, {appearance: 'warning', autoDismiss: true});   
                }
                setCurrentData(new PendingInspection(result.data));
                    
                setOriginalData(o);
            }
        });

        return () => {
            subs.unsubscribe();
            query.stopPolling();
        }
      
    }, []);

    useEffect(() => {
        originalDataRef.current.odometer = originalData.odometer;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
    }, [originalData]);


    const getOdometerBox = () => {
        const odometer = new RequiredPhoto(originalData.photos.find(x => x.type == 'Odometer'));
        return <CardInspection onPhotoTaken={(evt) => {
            let reader = new FileReader();
            reader.onloadend = function() {
                uploadImage('Odometer',reader.result.toString(), evt.name)
            }
            reader.readAsDataURL(evt);
            setOpenOdometer(true)
        } } onSubmit={(data,value)=>{setValueOdometer(value)}} image={odometer.uid} name={'Odometer'} onSelect={name =>{setPara(name);toggleShowModal(odometer.uid)}}/>
    }

    async function updateOdometer(odometer = +valueOdometer) {
        try {
            const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';
            const mutation = gql`
                mutation($pending_${word}_inspection_id: Int, $odometer:Float){
                    update_odometer_pending_${word}_inspection(pending_${word}_inspection_id:$pending_${word}_inspection_id, odometer:$odometer){
                    id
                    message
                    success
                }
            }
            `;
            loading.open('Updating odometer...');
            const variables = {[`pending_${word}_inspection_id`]: originalData.pending_inspection_id, odometer};

            const data = await GraphqlService.SendMutation(mutation, variables);
            loading.close();
            setValueOdometer(null);
            setOpenOdometer(false);
        } catch (ex) {
            console.log('ex', ex.message);
           loading.close();
        }
    }

    async function uploadImage(type, file,fileName) {
        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';
            const mutation = gql`
                
                mutation($pending_${word}_inspection_id: Int, $type: String, $file: Upload) {
                    upload_mandatory_photo_pending_${word}_inspection(
                        pending_${word}_inspection_id: $pending_${word}_inspection_id
                        type: $type
                        file: $file
                    ) {
                        id
                        message
                        success
                    }
                }
            `;
            

        const variables = {[`pending_${word}_inspection_id`]: originalDataRef.current.pending_inspection_id, type, file :await dataUrlToFile(file, fileName)}

        console.log(variables)


        loading.open(`Uploading image for ${type}...`);

        try {
            const data = await GraphqlService.SendMutation(mutation, variables);
            if (!data?.success)
                throw new Error(data?.message || 'Something went wrong');
           loading.close();
            console.log(originalData.odometer, type);
            setOpen(false)
            if (type == 'Odometer')
                setValueOdometer(originalData.odometer == -1 ? '' : (originalData.odometer?.toString() || ''))
                
            // toast.addToast(data.message, {appearance: 'success', autoDismiss: true});
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
    }

    async function getTempLink(is_thumbnail = false,image) {
        const query = gql`
            query($uid:String, $is_thumbnail:Boolean){
                get_temp_link_new_inspections(uid:$uid is_thumbnail:$is_thumbnail)
            }
        `;
        const variables = {uid: image, is_thumbnail}

        try {
            const data = await GraphqlService.SendQuery(query, variables);
            return data;

        } catch (ex) {
            return '';
        }
    }

    const toggleShowModal=(item)=>{
        setPreviewPhoto('')
        if(item){
            getTempLink(true,item).then(x=>setPreviewPhoto(x))
            setTab(PREVIEW_PHOTO)
            setOpen(true)
        }else if(!device && !item){
            setTab(CAMERA)
            setOpen(true)
        } 
    }

    return <>
    <Toolbar vehicle={currentData.vehicle_data} driver_name={currentData.driver_name} />
                
                <Content>
                    <div className="mb-3">
                        <h4 className="font-11">Required Photos</h4>
                        <h5 className="font-10 font-normal">Tap on an icon to take a photo</h5>
                    </div>
                    <div className='flex-1-container'>
                    {/* <div className=""></div> */}
                    {/*<h4 className="font-14 text-center flex-1-container justify-content-center align-items-center">CONTENT</h4>*/}
                            <div className="" style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: "hidden"}}>



                        <div className={`row w-100 gx-2 d-flex`} style={{flex: 1, overflowY: "auto"}}>


                        <div className="col-12 mb-3 mt-3">
                                <p className="font-12 font-medium">Odometer</p>
                                

                                <div className="row w-100 gx-2 mt-3">
                                    <div className="col-3">
                                
                                        <div className="col-12 position-relative">
                                            {getOdometerBox()}
                                        </div>

                                    </div>

                                    <div className="col-9">

                                    <div className="row flex-column">

                                        <FormGroup  name={'odometer'}
                                            value={originalData.odometer == -1 ? 'CANNOT READ' : originalData.odometer?.toString()}
                                            label={'Reading'}
                                            readOnly
                                            //onClick={(evt) => setValueOdometer(originalData.odometer == -1 ? '' : (originalData.odometer?.toString() || ''))}
                                            onClick={()=>setOpenOdometer(true)}
                                            />


                                        <FormGroup  name={'not_reached'}
                                                    checked={originalData.odometer == -1}
                                                    type={'checkbox'}
                                                    className={"d-flex "}
                                                    label={'Cannot be reached'}
                                                    //onClick={(evt) => setValueOdometer(originalData.odometer == -1 ? '' : (originalData.odometer?.toString() || ''))}
                                                    onClick={()=>setOpenOdometer(true)}
                                                    onTextChange={value => console.log(value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 mb-3 mt-3">
                                <p className="font-12 font-medium mb-3">Exterior</p>

                                <div className="row w-100 gx-2">

                                    {
                                        originalData.photos.filter(x => x.type != 'Odometer').map(x=>{
                                            return( 
                                                <>
                                                  <div className="col-3 position-relative mb-3">
                                                    <CardInspection onPhotoTaken={(evt) => {
                                                            let reader = new FileReader();
                                                            reader.onloadend = function() {
                                                                uploadImage(x.type,reader.result.toString(), evt.name)
                                                                }
                                                            reader.readAsDataURL(evt);
                                        
                                                        
                                                    }} onSubmit={()=>console.log('camera')} image={x.uid} name={x.type} onSelect={name =>{setPara(x.type);toggleShowModal(x.uid)}}/>
                                                </div>
                                                </>
                                            )
                                        })
                                    }

                                </div>
                        </div>
                    
                        </div>
                                        
                        <ReactModal className={`${tab === CAMERA ? '' : 'modal-fit modal-90'}`} isOpen={open} onRequestClose={evt => setOpen(false)}>
                            {
                                tab === CAMERA  && <Camera formOdometer={para === 'Odometer' && true} onCancel={()=>setOpen(false)} onSetImage={(data,value)=>{setImges(data);setData({...data , [para]: data});setValueOdometer(value);
                                uploadImage(para,data,'');para === 'Odometer' && setOpenOdometer(true)
                                }}/>
                            } 
                            {
                                tab === PREVIEW_PHOTO && 
                                <>
                                    <div>
                                        {
                                            !previewPhoto?<Loading/>: <img  style={{height: '300px', width: '100%'}}src={previewPhoto}/>
                                        }
                                    </div>
            
                                    <div className="row mt-4">
                                        <div className="col-6 text-right">
                                            <button className="btn btn-skyblue-outline w-100 py-2  rounded-pill font-12 text-light-blue"
                                                    onClick={() => {
                                                        setOpen(false);
                                                    }}>CANCEL
                                            </button>
                                        </div>
                                        <div className="col-6">
                                
                                            <button className={"btn btn-skyblue w-100 py-2 rounded-pill fs-12  font-medium-i"}
                                            onClick={()=>{
                                                !device && setTab(CAMERA)   
                                                                                             
                                            }}>
                                               {device && <input onChange={(evt) => {
                                                   let reader = new FileReader();
                                                   reader.onloadend = function() {
                                                       uploadImage(para,reader.result.toString(), evt.target.files[0].name)
                                                       }
                                                   reader.readAsDataURL(evt.target.files[0]);
                                               }} className={`input-capture`} type="file"
                                                accept="image/*" capture/>}
                                                    RETAKE PHOTO
                                            </button>
                                        </div>
                                    </div>
        
                                </>
                            }
                        </ReactModal>

                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <button onClick={(evt) => Helper.Navigation.NavigateTo(backLink)} className="btn btn-back w-100 "><i className="fas fa-chevron-left"></i> VEHICLE DAMAGES</button>
                        </div>
                        <div className="col-6">
                            <button onClick={(evt) => Helper.Navigation.NavigateTo(forwardLink)} className="btn btn-skyblue w-100">NEXT: ACCESSORIES</button>

                        </div>
                    </div>
                </Content>

                <ReactModal isOpen={openOdometer} className='modal-fit modal-90'>
                    <div className="row mb-3">
                        <h4 className="font-14">Odometer</h4>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-6">
                            <FormGroup colSize={12} value={valueOdometer?.toString()} onTextChange={(evt) => setValueOdometer(Helper.Masks.IntNumbers(evt))} name='odometer' label='Odometer Reading' required />
                        </div>
                        <div className="col-6">
                            <button className="btn btn-danger font-11 py-2 w-100" onClick={(evt) => updateOdometer(-1)}>CANNOT READ</button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        
                       {/*  <button className="btn btn-clear text-light-blue font-11 py-2 col-6" onClick={(evt) => setValueOdometer(null)}>CANCEL</button> */}
                       <div className="col-6">
                            <button className="btn btn-clear text-light-blue font-11 py-2 w-100" onClick={() => setOpenOdometer(false)}>CANCEL</button>
                       </div>
                       <div className="col-6">
                            <button className="btn btn-blue-light font-11 py-2 w-100" onClick={(evt) => {updateOdometer();setOpenOdometer(false)}}>SUBMIT</button>
                       </div>
                    </div>
                    </ReactModal>
            </>
}

export default RequiredPhotos;