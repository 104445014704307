import React, {useEffect, useState} from "react";
import FormGroup from "../../../../components/FormGroup/FormGroup";
import './VehicleDetailsForm.scss'
import Helper from "../../../../helper/Helper";
//import {getAllMakes, getAllModelsMake, resetModels} from "../../../../redux/actions/Vehicle.action";
import {useDispatch, useSelector} from "react-redux";
import {QuoteVehicleInfo, Vehicle} from "../../../../classes/classes";
import {AllStates} from "../../../../redux/reducers";
import { GraphqlService } from "../../../../services/graphql.service";
import VehicleQueries from "../../../../graphql/query/Vehicle";
import { useToasts } from "react-toast-notifications";
//import {IVehicleState} from "../../../../redux/reducers/Vehicle.reducer";


const VehicleDetailsForm = ({vehicle, onSubmit, onCancel}:{vehicle: Vehicle, onSubmit: (v:Vehicle) => void, onCancel:()=>void}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const toast = useToasts();
    //const [vehicle, setVehicle] = useState(new Vehicle());
    //const {makes, models} = useSelector<AllStates>(x => x.vehicles) as IVehicleState;

    const dispatch = useDispatch();

    const handleOnChanged = (key:
            'id'|
            'vin'|
            'no_vin'|
            'special_vin'|
            'incomplete_vin'|
            'not_running'|
            'year'|
            'make'|
            'model'|
            'submodel'|
            'type'|
            'color'|
            'license_plate'|
            'internal_note', value: any) => {

        let temp = {...vehicle};

        temp[key as string] = value;

        //setVehicle(temp);
        onSubmit(temp);
        // console.log(temp);
    }

    // useEffect(() => {
    //     setVehicle(new Vehicle(data));
    // }, [data]);

    async function decodeVIN(){
        if(loading) return;

        setLoading(true);
        try {
            let res = await GraphqlService.SendQuery(VehicleQueries.DECODE_VIN, {vin: vehicle.vin});
            if(!res ||!res.success){
                throw new Error(res?res.message:'No response from NHTSA');
            }
            onSubmit({...vehicle, ...res.vehicle})
        } catch (ex) {
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
        }
        setLoading(false);
    }

    return (
        <>
            
         
            <div className="col-12 mb-2">
                <p className="fs-12">VIN Type*</p>
            </div>

            <div className="row mt-4 gx-2 w-100" >
                <div className="col-6 pb-1">
                    <button className={`btn-vin ${!vehicle.special_vin && !vehicle.no_vin ? 'selected-vin' : ''}`}
                        // onClick={() => changeVinType('special_vin', false)}
                    >
                      <span className="d-flex justify-content-between align-items-center">
                          <span>US Standard</span>
                            <span>17 DIGITS</span>
                       </span>
                    </button>
                </div>
                <div className="col-6 pb-1">
                    <button className={`btn-vin ${vehicle.special_vin ? 'selected-vin' : ''}`}
                        //</div>onClick={() => changeVinType('special_vin')}
                    >
                    <span className="d-flex justify-content-between align-items-center">
                            <span>Special</span>
                            <span>ANY DIGITS</span>
                        </span>
                    </button>
                </div>
                <div className="col-6 pb-1">
                    <button className={`btn-vin ${vehicle.no_vin ? 'selected-vin' : ''}`}
                        // onClick={() => changeVinType('no_vin')}
                    >
                        <span className="d-flex justify-content-between align-items-center">NO VIN</span>
                    </button>
                </div>
                </div>


      
                <div className="row w-100 gx-3">
                <div className="col-12 row">
                <FormGroup name={'VIN'}
                           value={vehicle.vin}
                           required={false}
                           label={'VIN'}
                           colSize={vehicle.vin.length == 17 ? 8:12}
                           onTextChange={value => handleOnChanged('vin', value)}/>
                {vehicle.vin.length == 17 && <div className="col-4 font-12 decode-vin-button"
                onClick={() =>decodeVIN()}>
                    {loading?'...':'DECODE'}
                </div>}
                </div>

                
                <FormGroup name={'year'}
                           value={vehicle.year}
                           required={false}
                           colSize={6}
                           label={'Year'}
                           onTextChange={value => handleOnChanged('year', Helper.Masks.IntNumbers(value, 4))}/>

                <FormGroup name={'make'}
                           value={vehicle.make}
                           required={false}
                           colSize={6}
                           label={'Make'}
                           onTextChange={value => handleOnChanged('make', value)}/>

                <FormGroup name={'model'}
                           value={vehicle.model}
                           label={'Model'}
                           type='combobox'
                          // options={{
                            //   data: models,
                              // value: x => x.Model_Name,
                              // label: y => y.Model_Name
                           //}}
                           onBlur={(evt) => {
                             //  dispatch(resetModels());
                           }}
                          // onFocus={(evt) => {
                              // if (vehicle.make.trim() != '')
                              //   dispatch(getAllModelsMake(vehicle.make.trim()))

                          // }}
                           placeholder={'Model'}
                           onTextChange={value => handleOnChanged('model', value)}/>

                <FormGroup name={'submodel'}
                           value={vehicle.submodel}
                           required={false}
                           label={'Submodel'}
                           onTextChange={value => handleOnChanged('submodel',value)}/>

                <FormGroup name={'vehicletype'}
                           value={vehicle.type}
                           required={false}
                           colSize={6}
                           label={'Vehicle type'}
                           type={'select'}
                           placeholder={vehicle.type}
                            //options={{data: type, value: x => x, label: x => x}}
                           onTextChange={value => handleOnChanged('type',value)}/>

                <FormGroup name={'color'}
                           value={vehicle.color}
                           required={false}
                           colSize={6}
                           label={'Color'}
                           type={'select'}
                           placeholder={vehicle.color}
                           options={{data: ['White','Black','Gray','Silver' ,'Red','Blue','Brown','Green','Beige','Orange' ,'Gold','Yellow','Purple','Pink'], value: x => x, label: x => x}}
                           onTextChange={value => handleOnChanged('color',value)}/>

                <FormGroup name={'license_plate'}
                           value={vehicle.license_plate?.split('@')[0]||''}
                           required={false}
                           colSize={6}
                           label={'License plate'}
                           onTextChange={value => handleOnChanged('license_plate', `${value}@${vehicle.license_plate?.split('@')[1] || ''}`)}/>

                <FormGroup name={'registered_state'}
                           value={vehicle.license_plate?.split('@')[1]?.toUpperCase().substring(0,2) || ''}
                           required={false}
                           colSize={6}
                           label={'Registered state'}
                           type={'select'}
                           placeholder={'--'}
                           options={{data: Helper.States, value: x => x, label: x => x}}
                           onTextChange={value => handleOnChanged('license_plate', `${vehicle.license_plate?.split('@')[0]||''}@${value}`)}/>

                <FormGroup  value={vehicle.internal_note}
                            onTextChange={(value) => handleOnChanged('internal_note', value)}
                            textAreaHeight={'10rem'}
                            className={'mt-3'}
                            placeholder='Inspection notes...' 
                            type='textarea' 
                            name='inspection_notes'
                            label='Notes'
                            />
                </div>
   

            


        </>
    )
}

export default VehicleDetailsForm