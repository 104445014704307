import {gql, ObservableQuery} from "apollo-boost";
import {GraphqlService, ObservableQueryT} from "../../services/graphql.service";
import OrderRequestTypes from "../types/OrderRequests.type";
import {Order, OrderRequest} from "../../classes/classes";
import OrderTypes from "../types/Order.type";

const query = gql`
   query($completed: Boolean){
  get_orders(completed: $completed){
    id
    accepted
    rejected
    cancelled
    expired 
    price
    driver_pay
    completed
    change_requests {
      id
      accepted
      rejected
      active
      expired
      expires_at
      weblink
    }
    pending_change_request
    origin{
      scheduled
      nofail
      contacts{
        name
        value
      }
      time_completed
      completed
      address{
        street_number
        street
        city
        state
        postal_code
        country
      }
    }
    destination{
      scheduled
      nofail
      contacts{
        name
        value
      }
      time_completed
      completed
      address{
        street_number
        street
        city
        state
        postal_code
        country
      }
    }
    vehicle{
      vin
      make
      year
      model
      submodel
      type
    }
    broker{
      id
      name
    }
    carrier{
      id
      name
    }
    driver{
      id
      name
    }
  }
}
`
export default function getOrders() {
    return async dispatch => {
        dispatch(getOrdersStartedAction(true, 'open'));
        dispatch(getOrdersStartedAction(true, 'completed'));
        GraphqlService.SendQuery(query, {completed: false}).then(d => dispatch(getOrdersSuccesAction(d, 'open'))).catch(err => dispatch(getOrdersSuccesError(err.message, 'open')))
        GraphqlService.SendQuery(query, {completed: true}).then(d => dispatch(getOrdersSuccesAction(d, 'completed'))).catch(err => dispatch(getOrdersSuccesError(err.message, 'completed')))
    }
}

const getOrdersStartedAction = (payload, status) => ({type: OrderTypes.GET_ALL_ORDER_STARTED, payload, status});

const getOrdersSuccesAction = (payload, status) => ({
    type: OrderTypes.GET_ALL_ORDER_SUCCESS,
    payload: payload.map(x => new Order(x)),
    status
});

const getOrdersSuccesError = (payload, status) => ({type: OrderTypes.GET_ALL_ORDER_ERROR, payload, status});

