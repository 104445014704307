import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Helper from '../../../../helper/Helper';
import Content from '../../components/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
import FormGroup from '../../../../components/FormGroup/FormGroup';
import { PendingInspection } from '../../../../classes/classes';
import InspectionObservable from '../../observables/InspectionObservable';
import { useToasts } from 'react-toast-notifications';
import {gql} from 'apollo-boost';
import { GraphqlService } from '../../../../services/graphql.service';
import useLoading from "../../../../hooks/useLoading/useLoading";

const Notes = () => {
    const {token, origin} = useParams<any>();

    const [originalData, setOriginalData] = useState(new PendingInspection());
    const [currentData, setCurrentData] = useState(new PendingInspection());
    const originalDataRef = useRef<PendingInspection>(new PendingInspection());

    const backLink = `/pending-inspection/${origin}/${token}/accessories`;
    const forwardLink = `/pending-inspection/${origin}/${token}/confirmation`;

    const toast = useToasts();
    const loading = useLoading();

    useEffect(() => {
        originalDataRef.current.vehicle_data = originalData.vehicle_data;
        originalDataRef.current.note = originalData.note;
        originalDataRef.current.pending_inspection_id = originalData.pending_inspection_id;
    }, [originalData]);

    useEffect(() => {
        let query = InspectionObservable.GetObservable(token, origin?.toLowerCase() == 'true');
        
        let subs = query.onResults.subscribe(result => {
            if (result.error)
                toast.addToast(result.error.message, {appearance: 'error', autoDismiss: true})
            else
            {
                console.log('something changed');
                let o = new PendingInspection(result.data);
                o.is_origin = origin?.toLowerCase() == 'true';
                if (originalDataRef.current.note != o.note || originalDataRef.current.pending_inspection_id == null)
                {
                    setCurrentData(new PendingInspection(result.data));
                    
                    setOriginalData(o);

                    if (originalDataRef.current.pending_inspection_id != null)
                        toast.addToast(`USER has updated inspection note`, {appearance: 'warning', autoDismiss: true});
                }
            }
        });

        return () => {
            subs.unsubscribe();
            query.stopPolling();
        }
    }, []);

    async function updateNotes() {

        const word = origin?.toLowerCase() == 'true' ? 'origin' : 'destination';
        try {
            const mutation = gql`
                mutation($pending_${word}_inspection_id:Int, $note:String){
                    update_note_pending_${word}_inspection(pending_${word}_inspection_id:$pending_${word}_inspection_id, note:$note){
                        id
                        message
                        success
                    }
                }
            `;
            const variables = {[`pending_${word}_inspection_id`]: originalData.pending_inspection_id, note: currentData.note};
            loading.open('loading...');
            const data = await GraphqlService.SendQuery(mutation, variables);
            loading.close();
            console.log(data);
            return true;

            
        } catch (ex) {
            loading.close();
            toast.addToast(ex.message, {appearance: 'error', autoDismiss: true});
            return false;
        }
    }

    return <>
    <Toolbar vehicle={currentData.vehicle_data} driver_name={currentData.driver_name} />
                
                <Content>
                    <div className="mb-3">
                        <h4 className="font-11">Inspection Notes (Optional)</h4>
                    </div>
                    <div className='flex-1-container'>
                       {/* <h4 className="font-14 text-center flex-1-container justify-content-center align-items-center">CONTENT</h4>*/}
                    <   FormGroup   value={currentData.note}
                                    onTextChange={(evt) => setCurrentData({...currentData, note: evt})}
                                    textAreaHeight={'15rem'}
                                    placeholder='Inspection notes...' 
                                    type='textarea' 
                                    name='inspection_notes'
                                    />
                                       
                    </div>

                    <div className="row w-100 gx-1 mt-3">
                        <div className="col-6">
                            <button onClick={(evt) => Helper.Navigation.NavigateTo(backLink)} className="btn btn-back w-100"><i className="fas fa-chevron-left"></i> ACCESSORIES</button>
                        </div>
                       <div className="col-6">
                        <button onClick={async (evt) => {
                                if (currentData.note != originalDataRef.current.note)
                                    if (!await updateNotes()) return;

                                Helper.Navigation.NavigateTo(forwardLink);
                            }} className="btn btn-skyblue w-100">NEXT: CONFIRMATION</button>
                       </div>
                    </div>
                </Content>
            </>
}

export default Notes;