import React from 'react'
import ICONS from '../../../../../assets/svg'
import './CardHistoryInspections.scss'

const CardHistoryInspections =(props)=>{
    return(
        <>
          <div className="card mt-3 mb-3">
                <div className="card-body card-history">
                    <div className="row " >
                      {/*   <div className="col-12 d-flex fs-13 font-bold">
                            <div className="col-6 p-2">
                                <span>
                                    3/31/2021 8:12 am
                                </span>
                            </div>
                            <div className="col-6 d-flex justify-content-end p-2">
                                <span>$300.00 / $300.00</span>
                            </div>
                        </div> */}
                        <div className="col-12 d-flex ">
                            <div className="col-12 p-2 row">

                            <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-between">
                                    <span className="fs-13 font-bold">{props.inspection.vin || '-'}</span>
                                    {/* <img className="mr-2 cursor-pointer" style={{transform:'rotate(180deg)'}} src={ICONS.IconArrowLeft} alt="" /> */}
                                    </div>
                                    <span className="font-light fs-12">{props.inspection.vehicle}</span>
                                </div>

                                <div>
                                    <h4 className="font-9 d-flex align-items-center mb-1">VLX-{props.inspection.order_id} <span style={{fontSize: '1.05rem', display: 'inline-block'}} className={"ml-1 text-right text-" + (props.inspection.is_pickup ? 'green' : 'red')}>
                                        <i className={"fas fa-caret-" + (props.inspection.is_pickup ? 'up' : 'down')}></i>    
                                    </span></h4>
                                    
                                </div>
                               
                            </div>
                            {/* <div className="col-6 d-flex justify-content-end p-2">
                                <div className="d-flex align-items-center">
                                    <span className="btn rounded-pill fs-9 font-medium no-select btn-danger-outline">VIEW INSPECTION</span>
                                </div> 
                            </div> */}
                        </div>
                        <div className="col-12  d-flex">
                            <div className="col-8 p-2">
                                <div className="d-flex justify-content-between font-9 font-bold mb-1">
                                    <span>Inspection Progress</span>
                                    <span>{props.inspection.progress}%</span>
                                </div>
                                <div className="w-100  progress-content">
                                    <div className="progress-bar" style={{width: `${props.inspection.progress}%`}}></div>
                                </div>
                            </div>
                            <div className="col-4 p-2">
                                <div className="d-flex justify-content-between font-9 font-bold mb-1">
                                    <span>Uploaded Files</span>
                                    <span>{props.inspection.uploading}%</span>
                                </div>
                                <div className="w-100 progress-content">
                                    <div className="progress-bar" style={{width:`${props.inspection.uploading}%`}}></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-8 p-2">
                            <div className="row">
                                <h4 className="font-12">Uploaded Data:</h4>
                                <h4 className={`font-12 ${props.inspection.uploadedData ? 'text-green' : 'text-red'}`}>{props.inspection.uploadedData ? 'Yes' : 'No'}</h4>
                            </div>

                            <div className="row my-2">
                                <h4 className="font-12 mr-1">Uploaded Photos:</h4>
                                <h4 className={`font-12 ${props.inspection.uploadedPhotos ? 'text-green' : 'text-red'}`}>{props.inspection.uploadedPhotos ? 'Yes' : 'No'}</h4>
                            </div>

                            <div className="row">
                                <h4 className="font-12">Uploaded Singature:</h4>
                                <h4 className={`font-12 ${props.inspection.uploadedSignature ? 'text-green' : 'text-red'}`}>{props.inspection.uploadedSignature ? 'Yes' : 'No'}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CardHistoryInspections;