import React,{useState,useRef} from 'react'
import ICONS from "../../../assets/svg";
import { OrderRequest } from '../../classes/classes';
import { IOrderRequest } from '../../redux/reducers/OrderRequests.reducer';
import './CardServiceRequested.scss'


const CardServiceRequested =({data})=>{
    
    return(
        <>
            <div className="row mt-3">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <span className="font-12 font-medium-500">Requested by</span>
                </div>
            </div>

            <div className="row mt-3">
                
                <div className="col-12 card bg-light-gray ">
                    <div className="card-body d-flex flex-column">
                      
                            <span className= "font-14 font-medium-500">{data[0]?.broker?.name} <img style={{height:"14px",width:"14px"}} src={ICONS.IconConfirmBlue}/></span>

                    </div>
                </div>
            </div>

        
        </>
    )
}

export default CardServiceRequested