import Status from "../Status/Status";
import './LegDetail.scss';
import {Order} from "../../classes/classes";
import LegDetailDestination from "./LegDetailDestination";
import Helper from "../../helper/Helper";

const LegDetail = ({order, onClose}: {order: Order, onClose: () => void}) => {
    return(
        <>
            <div className="card card-leg-detail">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-start align-items-center">
                                <p className="font-16 font-medium mb-0 me-3">LG-{ order?.id }</p>
                                <Status statusName={order?.status}/>
                            </div>

                            <i className="ti-close font-12 font-medium cursor-pointer" onClick={() => onClose()}></i>
                        </div>

                        <div className="col-12 mt-3">
                            <p className="font-14 font-medium">Vehicle & trip</p>
                        </div>

                        <div className="col-6 mt-3">
                            <p className="font-12 font-medium">Vehicle</p>

                            <div className="card bg-gray">
                                <div className="card-body p-3">
                                    <p className="font-14 font-medium m-0">{ order?.vehicle?.getName() }</p>
                                    <p className="font-12 m-0">{ order?.vehicle?.vin }</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-6 mt-3">
                            <p className="font-12 font-medium">Requested by</p>

                            <div className="card bg-gray">
                                <div className="card-body p-3">
                                    <p className="font-14 font-medium m-0">{ order?.carrier?.name }</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-5">
                            <p className="font-12 font-medium">Trip</p>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 col-3 mt-3">
                            <div className="leg-trip">
                                <div className="mt-3">
                                    <p className="font-14 m-0 font-medium">{ order?.origin?.getDate() }</p>
                                    <p className="font-12 m-0">{ order?.origin?.getTime() }</p>

                                    { order?.origin?.nofail && <span className="badge-rounded badge-late">NO FAIL</span> }
                                </div>

                                <div className="">
                                    <p className="font-14 m-0 font-medium">{ order?.destination?.getDate() }</p>
                                    <p className="font-12 m-0">{ order?.destination?.getTime() }</p>

                                    { order?.destination?.nofail && <span className="badge-rounded badge-late">NO FAIL</span> }
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8 col-sm-9 col-xs-9 col-9 mt-3">
                            <LegDetailDestination destination={order?.origin}/>
                            <br/>
                            <LegDetailDestination destination={order?.destination}/>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-12 mt-3">
                            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                <div className="card w-100 bg-gray">
                                    <div className="card-body p-3">
                                        <p className="font-12 m-0 font-medium">Payment from broker for this order</p>
                                        <p className="font-12">{Helper.FORMAT.USCurrency(order?.price)}</p>

                                        <p className="font-12 m-0 font-medium">Payout to the driver</p>
                                        <p className="font-12 m-0">{Helper.FORMAT.USCurrency(order?.driver_pay)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LegDetail;
